import React, { useState, useEffect } from 'react';
import { Typography, RadioGroup, FormControlLabel, Radio } from '@material-ui/core';

import ProductTable from '../components/ProductTable';
import axios from '../utils/axios';
import Loader from '../layout/Loader';
import { Alert, AlertTitle } from '@material-ui/lab';

const NUMBER_REPORT_FIELDS = [
  { field: 'all_total', title: 'Celkový počet produktů', order: 0 },
  { field: 'draft_total', title: 'Neúplné produkty', order: 1  },
  { field: 'publishable_total', title: 'Publikované produkty', order: 3  },
  { field: 'decomissioned_total', title: 'Nepublikované produkty', order: 5  },
  { field: 'audiobooks_total', title: 'Audiobooky', order: 2  },
  { field: 'ebooks_total', title: 'Ebooky', order: 4  },
  { field: 'physicalgoods_total', title: 'Fyzické zboží', order: 6  },
  { field: 'physicalgoodsnonbooks_total', title: 'Fyzické zboží neknižní', order: 7  },
];

const INCOMPLETES_REPORT_FIELDS = [
  { field: 'missing_author', title: 'Chybí autor' },
  { field: 'missing_cover', title: 'Chybí přebal' },
  { field: 'missing_distribution_assets', title: 'Chybí distribuční assety' },
  { field: 'missing_summary', title: 'Chybí slogan' },
  { field: 'missing_description', title: 'Chybí anotace' },
];

const ProductReporting = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [serverError, setServerError] = useState(null);
  const [incompletesSelectValue, setIncompletesSelectValue] = useState(INCOMPLETES_REPORT_FIELDS[0].field);

  const loadData = async () => {
    try {
      setLoading(true);
      setServerError(null);

      const res = await axios('/reporting/product');
      setData(res.data.data);
    } catch (e) {
      setServerError(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <>
      {loading && <Loader />}
      {serverError && (
        <Alert severity="error" style={{ marginTop: 20 }}>
          <AlertTitle>Ajajaj!</AlertTitle>
          Chyba při načítání dat.
        </Alert>
      )}
      {!loading && !serverError && data && (
        <>
          <ul className="report-numbers">
            {NUMBER_REPORT_FIELDS.map((item) => (
              <li
                className={`report-numbers__row${item.order === 0 ? ' report-numbers__row--wide' : ''}`}
                key={`${item.field}`}
                style={{ order: item.order }}
              >
                <p className="report-numbers__row-title">{item.title}</p>
                <p className="report-numbers__row-value">{data[item.field]}</p>
              </li>
            ))}
          </ul>
          <div className="report-incomplete-section">
            <Typography variant="h6" style={{ marginBottom: '0.2rem' }}>
              Datově nekompletní produkty:
            </Typography>
            <RadioGroup name="incompletes-select" row value={incompletesSelectValue} onChange={(e) => setIncompletesSelectValue(e.target.value)}>
              {INCOMPLETES_REPORT_FIELDS.map((item) => (
                <FormControlLabel value={item.field} control={<Radio />} label={item.title} key={item.field} />
              ))}
            </RadioGroup>
          </div>
          <div className="reportTable">
            <p className="results">
              <strong>Počet: {data[incompletesSelectValue].total}</strong>
            </p>
            <ProductTable products={data[incompletesSelectValue].products} />
            {data[incompletesSelectValue].total > data[incompletesSelectValue].products.length && (
              <Alert severity="warning">
                Zobrazuji pouze {data[incompletesSelectValue].products.length} produktů z celkem {data[incompletesSelectValue].total} nekompletních.
              </Alert>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default ProductReporting;
