export const getCzechTypeName = (typeName) => {
  switch (typeName) {
    case 'audiobook':
      return 'audiobook';
    case 'ebook':
      return 'ebook';
    case 'physicalgood':
      return 'fyzické zboží';
    case 'physicalgoodsnonbooks':
      return 'fyzické zboží neknižní';
    default:
      return '';
  }
};

export const getCzechSubtypeName = (subtype) => {
  switch (subtype) {
    case 'book':
      return 'kniha';
    case 'media-cd':
      return 'CD';
    case 'media-cd-mp3':
      return 'CD mp3';
    case 'media-dvd':
      return 'DVD';
    case 'media-dvd-mp3':
      return 'DVD mp3';
    case 'media-mc':
      return 'MC';
    case 'bag':
      return 'taška';
    case 'other':
      return 'ostatní';
    case 'desktop-calendar':
      return 'kalendář stolní';
    case 'puzzle':
      return 'puzzle';
    case 'desk-game':
      return 'stolní hra';
    case 'book-map':
      return 'mapa knižní';
    case 'cards':
      return 'karty';
    case 'calendar-other':
      return 'kalendář ostatní';
    case 'wall-calendar':
      return 'kalendář nástěnný';
    case 'picture':
      return 'obraz';
    case 'wrap-paper':
      return 'balící papír';
    case 'globe':
      return 'globus';
    case 'diary-book':
      return 'diář knižní';
    case 'monthly-periodical':
      return 'periodikum měsíčník';
    case 'periodical-other':
      return 'periodikum ostatní';
    case 'wall-map':
      return 'mapa nástěnná';
    case 'folded-map':
      return 'mapa skládáná';
    case 'toy':
      return 'hračka';
    case 'stationery':
      return 'papírenský sortiment';
    case 'gifts':
      return 'dárkový sortiment';
    case 'unusual-cards':
      return 'netradiční karty';
    case 'home-accessories':
      return 'bytové doplňky';
    default:
      return 'neznámý podtyp';
  }
};
