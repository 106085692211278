import { Box, Grid, Paper, Typography } from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import authorEntity from "../entities/author";
import AuthorForm from "../forms/Author";
import useLocks from '../hooks/useLocks';
import Loader from "../layout/Loader";
import axios from "../utils/axios";
import formatDate4Input from "../utils/formatDate4Input";
import nullToString from "../utils/nullToString";

export default (props) => {
  let { id } = useParams();
  id = id === undefined ? "" : id;
  const apiEndpoint = "author/" + id;
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [authorItem, setAuthorItem] = useState({ ...authorEntity });
  const newAuthorItem = id === "" ? true : false;
  const { locks, lock, unlock } = useLocks('author', id);

  useEffect(() => {
    const fetchData = async () => {
      setError(false);
      setLoading(true);
      try {
        const result = await axios(apiEndpoint);
        let response = result.data.data;
        if (response && (response.first_name || response.pseudonym) && newAuthorItem === false) {
          console.log(response);
          let cleanResponse = nullToString(response);
          cleanResponse.birth_date = formatDate4Input(cleanResponse.birth_date);
          cleanResponse.death_date = formatDate4Input(cleanResponse.death_date);
          setAuthorItem(cleanResponse);
        } else {
          console.warn("Invalid data format?");
        }
      } catch (error) {
        setError(true);
      }
      setLoading(false);
    };
    fetchData();
  }, [apiEndpoint]);

  return (
    <Paper className="basePaper">
      <Grid container direction="row" justify="space-between">
        <Grid item xs={12}>
          <Typography variant="h5">{newAuthorItem === true ? "Nový autor:" : "Upravit autora:"} </Typography>
          <Box>
            {loading && <Loader />}
            {error && (
              <Alert severity="error" style={{ marginTop: 20 }}>
                <AlertTitle>Ajajaj!</AlertTitle>
                Chyba při načítání dat.
              </Alert>
            )}
          </Box>
          <AuthorForm
            key={authorItem.id}
            data={authorItem}
            locks={locks}
            onLock={lock}
            onUnLock={unlock}
          />
        </Grid>
      </Grid>
      <br />
      {/* <Alert severity="info">
        <AlertTitle>Dev notes:</AlertTitle>
        Should we use some kind of file uploader for the picture field? <br />
      </Alert> */}
    </Paper>
  );
};
