import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';

const ConfirmationDialog = ({
  open,
  title,
  text,
  onClose,
  onConfirm,
}) => (
  <Dialog open={open} keepMounted onClose={onClose}>
    <DialogTitle>
      {title}
    </DialogTitle>
    <DialogContent>
      <DialogContentText>
        {text}
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose} color='primary'>
        Ne
      </Button>
      <Button onClick={onConfirm} color='primary'>
        Ano
      </Button>
    </DialogActions>
  </Dialog>
);

export default ConfirmationDialog;
