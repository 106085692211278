import React from 'react';
import { IconButton } from '@material-ui/core';
import LockIcon from '@material-ui/icons/Lock';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import useStyles from '../styles/forms';

const Lock = ({
  isLocked = undefined,
  onToggleLock = () => {},
  hideable = true,
}) => {
  const classes = useStyles();
  return (
    <IconButton
      className={[
        classes.lockButton,
        hideable ? classes.lockButtonHideable : '',
        ...(isLocked ? [classes.lockButtonLocked] : []),
      ].join(' ')}
      size="small"
      onClick={() => onToggleLock(!isLocked)}
    >
      {isLocked ? (
        <LockIcon fontSize="small" color="primary" />
      ) : (
        <LockOpenIcon fontSize="small" />
      )}
    </IconButton>
  );
};

export default Lock;
