import MaterialTable from 'material-table';
import MTLocalization from '../utils/MTLocalization';
import React from 'react';

const CrosslinkList = ({ crosslinks }) => {
  const data = Object.keys(crosslinks).map(key => ({
    siteKey: key,
    urlValue: crosslinks[key],
  }));

  return (
    <MaterialTable
      localization={MTLocalization}
      options={{ toolbar: false, paging: false }}
      style={{ margin: 0, boxShadow: 'none' }}
      columns={[
        { title: "Klíč", field: "siteKey" },
        { title: "URL", field: "urlValue" },
      ]}
      data={data}
    />
  );
};

export default CrosslinkList;
