import { Button, Grid, makeStyles, TextField } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import AutocompleteSearch from '../../components/AutocompleteSearch';
import ChosenValuesTable from '../../components/ChosenValuesTable';
import Lock from '../../components/Lock';

const useStyles = makeStyles({
  noMargin: {
    margin: '0 !important',
  },
});

const TagList = ({ values, onValuesChange, onError, isLocked, onToggleLock }) => {
  const TAG_TABLE_VALUES = [
    { property: 'lock', caption: () => (<Lock isLocked={isLocked} onToggleLock={onToggleLock} hideable={false} />) },
    { property: 'name', caption: 'Tag' },
    { property: 'valid_from', caption: 'Platnost od', transformation: (date) => (date ? (new Date(date)).toLocaleString() : 'bez omezení') },
    { property: 'valid_until', caption: 'Platnost do', transformation: (date) => (date ? (new Date(date)).toLocaleString() : 'bez omezení') },
  ];

  const classes = useStyles();
  const [tags, setTags] = useState([]);
  const [autocompleteValue, setAutocompleteValue] = useState(null);
  const [validFromValue, setValidFromValue] = useState('');
  const [validUntilValue, setValidUntilValue] = useState('');

  useEffect(() => {
    setTags(values || []);
  }, [values]);

  useEffect(() => {
    onValuesChange(tags);
  }, [tags]);

  const handleAutocompleteValueChange = (newValue) => {
    setAutocompleteValue(newValue);
  };

  const handleSubmit = () => {
    if (autocompleteValue) {
      handleAddItem({
        ...autocompleteValue,
        valid_from: validFromValue,
        valid_until: validUntilValue,
      });
      setAutocompleteValue(null);
      setValidFromValue('');
      setValidUntilValue('');
    }
  };

  const handleValidityFromChange = (event) => {
    setValidFromValue(event.target.value);
  }

  const handleValidityUntilChange = (event) => {
    setValidUntilValue(event.target.value);
  }

  const handleAddItem = (item) => {
    if (!tags.some((p) => p.id === item.id)) {
      setTags((prevValue) => [...prevValue, item]);
    }
  };

  const handleDeleteItem = (item) => {
    if (tags.some((p) => p.id === item.id)) {
      setTags((prevValue) => prevValue.filter((prevItem) => prevItem.id !== item.id));
    }
  };

  const handleError = (e) => onError(e);

  return (
    <>
      <ChosenValuesTable description={TAG_TABLE_VALUES} values={tags} onDeleteItem={handleDeleteItem} />
      <div>
        <Grid container spacing={1}>
          <Grid item xs={5} className={classes.noMargin}>
            <AutocompleteSearch
            label="Přidat tag"
            baseUrl="/tag"
            searchBy="name"
            alreadySelected={tags}
            getOptionLabel={(option) => option.name}

            value={autocompleteValue}
            onValueChange={handleAutocompleteValueChange}
            onError={handleError}
          />
          </Grid>
          <Grid item xs={3} className={classes.noMargin}>
            <TextField
              type="datetime-local"
              fullWidth
              label="Platnost od"
              value={validFromValue}
              onChange={handleValidityFromChange}
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item xs={3} className={classes.noMargin}>
            <TextField
              type="datetime-local"
              fullWidth
              label="Platnost do"
              value={validUntilValue}
              onChange={handleValidityUntilChange}
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
        </Grid>
        <Button size="small" variant="outlined" color="primary" onClick={handleSubmit}>
          Přidat
        </Button>
      </div>
    </>
  );
};

export default TagList;
