import React from 'react';

export default (props) => {
  // console.log(props);
  let assets = props.data;
  return (
    <div className='distributionListaaa'>
      {assets &&
        assets.map((item, index) => {
          return (
            <div className='distributionItemaaa' key={index}>
              <p key={index}>
                <a href={item.storage_url}>{item.filename}</a>
              </p>
            </div>
          );
        })}
    </div>
  );
};
