import React from 'react';
import MaterialTable from 'material-table';

import { READ, Can, PRODUCT } from '../permissions';
import { subject } from '@casl/ability';
import { Button } from '@material-ui/core';

const ProductTable = ({ products, addedColumns }) => {
  const columns = [
    { title: 'Název', field: 'title', render: (rowData) => <strong>{rowData.title}</strong> },
    { title: 'Id', field: 'id' },
    ...(addedColumns ? addedColumns : []),
    {
      width: 200,
      title: '',
      render: (rowData) => (
        <Can I={READ} a={subject(PRODUCT, rowData)}>
          {() => (
            <Button href={`/product/${rowData.id}`} variant="outlined" color="primary">
              Detail produktu
            </Button>
          )}
        </Can>
      ),
    },
  ];

  // === RENDER ===

  return <MaterialTable options={{ paging: false, toolbar: false }} style={{ margin: 0, boxShadow: 'none' }} columns={columns} data={products} />;
};

export default ProductTable;
