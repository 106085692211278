import React from 'react';
import { Button, makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  actions: {
    display: 'flex',
    flexDirection: 'row',
  },
});

const ProductStateActions = ({
  publishable,
  decomissioned,
  onPublishToggle,
  onDecomissionToggle,
}) => {
  const classes = useStyles();

  const getActions = (published, decomissioned) => {
    if (decomissioned) return (
      <Button
        size="small"
        variant="outlined"
        color="secondary"
        onClick={() => onDecomissionToggle(false)}
      >
        Zrušit vyřazení z prodeje
      </Button>
    );

    if (published) return (
      <>
        <Button
          size="small"
          variant="outlined"
          color="secondary"
          onClick={() => onPublishToggle(false)}
        >
          Označit jako neúplný
        </Button>
        <Button
          size="small"
          variant="outlined"
          color="secondary"
          onClick={() => onDecomissionToggle(true)}
        >
          Vyřadit z prodeje
        </Button>
      </>
    );

    return (
      <>
        <Button
          size="small"
          variant="outlined"
          color="secondary"
          onClick={() => onPublishToggle(true)}
        >
          Publikovat
        </Button>
        <Button
          size="small"
          variant="outlined"
          color="secondary"
          onClick={() => onDecomissionToggle(true)}
        >
          Vyřadit z prodeje
        </Button>
      </>
    );
  };

  return (
    <div className={classes.actions}>
      {getActions(publishable, decomissioned)}
    </div>
  );
};

export default ProductStateActions;
