import React from 'react';
import { makeStyles } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/CheckCircle';
import WarningIcon from '@material-ui/icons/Warning';
import ErrorIcon from '@material-ui/icons/Error';

const useStyles = makeStyles({
  indicator: {
    display: 'inline-flex',
    padding: '15px',
  },
  indicatorDecomissioned: {
    color: 'rgb(97, 26, 21)',
    backgroundColor: 'rgb(253, 236, 234)',
  },
  indicatorPublished: {
    color: 'rgb(30, 70, 32)',
    backgroundColor: 'rgb(237, 247, 237)',
  },
  indicatorDraft: {
    color: 'rgb(102, 60, 0)',
    backgroundColor: 'rgb(255, 244, 229)',
  },
  indicatorText: {
    margin: '0',
    marginLeft: '10px',
  },
});

const ProductState = ({
  publishable,
  decomissioned,
}) => {
  const classes = useStyles();

  if (decomissioned) return (
    <div className={`${classes.indicator} ${classes.indicatorDecomissioned}`}>
      <ErrorIcon />
      <p className={classes.indicatorText}>
        Nepublikovaný
      </p>
    </div>
  );

  if (publishable) return (
    <div className={`${classes.indicator} ${classes.indicatorPublished}`}>
      <CheckIcon />
      <p className={classes.indicatorText}>
        Publikovaný
      </p>
    </div>
  );

  return (
    <div className={`${classes.indicator} ${classes.indicatorDraft}`}>
      <WarningIcon />
      <p className={classes.indicatorText}>
        Neúplný
      </p>
    </div>
  );
};

export default ProductState;