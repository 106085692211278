const productEntity = {
  id: "",
  external_reference_key: "",
  type: "",
  subtype: "",
  project: null, // POST / PUT only !!!
  project_id: "", // GET
  project_bundle: null, // POST / PUT only !!!
  bundle_id: "", // GET
  isbn: "",
  ean: "",
  age_limit: 0,
  language: "cs",
  publisher: "" /* {
    id: "",
    name: "",
    external_reference_key: "",
    is_external: false,
    is_publishing_house: false,
    business_id: 0,
  } */,
  brand: "" /*  {
    id: "",
    name: "",
    external_reference_key: "",
    is_external: false,
    is_publishing_house: false,
    business_id: 0,
  } */,
  title: "",
  subtitle: "",
  original_title: "",
  summary: "",
  description: "",
  authors: [],
  create_date: "",
  modify_date: "",
  series: [],
  genres: [],
  editions: [],
  categories: [],
  tags: [],
  first_publish_date: "",
  publish_date: "",
  is_publishable: false,
  distributable_from: "",
  distributable_until: "",
  distribution_region: "cs_cz",

  stock_availability: false,
  video_sample_url: "",
  presale_valid_from: "",
  presale_valid_until: "",

  // All type-specific attributes
  spec_length: "", // [audio]– audio book length in seconds
  'spec_ean-mp3': '',
  'spec_ean-cd': '',

  "spec_total-pages": "", // [ebook, physicalgood] – number of pages
  "spec_release-order": "", // [ebook, physicalgood] – pořadí vydání v jazyce (int)

  // spec_ebook_type: "", // [ebook] – the type of the ebook _ epub/mobi
  // spec_ebook_size: "", // [ebook] – size of the digital asset
  "spec_ean-mobi": "",
  "spec_ean-pdf": "",
  "spec_ean-epub": "",
  "spec_isbn-mobi": "",
  "spec_isbn-pdf": "",
  "spec_isbn-epub": "", // [ebook]

  spec_weight: "", // [physicalgood] – weight of the physicalgood in grams
  spec_width: "",
  spec_height: "",
  spec_depth: "",
  "spec_binding-type": "", // [physicalgood]
  "spec_expedition-date": "", // [physicalgood]
  "spec_reprint-date": "", // [physicalgood]
  "spec_stock-availability-count": "", // [physicalgood]
  "spec_stock-availability-central-count": "", // [physicalgood]

  // All consumer-specific attributes
  spec_title: "", // – Název, Obousměrný sync do specifických atributů
  spec_subtitle: "", // – Podtitul, Obousměrný sync do specifických atributů
  spec_description: "", // – Anotace, Obousměrný sync do specifických atributů
  spec_summary: "", // – Claim, Obousměrný sync do specifických atributů
};
export default productEntity;
