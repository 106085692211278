import { makeStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import AutocompleteSearch from '../../components/AutocompleteSearch';
import axios from '../../utils/axios';

const useStyles = makeStyles({
  container: {
    display: 'inline-block',
    maxWidth: 'calc(50% - 40px)',
    minWidth: 'calc(50% - 40px)',
    marginTop: '16px',
    marginRight: '40px',
    marginBottom: '0',
  },
});

const IdAutocompleteField = ({
  valueId,
  onValueChange,
  onError,
  baseUrl,
  label,
  searchBy,
  searchQuery = {},
  disabled = false,
  disabledIds = [],
  className = undefined,
  inputClassName = undefined,
}) => {
  const classes = useStyles();

  const [autocompleteValue, setAutocompleteValue] = useState(null);

  useEffect(() => {
    if (valueId) {
      loadValue(valueId);
    } else {
      setAutocompleteValue(null);
    }
  }, [valueId]);

  useEffect(() => {
    onValueChange(autocompleteValue);
  }, [autocompleteValue]);

  const loadValue = (itemId) => {
    const doFetch = async () => {
      try {
        const result = await axios(`${baseUrl}/${itemId}`);
        setAutocompleteValue(result.data.data);
      } catch (e) {
        onError(e);
      }
    };

    doFetch();
  }

  const handleAutocompleteValueChange = (newValue) => {
    setAutocompleteValue(newValue);
  };

  const handleError = (e) => onError(e);

  return (
    <div className={className || classes.container}>
      <AutocompleteSearch
        inputClassName={inputClassName}
        label={label}
        baseUrl={baseUrl}
        searchBy={searchBy}
        searchQuery={searchQuery}
        alreadySelected={disabledIds.map(item => ({ id: item }))}
        identityAttribute="id"
        getOptionLabel={(option) => option[searchBy]}
        value={autocompleteValue}
        disabled={disabled}
        onValueChange={handleAutocompleteValueChange}
        onError={handleError}
      />
    </div>
  );
};

export default IdAutocompleteField;
