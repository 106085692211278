import AddCircleOutlineRoundedIcon from "@material-ui/icons/AddCircleOutlineRounded";
import { Box, Button, Link, Paper, Typography, TablePagination, makeStyles } from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import MaterialTable from "material-table";
import MTLocalization from "../utils/MTLocalization";
import React, { useEffect, useState } from "react";
import Loader from "../layout/Loader";
import axios from "../utils/axios";
import formatDate from "../utils/formatDate";
import { Can, UPDATE, CREATE, AUTHOR } from "../permissions";
import { subject } from "@casl/ability";
import { getSortString, getSortReadyColumns } from '../utils/tableSortUtils';

import WarningIcon from '@material-ui/icons/Warning';
import AdvancedSearch from '../components/AdvancedSearch';
import AuthorSearchFilter, { INITIAL_FILTER_VALUE, LABELS } from '../components/AuthorSearchFilter';

const SORT_FIELDS = {
  name: 'name',
  external_reference_key: 'externalReferenceKey',
  is_blacklisted: 'isBlacklisted',
};
const AUTHOR_FILTER = 'author-filter';

const useStyles = makeStyles({
  masterIndicator: {
    textTransform: 'uppercase',
    color: '#B9012B',
    fontWeight: 'bold',
  },
  missingMasterIndicator: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '3px',
    fontWeight: 'bold',
    color: '#db8400',
    textTransform: 'uppercase',
  },
  missingMasterIndicatorIcon: {
    marginRight: '10px',
  },
  masterAuthorId: {
    fontSize: '0.7rem',
  },
});

export default (props) => {
  const classes = useStyles();
  const [state, setState] = useState(null);

  // -- Data loading and pagination
  const [response, setResponse] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setDataLoading] = useState(false);
  const [error, setDataError] = useState(null);
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const [filter, setFilter] = useState(null);
  const [sort, setSort] = useState(null)

  const loadAuthorData = (page = currentPage, size = pageSize, currentFilter = filter, sortDesc = sort) => {
    const doFetch = async () => {
      setDataLoading(true);
      try {
        const query = {
          page: page + 1,
          limit: size,
          ...processFilterQueryToSend(currentFilter),
          ...(sortDesc && { sort: getSortString(sortDesc, SORT_FIELDS) }),
        };
        const res = await axios('/author', { params: query });
        setResponse(res.data);
        setTotalCount(res.data.meta.total_number);
      } catch (e) {
        setDataError(e);
      } finally {
        setDataLoading(false);
      }
    };

    doFetch();
  };

  const handlePageChange = (newPage, newPageSize) => {
    setCurrentPage(newPage);
    setPageSize(newPageSize);
    loadAuthorData(newPage, newPageSize);
  }

  const handleFilterChange = (value) => {
    setCurrentPage(0);
    setFilter(value);
    loadAuthorData(0, pageSize, value);
    preserveFilter(value);
  }

  const handleFilterReset = () => {
    handleFilterChange(null);
  }

  const preserveFilter = (filterQuery) => {
    if (filterQuery) {
      const stringified = JSON.stringify(filterQuery);
      window.localStorage.setItem(AUTHOR_FILTER, stringified);
    } else {
      window.localStorage.removeItem(AUTHOR_FILTER);
    }
  };

  const processFilterQueryToSend = (filterQuery) => {
    if (!filterQuery) return {};

    const { master, ...rest } = filterQuery;

    const getMasterStatusQuery = (requestedState) => {
      if (requestedState === 'master') return { master: '1' };
      if (requestedState === 'non-master') return { master: '0' };
      if (requestedState === 'not-assigned-master') return { assignedMaster: '0' };
      return {};
    };

    return {
      ...rest,
      ...getMasterStatusQuery(master),
    };
  }

  const handleOrderChange = (columnIdx, order) => {
    const sort = columnIdx >= 0 ? { field: state.columns[columnIdx].field, order: order } : null;
    
    setCurrentPage(0);
    setSort(sort);
    loadAuthorData(0, pageSize, filter, sort);
  }

  // runs on load only
  useEffect(() => {
    loadAuthorData();

    const existingFilterString = window.localStorage.getItem(AUTHOR_FILTER);
    if (existingFilterString) {
      const filter = JSON.parse(existingFilterString);
      setFilter(filter);
      loadAuthorData(currentPage, pageSize, filter);
    } else {
      loadAuthorData();
    }
  }, []);

  useEffect(() => {
    if (response && response.status === 200) {
      console.log(response);
      const newData = {
        columns: [
          {
            title: "Jméno",
            field: "name",
            render: (rowData) => <a href={`/author/${rowData.id}`}>{rowData.name}</a>,
          },
          {
            title: "Master",
            render: (rowData) => {
              if (rowData.is_master) {
                return (
                  <span className={classes.masterIndicator}>
                    master
                  </span>
                );
              }
              if (rowData.master_author_id) {
                return (
                  <span className={classes.masterAuthorId}>
                    {rowData.master_author_id}
                  </span>
                );
              }
              return (
                <span className={classes.missingMasterIndicator}>
                  <WarningIcon className={classes.missingMasterIndicatorIcon} />
                  chybí
                </span>
              );
            },
          },
          {
            title: "Datum narození",
            field: "birth_date",
            render: (rowData) => formatDate(rowData.birth_date),
          },
          { title: "Externí klíč", field: "external_reference_key", render: (rowData) => <span className="smallFont">{rowData.external_reference_key}</span> },
          { 
            title: 'Na blacklistu',
            field: 'is_blacklisted',
            lookup: { false: 'ne', true: 'ano' },
          },
          {
            width: 100,
            title: "",
            field: "action",
            render: (rowData) => (
              <Can I={UPDATE} an={subject(AUTHOR, rowData)}>
                {() => <Button href={`/author/edit/${rowData.id}`} variant="outlined" color="primary">
                  Upravit
                </Button>}
              </Can>
            ),
          },
        ],
        data: [...response.data],
      };
      setState(newData);
    }
  }, [response]);

  return (
    <Paper className="basePaper">
      <Typography variant="h5">
        Autoři &nbsp;
        <Can I={CREATE} an={AUTHOR}>
          {() => <Link href="/author/new/">
            <AddCircleOutlineRoundedIcon className="createNew" />
          </Link>}
        </Can>
      </Typography>
      <Box>
        {loading && <Loader />}
        {error && (
          <Alert severity="error" style={{ marginTop: 20 }}>
            <AlertTitle>Ajajaj!</AlertTitle>
            Chyba při načítání dat.
          </Alert>
        )}
      </Box>
      <AdvancedSearch 
        onFilterSubmit={handleFilterChange}
        onFilterReset={handleFilterReset}
        filterValue={filter}
        initialFilter={INITIAL_FILTER_VALUE}
        filterLabels={LABELS}
        filterFormComponent={AuthorSearchFilter}
      />
      {state && (
        <MaterialTable
          key={pageSize} // workaround for not functioning page size change
          localization={MTLocalization}
          options={{ pageSize: pageSize, addRowPosition: "first", toolbar: false }}
          style={{ margin: 0, boxShadow: "none" }}
          className="MuiTableContainer"
          columns={getSortReadyColumns(state.columns, Object.keys(SORT_FIELDS), sort)}
          data={state.data}
          onOrderChange={handleOrderChange}
          components={{
            Pagination: props => <TablePagination {...props}
              rowsPerPageOptions={[5, 10, 20, 30]}
              rowsPerPage={pageSize}
              count={totalCount}
              page={currentPage}
              onChangePage={(e, page) => handlePageChange(page, pageSize)}
              onChangeRowsPerPage={(e) => handlePageChange(currentPage, e.target.value)}
            />
          }}
        />
      )}
    </Paper>
  );
};
