import React from 'react';
import { Tabs, Tab, Paper, Typography } from '@material-ui/core';

import TabPanel from '../layout/TabPanel';
import UserTable from '../components/UserTable';
import { READ, AbilityContext, USER, CONSUMER_ENTITY, TAG } from '../permissions';
import { useAbility } from '@casl/react';
import ConsumerTable from '../components/ConsumerTable';
import TagTable from '../components/TagTable';

const getInitialTab = (ability) => {
  if (ability.can(READ, USER)) return 0;
  if (ability.can(READ, CONSUMER_ENTITY)) return 1;
  if (ability.can(READ, TAG)) return 2;
  return 3;
}

export default (props) => {
  const ability = useAbility(AbilityContext);
  const [tabValue, setTabValue] = React.useState(getInitialTab(ability));
  
  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <Paper className="basePaper">
      <Typography variant="h5">Nastavení</Typography>
      <br />
      <Tabs value={tabValue} onChange={handleChange} indicatorColor="primary" textColor="primary">
        <Tab label='Správa uživatelů' disabled={!ability.can(READ, USER)} />
        <Tab label='Správa consumerů' disabled={!ability.can(READ, CONSUMER_ENTITY)} />
        <Tab label='Správa tagů' disabled={!ability.can(READ, TAG)} />
        <Tab label='Ostatní' />
      </Tabs>
      <TabPanel value={tabValue} index={0} className="productAttributes">
        <UserTable />
      </TabPanel>
      <TabPanel value={tabValue} index={1} className="productAttributes">
        <ConsumerTable />
      </TabPanel>
      <TabPanel value={tabValue} index={2} className="productAttributes">
        <TagTable />
      </TabPanel>
      <TabPanel value={tabValue} index={3} className="productAttributes">
        <>
          Další nastavení...
          <br />
        </>
      </TabPanel>
    </Paper>
  );
};
