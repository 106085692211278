import { Button, TextField, FormControl, Input, InputLabel, Checkbox, NativeSelect, Box } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Alert } from '@material-ui/lab';
import React, { useState, useEffect, useContext } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import authorEntity from '../entities/author';
import Loader from '../layout/Loader';
import useStyles from '../styles/forms';
import axios from '../utils/axios';
import nullFromString from '../utils/nullFromString';
import AuthorImage from '../forms/AuthorImage';
import Modal from '@material-ui/core/Modal';
import ServerResponseAlert from '../components/ServerResponseAlert';
import IdAutocompleteField from './fields/IdAutocompleteField';
import { AuthorLockColumn } from '../hooks/useLocks';
import FormField from './fields/FormField';
import { UserContext } from '../permissions';

export default (props) => {
  const myData = props.data;
  const locks = props.locks;
  console.log(myData);
  const classes = useStyles();
  let { id } = useParams();
  id = id === undefined ? '' : id;
  // console.log(id);
  const userInfo = useContext(UserContext);
  const apiEndpoint = 'author/';
  const [loading, setLoading] = useState(false);
  const [authorItem, setAuthorItem] = useState({ ...authorEntity, ...myData });
  const newAuthorItem = id === '' ? true : false;

  // getModalStyle is not a pure function, we roll the style only on the first render
  const [FUopen, setFUopen] = useState(false);
  const handleFUopen = () => {
    setFUopen(true);
  };
  const handleFUClose = () => {
    setFUopen(false);
  };
  console.log(myData.pseudonym);
  const [pseudonymState, setPseudonym] = useState(myData.pseudonym);
  console.log(pseudonymState);

  const [serverSuccess, setServerSuccess] = useState(null);
  const [serverReply, setServerReply] = useState(false);
  const methods = useForm({ defaultValues: { ...myData } });
  const { handleSubmit, register, control, getValues, setValue, watch } = methods;
  const onSubmit = (data) => {
    // console.log(data);
    setLoading(true);
    const submitData = async () => {
      setLoading(true);
      try {
        // let newBody = { ...authorItem, name: authorItem.first_name + " " + authorItem.middle_name + " " + authorItem.last_name };
        let newBody = {
          ...myData,
          ...data,
          name: data.first_name + ' ' + data.middle_name + ' ' + data.last_name,
          birth_date: new Date(data.birth_date),
          death_date: new Date(data.death_date),
          is_blacklisted: data.is_blacklisted === 'true',
        };
        let cleanBody = nullFromString(newBody);
        cleanBody = JSON.stringify(cleanBody);
        // console.log(cleanBody);
        const response = newAuthorItem ? await axios.post(apiEndpoint, cleanBody) : await axios.put(apiEndpoint + '/' + id, cleanBody);
        const responseJson = response.data;
        // created
        setServerSuccess(true);
        setServerReply('V pořádku uloženo.');
        setorigValues({ ...origValues, ...responseJson.data });
        console.log(responseJson.data);
        const keys = Object.keys(responseJson.data);
        for (const key of keys) {
          console.log(key, responseJson.data[key]);
          setValue(key, responseJson.data[key]);
        }
        if (newAuthorItem === true && responseJson.data.id) {
          window.location.href = '/author/edit/' + responseJson.data.id;
        }

        console.log('Success');
        setLoading(false);
      } catch (err) {
        console.log('Chyba při ukládání. ' + err);
        setServerSuccess(false);
        setServerReply(err);
        window.scrollTo({ top: 0, behavior: 'smooth' });
        setLoading(false);
      }
    };
    submitData();
  };

  const [origValues, setorigValues] = useState(false);
  const leaveIfYouCan = () => {
    const values = getValues();
    console.log(origValues);
    console.log(values);
    const changeDetected = JSON.stringify(origValues) !== JSON.stringify(values);
    console.log(changeDetected);
    if (changeDetected) {
      setOpen(true);
    } else {
      confirmLeave();
    }
  };
  const [open, setOpen] = useState(false);
  const confirmLeave = () => {
    window.location.href = `/author/${id}`;
  };
  useEffect(() => {
    const values = getValues();
    setorigValues(values);
    console.log(values);
    // setPseudonym(values.pseudonym);
  }, [getValues]);
  useEffect(() => {
    console.log(pseudonymState);
  }, [pseudonymState]);
  const handleClose = () => {
    setOpen(false);
  };

  /*   useEffect(() => {
    console.log(errors);
  }, [errors]); */
  const [open2, setOpen2] = useState(false);
  const deleteAuthorConfirm = () => {
    setOpen(true);
  };
  const deleteAuthor = async () => {
    // disable button
    setLoading(true);
    const submitData = async () => {
      try {
        const response = await axios.delete(apiEndpoint + id);
        const data = response.data;
        // created
        setServerSuccess(true);
        setServerReply('Successfully deleted.');
        setAuthorItem(null);
        console.log('Success');
        setLoading(false);
      } catch (err) {
        setServerSuccess(false);
        setServerReply('Error deleting data. ' + err.message);
        console.log('Error deleting data. ' + err.message);
        setLoading(false);
      }
    };
    submitData();
    setOpen(false);
  };

  const toggleLock = async (resultLockedState, fieldName) => {
    try {
      await (resultLockedState ? props.onLock({ fieldName, userId: userInfo.id }) : props.onUnLock({ fieldName }));
    } catch (e) {
      setServerSuccess(false)
      setServerReply(e);
    }
  }

  const handleMasterStatusChange = (value) => {
    if (value) {
      setValue('master_author_id', null);
    }
  };

  const handleError = (e) => {
    setServerSuccess(false);
    setServerReply(e);
  };

  return (
    <>
      <Dialog open={open} keepMounted onClose={handleClose} aria-labelledby="alert-dialog-slide-title" aria-describedby="alert-dialog-slide-description">
        <DialogTitle id="alert-dialog-slide-title">Skartovat změny</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">Tato akce nelze vrátit. Přejete si pokračovat?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            ne
          </Button>
          <Button onClick={confirmLeave} color="primary">
            Ano
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={open2} keepMounted onClose={handleClose} aria-labelledby="alert-dialog-slide-title" aria-describedby="alert-dialog-slide-description">
        <DialogTitle id="alert-dialog-slide-title">Delete author</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">This action is irreversible. Are you sure you want to continue?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={deleteAuthor} color="primary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      {loading && <Loader />}
      {serverReply && (
        <>
          <br />
          <ServerResponseAlert
            serverSuccess={serverSuccess}
            serverReply={serverReply}
          />
          <br />
        </>
      )}
      {authorItem && (
        <form onSubmit={handleSubmit(onSubmit)}>

          <FormField size="small" isLocked={!!locks[AuthorLockColumn.FIRST_NAME]} onToggleLock={(locked) => toggleLock(locked, AuthorLockColumn.FIRST_NAME)}>
            <Controller
              as={TextField}
              className={classes.inputBase}
              inputRef={register({ required: pseudonymState === '' })}
              label="Jméno"
              name="first_name"
              control={control}
            />
          </FormField>
          <FormField size="small" isLocked={!!locks[AuthorLockColumn.MIDDLE_NAME]} onToggleLock={(locked) => toggleLock(locked, AuthorLockColumn.MIDDLE_NAME)}>
            <Controller
              as={TextField}
              className={classes.inputBase}
              inputRef={register({})}
              label="Druhé jméno"
              name="middle_name"
              control={control}
            />
          </FormField>
          <FormField size="small" isLocked={!!locks[AuthorLockColumn.LAST_NAME]} onToggleLock={(locked) => toggleLock(locked, AuthorLockColumn.LAST_NAME)}>
            <Controller
              as={TextField}
              className={classes.inputBase}
              inputRef={register({ required: pseudonymState === '' })}
              label="Příjmení"
              name="last_name"
              control={control}
            />
          </FormField>
          <br />
          <FormField size="small" isLocked={!!locks[AuthorLockColumn.TITLE_BEFORE]} onToggleLock={(locked) => toggleLock(locked, AuthorLockColumn.TITLE_BEFORE)}>
            <Controller
              as={TextField}
              className={classes.inputBase}
              inputRef={register({})}
              label="Titul před"
              name="title_before"
              control={control}
            />
          </FormField>
          <FormField size="small" isLocked={!!locks[AuthorLockColumn.TITLE_AFTER]} onToggleLock={(locked) => toggleLock(locked, AuthorLockColumn.TITLE_AFTER)}>
            <Controller
              as={TextField}
              className={classes.inputBase}
              inputRef={register({})}
              label="Titul za jménem"
              name="title_after"
              control={control}
            />
          </FormField>
          <FormField size="small" isLocked={!!locks[AuthorLockColumn.PSEUDONYM]} onToggleLock={(locked) => toggleLock(locked, AuthorLockColumn.PSEUDONYM)}>
            <input style={{ display: 'none' }} control={control} name="pseudonym" value={pseudonymState} readOnly={true} ref={register({})} />
            <FormControl className={classes.inputBase}>
              <InputLabel id="typ-label">Pseudonym</InputLabel>
              <Input
                {...props}
                name="pseudonymState"
                value={pseudonymState}
                onChange={(ev) => {
                  setPseudonym(ev.target.value);
                }}
              />
            </FormControl>
          </FormField>
          <br />
          <FormField size="small" isLocked={!!locks[AuthorLockColumn.IS_MASTER]} onToggleLock={(locked) => toggleLock(locked, AuthorLockColumn.IS_MASTER)}>
            <FormControl className={classes.inputBase}>
              <InputLabel htmlFor="is_master">Master</InputLabel>
              <Controller
                control={control}
                name="is_master"
                render={({ onChange, value, ...props }) => (
                  <NativeSelect
                    {...props}
                    onChange={(e) => {
                      const value = e.target.value === 'true';
                      handleMasterStatusChange(value);
                      onChange(value);
                    }}
                    value={value ? 'true' : 'false'}
                    inputProps={{
                      name: 'is_master',
                      id: 'is_master',
                    }}
                  >
                    <option value="false">ne</option>
                    <option value="true">ano</option>
                  </NativeSelect>
                )}
              />
            </FormControl>
          </FormField>
          <FormField isLocked={!!locks[AuthorLockColumn.MASTER_AUTHOR]} onToggleLock={(locked) => toggleLock(locked, AuthorLockColumn.MASTER_AUTHOR)}>
            <Controller
              control={control}
              name="master_author_id"
              render={({ onChange, value }) => (
                <IdAutocompleteField
                  className={classes.inputBase}
                  inputClassName={classes.innerInputBase}
                  valueId={value}
                  onValueChange={(value) => onChange(value ? value.id : null)}
                  onError={handleError}
                  baseUrl="/author"
                  label="Master autor"
                  searchBy="name"
                  searchQuery={{ master: 1 }}
                  disabled={watch('is_master')}
                  disabledIds={authorItem.id ? [authorItem.id] : []}
                />
              )}
            />
          </FormField>
          <br />
          <FormField size="small" isLocked={!!locks[AuthorLockColumn.BIRTH_DATE]} onToggleLock={(locked) => toggleLock(locked, AuthorLockColumn.BIRTH_DATE)}>
            <Controller
              as={TextField}
              className={classes.inputBase}
              inputRef={register({})}
              type="date"
              label="Datum narození"
              name="birth_date"
              control={control}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </FormField>
          <FormField size="small" isLocked={!!locks[AuthorLockColumn.DEATH_DATE]} onToggleLock={(locked) => toggleLock(locked, AuthorLockColumn.DEATH_DATE)}>
            <Controller
              as={TextField}
              className={classes.inputBase}
              inputRef={register({})}
              type="date"
              label="Datum umrtí"
              name="death_date"
              control={control}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </FormField>
          <FormField isLocked={!!locks[AuthorLockColumn.EXTERNAL_REFERENCE_KEY]} onToggleLock={(locked) => toggleLock(locked, AuthorLockColumn.EXTERNAL_REFERENCE_KEY)}>
            <Controller
              as={TextField}
              className={classes.inputBase}
              inputRef={register({})}
              label="Externní klíč"
              name="external_reference_key"
              control={control}
            />
          </FormField>
          <br />

          
          <FormField size="large" isLocked={!!locks[AuthorLockColumn.IMAGE]} onToggleLock={(locked) => toggleLock(locked, AuthorLockColumn.IMAGE)}>
            <div className={classes.imageBase}>
              {newAuthorItem ? (
                <p>Pro nahrání souboru nejdříve autora uložte.</p>
              ) : (
                <>
                  <Box>
                    {myData.picture_url && (
                      <img src={myData.picture_url} className="authorImage" />
                    )}
                    {myData.picture_external_reference_key && (
                      <p>
                        <strong>Externí klíč obrázku:</strong>
                        {' '}
                        {myData.picture_external_reference_key}
                      </p>
                    )}
                  </Box>
                  <Button
                    onClick={() => {
                      handleFUopen();
                    }}
                    variant="outlined"
                    color="secondary"
                    size="small"
                  >
                    {myData.picture_url ? 'Změnit obrázek' : 'Nahrát obrázek'}
                  </Button>
                </>
              )}
            </div>
          </FormField>
          
          
          <br />
          <FormField size="large" isLocked={!!locks[AuthorLockColumn.SUMMARY]} onToggleLock={(locked) => toggleLock(locked, AuthorLockColumn.SUMMARY)}>
            <Controller
              as={TextField}
              fullWidth
              multiline
              className={classes.inputBase}
              inputRef={register({})}
              label="Stručný popis"
              name="summary"
              control={control}
            />
          </FormField>
          <br />
          <FormField size="large" isLocked={!!locks[AuthorLockColumn.BIOGRAPHY]} onToggleLock={(locked) => toggleLock(locked, AuthorLockColumn.BIOGRAPHY)}>
            <Controller
              as={TextField}
              fullWidth
              multiline
              className={classes.inputBase}
              inputRef={register({})}
              label="Biografie"
              name="biography"
              control={control}
            />
          </FormField>
          <br />
          <br />
          <FormField isLocked={!!locks[AuthorLockColumn.WWW]} onToggleLock={(locked) => toggleLock(locked, AuthorLockColumn.WWW)}>
            <Controller
              as={TextField}
              className={classes.inputBase}
              inputRef={register({})}
              label="URL www stránek"
              name="website_url"
              control={control}
            />
          </FormField>
          <FormField isLocked={!!locks[AuthorLockColumn.TWITTER]} onToggleLock={(locked) => toggleLock(locked, AuthorLockColumn.TWITTER)}>
            <Controller
              as={TextField}
              className={classes.inputBase}
              inputRef={register({})}
              label="Twitter"
              name="twitter_url"
              control={control}
            />
          </FormField>
          <FormField isLocked={!!locks[AuthorLockColumn.FACEBOOK]} onToggleLock={(locked) => toggleLock(locked, AuthorLockColumn.FACEBOOK)}>
            <Controller
              as={TextField}
              className={classes.inputBase}
              inputRef={register({})}
              label="Facebook"
              name="facebook_url"
              control={control}
            />
          </FormField>
          <FormField isLocked={!!locks[AuthorLockColumn.INSTAGRAM]} onToggleLock={(locked) => toggleLock(locked, AuthorLockColumn.INSTAGRAM)}>
            <Controller
              as={TextField}
              className={classes.inputBase}
              inputRef={register({})}
              label="Instagram"
              name="instagram_url"
              control={control}
            />
          </FormField>
          <FormField isLocked={!!locks[AuthorLockColumn.YOUTUBE]} onToggleLock={(locked) => toggleLock(locked, AuthorLockColumn.YOUTUBE)}>
            <Controller
              as={TextField}
              className={classes.inputBase}
              inputRef={register({})}
              label="Youtube"
              name="youtube_url"
              control={control}
            />
          </FormField>
          <br />
          <br />
          <FormField size="small" isLocked={!!locks[AuthorLockColumn.IS_BLACKLISTED]} onToggleLock={(locked) => toggleLock(locked, AuthorLockColumn.IS_BLACKLISTED)}>
            <FormControl className={classes.inputBase}>
              <InputLabel htmlFor="is_blacklisted">Na blacklistu</InputLabel>
              <Controller
                inputRef={register()}
                control={control}
                name="is_blacklisted"
                defaultValue={authorItem.is_blacklisted ? 'true' : 'false'}
                as={
                  <NativeSelect
                    inputProps={{
                      name: 'is_blacklisted',
                      id: 'is_blacklisted',
                    }}
                  >
                    <option value="false">ne</option>
                    <option value="true">ano</option>
                  </NativeSelect>
                }
              ></Controller>
            </FormControl>
          </FormField>
          <br />
          <br />
          <Button size="large" variant="outlined" color="primary" onClick={leaveIfYouCan}>
            Zpět na detail
          </Button>
          <Button size="large" variant="contained" color="primary" type="submit">
            Uložit
          </Button>{' '}
          &nbsp;
          {/*           {!newAuthorItem && (
            <Button size="large" variant="outlined" color="primary" onClick={deleteAuthorConfirm}>
              Delete
            </Button>
          )} */}
          <br />
        </form>
      )}
      <Modal open={FUopen} onClose={handleFUClose} aria-labelledby="simple-modal-title" aria-describedby="simple-modal-description">
        <AuthorImage externalKey={myData.picture_external_reference_key} productId={id} />
      </Modal>
    </>
  );
};
