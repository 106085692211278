import {
  Paper,
  Typography,
  Button,
  makeStyles,
  DialogActions,
  FormGroup,
  DialogContent, DialogTitle, Dialog, Checkbox
} from '@material-ui/core';
import React, { useState } from "react";
import axios from "../utils/axios";
import Loading from "../layout/Loader";
import AutocompleteSearch from '../components/AutocompleteSearch';
import ServerResponseAlert from '../components/ServerResponseAlert';
import MultitreeMappingTable from '../components/MultitreeMappingTable';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const useStyles = makeStyles({
  actionContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  inputsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingBottom: '20px',
  },
  input: {
    width: '40%',
  },
  alert: {
    marginTop: '20px',
  },
});

const Multitree = () => {
  const classes = useStyles();

  const [sourceTree, setSourceTree] = useState(null);
  const [targetTree, setTargetTree] = useState(null);
  const [sourceTreeData, setSourceTreeData] = useState(null);
  const [targetTreeData, setTargetTreeData] = useState(null);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // used for delete dialog
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [deletedSourceBranchId, setSourceTargetBranchId] = useState(null);
  const [deletedTargetBranchId, setDeletedTargetBranchId] = useState(null);
  const [deleteCallback, setDeleteCallback] = useState(null);

  // ========== CREATE TREE ==========

  const getTreeFromNodes = (nodes) => {
    // for each node find its parent and assign itself as a child of said parent
    // thanks to shallow copies the references form a tree in background
    const nodesWithChildren = nodes.map(item => ({ ...item, children: [] }));
    nodesWithChildren.forEach(item => {
      if (item.parent_id) {
        const parent = nodesWithChildren.find(node => node.id === item.parent_id);
        if (parent) {
          parent.children.push(item);
        }
      }
    });

    // get roots - nodes without parents, their children will already be linked and forming a tree
    const roots = nodesWithChildren.filter(item => !item.parent_id);

    return roots;
  }

  // ========== API COMUNICATION ==========

  const loadData = (sourceKey, targetKey) => {
    const doFetch = async () => {
      setLoading(true);
      try {
        const sourceQuery = {
          treeKey: targetKey,
        };
        const sourceRes = await axios.get(`/tree-mapping/mapping/tree/key/${sourceKey}`, { params: sourceQuery });
        const targetRes = await axios.get(`/tree/key/${targetKey}`);

        setSourceTreeData({
          ...sourceRes.data.data,
          branchTree: getTreeFromNodes(sourceRes.data.data.branches),
        });
        setTargetTreeData({
          ...targetRes.data.data,
          branchTree: getTreeFromNodes(targetRes.data.data.branches),
        });
      } catch (e) {
        setError(e);
      } finally {
        setLoading(false);
      }
    };
    doFetch();
  };

  const createMapping = (sourceBranchId, targetBranchId) => {
    const doCreate = async () => {
      try {
        const body = {
          first_branch_id: sourceBranchId,
          second_branch_id: targetBranchId,
        };
        await axios.post('/tree-mapping', body);
      } catch (e) {
        setError('Při ukládání změn na server došlo k chybě, zobrazená data nemusí odpovídat uloženému stavu. Prosíme, načtěte stránku znovu.');
      }
    };
    doCreate();
  };

  const deleteButtonClicked = (sourceBranchId, targetBranchId, next) => {
    setSourceTargetBranchId(sourceBranchId);
    setDeletedTargetBranchId(targetBranchId);
    setDeleteCallback(() => next);
    setShowDeleteDialog(true);
  };

  const handleTreesSelect = () => {
    if (sourceTree && targetTree) {
      setTargetTreeData(null);
      setSourceTreeData(null);
      // setEndpoints({});
      // setReferences({});
      // jsplumb.reset();
      loadData(sourceTree.key, targetTree.key);
    }
  };

  // ========== RENDER ==========

  return (
    <Paper className="basePaper">
      <Typography variant="h5">Správa stromů kategorií</Typography>
      <div className={classes.actionContainer}>
          <Button
          size='large'
          variant='outlined'
          color='primary'
          onClick={() => handleTreesSelect()}
          disabled={!sourceTree || !targetTree}
        >
          Vyhledat
        </Button>
      </div>
      <div className={classes.inputsContainer}>
        <div className={classes.input}>
          <AutocompleteSearch
            label="Zdrojový strom"
            baseUrl="/tree"
            searchQuery={{ direction: 'IN' }}
            searchBy="key"
            alreadySelected={[
              ...(sourceTree ? [sourceTree] : []),
              ...(targetTree ? [targetTree] : []),
            ]}
            getOptionLabel={(option) => option.key}
            identityAttribute="key"

            value={sourceTree}
            onValueChange={setSourceTree}
            onError={setError}
          />
        </div>
        <div className={classes.input}>
          <AutocompleteSearch
            label="Cílový strom"
            baseUrl="/tree"
            searchQuery={{ direction: 'OUT' }}
            searchBy="key"
            alreadySelected={[
              ...(sourceTree ? [sourceTree] : []),
              ...(targetTree ? [targetTree] : []),
            ]}
            getOptionLabel={(option) => option.key}
            identityAttribute="key"

            value={targetTree}
            onValueChange={setTargetTree}
            onError={setError}
          />
        </div>
      </div>
      {loading && <Loading />}
      {error && (
        <div className={classes.alert}>
          <ServerResponseAlert
            serverSuccess={false}
            serverReply={error}
            onClose={() => setError(null)}
          />
        </div>
      )}
      {sourceTreeData && targetTreeData && (
        <MultitreeMappingTable
          sourceTreeData={sourceTreeData}
          targetTreeData={targetTreeData}
          onCreateMappping={createMapping}
          onDeleteMapping={deleteButtonClicked}
        />
      )}
      <MappingAlertDialog open={showDeleteDialog}
                          sourceBranchId={deletedSourceBranchId}
                          targetBranchId={deletedTargetBranchId}
                          onDelete={deleteCallback}
                          onClose={(error) => {
                            if(error)
                              setError(error);

                            setShowDeleteDialog(false)
                            setDeletedTargetBranchId(null)
                            setSourceTargetBranchId(null)
                            setDeleteCallback()
                          }} />
    </Paper>
  );
};

function MappingAlertDialog(props) {
  const { onClose, onDelete, open, sourceBranchId, targetBranchId } = props;
  const [deleteProductMappings, setDeleteProductMappings] = useState(false);

  const handleClose = () => {
    onClose();
  };

  const handleDelete = async () => {
    let error = "";
    let x = "delete: " + deleteProductMappings + " " + sourceBranchId + " " + targetBranchId;

    try {
      await axios.delete(`/tree-mapping/${sourceBranchId}/${targetBranchId}`);

      if(deleteProductMappings) {
        await axios.delete(`/product/tree-branch/${targetBranchId}`);
      }
    } catch (e) {
      error =
        'Při ukládání změn na server došlo k chybě, zobrazená data nemusí odpovídat uloženému'
        + ' stavu. Načtěte stránku znovu.';
    }

    if(onDelete)
      onDelete(error);

    onClose(error);
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {"Vymazat vazbu mezi stromy?"}
      </DialogTitle>
      <DialogContent>
        <FormGroup>
          <FormControlLabel control={<Checkbox />}
                            label="Vymazat vazby u všech produktů"
                            value={deleteProductMappings}
                            onChange={(event) => setDeleteProductMappings(event.target.checked)}
          />
        </FormGroup>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} autoFocus>Ne</Button>
        <Button onClick={handleDelete}>Ano</Button>
      </DialogActions>
    </Dialog>
  );
}

export default Multitree;
