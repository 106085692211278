import { Paper, Typography } from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import React from "react";

export default () => {
  return (
    <Paper className="basePaper">
      <Typography variant="h5">404</Typography>
      <br />
      <Alert severity="info">
        <AlertTitle>Page not found:</AlertTitle>
        - The page you thought exists... doesn't <br />
        - The page you thought exists does in fact exists but you typed the wrong URL on accident <br />
        - You typed the wrong URL on purpose <br />
        - The website you are coming from is not as advanced as ours and cannot keep up with our superior navigation system. <br />
        <br />
      </Alert>
      <br />
    </Paper>
  );
};
