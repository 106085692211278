import React, { useState } from 'react';
import { Button, makeStyles } from '@material-ui/core';
import AutocompleteSearch from './AutocompleteSearch';

const useStyles = makeStyles({
  form: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    marginBottom: '30px',
    color: 'black',
  },
  label: {
    marginRight: '15px',
    fontWeight: 'bold',
  },
  input: {
    marginRight: '15px',
    width: '200px',
  },
  button: {
    marginBottom: '0 !important',
  },
});

const AutocompleteViewSelect = ({
  label = 'Zobrazit jako',
  buttonlabel = 'Zobrazit',
  inputLabel,
  baseUrl,
  identityAttribute,
  defaultValue = null,
  onChange,
}) => {
  const classes = useStyles();
  const [value, setValue] = useState(defaultValue ? { key: defaultValue } : null);
  const [error, setError] = useState(null);

  const handleSubmit = (e) => {
    e.preventDefault();
    setError(null);

    onChange(value ? value.key : value);
  };

  return (
    <form className={classes.form} onSubmit={handleSubmit}>
      <span className={classes.label}>
        {label}
        :
      </span>
      <AutocompleteSearch
        inputClassName={classes.input}
        label={inputLabel}
        baseUrl={baseUrl}
        searchBy={identityAttribute}
        alreadySelected={[]}
        getOptionLabel={(option) => option[identityAttribute]}
        identityAttribute={identityAttribute}
        
        value={value}
        onValueChange={setValue}
        onError={() => setError('Chyba při načítání dat')}
        error={!!error}
        errorMessage={error}
      />
      <Button className={classes.button} type="submit" variant="outlined" color="primary">
        {buttonlabel}
      </Button>
    </form>
  );
};

export default AutocompleteViewSelect;
