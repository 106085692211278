import React from 'react';
import { IconButton, makeStyles, TextField, Tooltip } from '@material-ui/core';
import SwapHorizIcon from '@material-ui/icons/SwapHoriz';
import FastForwardIcon from '@material-ui/icons/FastForward';
import AddIcon from '@material-ui/icons/Add';
import CheckIcon from '@material-ui/icons/Check';
import AutocompleteSearch from './AutocompleteSearch';

const useStyles = makeStyles({
  table: {
    width: '100%',
    marginTop: '10px',
    marginBottom: '10px',
    borderCollapse: 'collapse',
  },
  headCell: {
    textAlign: 'center',
    padding: '10px 0 20px',
  },
  headSelectCell: {
    width: '45%',
  },
  attributeRowEven: {
    backgroundColor: '#EEE',
  },
  attributeRowOdd: {},
  attributeCell: {
    padding: '10px 20px',
    height: '34px',
  },
  attributeKeyCell: {
    width: '15%',
    fontWeight: 'bold',
  },
  attributeSuggestedKeyCell: {
    fontStyle: 'italic',
    fontWeight: 'normal',
  },
  attributeActionCell: {
    textAlign: 'center',
    color: 'rgba(0, 0, 0, 0.54)',
  },
  disclaimerCell: {
    textAlign: 'center',
    fontStyle: 'italic',
  },
  valueInputContainer: {
    marginBottom: '-2px',
  },
  valueInput: {
    fontSize: '0.9rem',
    fontFamily: '-apple-system, BlinkMacSystemFont, \'Segoe UI\', \'Roboto\', \'Oxygen\', \'Ubuntu\', \'Cantarell\', \'Fira Sans\', \'Droid Sans\', \'Helvetica Neue\', sans-serif',
    letterSpacing: '0',
    fontStretch: 'normal',
  },
  actionButton: {
    padding: '5px',
  },
});


const ConsumerAttributesComparisonTable = ({
  baseConsumer,
  editedConsumer,
  attributeRows,
  onBaseConsumerSelect,
  onEditedSoncumerSelect,
  onConsumerSwap,
  onAttributeUpdate,
  onError,
}) => {
  const classes = useStyles();
  const [editedRowKey, setEditedRowKey] = React.useState(null);

  const handleEditRequest = (rowKey) => {
    if (editedConsumer && baseConsumer) {
      setEditedRowKey(rowKey);
    }
  };

  const getAction = ({ key, valueBase, valueEdited }) => {
    if (!valueBase) return '-';

    if (!valueEdited) return (
      <Tooltip title="Zkopírovat atribut" enterDelay={1000}>
        <IconButton
          className={classes.actionButton}
          aria-label="copy-attribute-to-edited"
          onClick={() => onAttributeUpdate(key, valueBase)}
        >
          <AddIcon />
        </IconButton>
      </Tooltip>
    );

    if (valueBase === valueEdited) return (
      <Tooltip title="Shoda atributů" enterDelay={1000}>
        <CheckIcon aria-label="attributes-match" />
      </Tooltip>
    );

    return (
      <Tooltip title="Zkopírovat hodnotu" enterDelay={1000}>
        <IconButton
          className={classes.actionButton}
          aria-label="copy-attribute-value-to-edited"
          onClick={() => onAttributeUpdate(key, valueBase)}
        >
          <FastForwardIcon />
        </IconButton>
      </Tooltip>
    );
  };

  const getValueEdited = (row, currentEditedRowKey) => {
    if (row.key === currentEditedRowKey) {
      return (
        <TextField
          className={classes.valueInputContainer}
          inputProps={{ className: classes.valueInput }}
          name={row.key}
          defaultValue={row.valueEdited}
          size="small"
          fullWidth
          autoFocus
          onChange={(e) => onAttributeUpdate(e.target.name, e.target.value)}
          onBlur={() => setEditedRowKey(null)}
        />
      );
    }

    return editedConsumer ? (row.valueEdited || '---') : '';
  };

  return (
    <table className={classes.table}>
      <thead>
        <tr>
          <td className={`${classes.headCell} ${classes.headSelectCell}`} colSpan={2}>
            <AutocompleteSearch 
              label="Základní consumer"
              baseUrl="/consumer"
              searchBy="key"
              alreadySelected={[
                ...(baseConsumer ? [baseConsumer] : []),
                ...(editedConsumer ? [editedConsumer] : []),
              ]}
              getOptionLabel={(option) => option.key}
              identityAttribute="key"
              
              value={baseConsumer}
              onValueChange={onBaseConsumerSelect}
              onError={onError}
            />
          </td>
          <td className={classes.headCell}>
            <Tooltip title="Vyměnit consumery" enterDelay={1000}>
              <IconButton
                className={classes.actionButton}
                aria-label="swap-consumers"
                onClick={onConsumerSwap}
              >
                <SwapHorizIcon />
              </IconButton>
            </Tooltip>
          </td>
          <td className={`${classes.headCell} ${classes.headSelectCell}`} colSpan={2}>
            <AutocompleteSearch 
              label="Editovaný consumer"
              baseUrl="/consumer"
              searchBy="key"
              alreadySelected={[
                ...(baseConsumer ? [baseConsumer] : []),
                ...(editedConsumer ? [editedConsumer] : []),
              ]}
              getOptionLabel={(option) => option.key}
              identityAttribute="key"
              
              value={editedConsumer}
              onValueChange={onEditedSoncumerSelect}
              onError={onError}
            />
          </td>
        </tr>
      </thead>
      <tbody>
        {(baseConsumer || editedConsumer) && attributeRows.length === 0 && (
          <tr>
            <td className={`${classes.attributeCell} ${classes.disclaimerCell}`} colSpan={5}>
              Žádné consumer atributy
            </td>
          </tr>
        )}
        {attributeRows.map((row, idx) => (
          <tr key={idx} className={idx % 2 === 0 ? classes.attributeRowEven : classes.attributeRowOdd}>
            <td className={`${classes.attributeCell} ${classes.attributeKeyCell}`}>
              {row.valueBase ? row.key : ''}
            </td>
            <td className={classes.attributeCell}>
              {row.valueBase}
            </td>
            <td className={`${classes.attributeCell} ${classes.attributeActionCell}`}>
              {baseConsumer && editedConsumer ? getAction(row) : ''}
            </td>
            <td className={`${classes.attributeCell} ${classes.attributeKeyCell} ${row.valueEdited ? '' : classes.attributeSuggestedKeyCell}`}>
              {editedConsumer ? row.key : ''}
            </td>
            <td className={classes.attributeCell} onDoubleClick={() => handleEditRequest(row.key)}>
              {getValueEdited(row, editedRowKey)}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default ConsumerAttributesComparisonTable;
