import { useAbility } from '@casl/react';
import { Box, Grid, makeStyles, Paper, Typography } from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { subject } from '@casl/ability';
import AutocompleteViewSelect from '../components/AutocompleteViewSelect';
import productEntity from "../entities/product";
import BookForm from "../forms/Product";
import Loader from "../layout/Loader";
import { AbilityContext, ASSOCIATED_CONSUMER, CHANGE_VIEW_FOR, PRODUCT, UPDATE } from '../permissions';
import formatDate4Input from "../utils/formatDate4Input";
import nullToString from "../utils/nullToString";
import axios, { getConsumerHeader } from '../utils/axios';
import useLocks from '../hooks/useLocks';

const useStyles = makeStyles({
  title: {
    marginBottom: '20px',
  },
});

export default (props) => {
  const ability = useAbility(AbilityContext);
  const classes = useStyles();

  let { id } = useParams();
  id = id === undefined ? "" : id;
  const newBookItem = id === "" ? true : false;

  const [bookItem, setBookItem] = useState({ ...productEntity });
  const [response, setResponse] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [consumerViewKey, setConsumerViewKey] = useState(localStorage.getItem('consumer') || null);
  const { locks, lock, unlock } = useLocks('product', id);

  const loadData = (viewKey = consumerViewKey) => {
    const doFetch = async () => {
      setLoading(true);
      try {
        const headers = {
          ...(ability.can(CHANGE_VIEW_FOR, ASSOCIATED_CONSUMER)
            && ability.can(UPDATE, subject(PRODUCT, bookItem), 'consumer-attribute')
            && viewKey
            && getConsumerHeader(viewKey)),
        };
        const query = {
          withBranchPaths: '1',
        };
        const res = await axios.get(`product/${id}`, { headers, params: query });
        setResponse(res.data);
      } catch (e) {
        setError(e);
      } finally {
        setLoading(false);
      }
    };
    setError(false);
    doFetch();
  };

  useEffect(() => {
    loadData();
  }, [consumerViewKey]);

  useEffect(() => {
    if (response && response.data && response.data.title && newBookItem === false) {
      console.log(response);
      let cleanResponse = nullToString(response.data);
      cleanResponse.publish_date = formatDate4Input(cleanResponse.publish_date);
      cleanResponse.first_publish_date = formatDate4Input(cleanResponse.first_publish_date);
      cleanResponse.distributable_from = formatDate4Input(cleanResponse.distributable_from);
      cleanResponse.distributable_until = formatDate4Input(cleanResponse.distributable_until);
      cleanResponse.presale_valid_from = formatDate4Input(cleanResponse.presale_valid_from);
      cleanResponse.presale_valid_until = formatDate4Input(cleanResponse.presale_valid_until);
      console.log(cleanResponse);
      setBookItem(cleanResponse);
    }
  }, [response]);

  const handleConsumerViewChange = (viewKey) => {
    setConsumerViewKey(viewKey);
    localStorage.setItem('consumer', viewKey || '');
  };

  return (
    <>
      {ability.can(CHANGE_VIEW_FOR, ASSOCIATED_CONSUMER) && ability.can(UPDATE, subject(PRODUCT, bookItem), 'consumer-attribute') && (
        <AutocompleteViewSelect
          label="Editovat jako"
          buttonlabel="Přepnout"
          inputLabel="Consumer"
          baseUrl="/consumer"
          identityAttribute="key"
          defaultValue={consumerViewKey}
          onChange={handleConsumerViewChange}
        />
      )}

      <Paper className="basePaper">
        <Grid container direction="row" justify="space-between">
          <Grid item xs={12}>
            <Typography className={classes.title} variant="h5">{newBookItem === true ? "Nový produkt:" : "Upravit produkt:"} </Typography>

            <Box>
              {loading && <Loader />}
              {error && (
                <Alert severity="error" style={{ marginTop: 20 }}>
                  <AlertTitle>Ajajaj!</AlertTitle>
                  Chyba při načítání dat.
                </Alert>
              )}
            </Box>
            <BookForm
              key={bookItem.id}
              data={bookItem}
              locks={locks}
              consumerViewKey={consumerViewKey}
              onLock={lock}
              onUnLock={unlock}
            />
          </Grid>
        </Grid>
        <br />
        {/* <Alert severity="info">
          <AlertTitle>Dev notes:</AlertTitle>
          - Provision requests <br />
          <br />
        </Alert> */}
      </Paper>
    </>
  );
};
