import React from 'react';
import { TextField, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
import { useAdvancedSearchFilterStyles } from '../styles/advanced-search';

export const INITIAL_FILTER_VALUE = {
  name: '',
  master: 'all',
};

export const LABELS = {
  name: 'Jméno',
  master: 'Master status',
};

const AuthorSearchFilter = ({ filter, onFilterChange }) => {
  const classes = useAdvancedSearchFilterStyles();

  const setFilterValue = (e) => {
    e.persist();
    onFilterChange({
      ...filter,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <>
      <div className={classes.formLine}>
        <TextField className={classes.searchInput} name="name" label={LABELS.name} value={filter.name} onChange={setFilterValue} />
        <FormControl className={[classes.searchInput, classes.searchInputWide].join(' ')}>
          <InputLabel id="master-filter-label">{LABELS.master}</InputLabel>
          <Select labelId="master-filter-label" name="master" value={filter.master} onChange={setFilterValue}>
            <MenuItem value="all">Vše</MenuItem>
            <MenuItem value="master">Pouze master záznamy</MenuItem>
            <MenuItem value="non-master">Pouze ne-master záznamy</MenuItem>
            <MenuItem value="not-assigned-master">Pouze ne-master záznamy bez master záznamu</MenuItem>
          </Select>
        </FormControl>
      </div>
    </>
  );
};

export default AuthorSearchFilter;
