import { Button, Grid, TextField, Typography } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Alert, AlertTitle } from "@material-ui/lab";
import React, { useState, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import productEntity from "../entities/product";
import useStyles from "../styles/forms";
import axios from "../utils/axios";
import asyncForEach from "../utils/asyncForEach";
import nullFromString from "../utils/nullFromString";
import ServerResponseAlert from '../components/ServerResponseAlert';
import ProductList from './lists/ProductList';

export default (props) => {
  const myData = props.data;
  //console.log(myData);
  const classes = useStyles();
  let { id } = useParams();
  id = id === undefined ? "" : id;
  // console.log(id);
  const newProductItem = id === "" ? true : false;
  const [productItem, setProductItem] = useState({ ...productEntity, ...myData });
  const [products, setProducts] = useState([]);
  const [products2Add, setProducts2Add] = useState([]);
  const [products2Delete, setProducts2Delete] = useState([]);

  const [serverSuccess, setServerSuccess] = useState(null);
  const [serverReply, setServerReply] = useState(false);
  const methods = useForm({ defaultValues: { ...myData } });
  const { handleSubmit, errors, register, control, reset, getValues, setValue } = methods;
  const onSubmit = (data) => {
    // console.log(data);
    const submitData = async () => {
      try {
        let newBody = {
          ...myData,
          ...data,
        };
        console.log(newBody);
        let cleanBody = nullFromString(newBody);
        cleanBody = JSON.stringify(cleanBody);
        const response = newProductItem ? await axios.post("/product-bundle", cleanBody) : await axios.put(`/product-bundle/${id}`, cleanBody);
        const responseJson = response.data;
        if (response.status === 200 || response.status === 201) {
          // created
          setServerSuccess(true);
          setServerReply("V pořádku uloženo.");

          setorigValues({ ...origValues, ...responseJson.data });
          console.log(responseJson.data);

          const keys = Object.keys(responseJson.data);
          for (const key of keys) {
            // console.log(key, responseJson.data[key]);
            setValue(key, responseJson.data[key]);
          }

          if (newProductItem === true && responseJson.data.id) {
            window.location.href = "/product-bundle/edit/" + responseJson.data.id;
          }
          console.log("Success");
          window.scrollTo({ top: 0, behavior: "smooth" });
        } else {
          // data error
          // serverErrors(data);
          setServerSuccess(false);
          setServerReply("Chyba při ukládání.");
          console.log("Chyba při ukládání.");
          window.scrollTo({ top: 0, behavior: "smooth" });
        }
      } catch (err) {
        console.log("Chyba při ukládání. " + err);
        setServerSuccess(false);
        setServerReply(err);
        window.scrollTo({ top: 0, behavior: "smooth" });
      }
    };
    submitData();
    const submitProducts = async () => {
      setorigValues({ ...origValues, products: [...products.map(p => p.id)] });
      const promiseArray = [];
      products2Add.forEach((item) => {
        promiseArray.push(axios.patch(`/product/${item.id}`, { product_bundle: productItem.id }));
      });
      Promise.all([...promiseArray])
        .then((myArray) => {
          // if (isSubscribed) {
          const fillUp = async () => {
            const jsons = [];
            await asyncForEach(myArray, async (item, index) => {
              jsons.push({ ...item.data.data });
            });
            console.log("Yay! Products added.");
          };
          fillUp();
          // }
        })
        .catch((err) => {
          console.log("Chyba při přidávání produktů. " + err);
          setServerSuccess(false);
          setServerReply(err);
          window.scrollTo({ top: 0, behavior: "smooth" });
        });
      const promiseArray2 = [];
      products2Delete.forEach((item) => {
        if (item.bundle_id === productItem.id){
          promiseArray2.push(axios.patch(`/product/${item.id}`, { product_bundle: null }));
        }
      });
      Promise.all([...promiseArray2])
        .then((myArray) => {
          console.log("Yay! Products deleted.");
          if (serverSuccess !== false) setServerSuccess(true);
          setServerReply("V pořádku uloženo.");
        })
        .catch((err) => {
          console.log("Chyba při odstraňování produktů. " + err);
          setServerSuccess(false);
          setServerReply(err);
          window.scrollTo({ top: 0, behavior: "smooth" });
        });
    };
    submitProducts();
  };

  const [origValues, setorigValues] = useState(false);
  const leaveIfYouCan = () => {
    const values = getValues();
    console.log(productItem);
    console.log(origValues);
    const valuesWithShortenedProductsProp = { ...values, products: products ? products.map((item) => item.id) : [] };
    console.log(valuesWithShortenedProductsProp);
    const changeDetected = JSON.stringify(origValues) !== JSON.stringify(valuesWithShortenedProductsProp);
    console.log(changeDetected);
    if (changeDetected) {
      setOpen(true);
    } else {
      confirmLeave();
    }
  };
  const [open, setOpen] = useState(false);
  const confirmLeave = () => {
    window.location.href = `/product-bundle/${id}`;
  };
  useEffect(() => {
    const values = getValues();
    setorigValues({ ...values, products: productItem.products ? productItem.products.map((item) => item.id) : [] });
  }, [getValues]);
  const handleClose = () => {
    setOpen(false);
  };

  /*   useEffect(() => {
    console.log(errors);
  }, [errors]); */
  const [open2, setOpen2] = useState(false);
  const deleteProductConfirm = () => {
    setOpen(true);
  };
  const deleteProduct = async () => {
    // disable button
    const submitData = async () => {
      try {
        const response = await axios.delete(`/product-bundle/${id}`);

        const data = response.data;
        if (response.status === 200 || response.status === 201) {
          // created
          setServerSuccess(true);
          setServerReply("Successfully deleted.");
          setProductItem(null);
          console.log("Success");
          window.scrollTo({ top: 0, behavior: "smooth" });
        } else {
          // data error
          // serverErrors(data);
          setServerSuccess(false);
          setServerReply("Error deleting data.");
          console.log("Chyba při ukládání.");
          window.scrollTo({ top: 0, behavior: "smooth" });
        }
      } catch (err) {
        console.log("Error deleting data. " + err);
      }
    };
    submitData();
    setOpen(false);
  };
  const handleClose2 = () => {
    setOpen2(false);
  };
  const addProduct = (productToAdd) => {
    setProducts2Add((prevValue) => [...prevValue, productToAdd]);
    setProducts2Delete((prevValue) => prevValue.filter(toDel => productToAdd.id !== toDel.id));
  };
  const removeProduct = (productToDel) => {
    setProducts2Delete((prevValue) => [...prevValue, productToDel]);
    setProducts2Add((prevValue) => prevValue.filter(toAdd => productToDel.id !== toAdd.id));
    // const products
  };
  const handleError = (e) => {
    setServerReply(e);
    setServerSuccess(false);
  };

  return (
    <>
      <Dialog open={open} keepMounted onClose={handleClose} aria-labelledby="alert-dialog-slide-title" aria-describedby="alert-dialog-slide-description">
        <DialogTitle id="alert-dialog-slide-title">Skartovat změny</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">Tato akce nelze vrátit. Přejete si pokračovat?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            ne
          </Button>
          <Button onClick={confirmLeave} color="primary">
            Ano
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={open2} keepMounted onClose={handleClose} aria-labelledby="alert-dialog-slide-title" aria-describedby="alert-dialog-slide-description">
        <DialogTitle id="alert-dialog-slide-title">Delete product bundle</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">This action is irreversible. Are you sure you want to continue?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={deleteProduct} color="primary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      {serverReply && (
        <>
          <br />
          <ServerResponseAlert 
            serverSuccess={serverSuccess}
            serverReply={serverReply}
          />
          <br />
        </>
      )}
      {productItem && (
        <form onSubmit={handleSubmit(onSubmit)}>
          <Controller as={TextField} className={classes.input} inputRef={register({ required: true })} label="Název *" name="title" control={control} />
          {/* {!newProductItem && <Controller as={TextField} className={classes.input} inputRef={register({})} label="CDI id" name="id" control={control} />} */}
          <br />
          <Controller as={TextField} className={classes.input} inputRef={register({})} label="Podtitulek" name="subtitle" control={control} />
          <Controller as={TextField} className={classes.input} inputRef={register({})} label="Originální název" name="original_title" control={control} />
          <br />
          <Controller as={TextField} className={classes.inputFull} inputRef={register({})} label="Slogan" name="summary" control={control} />
          <Controller as={TextField} className={classes.inputFull} inputRef={register({})} label="Anotace" name="description" control={control} multiline />
          <Controller as={TextField} className={classes.input} inputRef={register({})} label="Externí klíč" name="external_reference_key" control={control} />
          <br />
          <br />
          {newProductItem && (
            <Alert severity="info">
              <AlertTitle>Poznámka:</AlertTitle>
              Po uložení budete moci přiřazovat svazku individální produkty.
            </Alert>
          )}
          {!newProductItem && (
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <ProductList 
                  values={productItem.products} 
                  onProductAdd={addProduct}
                  onProductDelete={removeProduct}
                  onProductsChange={setProducts}
                  onError={handleError}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="caption">Soubory</Typography>

                {/* <AssetList data={productItem.assets} callBack={updateFromChildComponents} /> */}
              </Grid>
            </Grid>
          )}
          <br />
          <Button size="large" variant="outlined" color="primary" onClick={leaveIfYouCan}>
            Zpět na detail
          </Button>
          <Button size="large" variant="contained" color="primary" type="submit">
            Uložit
          </Button>{" "}
          &nbsp;
          {/*           {!newProductItem && (
            <Button size="large" variant="outlined" color="primary" onClick={deleteProductConfirm}>
              Delete
            </Button>
          )} */}
          <br />
        </form>
      )}
    </>
  );
};
