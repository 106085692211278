import { Box, Button, Card, CardActions, CardContent, Grid, Paper, Typography } from '@material-ui/core';
import StorageRoundedIcon from '@material-ui/icons/StorageRounded';
import Brightness5RoundedIcon from '@material-ui/icons/Brightness5Rounded';
import MoveToInboxRoundedIcon from '@material-ui/icons/MoveToInboxRounded';
import FlagRoundedIcon from '@material-ui/icons/FlagRounded';
import CallSplitRoundedIcon from '@material-ui/icons/CallSplitRounded';
import React from 'react';
import { ACCESS, AbilityContext, DISTRIBUTION_MANAGEMENT, REPORTING, TOOLS, SETTINGS } from '../permissions';
import { useAbility } from '@casl/react';

const getDashboardData = (ability) => [
  {
    name: 'Správa dat',
    description: 'Lorem ipsum fenal bullet abraka a well meaning and kindly.',
    url: '/data-management',
    icon: <StorageRoundedIcon className="greyIcon" />,
  },
  ...(ability.can(ACCESS, DISTRIBUTION_MANAGEMENT)
    ? [
        {
          name: 'Správa distribuce',
          description: 'Lorem ipsum fenal bullet abraka a well meaning and kindly.',
          url: '/distribution-management',
          icon: <CallSplitRoundedIcon className="greyIcon" />,
        },
      ]
    : []),
  ...(ability.can(ACCESS, REPORTING)
    ? [
        {
          name: 'Reporting management',
          description: 'Lorem ipsum fenal bullet abraka a well meaning and kindly.',
          url: '/reporting',
          icon: <FlagRoundedIcon className="greyIcon" />,
        },
      ]
    : []),
  ...(ability.can(ACCESS, SETTINGS)
    ? [
        {
          name: 'Nastavení',
          description: 'Lorem ipsum fenal bullet abraka a well meaning and kindly.',
          url: '/settings',
          icon: <Brightness5RoundedIcon className="greyIcon" />,
        },
      ]
    : []),
  ...(ability.can(ACCESS, TOOLS)
    ? [
        {
          name: 'Nástroje',
          description: 'Lorem ipsum fenal bullet abraka a well meaning and kindly.',
          url: '/tools',
          icon: <MoveToInboxRoundedIcon className="greyIcon" />,
        },
      ]
    : []),
];

export default (props) => {
  const ability = useAbility(AbilityContext);
  const dashboardData = getDashboardData(ability);

  return (
    <Paper className="basePaper">
      <Typography variant="h5">Dashboard</Typography>
      <br />
      <Grid container spacing={3}>
        {dashboardData &&
          dashboardData.map((item, index) => (
            <Grid key={index} item xs={12} sm={6} md={3} style={{ flex: 1, display: 'flex', flexFlow: 'column' }}>
              <Card className="card" variant="outlined" style={{ flex: 1, display: 'flex', flexFlow: 'column', minWidth: 200 }}>
                <CardContent style={{ flex: 1 }}>
                  <Box mt={1} mb={2}>
                    {item.icon}
                  </Box>
                  <Typography variant="h5" color="textSecondary" gutterBottom>
                    {item.name}
                  </Typography>
                  {/*               <Typography variant="body2" component="p">
                    {item.description}
                  </Typography> */}
                </CardContent>
                <CardActions>
                  <Button variant="outlined" color="primary" href={item.url} size="small">
                    Otevřít
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          ))}
      </Grid>
      <br />
    </Paper>
  );
};
