import { Box, Button, Grid, makeStyles, Paper, Typography } from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import authorEntity from "../entities/author";
import LinkURL from "../layout/LinkURL";
import Loader from "../layout/Loader";
import axios from "../utils/axios";
import formatDate from "../utils/formatDate";
import nullToString from "../utils/nullToString";
import { Can, UPDATE, AUTHOR } from "../permissions";
import { subject } from "@casl/ability";

const useStyles = makeStyles({
  masterIndicator: {
    textTransform: 'uppercase',
    color: '#B9012B',
    fontWeight: 'bold',
  },
});

export default (props) => {
  const classes = useStyles();
  let { id } = useParams();
  id = id === undefined ? "" : id;
  const apiEndpoint = "author/" + id;
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [authorItem, setAuthorItem] = useState({ ...authorEntity });
  const [masterAuthor, setMasterAuthor] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      setError(false);
      setLoading(true);
      try {
        const result = await axios(apiEndpoint);
        let response = result.data.data;
        if (response) {
          let cleanResponse = nullToString(response);

          if (response.master_author_id) {
            const master = await axios.get(`/author/${response.master_author_id}`);
            setMasterAuthor(master.data.data);
          }

          console.log(cleanResponse);
          setAuthorItem(cleanResponse);
        } else {
          console.warn("Invalid data format?");
        }
      } catch (error) {
        setError(true);
        console.warn("Error");
      }
      setLoading(false);
    };
    fetchData();
  }, [apiEndpoint]);

  return (
    <Paper className="basePaper">
      <Grid container direction="row" justify="space-between">
        <Grid item xs={8}>
          <Typography variant="h5">{authorItem.name}</Typography>
          <Box>
            {loading && <Loader />}
            {error && (
              <Alert severity="error" style={{ marginTop: 20 }}>
                <AlertTitle>Ajajaj!</AlertTitle>
                Chyba při načítání dat.
              </Alert>
            )}
          </Box>
          {authorItem.picture_url && <img src={authorItem.picture_url} className="authorImage" style={{ margin: "0.5rem 0 0" }} />}
          <br />
          <strong>ID:</strong> {authorItem.id}
          <br />
          {authorItem.is_master && (
            <span className={classes.masterIndicator}>
              master
            </span>
          )}
          {!authorItem.is_master && (
            <>
              <strong>Master:</strong> {masterAuthor?.name || '---'}
            </>
          )}
          <br />
          <br />
          <strong>Jméno:</strong> {authorItem.first_name}
          <br />
          <strong>Druhé jméno:</strong> {authorItem.middle_name}
          <br />
          <strong>Příjmení:</strong> {authorItem.last_name}
          <br />
          <strong>Titul před:</strong> {authorItem.title_before}
          <br />
          <strong>Titul za:</strong> {authorItem.title_after}
          <br />
          <strong>Pseudonym:</strong> {authorItem.pseudonym}
          <br />
          <strong>Značka:</strong> {authorItem.name}
          <br />
          <strong>Datum narození:</strong> {formatDate(authorItem.birth_date)}
          <br />
          <strong>Datum umrtí:</strong> {formatDate(authorItem.death_date)}
          <br />
          <strong>URL www stránek:</strong> <LinkURL to={authorItem.website_url} />
          <br />
          <strong>Twitter:</strong> <LinkURL to={authorItem.twitter_url} />
          <br />
          <strong>Facebook:</strong> <LinkURL to={authorItem.facebook_url} />
          <br />
          <strong>Instagram:</strong> <LinkURL to={authorItem.instagram_url} />
          <br />
          <strong>Youtube:</strong> <LinkURL to={authorItem.youtube_url} />
          <br />
          <br />
          <strong>Container Id:</strong> {authorItem.picture_container_id}
          <br />
          <strong>URL obrázku:</strong> <LinkURL to={authorItem.picture_url} />
          <br />
          <strong>Checksum obrázku:</strong> {authorItem.picture_checksum}
          <br />
          <strong>Externí klíč obrázku:</strong> {authorItem.picture_external_reference_key}
          <p>
            <strong>Stručný popis:</strong> {authorItem.summary}
          </p>
          <p>
            <strong>Biografie:</strong> {authorItem.biography}
          </p>
          <p>
            <strong>Na blacklistu:</strong> {authorItem.is_blacklisted ? 'ano' : 'ne'}
          </p>
        </Grid>
        <Grid item xs={3}>
          <Box mt={0}>
            <Can I={UPDATE} an={subject(AUTHOR, authorItem)}>
              {() => <Button size="large" href={`/author/edit/${id}`} variant="outlined" color="primary">
                Upravit autora
              </Button>}
            </Can>
            <br />
            <br />
            <br />
          </Box>
        </Grid>
      </Grid>
      <br />
      {/*       <Alert severity="info">
        <AlertTitle>Dev notes:</AlertTitle>
        - name compiled automaticaly? <br />
        - date conversion <br />
      </Alert> */}
    </Paper>
  );
};
