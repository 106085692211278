import AddCircleOutlineRoundedIcon from "@material-ui/icons/AddCircleOutlineRounded";
import { Box, Button, Link, Paper, Typography, TablePagination } from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import MaterialTable from "material-table";
import MTLocalization from "../utils/MTLocalization";
import React, { useEffect, useState } from "react";
import Loader from "../layout/Loader";
import axios from "../utils/axios";
import { Can, UPDATE, CREATE, BUNDLE } from "../permissions";
import { subject } from "@casl/ability";
import SearchBox from '../components/SearchBox';
import { getSortString, getSortReadyColumns } from '../utils/tableSortUtils';

const SORT_FIELDS = {
  title: 'title',
  external_reference_key: 'externalReferenceKey',
};

export default (props) => {
  const [state, setState] = useState(null);

    // -- Data loading and pagination
    const [response, setResponse] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [loading, setDataLoading] = useState(false);
    const [error, setDataError] = useState(null);
    const [pageSize, setPageSize] = useState(10);
    const [currentPage, setCurrentPage] = useState(0);
    const [filterText, setFilterText] = useState(null);
    const [sort, setSort] = useState(null);
  
    const loadData = (page = currentPage, size = pageSize, filter = filterText, sortDesc = sort) => {
      const doFetch = async () => {
        setDataLoading(true);
        try {
          const query = {
            page: page + 1,
            limit: size,
            ...(filter && { title: filter }),
            ...(sortDesc && { sort: getSortString(sortDesc, SORT_FIELDS) }),
          };
          const res = await axios('/product-bundle', { params: query });
          setResponse(res.data);
          setTotalCount(res.data.meta.total_number);
        } catch (e) {
          setDataError(e);
        } finally {
          setDataLoading(false);
        }
      };
      doFetch();
    };
  
    const handlePageChange = (newPage, newPageSize) => {
      setCurrentPage(newPage);
      setPageSize(newPageSize);
      loadData(newPage, newPageSize);
    };

    const handleFilterChange = (value) => {
      setCurrentPage(0);
      setFilterText(value);
      loadData(0, pageSize, value);
    };
  
    const handleFilterReset = () => {
      handleFilterChange(null);
    };

    const handleOrderChange = (columnIdx, order) => {
      const sort = columnIdx >= 0 ? { field: state.columns[columnIdx].field, order: order } : null;
      
      setCurrentPage(0);
      setSort(sort);
      loadData(0, pageSize, filterText, sort);
    }
  
    // runs on load only
    useEffect(() => {
      loadData();
    }, []);


  useEffect(() => {
    if (response && response.status === 200) {
      console.log(response);
      let newData = {
        columns: [
          {
            title: "Název",
            field: "title",
            render: (rowData) => <a href={`/product-bundle/${rowData.id}`}>{rowData.title}</a>,
          },
          {
            title: "Podtitulek",
            field: "subtitle",
            render: (rowData) => {
              if (rowData.subtitle) {
                return rowData.subtitle.length > 80 ? rowData.subtitle.substr(0, 80) + "..." : rowData.subtitle;
              } else {
                return "";
              }
            },
          },
          {
            title: "Slogan",
            field: "summary",
            render: (rowData) => {
              if (rowData.summary) {
                return rowData.summary.length > 80 ? rowData.summary.substr(0, 80) + "..." : rowData.summary;
              } else {
                return "";
              }
            },
          },
          { title: "Externí klíč", field: "external_reference_key", render: (rowData) => <span className="smallFont">{rowData.external_reference_key}</span> },
          {
            width: 100,
            title: "",
            field: "action",
            render: (rowData) => (
              <Can I={UPDATE} a={subject(BUNDLE, rowData)}>
                {() => <Button href={`/product-bundle/edit/${rowData.id}`} variant="outlined" color="primary">
                  Upravit
                </Button>}
              </Can>
            ),
          },
        ],
        data: [...response.data],
      };
      console.log();
      setState(newData);
    }
  }, [response]);

  return (
    <Paper className="basePaper">
      <Typography variant="h5">
        Produktové svazky &nbsp;
        <Can I={CREATE} a={BUNDLE}>
          {() => <Link href="/product-bundle/new/">
            <AddCircleOutlineRoundedIcon className="createNew" />
          </Link>}
        </Can>
      </Typography>
      <Box>
        {loading && <Loader />}
        {error && (
          <Alert severity="error" style={{ marginTop: 20 }}>
            <AlertTitle>Ajajaj!</AlertTitle>
            Chyba při načítání dat.
          </Alert>
        )}
      </Box>
      <SearchBox 
        onFilterSubmit={handleFilterChange}
        onFilterReset={handleFilterReset}
      />
      { state && (
        <MaterialTable
          key={pageSize} // workaround for not functioning page size change
          localization={MTLocalization}
          options={{ pageSize: pageSize, addRowPosition: "first", toolbar: false }}
          style={{ margin: 0, boxShadow: "none" }}
          className="MuiTableContainer"
          columns={getSortReadyColumns(state.columns, Object.keys(SORT_FIELDS), sort)}
          data={state.data}
          onOrderChange={handleOrderChange}
          components={{
            Pagination: props => <TablePagination {...props}
              rowsPerPageOptions={[5, 10, 20, 30]}
              rowsPerPage={pageSize}
              count={totalCount}
              page={currentPage}
              onChangePage={(e, page) => handlePageChange(page, pageSize)}
              onChangeRowsPerPage={(e) => handlePageChange(currentPage, e.target.value)}
            />
          }}
        />
      )}
      {/*       <br />
      <Alert severity="info">
        <AlertTitle>Dev notes:</AlertTitle>
        - missing publisher
        <br />
        - missing author
        <br />
        - missing includes products
        <br />
      </Alert> */}
      <br />
    </Paper>
  );
};
