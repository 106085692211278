export const getSortString = (sort, fieldAliasMap) => (
  `${sort.order === 'asc' ? '+' : '-'}${fieldAliasMap[sort.field]}`
);

export const getSortReadyColumns = (columnBase, sortableFields, currentSort) => columnBase.map(column => ({
  sorting: sortableFields.includes(column.field),
  defaultSort: (currentSort && currentSort.field === column.field) ? currentSort.order : undefined,
  customSort: (a, b) => 1,
  ...column,
}));
