const noEmpties = (object) => {
  let noErrors = true;
  for (var key in object) {
    // console.log(object[key]);
    if (!object[key]) {
      noErrors = false;
    }
  }
  return noErrors;
};
export default noEmpties;
