import React from 'react';
import { IconButton, makeStyles } from "@material-ui/core";

import AddBoxIcon from '@material-ui/icons/AddBox';
import IndeterminateCheckBoxIcon from '@material-ui/icons/IndeterminateCheckBox';

const useStyles = makeStyles({
  treeNode: {
    position: 'relative',
    padding: '10px 20px 10px 40px',
    height: '24px',
  },
  treeNodeRight: {
    paddingLeft: '50px',
  },
  treeNodeContent: {
    position: 'relative',
    margin: 0,
  },
  line: {
    position: 'absolute',
    height: 'calc(50% + 10px)',
    width: '15px',
    top: '-10px',
    left: '42px',
    border: 'dotted #999',
    borderWidth: '0 0 2px 2px',
  },
  expandButton: {
    position: 'absolute',
    left: '-25px',
    zIndex: '10',
  },
  expandButtonChild: {
    left: '-50px',
  },
});

const FIRST_LINE_MARGIN = 42;
const FIRST_LINE_MARGIN_RIGHT = 52;
const LEVEL_MARGIN = 25;

const MultitreeElement = ({
  node,
  level,
  register,
  hasChildren,
  showChildren,
  onTriggerChildren,
  right = false,
}) => {
  const classes = useStyles();
  const ref = React.useRef();

  React.useEffect(() => {
    register(node.id, ref);
  }, []);

  const treeNodeClasses = [classes.treeNode];
  if (right) treeNodeClasses.push(classes.treeNodeRight);

  return (
    <div 
      className={treeNodeClasses.join(' ')}
      ref={ref}
      id={node.id}
    >
      {level > 0 && (
        <div
          className={classes.line}
          style={{ left: `${(right ? FIRST_LINE_MARGIN_RIGHT : FIRST_LINE_MARGIN) + ((level - 1) * LEVEL_MARGIN)}px` }}
        />
      )}
      <p
        className={classes.treeNodeContent}
        style={{ marginLeft: `${level * LEVEL_MARGIN}px` }}
      >
        {hasChildren && (
          <IconButton
            className={`${classes.expandButton}${level > 0 ? ` ${classes.expandButtonChild}` : ''}`}
            aria-label="trigger-children"
            size="small" 
            onClick={() => onTriggerChildren(!showChildren)}
          >
            {showChildren ? (
              <IndeterminateCheckBoxIcon fontSize="inherit" />
            ) : (
              <AddBoxIcon fontSize="inherit" />
            )}
          </IconButton>
        )}
        {node.title}
      </p>
    </div>
  );
};

export default MultitreeElement;
