import { AppBar, Box, Button, makeStyles, Toolbar, Typography } from '@material-ui/core';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import MenuRoundedIcon from '@material-ui/icons/MenuRounded';
import React, { useContext } from 'react';
import clsx from 'clsx';
import Nav from './Nav';
import { UserContext } from '../permissions';

const useStyles = makeStyles((theme) => ({
  menuButton: {
    marginRight: theme.spacing(2),
    color: '#ffffff !important',
  },
  title: {
    flexGrow: 1,
  },
}));

const Header = () => {
  const classes = useStyles();
  const userInfo = useContext(UserContext)
  const loggout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('consumer');
    window.location.href = '/';
  };

  // -- Drawer
  // const classes = useStyles();
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  // -- Menu v listu
  const [open, setOpen] = React.useState(true);

  const handleClick = () => {
    setOpen(!open);
  };

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === 'top' || anchor === 'bottom',
      })}
      role='presentation'
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <Nav></Nav>
    </div>
  );

  return (
    <AppBar position='static'>
      <Toolbar>
        {/* <Logo to="/" /> */}
        {/* -- Drawer*/}
        <div>
          {['left'].map((anchor) => (
            <React.Fragment key={anchor}>
              <IconButton className='menuButton' color='inherit' onClick={toggleDrawer(anchor, true)}>
                <MenuRoundedIcon style={{ color: '#fff' }} />
              </IconButton>
              <Drawer className='drawMenu' anchor={anchor} open={state[anchor]} onClose={toggleDrawer(anchor, false)}>
                {list(anchor)}
              </Drawer>
            </React.Fragment>
          ))}
        </div>
        {/* Konec Drawera */}
        <Typography variant='h6'>Správa CDI</Typography>
        <Box className={classes.title}></Box>
        <div className='userName'>{userInfo.username} •</div>
        <Button color='inherit' onClick={loggout}>
          Odhlásit
        </Button>
      </Toolbar>
    </AppBar>
  );
};
export default Header;
