import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, makeStyles, Modal } from '@material-ui/core';
import React from 'react';
import { AssetToUploadPicker } from '../forms/FilePicker';
import Loader from '../layout/Loader';
import axios from '../utils/axios';
import { AssetTable } from './AssetTable';
import ServerResponseAlert from './ServerResponseAlert';

const IMAGE_ASSET_TYPES = ['image-cover', 'image-carousel'];
const ATTACHMENT_ASSET_TYPES = ['file-attachment'];
const AUDIO_ASSET_TYPES = ['sample-audio', 'audio-playlist', 'audio-chapter-master', 'audio-archive'];

export const STATUS_ACTIVE = 'status-active';
export const STATUS_UPDATE = 'status-update';
export const STATUS_CREATE = 'status-create';

export const ASSET_MEDIA = 'media-asset';
export const ASSET_DISTRIBUTION = 'distribution-asset';
export const ASSET_SOURCE = 'source-asset';

export const isImageAsset = (asset) => IMAGE_ASSET_TYPES.includes(asset.type);
export const isAttachmentAsset = (asset) => ATTACHMENT_ASSET_TYPES.includes(asset.type);
export const isAudioAsset = (asset) => AUDIO_ASSET_TYPES.includes(asset.type);
export const isOtherAsset = (asset) => ![...IMAGE_ASSET_TYPES, ...ATTACHMENT_ASSET_TYPES, ...AUDIO_ASSET_TYPES].includes(asset.type);

const SORTING_PRIORITIES = { // the higher priority, the higher in list the item will be, must be grater than zero
  'audio-chapter-master': 2,
  'sample-audio': 1,
};

const useStyles = makeStyles({
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: '-20px',
  },
});

export const AssetList = ({
  productId,
  readOnly = false,
}) => {
  const classes = useStyles();

  const [assets, setAssets] = React.useState([]);

  const [assetModalOpened, setAssetModalOpened] = React.useState(false);
  const [editedAsset, setEditedAsset] = React.useState(null);
  const [modalLoading, setModalLoading] = React.useState(false);
  const [deletedAsset, setDeletedAsset] = React.useState(null);

  const [eventServerSuccess, setEventServerSuccess] = React.useState(null);
  const [eventServerMessage, setEventServerMessage] = React.useState(null);

  const [ebookEventServerSuccess, setEbookEventServerSuccess] = React.useState(null);
  const [ebookEventServerMessage, setEbookEventServerMessage] = React.useState(null);

  const [serverSuccess, setServerSuccess] = React.useState(null);
  const [serverMessage, setServerMessage] = React.useState(null);
  const [loading, setLoading] = React.useState(false);

  // ===== ASSETS PROCESSING =====

  const sortAssets = (assets) => assets
    .sort((a, b) => a.filename < b.filename ? -1 : (a.filename > b.filename ? 1 : 0))
    .sort((a, b) => a.type < b.type ? -1 : (a.type > b.type ? 1 : 0))
    .sort((a, b) => {
      const priorityA = SORTING_PRIORITIES[a.type] || 0;
      const priorityB = SORTING_PRIORITIES[b.type] || 0;
      return priorityB - priorityA;
    });

  const processAssets = (mediaAssets, distributionAssets, sourceAssets) => {
    const mappedAssets = [...mediaAssets, ...distributionAssets].map(asset => {
      const source = asset.source_id && sourceAssets.find(item => item.id === asset.source_id);
      const sourceLastUpdate = source && new Date(source.modify_date || source.create_date);
      const assetLastUpdate = new Date(asset.modify_date || asset.create_date);

      if (source && sourceLastUpdate > assetLastUpdate) {
          return {
          ...source,
          source_id: source.id,
          status: STATUS_UPDATE,
        };
      }

      return {
        ...asset,
        status: STATUS_ACTIVE,
      };
    });

    const restOfSources = sourceAssets
      .filter(source => !mappedAssets.some(asset => asset.source_id === source.id))
      .map(source => ({
        ...source,
        source_id: source.id,
        status: STATUS_CREATE,
      }));

    return sortAssets([...mappedAssets, ...restOfSources]);
  };

  // ===== SERVER COMMUNICATION =====

  const loadAssets = async () => {
    setLoading(true);
    try {
      const mediaAssets = (await axios.get(`product/${productId}/asset`)).data.data.map(item => ({
        ...item,
        asset_variant: ASSET_MEDIA,
      }));
      const distributionAssets = (await axios.get(`product/${productId}/distribution-asset`)).data.data.map(item => ({
        ...item,
        asset_variant: ASSET_DISTRIBUTION,
      }));
      const sourceAssets = (await axios.get(`product/${productId}/source-asset`)).data.data.map(item => ({
        ...item,
        asset_variant: ASSET_SOURCE,
      }));

      const result = processAssets(
        mediaAssets,
        distributionAssets,
        sourceAssets,
      );

      setAssets(result);
    } catch (e) {
      setServerSuccess(false);
      setServerMessage('Nastala neočekávaná chyba při načítání dat.');
    } finally {
      setLoading(false);
    }
  };

  const handleSampleGeneration = () => {
    setLoading(true);
    const doGenerate = async () => {
      try {
        await axios.post(`product/${productId}/events/audio-sample`);

        setEventServerSuccess(true);
        setEventServerMessage('Požadavek byl zařazen do fronty.');
      } catch (e) {
        setEventServerSuccess(false);
        setEventServerMessage('Při zadávání požadavku nastala chyba. Zkuste to prosím později.');
      } finally {
        setLoading(false);
      }
    };

    doGenerate();
  };

  const handleBundleGeneration = () => {
    setLoading(true);
    const doGenerate = async () => {
      try {
        await axios.post(`product/${productId}/events/audio-bundle`);

        setEventServerSuccess(true);
        setEventServerMessage('Požadavek byl zařazen do fronty.');
      } catch (e) {
        setEventServerSuccess(false);
        setEventServerMessage('Při zadávání požadavku nastala chyba. Zkuste to prosím později.');
      } finally {
        setLoading(false);
      }
    };

    doGenerate();
  };

  const handleEbookSampleGeneration = () => {
    setLoading(true);
    const doGenerate = async () => {
      try {
        await axios.post(`product/${productId}/events/ebook-sample`);

        setEbookEventServerSuccess(true);
        setEbookEventServerMessage('Požadavek byl zařazen do fronty.');
      } catch (e) {
        setEbookEventServerSuccess(false);
        setEbookEventServerMessage('Při zadávání požadavku nastala chyba. Zkuste to prosím později.');
      } finally {
        setLoading(false);
      }
    };

    doGenerate();
  };

  const handleAssetDelete = (asset) => {
    handleDeleteDialogClose();
    setLoading(true);
    const doDelete = async () => {
      try {
        if (asset.asset_variant === ASSET_SOURCE) {
          await axios.delete(`product/${productId}/source-asset/${asset.id}`);
        } else if (asset.asset_variant === ASSET_MEDIA) {
          await axios.delete(`product/${productId}/asset/${asset.id}`);
        } else if (asset.asset_variant === ASSET_DISTRIBUTION) {
          await axios.delete(`product/${productId}/distribution-asset/${asset.id}`);
        }

        setAssets(prevState => prevState.filter(item => item.id !== asset.id));
        setServerSuccess(true);
        setServerMessage('Soubor byl smazán.');
      } catch (e) {
        setServerSuccess(false);
        setServerMessage('Soubor se nepodařilo smazat, zkuste to prosím později.');
      } finally {
        setLoading(false);
      }
    };

    doDelete();
  };
  
  const createFormData = (data) => {
    var formData = new FormData();
    if (data.chapter_title) formData.set('chapter_title', data.chapter_title);
    if (data.external_reference_key) formData.set('external_reference_key', data.external_reference_key);
    if (data.order) formData.set('order', data.order);
    if (data.duration) formData.set('duration', Number(data.duration));
    if (data.page_count) formData.set('page_count', data.page_count);
    if (data.type) formData.set('type', data.type);
    if (data.file.length > 0) formData.append('file', data.file[0]);

    return formData;
  };

  const handleAssetEdit = (assetData) => {
    setModalLoading(true);
    const doEdit = async () => {
      try {
        const formData = createFormData(assetData);
        const assetId = editedAsset.source_id;
        await axios.post(
          `/product/${productId}/source-asset/${assetId}`,
          formData,
          { headers: { "Content-Type": "multipart/form-data" } },
        );
        const response = await axios.get(`/product/${productId}/source-asset/${assetId}`); // because of storage ID

        setAssets(prevValue => prevValue.map(item => {
          if (item.id !== editedAsset.id) return item;
          return {
            ...response.data.data,
            asset_variant: ASSET_SOURCE,
            source_id: response.data.data.id,
            status: editedAsset.status === STATUS_CREATE ? STATUS_CREATE : STATUS_UPDATE,
          };
        }));
        setServerSuccess(true);
        setServerMessage('Změny byly odeslány ke zpracování.');
      } catch (e) {
        setServerSuccess(false);
        setServerMessage('Změny se nepodařilo uložit, zkuste to prosím později.');
      } finally {
        setModalLoading(false);
        handleAssetModalClose();
      }
    };

    doEdit();
  };

  const handleAssetCreate = (assetData) => {
    setModalLoading(true);
    const doCreate = async () => {
      try {
        const formData = createFormData(assetData);
        const createResponse = await axios.post(
          `/product/${productId}/source-asset`,
          formData,
          { headers: { "Content-Type": "multipart/form-data" } },
        );
        const response = await axios.get(`/product/${productId}/source-asset/${createResponse.data.data.id}`); // because of storage ID

        setAssets(prevValue => sortAssets([
          ...prevValue,
          {
            ...response.data.data,
            asset_variant: ASSET_SOURCE,
            source_id: response.data.data.id,
            status: STATUS_CREATE,
          }
        ]));
        setServerSuccess(true);
        setServerMessage('Změny byly odeslány ke zpracování.');
      } catch (e) {
        setServerSuccess(false);
        setServerMessage('Změny se nepodařilo uložit, zkuste to prosím později.');
      } finally {
        setModalLoading(false);
        handleAssetModalClose();
      }
    };

    doCreate();
  };

  // ===== EVENT HANDLERS =====

  const handleReload = () => {
    loadAssets();
    handleCloseEventAlert();
    handleCloseAlert();
  };

  const handleCloseEventAlert = () => {
    setEventServerSuccess(null);
    setEventServerMessage(null);
  };
  const handleCloseEbookEventAlert = () => {
    setEbookEventServerSuccess(null);
    setEbookEventServerMessage(null);
  };

  const handleCloseAlert = () => {
    setServerSuccess(null);
    setServerMessage(null);
  };

  const handleAssetEditRequest = (asset) => {
    setEditedAsset(asset);
    setAssetModalOpened(true);
  };

  const handleAssetCreateRequest = () => {
    setAssetModalOpened(true);
  };

  const handleAssetDeleteRequest = (asset) => {
    setDeletedAsset(asset);
  };

  const handleAssetModalClose = () => {
    setEditedAsset(null);
    setAssetModalOpened(false);
  };

  const handleDeleteDialogClose = () => {
    setDeletedAsset(null);
  };

  // ===== EFFECTS =====

  React.useEffect(() => {
    if (productId) {
      loadAssets();
    }
  }, [productId]);

  // ===== RENDER =====

  if (!productId) {
    return (
      <p>Pro nahrání souborů nejdříve produkt prosím uložte.</p>
    );
  }

  return (
    <>
      {serverSuccess !== null && (
        <ServerResponseAlert
          serverSuccess={serverSuccess}
          serverReply={serverMessage}
          onClose={handleCloseAlert}
        />
      )}
      {!readOnly && (
        <>
          <div className={classes.actions}>
            <Button
              onClick={handleReload}
              variant="outlined"
              color="primary"
              size="small"
            >
              Načíst znovu
            </Button>
          </div>
          <div>
            <Button
              onClick={handleAssetCreateRequest}
              variant="outlined"
              color="secondary"
              size="small"
            >
              Nahrát nový soubor
            </Button>
          </div>
        </>
      )}
      {loading && <Loader />}
      {assets.length > 0 && (
        <>
          <AssetTable
            title="Obrázkové soubory"
            assets={assets.filter(item => isImageAsset(item))}
            onEdit={handleAssetEditRequest}
            onDelete={handleAssetDeleteRequest}
            readOnly={readOnly}
          />
          <AssetTable
            title="Přílohy"
            assets={assets.filter(item => isAttachmentAsset(item))}
            onEdit={handleAssetEditRequest}
            onDelete={handleAssetDeleteRequest}
            readOnly={readOnly}
          /> 
          <AssetTable
            title="Audio soubory"
            assets={assets.filter(item => isAudioAsset(item))}
            onEdit={handleAssetEditRequest}
            onDelete={handleAssetDeleteRequest}
            readOnly={readOnly}
          />
          {!readOnly && (
            <>
              {!assets.some(item => item.type === 'sample-audio') && (
                <Button
                  onClick={handleSampleGeneration}
                  variant="outlined"
                  color="secondary"
                  size="small"
                >
                  Generovat sample
                </Button>
              )}
              {!(assets.some(item => item.type === 'audio-playlist') && assets.some(item => item.type === 'audio-archive')) && (
                <Button
                  onClick={handleBundleGeneration}
                  variant="outlined"
                  color="secondary"
                  size="small"
                >
                  Generovat bundle
                </Button>
              )}
            </>
          )}
          {eventServerSuccess !== null && (
            <ServerResponseAlert
              serverSuccess={eventServerSuccess}
              serverReply={eventServerMessage}
              onClose={handleCloseEventAlert}
            />
          )}
          <AssetTable
            title="Ostatní soubory"
            assets={assets.filter(item => isOtherAsset(item))}
            onEdit={handleAssetEditRequest}
            onDelete={handleAssetDeleteRequest}
            readOnly={readOnly}
          />
          {!readOnly && !(assets.some(item => item.type === 'sample-pdf') && assets.some(item => item.type === 'sample-epub')) && (
            <Button
              onClick={handleEbookSampleGeneration}
              variant="outlined"
              color="secondary"
              size="small"
            >
              Generovat sample
            </Button>
          )}
          {ebookEventServerSuccess !== null && (
            <ServerResponseAlert
              serverSuccess={ebookEventServerSuccess}
              serverReply={ebookEventServerMessage}
              onClose={handleCloseEbookEventAlert}
            />
          )}
        </>
      )}
      <Modal open={assetModalOpened} onClose={handleAssetModalClose}>
        <AssetToUploadPicker
          title={editedAsset ? 'Upravit soubor' : 'Přidat soubor'}
          confirmationButtonText={editedAsset ? 'Upravit' : 'Přidat'}
          defaultValues={editedAsset}
          onConfirm={editedAsset ? handleAssetEdit : handleAssetCreate}
          serverLoading={modalLoading}
        />
      </Modal>
      <Dialog open={!!deletedAsset} keepMounted onClose={handleDeleteDialogClose}>
        <DialogTitle>Smazat soubor</DialogTitle>
        <DialogContent>
          <DialogContentText>Tato akce nelze vrátit. Přejete si pokračovat?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteDialogClose} color='primary'>
            ne
          </Button>
          <Button onClick={() => handleAssetDelete(deletedAsset)} color='primary'>
            Ano
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
