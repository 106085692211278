import { Button, FormControl, InputLabel, makeStyles, NativeSelect, Select, TextField } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import Loader from '../layout/Loader';
import useStyles from "../styles/forms";
import axios from "../utils/axios";

function rand() {
  return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
  const top = 50 + rand();
  const left = 50 + rand();
  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStylesLocal = makeStyles({
  horizontal: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
});

export default React.forwardRef((props, ref) => {
  const classesLocal = useStylesLocal();

  console.log("props.productId", props.productId);
  const id = props.productId;
  const assetType = props.assetType ? props.assetType : "source-asset";
  // console.log(myData);
  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);

  const [serverSuccess, setServerSuccess] = useState(null);
  const [serverReply, setServerReply] = useState(false);
  const [loading, setLoading] = useState(false);

  const methods = useForm({
    // defaultValues: {  },
    reValidateMode: "onBlur",
  }); // otherwise form conroller doesnt work

  const { handleSubmit, register, control, getValues, errors } = methods;

  const handleFileUpload = (data) => {
    console.log(data);
    const submitData = async () => {
      try {
        var formData = new FormData();
        // console.log("duration", data.duration, typeof data.duration);
        if (data.chapter_title) formData.set("chapter_title", data.chapter_title);
        if (data.external_reference_key) formData.set("external_reference_key", data.external_reference_key);
        if (data.order) formData.set("order", data.order);
        if (data.duration) formData.set("duration", Number(data.duration));
        if (data.page_count) formData.set("page_count", data.page_count);
        formData.set("type", data.type);
        formData.append("file", data.file[0]);
        // const response = await axios.post(`/product/${id}/${assetType}`, formData, { headers: { "Content-Type": "multipart/form-data" } });
        const response = await axios.post(`/product/${id}/source-asset`, formData, { headers: { "Content-Type": "multipart/form-data" } });
        console.log(response);
        const responseJson = response.data;
        if (response.status === 200 || response.status === 201) {
          // created
          setServerSuccess(true);
          setServerReply("Soubor byl úspěšně nahrán.");
          console.log("Success");
          window.scrollTo({ top: 0, behavior: "smooth" });
        } else {
          // data error
          // serverErrors(data);
          setServerSuccess(false);
          setServerReply("Chyba při nahrávání - " + responseJson.message);
          console.log("Chyba při nahrávání - " + responseJson.message);
          window.scrollTo({ top: 0, behavior: "smooth" });
        }
      } catch (err) {
        console.log("Chyba při nahrávání. " + err);
        setServerSuccess(false);
        setServerReply("Chyba při nahrávání: " + err.response.data.message);
        window.scrollTo({ top: 0, behavior: "smooth" });
      } finally {
        setLoading(false);
      }
    };
    setLoading(true);
    submitData();
  };
  const mediaAssetTypes = ["image-cover", "image-carousel", "sample-audio", "file-attachment", "sample-pdf", "sample-epub"];
  const distributionAssetTypes = ["ebook-master-pdf", "ebook-master-epub", "audio-chapter-master"];
  const dynamicSelect = () => {
    const outer = (
      <NativeSelect
        inputProps={{
          name: "type",
          id: "type",
        }}
      >
        {assetType == "source-asset"
          ? mediaAssetTypes.map((item) => (
              <option value={item} key={item}>
                {item}
              </option>
            ))
          : assetType != "source-asset" &&
            distributionAssetTypes.map((item) => (
              <option value={item} key={item}>
                {item}
              </option>
            ))}
      </NativeSelect>
    );
    return outer;
  };
  return (
    <div style={modalStyle} className={classes.modalWindow}>
      <h2 id="simple-modal-title">Nahrát nový soubor </h2>
      {/* <p style={{ margin: "-1rem 0 0" }}>({assetType === "source-asset" ? "media-asset" : "distribution-asset"})</p> */}
      {serverReply && (
        <>
          <Alert severity={serverSuccess ? "success" : "error"}>{serverReply}</Alert>
          <br />
          <Button
            size="large"
            variant="contained"
            color="primary"
            onClick={() => {
              window.location.reload();
            }}
          >
            Hotovo
          </Button>
          <br />
        </>
      )}
      {!serverReply && (
        <form onSubmit={handleSubmit(handleFileUpload)}>
          <Controller as={TextField} className={classes.inputFull} inputRef={register({})} label="Titulek" name="chapter_title" control={control} />
          <br />
          <FormControl className={classes.inputSmall}>
            <InputLabel id="type">Typ *</InputLabel>
            <Controller as={dynamicSelect()} inputRef={register({})} name="type" control={control} />
          </FormControl>
          <br />
          <br />
          <InputLabel id="file">Soubor *</InputLabel>
          {/* <Controller as={TextField} className={classes.inputFull} inputRef={register({})} label="Titulek" name="chapter_title" control={control} /> */}

          {/*           <Controller
            as={<input type="file" />}
            className={classes.inputFull}
            inputRef={register({})}
            rules={{ required: true }}
            error={errors.file ? true : false}
            id="file"
            name="file"
            control={control}
          /> */}

          <input ref={register({})} id="file" name="file" type="file" />
          <br />
          <Controller as={TextField} className={classes.inputFull} inputRef={register({})} label="Externí klíč" name="external_reference_key" control={control} />
          <Controller as={TextField} className={classes.inputFull} inputRef={register({})} label="Pořadí" name="order" control={control} />
          <Controller as={TextField} className={classes.inputFull} inputRef={register({})} label="Délka stopy" name="duration" control={control} />
          <Controller as={TextField} className={classes.inputFull} inputRef={register({})} label="Počet stránek" name="page_count" control={control} />
          <br />
          <br />
          <div className={classesLocal.horizontal}>
            <Button size="large" variant="contained" color="primary" type="submit" disabled={loading}>
              Nahrát
            </Button>
            { loading && (
              <Loader style={{width: '50px', height: '50px', margin: '0px', marginLeft: '10px'}} />
            )}
          </div>
        </form>
      )}
    </div>
  );
});
