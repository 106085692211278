import { Box, TextField, Select, FormControl, Paper, Typography, Grid, InputLabel, MenuItem, NativeSelect, TablePagination } from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import { Alert, AlertTitle } from "@material-ui/lab";
import MaterialTable from "material-table";
import MTLocalization from "../utils/MTLocalization";
import React, { useEffect, useState } from "react";
import useStyles from "../styles/forms";
import axios from "../utils/axios";
import { AbilityContext, CREATE, UPDATE, PUBLISHER } from "../permissions";
import { useAbility } from "@casl/react";
import { subject } from "@casl/ability";
import ServerResponseAlert from '../components/ServerResponseAlert';
import SearchBox from '../components/SearchBox';
import { getSortString, getSortReadyColumns } from '../utils/tableSortUtils';

const SORT_FIELDS = {
  name: 'name',
  external_reference_key: 'externalReferenceKey',
  is_blacklisted: 'isBlacklisted',
};

export default (props) => {
  const classes = /*  */ useStyles();
  const ability = useAbility(AbilityContext);

  const [state, setState] = useState(null);
  const [entityTypeFilter, setEntityTypeFilter] = useState('both');
  const [searchNameFilter, setSearchNameFilter] = useState('');
  const [serverSuccess, setServerSuccess] = useState(null);
  const [serverReply, setServerReply] = useState(false);
  const apiEndpoint = '/publisher';

  // -- Data loading and pagination
  const [response, setResponse] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setDataLoading] = useState(false);
  const [error, setDataError] = useState(null);
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const [sort, setSort] = useState(null);

  const getEntityTypeFilterParameter = (value) => {
    if (value === 'publishers') return { is_publishing_house: 1 };
    else if (value === 'brands') return { is_publishing_house: 0 };
    else return {};
  }

  const loadPublisherData = (page = currentPage, size = pageSize, typeFilter = entityTypeFilter, searchFilter = searchNameFilter, sortDesc = sort) => {
    const doFetch = async () => {
      setDataLoading(true);
      try {
        const query = {
          page: page + 1,
          limit: size,
          ...getEntityTypeFilterParameter(typeFilter),
          ...(searchFilter && { name: searchFilter }),
          ...(sortDesc && { sort: getSortString(sortDesc, SORT_FIELDS) }),
        }
        const res = await axios('/publisher', { params: query });
        setResponse(res.data);
        setTotalCount(res.data.meta.total_number);
      } catch (e) {
        setDataError(e);
      } finally {
        setDataLoading(false);
      }
    };

    doFetch();
  }

  const handlePageChange = (newPage, newPageSize) => {
    setCurrentPage(newPage);
    setPageSize(newPageSize);
    loadPublisherData(newPage, newPageSize);
  }

  const handleSearchFilterChange = (value) => {
    setCurrentPage(0);
    setSearchNameFilter(value);
    loadPublisherData(0, pageSize, entityTypeFilter, value);
  }

  const handleSearchFilterReset = () => {
    handleSearchFilterChange(null);
  }

  const handleEntityTypeFilterChange = (event) => {
    setCurrentPage(0);
    setEntityTypeFilter(event.target.value);
    loadPublisherData(0, pageSize, event.target.value);
  };

  const handleOrderChange = (columnIdx, order) => {
    const sort = columnIdx >= 0 ? { field: state.columns[columnIdx].field, order: order } : null;
    
    setCurrentPage(0);
    setSort(sort);
    loadPublisherData(0, pageSize, entityTypeFilter, searchNameFilter, sort);
  };

  // runs on load and on filter text change
  useEffect(() => {
    loadPublisherData();
  }, []);



  useEffect(() => {
    if (response && response.status === 200) {
      console.log(response);
      const lookupTable = { true: "ano", false: "ne" };
      const newData = {
        columns: [
          { title: "Název", field: "name" },
          {
            title: "ID / Business ID",
            field: "business_id",
            render: (rowData) => (
              <span className="smallFont">
                {rowData.id}
                <br />
                {rowData.business_id}
              </span>
            ),
            width: 300,
            editComponent: (props) => {
              return (
                <>
                  <span className="smallFont">{props.rowData.id}</span>
                  <br />
                  <TextField
                    as={TextField}
                    // className={classes.input}
                    value={props.rowData.business_id}
                    placeholder="Business ID"
                    name="business_id"
                    onChange={(e) =>
                      props.onRowDataChange({
                        ...props.rowData,
                        business_id: e.target.value,
                      })
                    }
                  />
                </>
              );
            },
          },
          { title: "Externí klíč", field: "external_reference_key", render: (rowData) => <span className="smallFont">{rowData.external_reference_key}</span> },
          {
            title: "Vydavatelství / Externí",
            field: "is_external",
            lookup: { ...lookupTable },
            render: (rowData) => (
              <span className="smallFont">
                {rowData.is_publishing_house ? "ano" : "ne"}
                <br />
                {rowData.is_external ? "ano" : "ne"}
              </span>
            ),
            width: 80,
            editComponent: (props) => (
              <>
                <NativeSelect
                  name="is_publishing_house"
                  id="is_publishing_house"
                  value={props.rowData.is_publishing_house ? 'true' : 'false'}
                  onChange={(e) =>
                    props.onRowDataChange({
                      ...props.rowData,
                      is_publishing_house: e.target.value === 'true',
                    })
                  }
                >
                  <option value="false">ne</option>
                  <option value="true">ano</option>
                </NativeSelect>
                <br />
                <NativeSelect
                  name="is_external"
                  id="is_external"
                  value={props.rowData.is_external ? 'true' : 'false'}
                  onChange={(e) =>
                    props.onRowDataChange({
                      ...props.rowData,
                      is_external: e.target.value === 'true',
                    })
                  }
                >
                  <option value="false">ne</option>
                  <option value="true">ano</option>
                </NativeSelect>
              </>
            ),
          },
          {
            title: 'Na blacklistu',
            field: 'is_blacklisted',
            lookup: { false: 'ne', true: 'ano' },
            initialEditValue: 'false',
            editComponent: (props) => (
                <NativeSelect
                  name="is_blacklisted"
                  id="is_blacklisted"
                  value={props.rowData.is_blacklisted ? 'true' : 'false'}
                  onChange={(e) =>
                    props.onRowDataChange({
                      ...props.rowData,
                      is_blacklisted: e.target.value === 'true',
                    })
                  }
                >
                  <option value="false">ne</option>
                  <option value="true">ano</option>
                </NativeSelect>
            ),
          }
          /* { title: "Vydavatelství", field: "is_publishing_house", lookup: { ...lookupTable }, width: 80 }, */
        ],
        data: [...response.data],
      };
      setState(newData);
    }
  }, [response]);


  const submitData = async (newData, oldData) => {
    setDataLoading(true);
    let newPublisherItem = oldData === undefined;
    if (newPublisherItem && newData.id) {
      delete newData.id;
    }
    try {
      let newBody = {
        ...newData,
      };
      newBody = JSON.stringify(newBody);
      console.log(newBody);
      const response = newPublisherItem ? await axios.post(apiEndpoint, newBody) : await axios.put(apiEndpoint + "/" + oldData.id, newBody);
      const responseJson = response.data;
      setDataLoading(false);
      if (response.status === 200 || response.status === 201) {
        // created
        setServerSuccess(true);
        setServerReply("V pořádku uloženo.");
        console.log("Success");
        console.log(responseJson.data);
        handlePageChange(currentPage, pageSize)
        window.scrollTo({ top: 0, behavior: "smooth" });
        return responseJson.data;
      } else {
        setServerSuccess(false);
        setServerReply("Chyba při ukládání.");
        console.log("Chyba při ukládání.");
        window.scrollTo({ top: 0, behavior: "smooth" });
        return false;
      }
    } catch (err) {
      console.log("Chyba při ukládání. " + err);
      setServerSuccess(false);
      setServerReply(err);
      window.scrollTo({ top: 0, behavior: "smooth" });
      setDataLoading(false);
      return false;
    }
  };
  const deleteItem = async (oldData) => {
    setDataLoading(true);
    try {
      const response = await axios.delete(apiEndpoint + oldData.id);
      const data = response.data;
      setDataLoading(false);
      if (response.status === 200 || response.status === 201) {
        // created
        setServerSuccess(true);
        setServerReply("Successfully deleted.");
        console.log("Success");
        handlePageChange(currentPage, pageSize);
        window.scrollTo({ top: 0, behavior: "smooth" });
      } else {
        setServerSuccess(false);
        setServerReply("Error deleting data.");
        console.log("Chyba při mazání.");
        window.scrollTo({ top: 0, behavior: "smooth" });
      }
    } catch (err) {
      setDataLoading(false);
      setServerReply("Error deleting data. " + err.message);
      console.log("Error deleting data. " + err);
    }
  };

  return (
    <Paper className="basePaper publishbrands">
      <Typography variant="h5">Vydavatelé / Značky</Typography>
      {serverReply && (
        <>
          <br />
          <ServerResponseAlert
            serverSuccess={serverSuccess}
            serverReply={serverReply}
          />
          <br />
        </>
      )}
      <Box>
        {/* {loading && <Loader />} */}
        {error && (
          <Alert severity="error" style={{ marginTop: 20 }}>
            <AlertTitle>Ajajaj!</AlertTitle>
            Chyba při načítání dat.
          </Alert>
        )}
      </Box>
      <SearchBox
        onFilterSubmit={handleSearchFilterChange}
        onFilterReset={handleSearchFilterReset}
      />
      <FormControl component="fieldset" style={{ position: "relative", zIndex: 555555 }}>
        {/* <FormLabel component="legend">Gender</FormLabel> */}
        <RadioGroup row aria-label="gender" name="gender1" value={entityTypeFilter} onChange={handleEntityTypeFilterChange}>
          <FormControlLabel value="both" control={<Radio />} label="Obojí" />
          <FormControlLabel value="publishers" control={<Radio />} label="Vydavatelé" />
          <FormControlLabel value="brands" control={<Radio />} label="Značky" />
          {loading && <div style={{ marginTop: "8px", color: "#ff9900" }}>Nahrávám...</div>}
        </RadioGroup>
      </FormControl>
      {state && (
        <MaterialTable
          key={pageSize} // workaround for not functioning page size change
          localization={MTLocalization}
          options={{ pageSize: pageSize, actionsColumnIndex: -1, addRowPosition: "first", toolbar: false }}
          style={{ margin: 0, boxShadow: "none" }}
          columns={getSortReadyColumns(state.columns, Object.keys(SORT_FIELDS), sort)}
          data={state.data}
          onOrderChange={handleOrderChange}
          editable={{
            isEditable: rowData => ability.can(UPDATE, subject(PUBLISHER, rowData)),
            ...(ability.can(CREATE, PUBLISHER) && {
              onRowAdd: (newData) =>
                new Promise((resolve, reject) => {
                  let saveResponse = submitData(newData);
                  if (saveResponse) {
                    resolve(saveResponse);
                  } else {
                    reject(error);
                  }
                })
            }),
            ...(ability.can(UPDATE, PUBLISHER) && {
              onRowUpdate: (newData, oldData) =>
                new Promise((resolve, reject) => {
                  let saveResponse = submitData(newData, oldData);
                  if (saveResponse) {
                    resolve(saveResponse);
                  } else {
                    reject(error);
                  }
                })
            }),
            /*           onRowDelete: (oldData) =>
              new Promise((resolve, reject) => {
                let deleteResponse = deleteItem(oldData);
                if (deleteResponse) {
                  resolve(deleteResponse);
                } else {
                  reject(error);
                }
              }), */
          }}
          components={{
            Pagination: props => <TablePagination {...props}
              rowsPerPageOptions={[5, 10, 20, 30]}
              rowsPerPage={pageSize}
              count={totalCount}
              page={currentPage}
              onChangePage={(e, page) => handlePageChange(page, pageSize)}
              onChangeRowsPerPage={(e) => handlePageChange(currentPage, e.target.value)}
            />
          }}
        />
      )}
    </Paper>
  );
};
