import { Button, TextField, Typography, Checkbox } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import React, { useEffect, useRef, useState } from "react";
import useStyles from "../../styles/forms";
import axios from "../../utils/axios";
import hierarchize from "../../utils/hierarchize";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

// DEPRECATED
export default (props) => {
  // console.log(props);
  // console.log(props.data);
  const classes = useStyles();
  let readOnlyComponent = typeof props.callBack !== "function";
  //   console.log(props);

  const [genreItems, setGenreItems] = useState(props.data);
  const inputEl = useRef(null);

  // -- Autocomplete:
  const [openAutocomplete, setOpenAutocomplete] = React.useState(false);
  const [valueState, setValueState] = React.useState([...genreItems]);
  const [options, setOptions] = React.useState([]);
  const [lookupTable, setLookupTable] = React.useState([]);
  const loading = openAutocomplete && options.length === 0;

  React.useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    (async () => {
      let response = await axios(`/${props.endpoint}?limit=500`);
      // await sleep(1e3); // For demo purposes.
      let fullList = response.data;
      fullList = hierarchize(fullList.data);
      setLookupTable(fullList);
      if (active) {
        // setOptions(Object.keys(fullList.data).map((key) => fullList.data[key].item[0]));
        setOptions(fullList);
      }
    })();

    return () => {
      active = false;
    };
  }, [loading]);
  // }, []);

  React.useEffect(() => {
    if (!openAutocomplete) {
      setOptions([]);
    }
  }, [openAutocomplete]);

  const removeDupes = (selectedGenres) => {
    let genresWithoutDupes = Object.values(
      selectedGenres.reduce((a, c) => {
        a[c.id] = c;
        return a;
      }, {})
    );
    return genresWithoutDupes;
  };

  useEffect(() => {
    let cleanedGenreArray = genreItems.map(
      (item) => item.id
      // return { id: item.id, name: item.title };
    );
    // console.log("cleanedGenreArray");
    // console.log(cleanedGenreArray);
    if (readOnlyComponent === false) {
      // props.callBack({ eval(props.endpoint): cleanedGenreArray });
      let myObject = {};
      // incostencies in API naming:
      let realName = props.endpoint === "genre" ? "genres" : props.endpoint === "category" ? "categories" : props.endpoint === "edition" ? "editions" : props.endpoint;
      myObject[realName] = cleanedGenreArray;
      props.callBack(myObject);
    }
  }, [genreItems]);

  return (
    <>
      <Typography variant="caption">{props.label}</Typography>
      <table className="lookup">
        <tbody>
          {genreItems &&
            readOnlyComponent === true &&
            genreItems.map((item, index) => (
              <tr key={index}>
                <td style={{ width: "99%" }}>{item.title}</td>
              </tr>
            ))}
          {readOnlyComponent === false && (
            <tr>
              <td colSpan="2">
                {/* <Link to="">Add</Link> */}
                <Autocomplete
                  multiple
                  id="checkboxes-tags-demo"
                  // label="Přidat kategorii"
                  options={options}
                  disableCloseOnSelect
                  getOptionLabel={(option) => (option.title ? option.title : "")}
                  renderOption={(option, { index }) => (
                    <>
                      <span style={{ width: option.indent * 33 }}></span> {option.indent > 0 ? " " : ""}
                      <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={valueState.find((item) => item.id === option.id) ? true : false} />
                      {/* {option.idx} &nbsp; */}
                      {option.title}
                    </>
                  )}
                  style={{ width: "95%" }}
                  className="tagClound"
                  value={[...genreItems]}
                  loading={loading}
                  renderInput={(params) => <TextField {...params} variant="outlined" label="" placeholder="..." />}
                  /*                   value={valueState} */
                  onChange={(event, selectedGenres) => {
                    let genresWithoutDupes = removeDupes(selectedGenres);
                    setGenreItems([...genresWithoutDupes]);
                    setValueState(genresWithoutDupes);
                  }}
                  onOpen={() => {
                    setOpenAutocomplete(true);
                  }}
                  onClose={() => {
                    setOpenAutocomplete(false);
                  }}
                />
              </td>
            </tr>
          )}
        </tbody>
      </table>
      {/*       <ul style={{ padding: 0, margin: 0, listStyle: "none" }}>
        {lookupTable.map((option) => (
          <li style={{ padding: 0, margin: 0, listStyle: "none" }}>
            <span style={{ width: option.indent * 33, display: "inline-block" }}></span> {option.indent > 0 ? " " : ""}
            {option.idx} &nbsp;
            {option.title}
          </li>
        ))}
      </ul> */}
    </>
  );
};
