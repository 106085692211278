const nullToString = object => {
  const keys = Object.keys(object);
  let newObject = {};
  for (const key of keys) {
    newObject[key] = object[key] === null ? "" : object[key];
  }
  // console.log(newObject);
  return newObject;
};
export default nullToString;
