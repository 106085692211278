import HeadsetRoundedIcon from '@material-ui/icons/HeadsetRounded';
import MenuBookRoundedIcon from '@material-ui/icons/MenuBookRounded';
import TabletMacRoundedIcon from '@material-ui/icons/TabletMacRounded';
import { Box, Button, Grid, Paper, Typography, Tabs, Tab, makeStyles } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useFetch from '../hooks/useFetch';
import DistributionAssets from '../layout/ProvisioningAssets';
import Loader from '../layout/Loader';
import TabPanel from '../layout/TabPanel';
import formatDate from '../utils/formatDate';
import axios from '../utils/axios';
import { Link } from 'react-router-dom';

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
});

export default (props) => {
  let { id } = useParams();
  const { response, loading, error } = useFetch(`/provision/${id}`);
  const [assetData, setassetData] = useState(null);
  useEffect(() => {
    // console.log(response);
    if (response && response.data && response.data.provision) {
      console.log(response.data.provision);
      const assets = response.data.provision.ebook_assets ? response.data.provision.ebook_assets : response.data.provision.audio_assets ? response.data.provision.audio_assets : [];
      setassetData(assets);
      console.log(assets);
    }
  }, [response]);
  return (
    <Paper className='basePaper'>
      <Grid container direction='row' justify='space-between'>
        <Grid item xs={12} md={9}>
          <Typography variant='h5' style={{ textTransform: 'capitalize' }}>
            {response && response.data.product_name ? response.data.product_name : '-'}
          </Typography>
          <Box>
            {loading && <Loader />}
            {error && (
              <Alert severity='error' style={{ marginTop: 20 }}>
                <AlertTitle>Ajajaj!</AlertTitle>
                Chyba při načítání dat.
              </Alert>
            )}
          </Box>
          <br />
          <strong>Odběratel:</strong> {response && response.data.consumer ? response.data.consumer : '-'}
          <br />
          <strong>CDI ID distribuce:</strong> {response && response.data.id ? response.data.id : '-'}
          <br />
          <strong>Číslo objednávky: </strong>
          {response && response.data.order_number ? response.data.order_number : '-'}
          <br />
          <strong>Datum zakoupení: </strong>
          {response && response.data.purchase_date ? formatDate(response.data.purchase_date, true) : '-'}
          <br />
          <strong>Platnost do:</strong> {response && response.data.valid_until ? formatDate(response.data.valid_until, true) : '-'}
          <br />
          <strong>Datum zprocesování v CDI:</strong> {response && response.data.processed ? formatDate(response.data.processed, true) : '-'}
          <br />
          <br />
          <strong>Název produktu:</strong>{' '}
          {response && response.data.product_name ? <Link to={`/product/${response.data.product_id}`}>{response.data.product_name}</Link> : '-'}
          <br />
          <strong>CDI ID produktu:</strong> {response && response.data.product_id ? response.data.product_id : '-'}
          <br />
          {response &&
            response.data.type &&
            (response.data.type === 'audiobook' ? (
              <span className='productType'>
                <HeadsetRoundedIcon style={{ verticalAlign: 'middle' }} />
                Audiobook
              </span>
            ) : response.data.type === 'ebook' ? (
              <span className='productType'>
                <TabletMacRoundedIcon style={{ verticalAlign: 'middle' }} />
                Ebook
              </span>
            ) : response.data.type === 'physicalgood' ? (
                <span className='productType'>
                <MenuBookRoundedIcon style={{ verticalAlign: 'middle' }} />
                Fyzické zboží
              </span>
            ) : response.data.type === 'physicalgoodsnonbooks' ? (
                <span className='productType'>
                <MenuBookRoundedIcon style={{ verticalAlign: 'middle' }} />
                Fyzické zboží neknižní
              </span>
            ) : (
              <span className='productType'>
                <MenuBookRoundedIcon style={{ verticalAlign: 'middle' }} />
                neznamý?
              </span>
            ))}
          <br />
        </Grid>
      </Grid>
      <Grid item xs={12} className='fullWidth'>
        <Typography variant='caption'>Soubory</Typography>
        {assetData && <DistributionAssets data={assetData} />}
      </Grid>
    </Paper>
  );
};
