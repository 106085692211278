import React from 'react';
import { Chip, IconButton, InputAdornment, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import AddIcon from '@material-ui/icons/Add';

const MultivalueInput = ({
  className,
  classes,
  name,
  value,
  onChange,
}) => (
  <Autocomplete
    className={className}
    classes={classes}
    name={name}
    multiple
    freeSolo
    disableClearable
    options={[]}
    value={value}
    onChange={(e, values) => {
      e.stopPropagation();
      onChange(values);
    }}
    renderTags={(selected, getTagProps) => selected.map((option, index) => (
      <Chip label={option} {...getTagProps({ index })} />
    ))}
    renderInput={(params) => (
      <TextField {...params} InputProps={{
        ...params.InputProps,
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="add value to list"
              onClick={() => {
                if (!params.inputProps.value) return;
                onChange([...value, params.inputProps.value]);
              }}
              size="small"
            >
              <AddIcon />
            </IconButton>
          </InputAdornment>
        ),
      }} />
    )}
  />
);

export default MultivalueInput;
