import React from "react";
import { Box, Button, Card, Tabs, Tab, Grid, Paper, Typography } from "@material-ui/core";
const TabPanel = (props) => {
  const { children, value, index, ...other } = props;
  return (
    <div role="tabpanel" hidden={value !== index} id={`scrollable-auto-tabpanel-${index}`} aria-labelledby={`scrollable-auto-tab-${index}`} {...other}>
      {value === index && <Box style={{ padding: "24px 0" }}>{children}</Box>}
    </div>
  );
};

export default TabPanel;
