import { Button, makeStyles, Typography } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles({
  primary: {
    fontWeight: 'bold',
    width: '30%',
  },
});

const ChosenValuesTable = ({ description, values, onDeleteItem, readonly }) => {
  const classes = useStyles();

  const handleDeleteItem = (item) => {
    onDeleteItem(item);
  };

  const renderTitle = (item) => {
    if (typeof item.caption === 'function') {
      return item.caption();
    }

    return (
      <Typography variant="h6">
        {item.caption}
      </Typography>
    );
  };

  return (
    <table>
      <thead>
        <tr>
          {description.map((item, index) => (
            <td key={index}>
              {renderTitle(item)}
            </td>
          ))}
        </tr>
      </thead>
      <tbody>
        {values.map((value, index) => (
          <tr key={index}>
            {description.map((item, index) => (
              <td key={index} className={item.primary ? classes.primary : ''}>
                {item.transformation ? item.transformation(value[item.property]) : value[item.property]}
              </td>
            ))}
            {!readonly && (
              <td>
                <Button
                  size="small"
                  variant="text"
                  color="primary"
                  onClick={() => handleDeleteItem(value)}
                >
                  X
                </Button>
              </td>
            )}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default ChosenValuesTable;
