import { Fab, Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography, Tabs, Tab, Box } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Alert } from "@material-ui/lab";
import React, { useState, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import languages from "../entities/languages";
import productEntity from "../entities/product";
import Publisher from "../forms/fields/Publisher";
import Brand from "../forms/fields/Brand";
import Project from "../forms/fields/Project";
import Authors from "../forms/lists/Authors";
import TagCloud from "../forms/lists/TagCloud";
import TabPanel from "../layout/TabPanel";
import Assets from "../layout/Assets";
import ProvisioningRequests from "../layout/ProvisioningRequests";
import FileUpload from "../forms/FileUpload";
import useStyles from "../styles/forms";
import axios from "../utils/axios";
import Modal from "@material-ui/core/Modal";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";

export default (props) => {
  const myData = props.data; //{ ...props.data, publisher: props.data.publisher.id, brand: props.data.brand.id };
  // console.log(myData);
  const classes = useStyles();
  const { id } = useParams();
  const newProductItem = id === undefined ? true : false;
  const [productItem, setProductItem] = useState({ ...productEntity, ...myData });
  /* , is_publishable: String(myData.is_publishable + "1")  */
  const [childrenData, setChildrenData] = useState({});
  const [assetType, setAssetType] = useState("source-asset");

  const [tabValue, setTabValue] = useState(0);
  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  // getModalStyle is not a pure function, we roll the style only on the first render
  const [FUopen, setFUopen] = useState(false);
  const handleFUopen = () => {
    setFUopen(true);
  };
  const handleFUClose = () => {
    setFUopen(false);
  };

  const [serverSuccess, setServerSuccess] = useState(null);
  const [serverReply, setServerReply] = useState(false);
  const methods = useForm({ defaultValues: { ...myData, is_publishable: "true" } }); // otherwise form conroller doesnt work
  const { handleSubmit, register, control, getValues, setValue } = methods;
  const onSubmit = (data) => {
    console.log(data);
    // console.log(myData);
    const submitData = async () => {
      console.log(childrenData);
      try {
        // let newBody = { ...productItem, name: productItem.first_name + " " + productItem.middle_name + " " + productItem.last_name };
        let newBody = {
          ...myData, // props
          ...data, // form data
          ...childrenData, // data from children components like genres
          // name: data.first_name + " " + data.middle_name + " " + data.last_name,
          // birth_date: new Date(data.birth_date),
          // death_date: new Date(data.death_date),
        };
        if (!childrenData.publisher) {
          newBody = { ...newBody, publisher: myData.publisher.id };
        }
        if (!childrenData.brand) {
          newBody = { ...newBody, brand: myData.brand.id };
        }
        if (!childrenData.genres) {
          let theseGenres = [];
          myData.genres.forEach((item) => {
            theseGenres.push(item.id);
          });
          console.log(newBody);
          newBody = { ...newBody, genres: theseGenres };
        }
        if (!childrenData.categories) {
          let theseCats = [];
          myData.categories.forEach((item) => {
            theseCats.push(item.id);
          });
          console.log(newBody);
          newBody = { ...newBody, categories: theseCats };
        }
        newBody = { ...newBody, ean: newBody.ean === "" ? 0 : parseInt(newBody.ean) }; // field sanitation
        console.log(newBody);
        newBody = JSON.stringify(newBody);
        const response = newProductItem ? await axios.post("/product", newBody) : await axios.put(`/product/${id}`, newBody);
        console.log(response);
        const responseJson = response.data;
        if (response.status === 200 || response.status === 201) {
          // created
          setServerSuccess(true);
          setServerReply("V pořádku uloženo.");
          console.log("Success");
          window.scrollTo({ top: 0, behavior: "smooth" });
        } else {
          // data error
          // serverErrors(data);
          setServerSuccess(false);
          setServerReply("Chyba při ukládání - " + responseJson.message);
          console.log("Chyba při ukládání - " + responseJson.message);
          window.scrollTo({ top: 0, behavior: "smooth" });
        }
      } catch (err) {
        console.log("Chyba při ukládání. " + err);
        setServerSuccess(false);
        setServerReply("Chyba při ukládání: " + err.response.data.message);
        window.scrollTo({ top: 0, behavior: "smooth" });
      }
    };
    // submitData();
  };
  /*   useEffect(() => {
    console.log(errors);
  }, [errors]); */
  const [open2, setOpen2] = useState(false);
  const deleteProductConfirm = () => {
    setOpen(true);
  };
  const deleteProduct = async () => {
    // disable button
    const submitData = async () => {
      try {
        const response = await axios.delete(`/product/${id}`);

        if (response.status === 200 || response.status === 201) {
          // created
          setServerSuccess(true);
          setServerReply("Successfully deleted.");
          setProductItem(null);
          console.log("Success");
          window.scrollTo({ top: 0, behavior: "smooth" });
        } else {
          // data error
          // serverErrors(data);
          setServerSuccess(false);
          setServerReply("Error deleting data.");
          console.log("Chyba při ukládání.");
          window.scrollTo({ top: 0, behavior: "smooth" });
        }
      } catch (err) {
        console.log("Error deleting data. " + err);
      }
    };
    submitData();
    setOpen(false);
  };
  const handleClose2 = () => {
    setOpen(false);
  };

  const [origValues, setorigValues] = useState(false);
  const leaveIfYouCan = () => {
    const values = getValues();
    console.log(origValues);
    console.log(values);
    const changeDetected = JSON.stringify(origValues) !== JSON.stringify(values);
    console.log(changeDetected);
    if (changeDetected) {
      setOpen(true);
    } else {
      confirmLeave();
    }
  };
  const [open, setOpen] = useState(false);
  const confirmLeave = () => {
    window.location.href = `/import-tool`;
  };
  useEffect(() => {
    const values = getValues();
    setorigValues(values);
  }, [getValues]);
  const handleClose = () => {
    setOpen(false);
  };

  const updateFromChildComponents = (params) => {
    console.log(params);
    setChildrenData({ ...childrenData, ...params });
  };
  useEffect(() => {
    console.log("productItem.is_publishable");
    console.log(typeof productItem.is_publishable);
    console.log(productItem.is_publishable);
  }, [productItem.is_publishable]);
  return (
    <>
      {/*       {!newProductItem && (
        <Button onClick={handleFUopen} variant="outlined" color="secondary" size="small">
          Nahrát nový soubor
        </Button>
      )}
 */}
      <br />
      <br />
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="topButtons">
          <Button size="large" variant="outlined" color="primary" onClick={leaveIfYouCan}>
            Zpět na detail
          </Button>
          <Button size="large" variant="contained" color="primary" type="submit">
            Uložit
          </Button>{" "}
          &nbsp;
        </div>
        <Dialog open={open} keepMounted onClose={handleClose} aria-labelledby="alert-dialog-slide-title" aria-describedby="alert-dialog-slide-description">
          <DialogTitle id="alert-dialog-slide-title">Skartovat změny</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">Tato akce nelze vrátit. Přejete si pokračovat?</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              ne
            </Button>
            <Button onClick={confirmLeave} color="primary">
              Ano
            </Button>
          </DialogActions>
        </Dialog>
        {serverReply && (
          <>
            <br />
            <Alert severity={serverSuccess ? "success" : "error"}>{serverReply}</Alert>
            <br />
          </>
        )}
        <br />
        <br />
        {productItem && (
          <Grid container>
            <Grid item xs={5}>
              <Typography variant="h5">Imported by User A</Typography>
            </Grid>
            <Grid item xs={2}>
              <Button variant="outlined" color="secondary">
                <KeyboardBackspaceIcon style={{ fontSize: 40 }} />
              </Button>
            </Grid>
            <Grid item xs={5}>
              <Typography variant="h5">Imported by User B</Typography>
              <Controller as={TextField} className={classes.inputFull} inputRef={register({ required: true })} label="Název *" name="title" control={control} />
              {/* <Controller as={TextField} className={classes.inputFull} inputRef={register({})} label="Type *" name="type" control={control} /> */}
              <FormControl className={classes.inputFull}>
                <InputLabel id="type">Typ</InputLabel>
                <Controller
                  as={
                    <Select>
                      <MenuItem value=""> </MenuItem>
                      <MenuItem value="audiobook">audiobook</MenuItem>
                      <MenuItem value="ebook">ebook</MenuItem>
                      <MenuItem value="physicalgood">fyzické zboží</MenuItem>
                      <MenuItem value="physicalgoodsnonbooks">fyzické zboží neknižní</MenuItem>
                    </Select>
                  }
                  inputRef={register({})}
                  name="type"
                  control={control}
                />
              </FormControl>
              {/* - {productItem.is_publishable} - {productItem.is_publishable ? "yes" : "no"} - */}
              <FormControl className={classes.inputFull}>
                <InputLabel id="is_publishable">Publikováno</InputLabel>
                <Controller
                  as={
                    <Select>
                      <MenuItem value="true">ano</MenuItem>
                      <MenuItem value="false">ne</MenuItem>
                    </Select>
                  }
                  inputRef={register({})}
                  name="is_publishable"
                  control={control}
                />
              </FormControl>
              {/* {!newProductItem && <Controller as={TextField} className={classes.inputFull} inputRef={register({})} label="CDI id" name="id" control={control} />} */}
              <Controller as={TextField} className={classes.inputFull} inputRef={register({})} label="ISBN *" name="isbn" control={control} />
              <Controller as={TextField} className={classes.inputFull} inputRef={register({})} label="EAN" name="ean" control={control} />
              <Controller
                as={TextField}
                className={classes.inputFull}
                inputRef={register({})}
                type="date"
                label="Datum prvního vydání"
                name="first_publish_date"
                control={control}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <Controller
                as={TextField}
                className={classes.inputFull}
                inputRef={register({})}
                type="date"
                label="Datum vydání"
                name="publish_date"
                control={control}
                InputLabelProps={
                  {
                    /* shrink: true, */
                  }
                }
              />
              <Controller as={TextField} className={classes.inputFull} inputRef={register({})} label="Podtitulek *" name="subtitle" control={control} />
              <Controller as={TextField} className={classes.inputFull} inputRef={register({})} label="Originální název *" name="original_title" control={control} />
              {/*               <br />
              <Publisher data={productItem.publisher} callBack={updateFromChildComponents} />
              <Brand data={productItem.brand} callBack={updateFromChildComponents} />
              <br /> */}
              <Controller as={TextField} className={classes.inputFull} inputRef={register({})} label="Slogan *" name="summary" control={control} />
              <Controller as={TextField} className={classes.inputFull} inputRef={register({})} label="Anotace *" name="description" control={control} multiline />
              <FormControl className={classes.inputFull}>
                <InputLabel id="age_limit">Věkový limit *</InputLabel>
                <Controller
                  as={
                    <Select>
                      <MenuItem value=""> </MenuItem>
                      <MenuItem value={0}>0</MenuItem>
                      <MenuItem value={15}>15</MenuItem>
                      <MenuItem value={18}>18</MenuItem>
                      <MenuItem value={21}>21</MenuItem>
                    </Select>
                  }
                  inputRef={register({})}
                  name="age_limit"
                  control={control}
                />
              </FormControl>
              <FormControl className={classes.inputFull}>
                <InputLabel id="language">Jazyk</InputLabel>
                <Controller
                  as={
                    <Select>
                      <MenuItem value=""> </MenuItem>
                      {languages.map((item) => (
                        <MenuItem key={item.code} value={item.code}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </Select>
                  }
                  inputRef={register({})}
                  name="language"
                  control={control}
                />
              </FormControl>
              <FormControl className={classes.inputFull}>
                <InputLabel id="distribution_region">Distribuční region *</InputLabel>
                <Controller
                  as={
                    <Select>
                      <MenuItem value="cs_cz">cs_cz</MenuItem>
                      <MenuItem value="sk_sk">sk_sk</MenuItem>
                    </Select>
                  }
                  inputRef={register({})}
                  name="distribution_region"
                  control={control}
                />
              </FormControl>
              <Controller as={TextField} className={classes.inputFull} inputRef={register({})} label="Externí klíč *" name="external_reference_key" control={control} />
              <br />
            </Grid>
          </Grid>
        )}
        <br />
        <Button size="large" variant="outlined" color="primary" onClick={leaveIfYouCan}>
          Zpět na detail
        </Button>
        <Button size="large" variant="contained" color="primary" type="submit">
          Uložit
        </Button>{" "}
        &nbsp;
      </form>
      <Modal open={FUopen} onClose={handleFUClose} aria-labelledby="simple-modal-title" aria-describedby="simple-modal-description">
        <div>
          <FileUpload key={`a${assetType}`} productId={productItem.id} assetType={assetType} />
        </div>
      </Modal>
    </>
  );
};
