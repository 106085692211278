import { Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography, Tabs, Tab, Box } from '@material-ui/core';
import NativeSelect from '@material-ui/core/NativeSelect';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useAbility } from '@casl/react';
import React, { useState, useEffect, useContext } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import languages from '../entities/languages';
import bindingTypes from '../entities/bindingTypes';
import productEntity from '../entities/product';
import TabPanel from '../layout/TabPanel';
import Assets from '../layout/Assets';
import Prices from '../layout/Prices';
import FileUpload from '../forms/FileUpload';
import useStyles from '../styles/forms';
import axios, { getConsumerHeader } from '../utils/axios';
import nullFromString from '../utils/nullFromString';
import extractIds from '../utils/extractIds';
import removeExtraFields from '../utils/removeExtraFields';
import formatDate4Input from '../utils/formatDate4Input';
import Modal from '@material-ui/core/Modal';
import { CONSUMER_SPECIFIC_ATTR, CONSUMER_SPEC_NAMES_LOOKUP, TYPE_SPECIFIC_ATTR } from '../entities/specificAttributes';
import AddConsumerAttribute from './fields/AddConsumerAttribute';
import { Can, UPDATE, PRODUCT, ASSET, AbilityContext, CHANGE_VIEW_FOR, ASSOCIATED_CONSUMER, MANAGE, UserContext } from '../permissions';
import { subject } from '@casl/ability';
import ServerResponseAlert from '../components/ServerResponseAlert';
import SimpleList from './lists/SimpleList';
import SerieList from './lists/SeriesList';
import AuthorList from './lists/AuthorList';
import TagList from './lists/TagList';
import BranchList from './lists/BranchList';
import IdAutocompleteField from './fields/IdAutocompleteField';
import AutocompleteField from './fields/AutocompleteField';
import FormField from './fields/FormField';
import ProductHeader from '../components/ProductHeader';
import { AssetList } from '../components/AssetList';
import DistributionList from '../components/DitributionList';
import CrosslinkList from '../components/CrosslinkList';
import StatusWarning from '../components/StatusWarning';
import { ProductLockColumn as LockColumn } from '../hooks/useLocks';

const TAB_ATTRIBUTES = 'attributes';
const TAB_TYPE_SPECIFIC_ATTRIBUTES = 'type-specific-attributes';
const TAB_PRICES = 'prices';
const TAB_ASSETS = 'assets';
const TAB_CONSUMER_ATTRIBUTES = 'consumer-attributes';
const TAB_DISTRIBUTIONS = 'distributions';
const TAB_CROSSLINKS = 'crosslinks';

export default (props) => {
  const myData = props.data; //{ ...props.data, publisher: props.data.publisher.id, brand: props.data.brand.id };
  const locks = props.locks;
  const classes = useStyles();
  const ability = useAbility(AbilityContext);
  const userInfo = useContext(UserContext);
  const { id } = useParams();
  const newProductItem = id === undefined ? true : false;
  const [productItem, setProductItem] = useState(productEntity);
  /* , is_publishable: String(myData.is_publishable + "1")  */
  const [childrenData, setChildrenData] = useState({
    publisher: myData && myData.publisher && myData.publisher.id ? myData.publisher.id : '',
    brand: myData && myData.brand && myData.brand.id ? myData.brand.id : '',
  });
  const [assetType, setAssetType] = useState('source-asset');
  const [productType, setProductType] = useState(myData && myData.type ? myData.type : productEntity.type);

  const [tabValue, setTabValue] = useState(TAB_ATTRIBUTES);
  const handleTabValueChange = (event, newValue) => {
    setTabValue(newValue);
  };

  // getModalStyle is not a pure function, we roll the style only on the first render
  const [FUopen, setFUopen] = useState(false);
  const handleFUopen = () => {
    setFUopen(true);
  };
  const handleFUClose = () => {
    setFUopen(false);
  };

  const [serverSuccess, setServerSuccess] = useState(null);
  const [serverReply, setServerReply] = useState(false);
  const methods = useForm({
    defaultValues: { ...productEntity, ...props.data, is_publishable: 'false' },
    reValidateMode: 'onBlur',
  }); // otherwise form conroller doesnt work
  const { handleSubmit, register, control, getValues, setValue, errors, reset } = methods;

  useEffect(() => {
    setProductItem({ ...productEntity, ...props.data });
    reset({...productEntity, ...props.data, is_publishable: 'false' });
    setTabValue(TAB_ATTRIBUTES);
  }, [props.data]);

  const normalizeValues = (productObject) => {
    let normalizedProductObject = {
      age_limit: productObject.age_limit === '' ? '0' : productObject.age_limit + '',
      brand: productObject.brand.id ? productObject.brand.id : null,
      description: productObject.description ? productObject.description : null,
      distributable_from: productObject.distributable_from ? formatDate4Input(productObject.distributable_from) : null,
      distributable_until: productObject.distributable_until ? formatDate4Input(productObject.distributable_until) : null,
      distribution_region: productObject.distribution_region ? productObject.distribution_region : null,
      ean: productObject.ean ? productObject.ean : null,
      external_reference_key: productObject.external_reference_key ? productObject.external_reference_key : '',
      first_publish_date: productObject.first_publish_date ? productObject.first_publish_date : '',
      isbn: productObject.isbn ? productObject.isbn : null,
      language: productObject.language ? productObject.language : null,
      original_title: productObject.original_title ? productObject.original_title : '',
      publish_date: productObject.publish_date ? formatDate4Input(productObject.publish_date) : null,
      publisher: productObject.publisher.id ? productObject.publisher.id : null,
      stock_availability: productObject.stock_availability ? productObject.stock_availability + '' : false,
      subtitle: productObject.subtitle ? productObject.subtitle : '',
      summary: productObject.summary ? productObject.summary : null,
      title: productObject.title ? productObject.title : null,
      video_sample_url: productObject.video_sample_url ? productObject.video_sample_url : '',
      presale_valid_from: productObject.presale_valid_from ? formatDate4Input(productObject.presale_valid_from) : null,
      presale_valid_until: productObject.presale_valid_until ? formatDate4Input(productObject.presale_valid_until) : null,
    };
    return normalizedProductObject;
  };
  const onSubmit = (data) => {
    console.log(data);
    // console.log(myData);
    const submitData = async () => {
      // console.log(childrenData);
      try {
        // let newBody = { ...productItem, name: productItem.first_name + " " + productItem.middle_name + " " + productItem.last_name };
        let newBody = {
          ...myData, // props
          ...data, // form data
          ...childrenData, // data from children components like genres
          type: productType,
          stock_availability: data.stock_availability === 'true' ? true : false,
          // name: data.first_name + " " + data.middle_name + " " + data.last_name,
          // birth_date: new Date(data.birth_date),
          // death_date: new Date(data.death_date),
        };
        if (!childrenData.publisher) {
          newBody = { ...newBody, publisher: myData.publisher && myData.publisher.id ? myData.publisher.id : null };
        }
        if (!childrenData.brand) {
          newBody = { ...newBody, brand: myData.brand && myData.brand.id ? myData.brand.id : null };
        }
        if (!childrenData.genres) {
          let arrayOfIds = extractIds(myData.genres);
          newBody = { ...newBody, genres: [...arrayOfIds] };
        }
        if (!childrenData.categories) {
          let arrayOfIds = extractIds(myData.categories);
          newBody = { ...newBody, categories: [...arrayOfIds] };
        }
        /* if (!childrenData.series) {
          let arrayOfIds = extractIds(myData.series);
          newBody = { ...newBody, series: [...arrayOfIds] };
        } */
        newBody = { ...newBody, series: childrenData.series };

        if (!childrenData.editions) {
          let arrayOfIds = extractIds(myData.editions);
          newBody = { ...newBody, editions: [...arrayOfIds] };
        }
        if (!childrenData.tree_branches) {
          let arrayOfIds = extractIds(myData.tree_branches);
          newBody = { ...newBody, tree_branches: [...arrayOfIds] };
        }

        console.log('newBody');
        console.log(newBody);
        let cleanBody = nullFromString(newBody);
        console.log('cleanbody');
        console.log(cleanBody);
        cleanBody = removeExtraFields(cleanBody, productType);
        cleanBody = JSON.stringify(cleanBody);
        console.log('cleanBody');
        console.log(cleanBody);

        const headers = {
          ...(ability.can(CHANGE_VIEW_FOR, ASSOCIATED_CONSUMER)
            && ability.can(UPDATE, subject(PRODUCT, data), 'consumer-attribute')
            && props.consumerViewKey
            && getConsumerHeader(props.consumerViewKey)),
        };

        const axiosResponse = newProductItem
          ? await axios.post('/product', cleanBody, { headers })
          : await axios.patch(`/product/${id}`, cleanBody, { headers });
        console.log('axiosResponse');
        console.log(axiosResponse);
        const responseData = axiosResponse.data;
        if (axiosResponse.status === 200 || axiosResponse.status === 201) {
          // created
          setServerSuccess(true);
          const normalizedProductObject = normalizeValues({ ...origValues, ...responseData.data });
          setorigValues(normalizedProductObject);
          console.log(responseData.data);
          const keys = Object.keys(responseData.data);
          for (const key of keys) {
            console.log(key, responseData.data[key]);
            setValue(key, responseData.data[key]);
          }
          if (newProductItem === true && responseData.data.id) {
            window.location.href = '/product/edit/' + responseData.data.id;
          }

          setServerReply('V pořádku uloženo.');
          console.log('Success');
          if (newProductItem) {
            console.log(`redirecting to: /product/${responseData.data.id}`);
            window.location.href = `/product/${responseData.data.id}`;
          } else {
            window.scrollTo({ top: 0, behavior: 'smooth' });
          }
        } else {
          // data error
          // serverErrors(data);
          setServerSuccess(false);
          setServerReply('Chyba při ukládání - ' + responseData.message);
          console.log('Chyba při ukládání - ' + responseData.message);
          window.scrollTo({ top: 0, behavior: 'smooth' });
        }
      } catch (err) {
        console.log('Chyba při ukládání. ' + err);
        setServerSuccess(false);
        setServerReply(err);
        /* if (err.axiosResponse && err.axiosResponse.data) {
          console.log(err.axiosResponse.data);
          console.log(err.axiosResponse.data.message);
          setServerReply('Chyba při ukládání: ' + err.axiosResponse.data.message);
        } */
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }
    };
    submitData();
  };
  const [open2, setOpen2] = useState(false);
  const deleteProductConfirm = () => {
    setOpen(true);
  };
  const deleteProduct = async () => {
    // disable button
    const submitData = async () => {
      try {
        const axiosResponse = await axios.delete(`/product/${id}`);

        if (axiosResponse.status === 200 || axiosResponse.status === 201) {
          // created
          setServerSuccess(true);
          setServerReply('Successfully deleted.');
          setProductItem(null);
          console.log('Success');
          window.scrollTo({ top: 0, behavior: 'smooth' });
        } else {
          // data error
          // serverErrors(data);
          setServerSuccess(false);
          setServerReply('Error deleting data.');
          console.log('Chyba při ukládání.');
          window.scrollTo({ top: 0, behavior: 'smooth' });
        }
      } catch (err) {
        console.log('Error deleting data. ' + err);
      }
    };
    submitData();
    setOpen(false);
  };
  const handleClose2 = () => {
    setOpen(false);
  };

  const toggleLock = async (resultLockedState, fieldName) => {
    try {
      await (resultLockedState ? props.onLock({ fieldName, userId: userInfo.id }) : props.onUnLock({ fieldName }));
    } catch (e) {
      setServerSuccess(false)
      setServerReply(e);
    }
  }

  const [origValues, setorigValues] = useState(false);
  const leaveIfYouCan = () => {
    const values = getValues();
    function sortObject(o) {
      let sorted = {},
        key,
        a = [];
      for (key in o) {
        if (o.hasOwnProperty(key)) {
          a.push(key);
        }
      }
      a.sort();
      for (key = 0; key < a.length; key++) {
        sorted[a[key]] = o[a[key]];
      }
      return sorted;
    }
    const sortedValues = sortObject(values);
    const sortedOrigValues = sortObject(origValues);
    console.log(sortedOrigValues);
    console.log(sortedValues);
    const changeDetected = JSON.stringify(sortedOrigValues) !== JSON.stringify(sortedValues);
    console.log(changeDetected);
    if (changeDetected) {
      setOpen(true);
    } else {
      confirmLeave();
    }
  };
  const [open, setOpen] = useState(false);
  const confirmLeave = () => {
    window.location.href = `/product/${id}`;
  };
  useEffect(() => {
    const values = getValues();
    setorigValues({
      ...values,
      age_limit: productItem.age_limit === '' ? '0' : productItem.age_limit + '',
      brand: productItem.brand.id ? productItem.brand.id : null,
      publisher: productItem.publisher.id ? productItem.publisher.id : null,
    });
  }, [getValues]);
  const handleClose = () => {
    setOpen(false);
  };

  const updateFromChildComponents = (params) => {
    // console.log("updateFromChildComponents");
    console.log(params);
    // if (params.publisher) {
    // }
    setChildrenData({ ...childrenData, ...params });
  };
  useEffect(() => {
    console.log('errors');
    console.log(errors);
  }, [errors]);
  //throw "Too big!";

  const getNonstandardConsumerSpecificAttributes = (data) =>
    Object.keys(data).filter(
      (item) =>
        item.startsWith('spec_') && // get spec attributes
        !TYPE_SPECIFIC_ATTR.some((attr) => `spec_${attr}` === item) && // that are not type specific
        !CONSUMER_SPECIFIC_ATTR.some((attr) => `spec_${attr}` === item)
    ); // nor are they standard consumer specific

  const addNonstandardConsumerAttribute = (name) => {
    setProductItem((prevState) => ({ ...prevState, [name]: '' }));
  };

  const handleError = (e) => {
    setServerReply(e);
    setServerSuccess(false);
  };

  const getCzechTypeName = (typeName) => {
    switch (typeName) {
      case 'audiobook':
        return 'audiobook';
      case 'ebook':
        return 'ebook';
      case 'physicalgood':
        return 'fyzické zboží';
      case 'physicalgoodsnonbooks':
        return 'fyzické zboží neknižní';
    }
    return '';
  };

  return (
    <>
      {/*       {!newProductItem && (
        <Button onClick={handleFUopen} variant="outlined" color="secondary" size="small">
          Nahrát nový soubor
        </Button>
      )}
 */}
      <form onSubmit={handleSubmit(onSubmit)}>
        <ProductHeader
          title={productItem.title}
          type={productItem.type}
          subtype={productItem.subtype}
          cover={productItem.assets && productItem.assets.find(item => item.type === 'image-cover')}
          isPublishable={productItem.is_publishable}
          source={productItem}
          showButtons={false}
          actions={
            <>
              <Grid item>
                {!newProductItem && (
                  <Button variant='outlined' color='primary' onClick={leaveIfYouCan}>
                    Zpět na detail
                  </Button>
                )}
              </Grid>
              <Grid item>
                <Button variant='contained' color='primary' type='submit'>
                  Uložit
                </Button>{' '}
                {/*           {!newProductItem && (
                  <Button size="large" variant="outlined" color="primary" onClick={deleteProductConfirm}>
                    Delete
                  </Button>
                )} */}
              </Grid>
            </>
          }
          handleChange={() => console.log('klik')}
        />
        <Dialog open={open} keepMounted onClose={handleClose} aria-labelledby='alert-dialog-slide-title' aria-describedby='alert-dialog-slide-description'>
          <DialogTitle id='alert-dialog-slide-title'>Skartovat změny</DialogTitle>
          <DialogContent>
            <DialogContentText id='alert-dialog-slide-description'>Tato akce nelze vrátit. Přejete si pokračovat?</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color='primary'>
              ne
            </Button>
            <Button onClick={confirmLeave} color='primary'>
              Ano
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog open={open2} keepMounted onClose={handleClose} aria-labelledby='alert-dialog-slide-title' aria-describedby='alert-dialog-slide-description'>
          <DialogTitle id='alert-dialog-slide-title'>Delete product</DialogTitle>
          <DialogContent>
            <DialogContentText id='alert-dialog-slide-description'>This action is irreversible. Are you sure you want to continue?</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color='primary'>
              Cancel
            </Button>
            <Button onClick={deleteProduct} color='primary'>
              Delete
            </Button>
          </DialogActions>
        </Dialog>
        {serverReply && (
          <>
            <br />
            <ServerResponseAlert
              serverSuccess={serverSuccess}
              serverReply={serverReply}
            />
            <br />
          </>
        )}
        {productItem && (
          <>
            <Can I={UPDATE} a={subject(PRODUCT, productItem)} field='product-details'>
              {() => (
                <>
                  <FormField isLocked={!!locks[LockColumn.TITLE]} onToggleLock={(locked) => toggleLock(locked, LockColumn.TITLE)}>
                    <Controller
                      as={TextField}
                      className={classes.inputBase}
                      label='Název *'
                      name='title'
                      inputRef={register()}
                      rules={{ required: true }}
                      error={errors.title ? true : false}
                      control={control}
                    />
                  </FormField>
                  <FormField size="small">
                    {newProductItem && (
                      <FormControl
                        className={classes.inputBase}
                        defaultValue={productType}
                        onChange={(ev) => {
                          setProductType(ev.target.value);
                        }}
                      >
                        <InputLabel htmlFor='type'>Typ *</InputLabel>
                        <Controller
                          inputRef={register({ required: true })}
                          rules={{ required: true }}
                          error={errors.type ? true : false}
                          control={control}
                          name='type'
                          as={
                            <NativeSelect
                              inputProps={{
                                name: 'type',
                                id: 'type',
                              }}
                            >
                              <option value=''> </option>
                              <option value='audiobook'>audiobook</option>
                              <option value='ebook'>ebook</option>
                              <option value='physicalgood'>fyzické zboží</option>
                              <option value='physicalgoodsnonbooks'>fyzické zboží neknižní</option>
                            </NativeSelect>
                          }
                        ></Controller>
                      </FormControl>
                    )}
                    {!newProductItem && (
                      <FormControl className={classes.inputBase}>
                        <InputLabel id='typ-label'>Typ *</InputLabel>
                        <Select name='type' value={productType} readOnly={true} disabled={true}>
                          <MenuItem value=''></MenuItem>
                          <MenuItem value='audiobook'>audiobook</MenuItem>
                          <MenuItem value='ebook'>ebook</MenuItem>
                          <MenuItem value='physicalgood'>fyzické zboží</MenuItem>
                          <MenuItem value='physicalgoodsnonbooks'>fyzické zboží neknižní</MenuItem>
                        </Select>
                      </FormControl>
                    )}
                  </FormField>
                  {(productType === 'physicalgood' || productType === 'physicalgoodsnonbooks') && (
                    <FormField size="small" isLocked={!!locks[LockColumn.SUBTYPE]} onToggleLock={(locked) => toggleLock(locked, LockColumn.SUBTYPE)}>
                      <FormControl className={classes.inputBase}>
                        <InputLabel htmlFor='subtype'>Podtyp *</InputLabel>
                        <Controller
                          inputRef={register()}
                          rules={{ required: true }}
                          error={errors.subtype ? true : false}
                          control={control}
                          name='subtype'
                          as={
                            <NativeSelect
                              inputProps={{
                                name: 'subtype',
                                id: 'subtype',
                              }}
                            >
                              <option value=''></option>
                              <option value='book'>kniha</option>
                              <option value='media-cd'>CD</option>
                              <option value='media-cd-mp3'>CD mp3</option>
                              <option value='media-dvd'>DVD</option>
                              <option value='media-dvd-mp3'>DVD mp3</option>
                              <option value='media-mc'>MC</option>
                              <option value="book">kniha</option>
                              <option value="media-cd">CD</option>
                              <option value="media-cd-mp3">CD mp3</option>
                              <option value="media-dvd">DVD</option>
                              <option value="media-dvd-mp3">DVD mp3</option>
                              <option value="media-mc">MC</option>
                              <option value="bag">taška</option>
                              <option value="other">ostatní</option>
                              <option value="desktop-calendar">kalendář stolní</option>
                              <option value="puzzle">puzzle</option>
                              <option value="desk-game">stolní hra</option>
                              <option value="book-map">mapa knižní</option>
                              <option value="cards">karty</option>
                              <option value="calendar-other">kalendář ostatní</option>
                              <option value="wall-calendar">kalendář nástěnný</option>
                              <option value="picture">obraz</option>
                              <option value="wrap-paper">balící papír</option>
                              <option value="globe">globus</option>
                              <option value="diary-book">diář knižní</option>
                              <option value="monthly-periodical">periodikum měsíčník</option>
                              <option value="periodical-other">periodikum ostatní</option>
                              <option value="wall-map">mapa nástěnná</option>
                              <option value="folded-map">mapa skládáná</option>
                              <option value="toy">hračka</option>
                              <option value="stationery">papírenský sortiment</option>
                              <option value="gifts">dárkový sortiment</option>
                              <option value="unusual-cards">netradiční karty</option>
                              <option value="home-accessories">bytové doplňky</option>
                            </NativeSelect>
                          }
                        ></Controller>
                      </FormControl>
                    </FormField>
                  )}
                  <FormField size="small" isLocked={!!locks[LockColumn.ISBN]} onToggleLock={(locked) => toggleLock(locked, LockColumn.ISBN)}>
                    <Controller
                      as={TextField}
                      className={classes.inputBase}
                      inputRef={register({})}
                      label='ISBN'
                      name='isbn'
                      control={control}
                    />
                  </FormField>
                  <FormField size="small" isLocked={!!locks[LockColumn.EAN]} onToggleLock={(locked) => toggleLock(locked, LockColumn.EAN)}>
                    <Controller
                      as={TextField}
                      className={classes.inputBase}
                      inputRef={register({})}
                      label='EAN'
                      name='ean'
                      control={control}
                    />
                  </FormField>
                  <FormField size="small" isLocked={!!locks[LockColumn.FIRST_PUBLISH_DATE]} onToggleLock={(locked) => toggleLock(locked, LockColumn.FIRST_PUBLISH_DATE)}>
                    <Controller
                      as={TextField}
                      className={classes.inputBase}
                      inputRef={register({})}
                      type='date'
                      label='Datum prvního vydání'
                      name='first_publish_date'
                      control={control}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </FormField>
                  <FormField size="small" isLocked={!!locks[LockColumn.PUBLISH_DATE]} onToggleLock={(locked) => toggleLock(locked, LockColumn.PUBLISH_DATE)}>
                    <Controller
                      as={TextField}
                      className={classes.inputBase}
                      inputRef={register({})}
                      type='date'
                      label='Datum vydání'
                      name='publish_date'
                      control={control}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </FormField>
                  <br />
                  <FormField isLocked={!!locks[LockColumn.SUBTITLE]} onToggleLock={(locked) => toggleLock(locked, LockColumn.SUBTITLE)}>
                    <Controller
                      as={TextField}
                      className={classes.inputBase}
                      inputRef={register()}
                      /*               rules={{ required: true }}
                error={errors.subtitle ? true : false} */
                      label='Podtitulek'
                      name='subtitle'
                      control={control}
                    />
                  </FormField>
                  <FormField isLocked={!!locks[LockColumn.ORIGINAL_TITLE]} onToggleLock={(locked) => toggleLock(locked, LockColumn.ORIGINAL_TITLE)}>
                    <Controller
                      as={TextField}
                      className={classes.inputBase}
                      inputRef={register()}
                      /*               rules={{ required: true }}
                error={errors.original_title ? true : false} */
                      label='Originální název'
                      name='original_title'
                      control={control}
                    />
                    </FormField>
                    <FormField isLocked={!!locks[LockColumn.PROJECT]} onToggleLock={(locked) => toggleLock(locked, LockColumn.PROJECT)}>
                    <IdAutocompleteField
                      className={classes.inputBase}
                      valueId={productItem.project_id}
                      onValueChange={(value) => updateFromChildComponents({ project: value ? value.id : null })}
                      onError={handleError}
                      baseUrl="/project"
                      label="Projekt"
                      searchBy="name"
                    />
                  </FormField>
                  <FormField isLocked={!!locks[LockColumn.BUNDLE]} onToggleLock={(locked) => toggleLock(locked, LockColumn.BUNDLE)}>
                    <IdAutocompleteField
                      className={classes.inputBase}
                      valueId={productItem.bundle_id}
                      onValueChange={(value) => updateFromChildComponents({ product_bundle: value ? value.id : null })}
                      onError={handleError}
                      baseUrl="/product-bundle"
                      label="Svazek"
                      searchBy="title"
                    />
                  </FormField>
                  <FormField isLocked={!!locks[LockColumn.PUBLISHER]} onToggleLock={(locked) => toggleLock(locked, LockColumn.PUBLISHER)}>
                    <AutocompleteField
                      className={classes.inputBase}
                      value={productItem.publisher}
                      onValueChange={(value) => updateFromChildComponents({ publisher: value ? value.id : null })}
                      onError={handleError}
                      baseUrl="/publisher"
                      label="Vydavatel*"
                      searchBy="name"
                      searchQuery={{ is_publishing_house: 1 }}
                      error={errors.publisher ? true : false}
                    />
                    <input /* HAS TO BE PRESENT FOR ERROR CONTROL */
                      style={{ display: 'none' }}
                      name='publisher'
                      value={childrenData.publisher ? childrenData.publisher : ''}
                      readOnly={true}
                      ref={register({ required: true })}
                    />
                  </FormField>
                  <FormField isLocked={!!locks[LockColumn.BRAND]} onToggleLock={(locked) => toggleLock(locked, LockColumn.BRAND)}>
                    <AutocompleteField
                      className={classes.inputBase}
                      value={productItem.brand}
                      onValueChange={(value) => updateFromChildComponents({ brand: value ? value.id : null })}
                      onError={handleError}
                      baseUrl="/publisher"
                      label="Značka*"
                      searchBy="name"
                      searchQuery={{ is_publishing_house: 0 }}
                      error={errors.brand ? true : false}
                    />
                    <input /* HAS TO BE PRESENT FOR ERROR CONTROL */
                      style={{ display: 'none' }}
                      control={control}
                      name='brand'
                      value={childrenData.brand ? childrenData.brand : ''}
                      readOnly={true}
                      ref={register({ required: true })}
                    />
                  </FormField>
                  <br />
                  <FormField size="large" isLocked={!!locks[LockColumn.SUMMARY]} onToggleLock={(locked) => toggleLock(locked, LockColumn.SUMMARY)}>
                    <Controller
                      as={TextField}
                      className={classes.inputBase}
                      inputRef={register()}
                      /*               rules={{ required: true }}
                error={errors.summary ? true : false} */
                      label='Slogan'
                      name='summary'
                      control={control}
                    />
                  </FormField>
                  <FormField size="large" isLocked={!!locks[LockColumn.DESCRIPTION]} onToggleLock={(locked) => toggleLock(locked, LockColumn.DESCRIPTION)}>
                    <Controller
                      as={TextField}
                      className={classes.inputBase}
                      inputRef={register()}
                      rules={{ required: true }}
                      error={errors.description ? true : false}
                      label='Anotace *'
                      name='description'
                      control={control}
                      multiline
                    />
                  </FormField>
                  <br />
                  <FormField size="small" isLocked={!!locks[LockColumn.AGE_LIMIT]} onToggleLock={(locked) => toggleLock(locked, LockColumn.AGE_LIMIT)}>
                    <FormControl className={classes.inputBase}>
                      <InputLabel htmlFor='age_limit'>Věkový limit</InputLabel>
                      <Controller
                        inputRef={register({ required: true })}
                        rules={{ required: true }}
                        error={errors.age_limit ? true : false}
                        control={control}
                        name='age_limit'
                        as={
                          <NativeSelect
                            inputProps={{
                              name: 'age_limit',
                              id: 'age_limit',
                            }}
                          >
                            <option value='0'>žádný</option>
                            <option value='15'>15</option>
                            <option value='18'>18</option>
                            <option value='21'>21</option>
                          </NativeSelect>
                        }
                      ></Controller>
                    </FormControl>
                  </FormField>
                  <FormField size="small" isLocked={!!locks[LockColumn.LANGUAGE]} onToggleLock={(locked) => toggleLock(locked, LockColumn.LANGUAGE)}>
                    <FormControl className={classes.inputBase}>
                      <InputLabel htmlFor='language'>Jazyk</InputLabel>
                      <Controller
                        inputRef={register({})}
                        control={control}
                        name='language'
                        as={
                          <NativeSelect
                            inputProps={{
                              name: 'language',
                              id: 'language',
                            }}
                          >
                            <option value=''> </option>
                            {languages.map((item) => (
                              <option key={item.code} value={item.code}>
                                {item.name}
                              </option>
                            ))}
                          </NativeSelect>
                        }
                      ></Controller>
                    </FormControl>
                  </FormField>
                  <FormField size="small" isLocked={!!locks[LockColumn.ORIGINAL_LANGUAGE]} onToggleLock={(locked) => toggleLock(locked, LockColumn.ORIGINAL_LANGUAGE)}>
                    <FormControl className={classes.inputBase}>
                      <InputLabel htmlFor='language'>Jazyk originálu</InputLabel>
                      <Controller
                        inputRef={register({})}
                        control={control}
                        name='original_language'
                        as={
                          <NativeSelect
                            inputProps={{
                              name: 'original_language',
                              id: 'original_language',
                            }}
                          >
                            <option value=''> </option>
                            {languages.map((item) => (
                              <option key={item.code} value={item.code}>
                                {item.name}
                              </option>
                            ))}
                          </NativeSelect>
                        }
                      ></Controller>
                    </FormControl>
                  </FormField>
                  <FormField size="small" isLocked={!!locks[LockColumn.DISTRIBUTION_REGION]} onToggleLock={(locked) => toggleLock(locked, LockColumn.DISTRIBUTION_REGION)}>
                    <FormControl className={classes.inputBase}>
                      <InputLabel htmlFor='distribution_region'>Distribuční region</InputLabel>
                      <Controller
                        inputRef={register({ required: true })}
                        rules={{ required: true }}
                        error={errors.distribution_region ? true : false}
                        control={control}
                        name='distribution_region'
                        as={
                          <NativeSelect
                            inputProps={{
                              name: 'distribution_region',
                              id: 'distribution_region',
                            }}
                          >
                            <option value='cs_cz'>cs_cz</option>
                            <option value='sk_sk'>sk_sk</option>
                          </NativeSelect>
                        }
                      ></Controller>
                    </FormControl>
                  </FormField>
                  <FormField size="small" isLocked={!!locks[LockColumn.DISTRIBUTABLE_FROM]} onToggleLock={(locked) => toggleLock(locked, LockColumn.DISTRIBUTABLE_FROM)}>
                    <Controller
                      as={TextField}
                      className={classes.inputBase}
                      inputRef={register({})}
                      rules={{ required: true }}
                      error={errors.distributable_from ? true : false}
                      type='date'
                      label='Distribuce od *'
                      name='distributable_from'
                      control={control}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </FormField>
                  <FormField size="small" isLocked={!!locks[LockColumn.DISTRIBUTABLE_UNTIL]} onToggleLock={(locked) => toggleLock(locked, LockColumn.DISTRIBUTABLE_UNTIL)}>
                    <Controller
                      as={TextField}
                      className={classes.inputBase}
                      inputRef={register({})}
                      rules={{ required: true }}
                      error={errors.distributable_until ? true : false}
                      type='date'
                      label='Distribuce do *'
                      name='distributable_until'
                      control={control}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </FormField>
                  <FormField size="small" isLocked={!!locks[LockColumn.STOCK_AVAILABILITY]} onToggleLock={(locked) => toggleLock(locked, LockColumn.STOCK_AVAILABILITY)}>
                    <FormControl className={classes.inputBase}>
                      <InputLabel htmlFor='stock_availability'>Dostupnost skladem</InputLabel>
                      <Controller
                        inputRef={register({ required: true })}
                        rules={{ required: true }}
                        error={errors.stock_availability ? true : false}
                        control={control}
                        name='stock_availability'
                        defaultValue={productItem.stock_availability ? 'true' : 'false'}
                        as={
                          <NativeSelect
                            inputProps={{
                              name: 'stock_availability',
                              id: 'stock_availability',
                            }}
                          >
                            <option value='true'>ano</option>
                            <option value='false'>ne</option>
                          </NativeSelect>
                        }
                      ></Controller>
                    </FormControl>
                  </FormField>
                  <FormField size="small" isLocked={!!locks[LockColumn.PRIORITY]} onToggleLock={(locked) => toggleLock(locked, LockColumn.PRIORITY)}>
                    <Controller
                      as={TextField}
                      className={classes.inputBase}
                      inputRef={register({})}
                      error={errors.priority ? true : false}
                      type='number'
                      label='Priorita'
                      name='priority'
                      control={control}
                      rules={{
                        min: { value: 0, message: 'Priorita musí být minimálně 0' },
                        max: { value: 2**31 - 1, message: 'Priorita musí být menší než 2 147 483 648' }
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </FormField>
                  <FormField isLocked={!!locks[LockColumn.EXTERNAL_REFERENCE_KEY]} onToggleLock={(locked) => toggleLock(locked, LockColumn.EXTERNAL_REFERENCE_KEY)}>
                    <Controller
                      as={TextField}
                      className={classes.inputBase}
                      inputRef={register()}
                      /*               rules={{ required: true }}
                error={errors.external_reference_key ? true : false} */
                      label='Externí klíč'
                      name='external_reference_key'
                      control={control}
                    />
                  </FormField>
                  <FormField isLocked={!!locks[LockColumn.VIDEO_SAMPLE_URL]} onToggleLock={(locked) => toggleLock(locked, LockColumn.VIDEO_SAMPLE_URL)}>
                    <Controller as={TextField} className={classes.inputBase} inputRef={register({})} label='Video ukázka' name='video_sample_url' control={control} />
                  </FormField>
                  <FormField size="small" isLocked={!!locks[LockColumn.PRESALE_VALID_FROM]} onToggleLock={(locked) => toggleLock(locked, LockColumn.PRESALE_VALID_FROM)}>
                    <Controller
                      as={TextField}
                      className={classes.inputBase}
                      inputRef={register({})}
                      error={errors.presale_valid_from ? true : false}
                      type='date'
                      label='Předprodej od'
                      name='presale_valid_from'
                      control={control}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </FormField>
                  <FormField size="small" isLocked={!!locks[LockColumn.PRESALE_VALID_UNTIL]} onToggleLock={(locked) => toggleLock(locked, LockColumn.PRESALE_VALID_UNTIL)}>
                    <Controller
                      as={TextField}
                      className={classes.inputBase}
                      inputRef={register({})}
                      error={errors.presale_valid_until ? true : false}
                      type='date'
                      label='Předprodej do'
                      name='presale_valid_until'
                      control={control}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </FormField>
                  <FormField size="small" isLocked={!!locks[LockColumn.EXTERNAL_PROVISION]} onToggleLock={(locked) => toggleLock(locked, LockColumn.EXTERNAL_PROVISION)}>
                    <Controller
                      name='external_provision'
                      control={control}
                      render={({ value }) => (
                        <TextField
                          value={value !== null ? (value ? 'Ano' : 'Ne') : '-'}
                          label='Exernal provision'
                          className={classes.inputBase}
                          disabled
                        />
                      )}
                    />
                  </FormField>
                  <br />
                  <br />
                  <Box mt={6} className="productTabs">
                    <Tabs value={tabValue} onChange={handleTabValueChange} variant="scrollable" scrollButtons="auto" indicatorColor='primary' textColor='primary'>
                      <Tab value={TAB_ATTRIBUTES} label='Atributy' />
                      {productType && <Tab value={TAB_TYPE_SPECIFIC_ATTRIBUTES} label={`Atributy pro ${getCzechTypeName(productType)}`} />}
                      {ability.can(UPDATE, subject(PRODUCT, productItem), 'consumer-attribute') && props.consumerViewKey && (
                        <Tab value={TAB_CONSUMER_ATTRIBUTES} label='Consumer atributy' />
                      )}
                      <Tab value={TAB_PRICES} label='Ceny' />
                      {!newProductItem && <Tab value={TAB_ASSETS} label='Soubory' />}
                      {!newProductItem && <Tab label="Distribuce" value={TAB_DISTRIBUTIONS} />}
                      {!newProductItem && <Tab label="Křížové odkazy" value={TAB_CROSSLINKS} />}
                    </Tabs>
                  </Box>
                  <TabPanel value={tabValue} index={TAB_ATTRIBUTES} className='productAttributes'>
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <AuthorList
                          values={productItem.authors}
                          onValuesChange={(value) => updateFromChildComponents({authors: value.map(i => ({id: i.id, role: i.role}))})}
                          onError={handleError}
                          isLocked={!!locks[LockColumn.AUTHOR]}
                          onToggleLock={(locked) => toggleLock(locked, LockColumn.AUTHOR)}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <SerieList
                          values={productItem.series}
                          onValuesChange={(value) => updateFromChildComponents({series: value.map(i => ({id: i.id, order_in_serie: i.order_in_serie}))})}
                          onError={handleError}
                          isLocked={!!locks[LockColumn.SERIE]}
                          onToggleLock={(locked) => toggleLock(locked, LockColumn.SERIE)}
                        />
                      </Grid>
                      <Grid item xs={7}>
                        <SimpleList
                          values={productItem.genres}
                          onValuesChange={(value) => updateFromChildComponents({genres: value.map(i => i.id)})}
                          onError={handleError}
                          tableLabel="Žánry"
                          searchLabel="Přidat žánr"
                          mainProperty="title"
                          baseUrl="/genre"
                          lockable
                          isLocked={!!locks[LockColumn.GENRE]}
                          onToggleLock={(locked) => toggleLock(locked, LockColumn.GENRE)}
                        />
                      </Grid>
                      <Grid item xs={7}>
                        <SimpleList
                          values={productItem.categories}
                          onValuesChange={(value) => updateFromChildComponents({categories: value.map(i => i.id)})}
                          onError={handleError}
                          tableLabel="Kategorie"
                          searchLabel="Přidat kategorii"
                          mainProperty="title"
                          baseUrl="/category"
                          lockable
                          isLocked={!!locks[LockColumn.CATEGORY]}
                          onToggleLock={(locked) => toggleLock(locked, LockColumn.CATEGORY)}
                        />
                      </Grid>
                      <Grid item xs={7}>
                        <SimpleList
                          values={productItem.editions}
                          onValuesChange={(value) => updateFromChildComponents({editions: value.map(i => i.id)})}
                          onError={handleError}
                          tableLabel="Edice"
                          searchLabel="Přidat edici"
                          mainProperty="title"
                          baseUrl="/edition"
                          lockable
                          isLocked={!!locks[LockColumn.EDITION]}
                          onToggleLock={(locked) => toggleLock(locked, LockColumn.EDITION)}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <BranchList
                          values={productItem.tree_branches}
                          onValuesChange={(value) => updateFromChildComponents({tree_branches: value.map(i => i.id)})}
                          onError={handleError}
                          isLocked={!!locks[LockColumn.TREE_BRANCH]}
                          onToggleLock={(locked) => toggleLock(locked, LockColumn.TREE_BRANCH)}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TagList
                          values={productItem.tags}
                          onValuesChange={(value) => updateFromChildComponents({tags: value.map(i => ({
                            identifier: i.id,
                            valid_from: i.valid_from ? (new Date(i.valid_from)).toISOString() : null,
                            valid_until: i.valid_until ? (new Date(i.valid_until)).toISOString() : null,
                          }))})}
                          onError={handleError}
                          isLocked={!!locks[LockColumn.TAG]}
                          onToggleLock={(locked) => toggleLock(locked, LockColumn.TAG)}
                        />
                      </Grid>
                    </Grid>
                  </TabPanel>
                  <TabPanel value={tabValue} index={TAB_TYPE_SPECIFIC_ATTRIBUTES} className='productAttributes'>
                    {(productType === 'ebook' || productType === 'physicalgood' || productType === 'physicalgoodsnonbooks') && (
                      <>
                        {/*                   <Typography variant="caption">pro ebook a physicalgood:</Typography>
                  <br /> */}
                        <Controller as={TextField} className={classes.input} inputRef={register({})} label='Počet stránek' name='spec_total-pages' control={control} />
                        <Controller as={TextField} className={classes.input} inputRef={register({})} label='Pořadí výdání v jazyce' name='spec_release-order' control={control} />
                        <br />
                      </>
                    )}
                    {productType === 'ebook' && (
                      <>
                        {/*                   <Typography variant="caption">pro ebook:</Typography>
                  <br /> */}
                        <Controller as={TextField} className={classes.input} inputRef={register({})} label='EAN mobi' name='spec_ean-mobi' control={control} />
                        <Controller as={TextField} className={classes.input} inputRef={register({})} label='EAN pdf' name='spec_ean-pdf' control={control} />
                        <Controller as={TextField} className={classes.input} inputRef={register({})} label='EAN epub' name='spec_ean-epub' control={control} />
                        <Controller as={TextField} className={classes.input} inputRef={register({})} label='ISBN mobi' name='spec_isbn-mobi' control={control} />
                        <Controller as={TextField} className={classes.input} inputRef={register({})} label='ISBN pdf' name='spec_isbn-pdf' control={control} />
                        <Controller as={TextField} className={classes.input} inputRef={register({})} label='ISBN epub' name='spec_isbn-epub' control={control} />
                      </>
                    )}
                    {(productType === 'physicalgood' || productType === 'physicalgoodsnonbooks') && (
                      <>
                        {/*                   <Typography variant="caption">pro physicalgood:</Typography>
                  <br /> */}
                        <Controller as={TextField} className={classes.input} inputRef={register({})} label='Váha' name='spec_weight' control={control} />
                        {/* <Controller as={TextField} className={classes.input} inputRef={register({})} label="Vazba" name="spec_binding-type" control={control} /> */}
                        <FormControl className={classes.inputSmall}>
                          <InputLabel htmlFor='spec_binding-type'>Vazba</InputLabel>
                          <Controller
                            inputRef={register()}
                            control={control}
                            name='spec_binding-type'
                            as={
                              <NativeSelect
                                inputProps={{
                                  name: 'spec_binding-type',
                                  id: 'spec_binding-type',
                                }}
                              >
                                <option value=''> </option>
                                {bindingTypes.map((item) => (
                                  <option key={item.name} value={item.name}>
                                    {item.name}
                                  </option>
                                ))}
                              </NativeSelect>
                            }
                          ></Controller>
                        </FormControl>

                        <Controller as={TextField} className={classes.input} inputRef={register({})} label='Rozměry' name='spec_dimensions' control={control} />

                        <Controller
                          as={TextField}
                          className={classes.inputDate}
                          inputRef={register({})}
                          type='date'
                          label='Datum expedice'
                          name='spec_expedition-date'
                          control={control}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                        <Controller
                          as={TextField}
                          className={classes.inputDate}
                          inputRef={register({})}
                          type='date'
                          label='Datum tisku'
                          name='spec_reprint-date'
                          control={control}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                        <Controller
                          as={TextField}
                          className={classes.input}
                          inputRef={register({})}
                          label='Dostupnost skladem'
                          name='spec_stock-availability-count'
                          control={control}
                        />
                        <Controller
                          as={TextField}
                          className={classes.input}
                          inputRef={register({})}
                          label='Dostupnost v centrálním skladu'
                          name='spec_stock-availability-central-count'
                          control={control}
                        />
                      </>
                    )}
                    {(productType === 'audiobook' || productType === 'physicalgood' || productType === 'physicalgoodsnonbooks') && (
                      <>
                        <Controller as={TextField} className={classes.input} inputRef={register({})} label='Délka v sekundách' name='spec_length' control={control} />
                        <Controller as={TextField} className={classes.input} inputRef={register({})} label='EAN mp3' name='spec_ean-mp3' control={control} />
                        <Controller as={TextField} className={classes.input} inputRef={register({})} label='EAN CD' name='spec_ean-cd' control={control} />
                      </>
                    )}
                  </TabPanel>
                  <TabPanel value={tabValue} index={TAB_PRICES} className='productAttributes'>
                    <Typography variant='caption' style={{ position: 'relative', marginTop: 0, zIndex: 95555 }}>
                      Ceny
                    </Typography>
                    {productItem && <Prices productId={productItem.id} />}
                  </TabPanel>
                  <TabPanel value={tabValue} index={TAB_ASSETS} className='productAttributes'>
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <AssetList
                          productId={productItem.id || null}
                          readOnly={!ability.can(MANAGE, ASSET)}
                        />
                      </Grid>
                    </Grid>
                  </TabPanel>
                  <TabPanel value={tabValue} index={TAB_DISTRIBUTIONS}>
                    <StatusWarning text="Needitovatelné" />
                    {!productItem.distributions || productItem.distributions.length === 0 ? (
                      <p className={classes.line}>
                        Tento produkt nemá žádné distribuce
                      </p>
                    ) : (
                      <DistributionList distributions={productItem.distributions} />
                    )}
                  </TabPanel>
                  <TabPanel value={tabValue} index={TAB_CROSSLINKS}>
                    <StatusWarning text="Needitovatelné" />
                    {!productItem.crosslinks || Object.keys(productItem.crosslinks).length === 0 ? (
                      <p className={classes.line}>
                        Tento produkt nemá žádné křížové odkazy
                      </p>
                    ) : (
                      <CrosslinkList crosslinks={productItem.crosslinks} />
                    )}
                  </TabPanel>
                  {ability.can(UPDATE, subject(PRODUCT, productItem), 'consumer-attribute') && props.consumerViewKey && (
                    <TabPanel value={tabValue} index={TAB_CONSUMER_ATTRIBUTES} className='productAttributes'>
                      <Box>
                        {CONSUMER_SPECIFIC_ATTR.map((attr, idx) => (
                          <Controller
                            as={TextField}
                            key={`${idx}_${attr}`}
                            className={classes.input}
                            inputRef={register({})}
                            label={CONSUMER_SPEC_NAMES_LOOKUP[attr]}
                            name={`spec_${attr}`}
                            control={control}
                            defaultValue={productItem[`spec_${attr}`]}
                          />
                        ))}
                        {getNonstandardConsumerSpecificAttributes(productItem).map((attr, idx) => (
                          <Controller
                            as={TextField}
                            key={`${idx}_${attr}`}
                            className={classes.input}
                            inputRef={register({})}
                            label={attr.slice('spec_'.length)}
                            name={attr}
                            control={control}
                            defaultValue={productItem[attr]}
                          />
                        ))}
                        <AddConsumerAttribute
                          onAttributeAdd={addNonstandardConsumerAttribute}
                          existingNonstandardAttributes={getNonstandardConsumerSpecificAttributes(productItem)}
                        />
                      </Box>
                    </TabPanel>
                  )}
                </>
              )}
            </Can>
            {ability.can(UPDATE, subject(PRODUCT, productItem), 'consumer-attribute') && !ability.can(UPDATE, subject(PRODUCT, productItem), 'product-details') && (
              <>
                <br />
                <Typography variant='h6' display='block' style={{ fontSize: '16px' }}>
                  "Consumer" atributy:
                </Typography>
                <Box>
                  {CONSUMER_SPECIFIC_ATTR.map((attr, idx) => (
                    <Controller
                      as={TextField}
                      key={`${idx}_${attr}`}
                      className={classes.input}
                      inputRef={register({})}
                      label={CONSUMER_SPEC_NAMES_LOOKUP[attr]}
                      name={`spec_${attr}`}
                      control={control}
                      defaultValue={productItem[`spec_${attr}`]}
                    />
                  ))}
                  {getNonstandardConsumerSpecificAttributes(productItem).map((attr, idx) => (
                    <Controller
                      as={TextField}
                      key={`${idx}_${attr}`}
                      className={classes.input}
                      inputRef={register({})}
                      label={attr.slice('spec_'.length)}
                      name={attr}
                      control={control}
                      defaultValue={productItem[attr]}
                    />
                  ))}
                  <AddConsumerAttribute
                    onAttributeAdd={addNonstandardConsumerAttribute}
                    existingNonstandardAttributes={getNonstandardConsumerSpecificAttributes(productItem)}
                  />
                </Box>
                <br />
              </>
            )}
            <br />
            {!newProductItem && (
              <Button size='large' variant='outlined' color='primary' onClick={leaveIfYouCan}>
                Zpět na detail
              </Button>
            )}
            <Button size='large' variant='contained' color='primary' type='submit'>
              Uložit
            </Button>{' '}
            &nbsp;
            {/*             {!newProductItem && (
              <Button size="large" variant="outlined" color="primary" onClick={deleteProductConfirm}>
                Delete
              </Button>
            )} */}
            <br />
          </>
        )}
      </form>
      <Modal open={FUopen} onClose={handleFUClose} aria-labelledby='simple-modal-title' aria-describedby='simple-modal-description'>
        <div>
          <FileUpload key={`a${assetType}`} productId={productItem.id} assetType={assetType} />
        </div>
      </Modal>
    </>
  );
};
