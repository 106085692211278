import { Paper, Typography, Button, makeStyles, Modal, IconButton, Dialog, DialogTitle, DialogContent, DialogActions, DialogContentText } from '@material-ui/core';
import React, { useState } from 'react';
import axios from '../utils/axios';
import Loading from '../layout/Loader';
import AutocompleteSearch from '../components/AutocompleteSearch';
import ServerResponseAlert from '../components/ServerResponseAlert';
import { TreeBranchForm } from '../forms/TreeBranchForm';
import { ability, CREATE, DELETE, MULTITREE, UPDATE } from '../permissions';
import MaterialTable from 'material-table';
import MTLocalization from '../utils/MTLocalization';
import { subject } from '@casl/ability';

import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

const useStyles = makeStyles({
  inputsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingBottom: '20px',
    paddingTop: '20px',
  },
  input: {
    width: '40%',
  },
  alert: {
    marginTop: '20px',
  },
  bottomActionsContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingTop: '20px',
  },
  addButton: {
    width: '40%',
    marginLeft: '0',
  },
  actionsColumn: {
    textAlign: 'right',
  },
  treeNode: {
    position: 'relative',
  },
  line: {
    position: 'absolute',
    height: 'calc(50% + 30px)',
    width: '15px',
    top: '-30px',
    left: '0',
    border: 'dotted #999',
    borderWidth: '0 0 2px 2px',
  },
});

const TreeBranches = () => {
  const classes = useStyles();

  const [selectedTree, setSelectedTree] = useState();
  const [treeData, setTreeData] = useState(null);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const [editedBranch, setEditedBranch] = useState(null);
  const [editLoading, setEditLoading] = useState(false);
  const [deletedBranch, setDeletedBranch] = useState(null);

  // ========== CREATE TREE ==========

  const nodeCompareTitle = (a, b) => a.title < b.title ? -1 : a.title > b.title;
  const nodeCompareOrder = (a, b) => a.level_order - b.level_order;

  const getTreeFromNodes = (nodes) => {
    // for each node find its parent and assign itself as a child of said parent
    // thanks to shallow copies the references form a tree in background
    const nodesWithChildren = nodes.map(item => ({ ...item, children: [] }));
    nodesWithChildren.forEach(item => {
      if (item.parent_id) {
        const parent = nodesWithChildren.find(node => node.id === item.parent_id);
        if (parent) {
          parent.children.push(item);
        }
      }
    });

    // get roots - nodes without parents, their children will already be linked and forming a tree
    const roots = nodesWithChildren.filter(item => !item.parent_id);

    return roots;
  };

  const getTreePaths = (roots) => {
    const processNode = (node, pathAccumulator) => {
      node.path = `${pathAccumulator}/${node.title}`;
      node.children.forEach(child => processNode(child, node.path));
    }

    roots.forEach(root => {
      root.path = root.title;
      root.children.forEach(child => processNode(child, root.path));
    });
  }

  const flattenNodeTree = (tree) => {
    const result = [];

    const processNode = (node, level) => {
      result.push({
        ...node,
        level,
      });

      node.children
        .sort(nodeCompareTitle)
        .sort(nodeCompareOrder)
        .forEach(child => processNode(child, level + 1));
    };

    tree
      .sort(nodeCompareTitle)
      .sort(nodeCompareOrder)
      .forEach(root => processNode(root, 0));

    return result;
  };

  const processTreeBranches = (treeBranches) => {
    const tree = getTreeFromNodes(treeBranches);
    getTreePaths(tree);
    return flattenNodeTree(tree);
  };

  // ========== API COMUNICATION ==========

  const loadData = (treeKey) => {
    const doFetch = async () => {
      setLoading(true);
      try {
        const sourceRes = await axios.get(`/tree/key/${treeKey}`);

        setTreeData({
          ...sourceRes.data.data,
          processedBranches: processTreeBranches(sourceRes.data.data.branches),
        });
      } catch (e) {
        setError(e);
      } finally {
        setLoading(false);
      }
    };
    doFetch();
  };

  const getBranchBody = (data) => ({
    ...data,
    title: data.title || null,
    external_reference_key: data.external_reference_key || null,
    level_order: data.level_order || 0,
    parent_id: data.parent_id || null,
    tree_key: data.tree_key || treeData.key,
  });

  const createBranch = (data) => {
    const doCreate = async () => {
      setEditLoading(true);
      try {
        const response = await axios.post('/tree-branch', getBranchBody(data));
        setTreeData(prevValue => {
          const newBranches = [...prevValue.branches, response.data.data];

          return {
            ...prevValue,
            branches: newBranches,
            processedBranches: processTreeBranches(newBranches),
          }
        });
      } catch {
        setError('Při ukládání změn na server došlo k chybě, zkuste to prosím znovu.');
      } finally {
        setEditLoading(false);
        handleModalClose();
      }
    };
    doCreate();
  };

  const editBranch = (data) => {
    const doEdit = async () => {
      setEditLoading(true);
      try {
        const response = await axios.put(`/tree-branch/${editedBranch.id}`, getBranchBody(data));
        const responseData = response.data.data;
        setTreeData(prevValue => {
          const newBranches = prevValue.branches.map(item => item.id !== responseData.id ? item : responseData);

          return {
            ...prevValue,
            branches: newBranches,
            processedBranches: processTreeBranches(newBranches),
          }
        });
      } catch {
        setError('Při ukládání změn na server došlo k chybě, zkuste to prosím znovu.');
      } finally {
        setEditLoading(false);
        handleModalClose();
      }
    };
    doEdit();
  };

  const deleteBranch = (id) => {
    const doDelete = async () => {
      try {
        await axios.delete(`/tree-branch/${id}`);
        setTreeData(prevValue => {
          const newBranches = prevValue.branches
            .filter(item => item.id !== id)
            .map(item => ({
              ...item,
              parent_id: item.parent_id === id ? null : item.parent_id,
            }));

          return {
            ...prevValue,
            branches: newBranches,
            processedBranches: processTreeBranches(newBranches),
          }
        });
      } catch {
        setError('Při ukládání změn na server došlo k chybě, zkuste to prosím znovu.');
      } finally {
        handleDeleteDialogClose();
      }
    };
    doDelete();
  };

  // ========== HANDLERS ==========

  const handleTreeSelect = () => {
    if (selectedTree) {
      setTreeData(null);
      loadData(selectedTree.key);
    }
  };

  const handleModalClose = () => {
    setEditedBranch(null);
  };

  const handleCreateNodeRequest = (treeKey) => {
    setEditedBranch({ tree_key: treeKey });
  };

  const handleEditNodeRequest = (node) => {
    setEditedBranch(node);
  };

  const handleDeleteNodeRequest = (node) => {
    setDeletedBranch(node);
  };

  const handleDeleteDialogClose = () => {
    setDeletedBranch(null);
  };

  // ========== RENDER ==========

  const LEVEL_MARGIN = 25;

  const parentLookupTable = treeData
    ? Object.fromEntries(treeData.branches.map(branch => [branch.id, branch.title]))
    : {};

  const columns = [
    {
      title: 'Název',
      field: 'title',
      render: (rowData) => (
        <div className={classes.treeNode}>
          {rowData.level > 0 && (
            <div
              className={classes.line}
              style={{ left: `${(rowData.level - 1) * LEVEL_MARGIN}px` }}
            />
          )}
          <strong style={{ marginLeft: `${rowData.level * LEVEL_MARGIN}px` }} >
            {rowData.level_order}. {rowData.title}
          </strong>
        </div>
      ),
    },
    {
      title: 'Rodič',
      field: 'parent_id',
      lookup: parentLookupTable,
    },
    {
      title: 'Externí klíč',
      field: 'external_reference_key',
    },
    {
      title: '',
      render: (rowData) => (
        <div className={classes.actionsColumn}>
          {ability.can(UPDATE, subject(MULTITREE, rowData)) && (
            <IconButton color="primary" aria-label="edit" onClick={() => handleEditNodeRequest(rowData)}>
              <EditIcon fontSize="inherit" />
            </IconButton>
          )}
          {ability.can(DELETE, subject(MULTITREE, rowData)) && (
            <IconButton color="primary" aria-label="delete" onClick={() => handleDeleteNodeRequest(rowData)}>
              <DeleteIcon fontSize="inherit" />
            </IconButton>
          )}
        </div>
      ),
    },
  ];

  return (
    <Paper className="basePaper">
      <Typography variant="h5">Správa stromů kategorií</Typography>
      <div className={classes.inputsContainer}>
        <div className={classes.input}>
          <AutocompleteSearch
            label="Vybraný strom"
            baseUrl="/tree"
            searchBy="key"
            alreadySelected={[
              ...(selectedTree ? [selectedTree] : []),
            ]}
            getOptionLabel={(option) => option.key}
            identityAttribute="key"

            value={selectedTree}
            onValueChange={setSelectedTree}
            onError={setError}
          />
        </div>
        <Button
          size="large"
          variant="outlined"
          color="primary"
          onClick={() => handleTreeSelect()}
          disabled={!selectedTree}
        >
          Vyhledat
        </Button>
      </div>
      {loading && <Loading />}
      {error && (
        <div className={classes.alert}>
          <ServerResponseAlert
            serverSuccess={false}
            serverReply={error}
            onClose={() => setError(null)}
          />
        </div>
      )}
      {treeData && (
        <>
          <MaterialTable
            localization={MTLocalization}
            options={{ toolbar: false, paging: false }}
            style={{ margin: 0, boxShadow: "none" }}
            columns={columns}
            data={treeData.processedBranches}
          />
          <div className={classes.bottomActionsContainer}>
            {ability.can(CREATE, MULTITREE) && (
              <Button
                className={classes.addButton}
                onClick={() => handleCreateNodeRequest(treeData.key)}
                variant="outlined"
                color="primary"
              >
                Přidat kategorii
              </Button>
            )}
          </div>
        </>
      )}
      <Modal open={!!editedBranch} onClose={handleModalClose}>
        {treeData && editedBranch ? (
          <TreeBranchForm
            title={editedBranch.id ? 'Upravit kategorii' : 'Přidat kategorii'}
            confirmationButtonText={editedBranch.id ? 'Upravit' : 'Přidat'}
            defaultValues={editedBranch}
            onConfirm={(data) => editedBranch.id ? editBranch(data) : createBranch(data)}
            serverLoading={editLoading}
            availableBranches={treeData.processedBranches}
          />
        ) : (
          <div />
        )}
      </Modal>
      <Dialog open={!!deletedBranch} keepMounted onClose={handleDeleteDialogClose}>
        <DialogTitle>Smazat kategorii</DialogTitle>
        <DialogContent>
          <DialogContentText>Tato akce nelze vrátit. Přejete si pokračovat?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteDialogClose} color='primary'>
            ne
          </Button>
          <Button onClick={() => deleteBranch(deletedBranch.id)} color='primary'>
            Ano
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
};

export default TreeBranches;
