import React from 'react';
import HeadsetRoundedIcon from '@material-ui/icons/HeadsetRounded';
import MenuBookRoundedIcon from '@material-ui/icons/MenuBookRounded';
import TabletMacRoundedIcon from '@material-ui/icons/TabletMacRounded';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import { getCzechSubtypeName } from '../utils/productTypeCzechName';
import ProductState from './ProductState';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'row',
    gap: '20px',
    marginBottom: '30px',
  },
  image: {
    width: '180px',
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: '1',
  },
});

export const ProductHeader = ({
  title,
  type,
  subtype,
  cover,
  isPublishable,
  isDecomissioned,
  actions,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      {cover && (
        <img className={classes.image} src={cover.storage_url} alt={title} />
      )}
      <div className={classes.contentContainer}>
        <Typography variant="h5">{title ? title : '-'}</Typography>
        <Grid item container direction="row" xs={12}>
          <Grid item container direction="column" xs={12} sm={3}>
            <p style={{ margin: '.5rem 0' }}>
              {type &&
                (type === 'audiobook' ? (
                  <span className="productType">
                    <HeadsetRoundedIcon style={{ verticalAlign: 'middle' }} />
                    Audiobook
                  </span>
                ) : type === 'ebook' ? (
                  <span className="productType">
                    <TabletMacRoundedIcon style={{ verticalAlign: 'middle' }} />
                    Ebook
                  </span>
                ) : type === 'physicalgood' ? (
                    <span className="productType">
                    <MenuBookRoundedIcon style={{ verticalAlign: 'middle' }} />
                    Fyzické zboží - {getCzechSubtypeName(subtype)}
                  </span>
                ) : type === 'physicalgoodsnonbooks' ? (
                    <span className="productType">
                    <MenuBookRoundedIcon style={{ verticalAlign: 'middle' }} />
                    Fyzické zboží neknižní - {getCzechSubtypeName(subtype)}
                  </span>
                ) : (
                  <span className="productType">
                    <MenuBookRoundedIcon style={{ verticalAlign: 'middle' }} />
                    neznamý?
                  </span>
              ))}
            </p>
            <Grid item container direction="row" alignItems="center">
              <ProductState
                publishable={isPublishable}
                decomissioned={isDecomissioned}
              />
            </Grid>
          </Grid>
          <Grid item container direction="row" xs={12} sm={9} style={{ justifyContent: 'end', alignContent: 'flex-end', alignItems: 'center' }}>
            {actions}
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default ProductHeader;
