// --- type specific attributes ---

export const LENGTH = 'length';
export const TOTAL_PAGES = 'total-pages';
export const WEIGHT = 'weight';
export const RELEASE_ORDER = 'release-order';
export const ISBN_MOBI = 'isbn-mobi';
export const ISBN_PDF = 'isbn-pdf';
export const ISBN_EPUB = 'isbn-epub';
export const EAN_MOBI = 'ean-mobi';
export const EAN_PDF = 'ean-pdf';
export const EAN_EPUB = 'ean-epub';
export const EAN_MP3 = 'ean-mp3';
export const EAN_CD = 'ean-cd';
export const BINDING_TYPE = 'binding-type';
export const WIDTH = 'width';
export const HEIGHT = 'height';
export const DEPTH = 'depth';
export const EXPEDITION_DATE = 'expedition-date';
export const REPRINT_DATE = 'reprint-date';
export const STOCK_AVAILABILITY_COUNT = 'stock-availability-count';
export const STOCK_AVAILABILITY_CENTRAL_COUNT = 'stock-availability-central-count';

export const TYPE_SPECIFIC_ATTR = [
  LENGTH,
  TOTAL_PAGES,
  WEIGHT,
  RELEASE_ORDER,
  ISBN_MOBI,
  ISBN_PDF,
  ISBN_EPUB,
  EAN_MOBI,
  EAN_PDF,
  EAN_EPUB,
  EAN_MP3,
  EAN_CD,
  BINDING_TYPE,
  WIDTH,
  HEIGHT,
  DEPTH,
  EXPEDITION_DATE,
  REPRINT_DATE,
  STOCK_AVAILABILITY_COUNT,
  STOCK_AVAILABILITY_CENTRAL_COUNT,
];

export const TYPE_SPEC_NAMES_LOOKUP = {
  [LENGTH]: 'Délka v sekundách',
  [TOTAL_PAGES]: 'Počet stránek',
  [RELEASE_ORDER]: 'Pořadí vydání v jazyce',
  [ISBN_MOBI]: 'ISBN mobi',
  [ISBN_PDF]: 'ISBN pdf',
  [ISBN_EPUB]: 'ISBN epub',
  [EAN_MOBI]: 'EAN mobi',
  [EAN_PDF]: 'EAN pdf',
  [EAN_EPUB]: 'EAN epub',
  [EAN_MP3]: 'EAN mp3',
  [EAN_CD]: 'EAN CD',
  [WEIGHT]: 'Váha',
  [BINDING_TYPE]: 'Vazba',
  [WIDTH]: 'Šířka',
  [HEIGHT]: 'Výška',
  [DEPTH]: 'Tloušťka',
  [EXPEDITION_DATE]: 'Datum expedice',
  [REPRINT_DATE]: 'Datum tisku',
  [STOCK_AVAILABILITY_COUNT]: 'Dostupnost skladem',
  [STOCK_AVAILABILITY_CENTRAL_COUNT]: 'Dostupnost v centrálním skladu',
};

// --- consumer specific attributes ---

export const TITLE = 'title';
export const SUBTITLE = 'subtitle';
export const DESCRIPTION = 'description';
export const SUMMARY = 'summary';

export const CONSUMER_SPECIFIC_ATTR = [
  TITLE,
  SUBTITLE,
  DESCRIPTION,
  SUMMARY,
];

export const CONSUMER_SPEC_NAMES_LOOKUP = {
  [TITLE]: 'Titulek',
  [SUBTITLE]: 'Podtitulek',
  [DESCRIPTION]: 'Anotace',
  [SUMMARY]: 'Slogan',
}

// --- ---
