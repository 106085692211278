import React from 'react';
import { Tabs, Tab, Paper, Typography } from '@material-ui/core';
import TabPanel from '../layout/TabPanel';
import DeduplicationTable from '../forms/DeduplicationTable';
import ImportValidation from '../components/ImportValidation';
import ImportExecution from '../components/ImportExecution';

export default () => {
  const [tabValue, setTabValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <Paper className="basePaper">
      <Typography variant="h5">Import</Typography>
      <br />
      <Tabs value={tabValue} onChange={handleChange} indicatorColor="primary" textColor="primary">
        <Tab label='Kontrola validity' />
        <Tab label='Import dat' />
        {/* <Tab label='Odstranění duplikací' /> */}
      </Tabs>
      <TabPanel value={tabValue} index={0} className="productAttributes">
        <ImportValidation />
      </TabPanel>
      <TabPanel value={tabValue} index={1} className="productAttributes">
        <ImportExecution />
      </TabPanel>
      {/* <TabPanel value={tabValue} index={2} className="productAttributes">
        <DeduplicationTable />
      </TabPanel> */}
    </Paper>
  );
};
