import AddCircleOutlineRoundedIcon from "@material-ui/icons/AddCircleOutlineRounded";
import { Box, Button, Link, Paper, Typography, TablePagination } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Alert, AlertTitle } from "@material-ui/lab";
import MaterialTable from "material-table";
import MTLocalization from "../utils/MTLocalization";
import React, { useEffect, useReducer, useState } from "react";
import useFetch from "../hooks/useFetch";
import Loader from "../layout/Loader";
import asyncForEach from "../utils/asyncForEach";
import axios from "../utils/axios";
import { Can, CREATE, UPDATE, PROJECT } from "../permissions";
import { subject } from "@casl/ability";
import SearchBox from '../components/SearchBox';
import { getSortString, getSortReadyColumns } from '../utils/tableSortUtils';

const SORT_FIELDS = {
  name: 'name',
  external_reference_key: 'externalReferenceKey',
};

export default (props) => {
  const [state, setState] = useState(null);

  // -- Data loading and pagination
  const [response, setResponse] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setDataLoading] = useState(false);
  const [error, setDataError] = useState(null);
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const [filterText, setFilterText] = useState(null);
  const [sort, setSort] = useState(null);

  const loadData = (page = currentPage, size = pageSize, filter = filterText, sortDesc = sort) => {
    const doFetch = async () => {
      setDataLoading(true);
      try {
        const query = {
          page: page + 1,
          limit: size,
          ...(filter && { name: filter }),
          ...(sortDesc && { sort: getSortString(sortDesc, SORT_FIELDS) }),
        };
        const res = await axios('/project', { params: query });
        setResponse(res.data);
        setTotalCount(res.data.meta.total_number);
      } catch (e) {
        setDataError(e);
      } finally {
        setDataLoading(false);
      }
    };
    doFetch();
  };

  const handlePageChange = (newPage, newPageSize) => {
    setCurrentPage(newPage);
    setPageSize(newPageSize);
    loadData(newPage, newPageSize);
  };

  const handleFilterChange = (value) => {
    setCurrentPage(0);
    setFilterText(value);
    loadData(0, pageSize, value);
  };

  const handleFilterReset = () => {
    handleFilterChange(null);
  };

  const handleOrderChange = (columnIdx, order) => {
    const sort = columnIdx >= 0 ? { field: state.columns[columnIdx].field, order: order } : null;
    
    setCurrentPage(0);
    setSort(sort);
    loadData(0, pageSize, filterText, sort);
  }

  // runs on load only
  useEffect(() => {
    loadData();
  }, []);


  function reducer(state, action) {
    switch (action.type) {
      case "add":
        return [...state, action.item];
      case "remove":
        const newArray = state.filter((item) => item !== action.item);
        return newArray;
      default:
        throw new Error();
    }
  }

  const [items2Delete, setItems2Delete] = useReducer(reducer, []);
  const [deletedItems, setDeletedItems] = useState([]);
  const [open, setOpen] = React.useState(false);
  const deleteItemsConfirm = () => {
    setOpen(true);
  };
  const deleteItems = () => {
    setOpen(false);
    const promiseArray = [];
    items2Delete.forEach((item) => {
      promiseArray.push(axios.delete(`/project/${item}`));
    });
    Promise.all([...promiseArray])
      .then((myArray) => {
        const fillUp = async () => {
          const jsons = [];
          await asyncForEach(myArray, async (item) => {
            jsons.push({ ...item.data.data });
          });
          setDeletedItems([...deletedItems, ...jsons]);
          window.location.reload();
        };
        fillUp();
      })
      .catch((err) => {
        console.log(err);
      }); /*  */
  };
  const handleChange = (event) => {
    if (event.target.checked) {
      setItems2Delete({ type: "add", item: event.target.name });
    } else {
      setItems2Delete({ type: "remove", item: event.target.name });
    }
  };

  useEffect(() => {
    console.log(items2Delete);
  }, [items2Delete]);

  useEffect(() => {
    if (response && response.status === 200) {
      console.log(response);
      let newData = {
        columns: [
          /*          {
            title: "",
            field: "",
            width: 10,
            render: (rowData) => <Checkbox name={`${rowData.id}`} onClick={handleChange} inputProps={{ "aria-label": "uncontrolled-checkbox" }} />,
          }, */
          {
            title: "Název",
            field: "name",
            render: (rowData) => <a href={`/project/${rowData.id}`}>{rowData.name}</a>,
          },
          { title: "Id", field: "id", render: (rowData) => <span className="smallFont">{rowData.id}</span> },
          { title: "Externí klíč", field: "external_reference_key", render: (rowData) => <span className="smallFont">{rowData.external_reference_key}</span> },
          {
            width: 100,
            title: "",
            field: "action",
            render: (rowData) => (
              <Can I={UPDATE} a={subject(PROJECT, rowData)}>
                {() => <Button href={`/project/edit/${rowData.id}`} variant="outlined" color="primary">
                  Upravit
                </Button>}
              </Can>
            ),
          },
        ],
        data: [...response.data],
      };
      console.log();
      setState(newData);
    }
  }, [response]);
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <Dialog open={open} keepMounted onClose={handleClose} aria-labelledby="alert-dialog-slide-title" aria-describedby="alert-dialog-slide-description">
        <DialogTitle id="alert-dialog-slide-title">Delete items</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">This action is irreversible. Are you sure you want to continue?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={deleteItems} color="primary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <Paper className="basePaper">
        <Typography variant="h5">
          Projekty &nbsp;
          <Can I={CREATE} a={PROJECT}>
            {() => <Link href="/project/new/">
              <AddCircleOutlineRoundedIcon className="createNew" />
            </Link>}
          </Can>
        </Typography>
        <Box>
          {loading && <Loader />}
          {error && (
            <Alert severity="error" style={{ marginTop: 20 }}>
              <AlertTitle>Ajajaj!</AlertTitle>
              Chyba při načítání dat.
            </Alert>
          )}
        </Box>
        <SearchBox 
          onFilterSubmit={handleFilterChange}
          onFilterReset={handleFilterReset}
        />
        { state && (
          <MaterialTable
            key={pageSize} // workaround for not functioning page size change
            localization={MTLocalization}
            options={{ pageSize: pageSize, addRowPosition: "first", toolbar: false }}
            style={{ margin: 0, boxShadow: "none" }}
            className="MuiTableContainer"
            columns={getSortReadyColumns(state.columns, Object.keys(SORT_FIELDS), sort)}
            data={state.data}
            onOrderChange={handleOrderChange}
            components={{
              Pagination: props => <TablePagination {...props}
                rowsPerPageOptions={[5, 10, 20, 30]}
                rowsPerPage={pageSize}
                count={totalCount}
                page={currentPage}
                onChangePage={(e, page) => handlePageChange(page, pageSize)}
                onChangeRowsPerPage={(e) => handlePageChange(currentPage, e.target.value)}
              />
            }}
          />
        )}
        {/*         <Button onClick={deleteItemsConfirm} variant="outlined" color="primary" disabled={items2Delete.length === 0} style={{ marginTop: -80 }}>
          Delete items
        </Button> */}

        {/*       <br />
      <Alert severity="info">
        <AlertTitle>Dev notes:</AlertTitle>
        - missing publisher
        <br />
        - missing author
        <br />
        - missing includes products
        <br />
      </Alert> */}
        <br />
      </Paper>
    </>
  );
};
