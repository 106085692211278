import React from 'react';
import { MenuItem, Menu, Button } from '@material-ui/core';


export const DropdownSelector = ({
  title,
  availableOptions,
  onOptionSelect,
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleOpen = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelection = (ruleKey) => {
    handleClose();
    onOptionSelect(ruleKey);
  };

  return (
    <>
      <Button onClick={handleOpen} variant="outlined" color="primary">
        {title}
      </Button>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {availableOptions.map(option => (
          <MenuItem key={option.key} onClick={() => handleSelection(option.key)}>{option.label}</MenuItem>
        ))}
      </Menu>
    </>
  );
};
