import { Button, InputLabel, TextField, makeStyles } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import useStyles from "../styles/forms";
import axios from "../utils/axios";
import Loader from '../layout/Loader';

function rand() {
  return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
  const top = 50 + rand();
  const left = 50 + rand();
  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStylesLocal = makeStyles({
  horizontal: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
});

export default (props) => {
  const classesLocal = useStylesLocal();

  console.log("props.productId", props.productId);
  const id = props.productId;
  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);

  const [serverSuccess, setServerSuccess] = useState(null);
  const [serverReply, setServerReply] = useState(false);
  const [loading, setLoading] = useState(false);

  const { handleSubmit, register, control } = useForm({});

  const handleFileUpload = (data) => {
    console.log(data);
    const submitData = async () => {
      try {
        var formData = new FormData();
        formData.append("file", data.file[0]);
        formData.append('external_reference_key', data.external_reference_key);
        const response = await axios.post(`/author/${id}/asset`, formData, { headers: { "Content-Type": "multipart/form-data" } });
        console.log(response);
        const responseJson = response.data;
        if (response.status === 200 || response.status === 201) {
          // created
          setServerSuccess(true);
          setServerReply("Soubor byl úspěšně nahrán.");
          console.log("Success");
          window.scrollTo({ top: 0, behavior: "smooth" });
        } else {
          // data error
          // serverErrors(data);
          setServerSuccess(false);
          setServerReply("Chyba při nahrávání - " + responseJson.message);
          console.log("Chyba při nahrávání - " + responseJson.message);
          window.scrollTo({ top: 0, behavior: "smooth" });
        }
      } catch (err) {
        console.log("Chyba při nahrávání. " + err);
        setServerSuccess(false);
        setServerReply("Chyba při nahrávání: " + err.response.data.message);
        window.scrollTo({ top: 0, behavior: "smooth" });
      } finally {
        setLoading(false);
      }
    };
    setLoading(true);
    submitData();
  };
  return (
    <div style={modalStyle} className={classes.modalWindow}>
      <h2 id="simple-modal-title">Nahrát nový soubor </h2>
      {serverReply && (
        <>
          <Alert severity={serverSuccess ? "success" : "error"}>{serverReply}</Alert>
          <br />
          <Button
            size="large"
            variant="contained"
            color="primary"
            onClick={() => {
              window.location.reload();
            }}
          >
            Hotovo
          </Button>
          <br />
        </>
      )}
      {!serverReply && (
        <form onSubmit={handleSubmit(handleFileUpload)}>
          <InputLabel id="file">Fotka autora *</InputLabel>
          <input ref={register({})} id="file" name="file" type="file" />
          <Controller
            as={TextField}
            className={classes.inputFull}
            inputRef={register({})}
            label="Externí klíč obrázku"
            name="external_reference_key"
            control={control}
            defaultValue={props.externalKey}
          />
          <br />
          <br />
          <div className={classesLocal.horizontal}>
            <Button size="large" variant="contained" color="primary" type="submit" disabled={loading}>
              Nahrát
            </Button>
            { loading && (
              <Loader style={{width: '50px', height: '50px', margin: '0px', marginLeft: '10px'}} />
            )}
          </div>
        </form>
      )}
    </div>
  );
};
