
import { createContext } from 'react';

export const userContextInitialState = {
  username: '',
  role: '',
  // Consumer key
};

export const UserContext = createContext(userContextInitialState);
