import React from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import { useAbility } from '@casl/react';

import Layout from './layout/Layout';
import Author from './pages/Author';
import AuthorEdit from './pages/AuthorEdit';
import Authors from './pages/Authors';
import Categories from './pages/Categories';
import Dashboard from './pages/Dashboard';
import ImportTool from './pages/ImportTool';
import DataManagement from './pages/DataManagement';
import DistributionManagement from './pages/DistributionManagement';
import Provision from './pages/Provision';
import Editions from './pages/Editions';
import Genres from './pages/Genres';
import Login from './pages/Login';
import Multitree from './pages/Multitree';
import NoMatch from './pages/NoMatch';
import Product from './pages/Product';
import ProductBundle from './pages/ProductBundle';
import ProductBundleEdit from './pages/ProductBundleEdit';
import ProductBundles from './pages/ProductBundles';
import ProductEdit from './pages/ProductEdit';
import Products from './pages/Products';
import Prices from './layout/Prices';
import ProductTypes from './pages/ProductTypes';
import Project from './pages/Project';
import ProjectEdit from './pages/ProjectEdit';
import Projects from './pages/Projects';
import Settings from './pages/Settings';
import Publishers from './pages/Publishers';
import Reporting from './pages/Reporting';
import Series from './pages/Series';
import DeduplicationDetail from './forms/DeduplicationDetail';
import BundleTool from './pages/BundleTool';
import Tools from './pages/Tools';
import TreeBranches from './pages/TreeBranches';
import ConsumerAttributesManagement from './pages/ConsumerAttributesManagement';
import {
  AbilityContext,
  ACCESS,
  CREATE,
  UPDATE,
  READ,
  PRODUCT,
  BUNDLE,
  AUTHOR,
  PROJECT,
  PUBLISHER,
  SERIE,
  GENRE,
  EDITION,
  CATEGORY,
  MULTITREE,
  PRODUCT_TYPE,
  DISTRIBUTION_MANAGEMENT,
  REPORTING,
  IMPORT_TOOL,
  TOOLS,
  BUNDLE_TOOL,
  DEDUPLICATION_TOOL,
  SETTINGS,
  CONTROL,
} from './permissions';

const Routing = (props) => {
  const ability = useAbility(AbilityContext)

  const renderComponent = (component, isAccessible) =>
    isAccessible ? component : <Redirect to='/' />;

  return (
    <Router onUpdate={() => window.scrollTo(0, 0)}>
            {props.validUser === false && (
              <Layout loggedIn={false}>
                <Switch>
                  <Route path='/' component={Login} />
                </Switch>
              </Layout>
            )}
            {props.validUser && (
              <Layout loggedIn={true}>
                <Switch>
                  <Route exact path='/' component={Dashboard} />
                  <Route exact path='/data-management' component={DataManagement} />

                  <Route exact path='/product-bundle/new/' render={(props) => renderComponent(<ProductBundleEdit {...props} />, ability.can(CREATE, BUNDLE))} />
                  <Route path='/product-bundle/edit/:id' render={(props) => renderComponent(<ProductBundleEdit {...props} />, ability.can(UPDATE, BUNDLE))} />
                  <Route exact path='/product-bundle/:id' render={(props) => renderComponent(<ProductBundle {...props} />, ability.can(READ, BUNDLE))} />
                  <Route exact path='/product-bundles' render={(props) => renderComponent(<ProductBundles {...props} />, ability.can(READ, BUNDLE))} />

                  <Route exact path='/product/new/' render={(props) => renderComponent(<ProductEdit {...props} />, ability.can(CREATE, PRODUCT))} />
                  <Route path='/product/edit/:id' render={(props) => renderComponent(<ProductEdit {...props} />, ability.can(UPDATE, PRODUCT))} />
                  <Route path='/product/consumer-attribute/:id' render={(props) => renderComponent(<ConsumerAttributesManagement {...props} />, ability.can(CONTROL, PRODUCT, 'consumer-attribute'))} />
                  <Route exact path='/product/:id' render={(props) => renderComponent(<Product {...props} />, ability.can(READ, PRODUCT))} />
                  <Route exact path='/product/:id/prices' render={(props) => renderComponent(<Prices {...props} />, ability.can(READ, PRODUCT))} />
                  <Route exact path='/products' render={(props) => renderComponent(<Products {...props} />, ability.can(READ, PRODUCT))} />

                  <Route exact path='/project/new/' render={(props) => renderComponent(<ProjectEdit {...props} />, ability.can(CREATE, PROJECT))} />
                  <Route path='/project/edit/:id' render={(props) => renderComponent(<ProjectEdit {...props} />, ability.can(UPDATE, PROJECT))} />
                  <Route exact path='/project/:id' render={(props) => renderComponent(<Project {...props} />, ability.can(READ, PROJECT))} />
                  <Route exact path='/projects' render={(props) => renderComponent(<Projects {...props} />, ability.can(READ, PROJECT))} />

                  <Route exact path='/author/new/' render={(props) => renderComponent(<AuthorEdit {...props} />, ability.can(CREATE, AUTHOR))} />
                  <Route path='/author/edit/:id' render={(props) => renderComponent(<AuthorEdit {...props} />, ability.can(UPDATE, AUTHOR))} />
                  <Route exact path='/author/:id' render={(props) => renderComponent(<Author {...props} />, ability.can(READ, AUTHOR))} />
                  <Route exact path='/authors' render={(props) => renderComponent(<Authors {...props} />, ability.can(READ, AUTHOR))} />

                  <Route exact path='/products-types' render={(props) => renderComponent(<ProductTypes {...props} />, ability.can(READ, PRODUCT_TYPE))} />
                  <Route exact path='/editions' render={(props) => renderComponent(<Editions {...props} />, ability.can(READ, EDITION))} />
                  <Route exact path='/publishers' render={(props) => renderComponent(<Publishers {...props} />, ability.can(READ, PUBLISHER))} />
                  <Route exact path='/series' render={(props) => renderComponent(<Series {...props} />, ability.can(READ, SERIE))} />
                  <Route exact path='/genres' render={(props) => renderComponent(<Genres {...props} />, ability.can(READ, GENRE))} />
                  <Route exact path='/categories' render={(props) => renderComponent(<Categories {...props} />, ability.can(READ, CATEGORY))} />
                  <Route exact path='/tree-branches' render={(props) => renderComponent(<TreeBranches {...props} />, ability.can(READ, MULTITREE))} />
                  <Route exact path='/multitree' render={(props) => renderComponent(<Multitree {...props} />, ability.can(READ, MULTITREE))} />

                  <Route exact path='/settings' render={(props) => renderComponent(<Settings {...props} />, ability.can(ACCESS, SETTINGS))} />
                  <Route
                    exact
                    path='/distribution-management'
                    render={(props) => renderComponent(<DistributionManagement {...props} />, ability.can(ACCESS, DISTRIBUTION_MANAGEMENT))}
                  />
                  <Route path='/distribution-management/:id' render={(props) => renderComponent(<Provision {...props} />, ability.can(ACCESS, DISTRIBUTION_MANAGEMENT))} />
                  <Route exact path='/reporting' render={(props) => renderComponent(<Reporting {...props} />, ability.can(ACCESS, REPORTING))} />
                  <Route exact path='/tools' render={(props) => renderComponent(<Tools {...props} />, ability.can(ACCESS, TOOLS))} />
                  <Route exact path='/import-tool' render={(props) => renderComponent(<ImportTool {...props} />, ability.can(ACCESS, IMPORT_TOOL))} />
                  <Route exact path='/bundle-tool' render={(props) => renderComponent(<BundleTool {...props} />, ability.can(ACCESS, BUNDLE_TOOL))} />
                  <Route exact path='/deduplication/' render={(props) => renderComponent(<Reporting {...props} />, ability.can(ACCESS, DEDUPLICATION_TOOL))} />
                  <Route exact path='/deduplication/:id' render={(props) => renderComponent(<DeduplicationDetail {...props} />, ability.can(ACCESS, DEDUPLICATION_TOOL))} />



                  <Route component={NoMatch} />
                </Switch>
              </Layout>
            )}
          </Router>
  )
}

export default Routing;