import React, { useContext, useEffect, useState } from 'react';
import { Button, makeStyles, Typography } from '@material-ui/core';
import ChosenValuesTable from '../../components/ChosenValuesTable';
import ProductAutocompleteSearch from '../../components/ProductAutocompleteSearch';
import useLocks, { ProductLockColumn as LockColumn } from '../../hooks/useLocks';
import Lock from '../../components/Lock';
import { UserContext } from '../../permissions';

const useStyles = makeStyles({
  headline: {
    marginTop: '50px',
    marginBottom: '10px',
  },
  list: {
    marginBottom: '50px',
  },
});

const ProductList = ({ values, onProductAdd, onProductDelete, onProductsChange, onError }) => {
  const classes = useStyles();
  const [products, setProducts] = useState([]);
  const userInfo = useContext(UserContext);
  const [locks, setLocks] = useState({});
  const { loadLocks, lock, unlock } = useLocks('product');
  const lockedProducts = products.filter(product => !!(locks[product.id] || {})[LockColumn.BUNDLE]);

  useEffect(() => {
    const loadLocksForProducts = async (products) => Promise
      .all(products.map(product => loadLocks(product.id).then(locks => ({
        id: product.id,
        locks,
      }))))
      .then(locksList => locksList.reduce((stack, productLocks) => ({
        ...stack,
        [productLocks.id]: productLocks.locks,
      }), {}));

    setProducts(values || []);
    values && loadLocksForProducts(values).then(setLocks);
  }, [values]);

  useEffect(() => {
    onProductsChange(products);
  }, [products]);

  const handleAddItem = (item) => {
    if (!products.some((p) => p.id === item.id)) {
      setProducts((prevValue) => [...prevValue, item]);
      onProductAdd(item);
    }
  };

  const handleDeleteItem = (item) => {
    if (products.some((p) => p.id === item.id)) {
      setProducts((prevValue) => prevValue.filter((product) => product.id !== item.id));
      onProductDelete(item);
    }
  };

  const handleError = (e) => onError(e);

  const lockAllProducts = async () => {
    try {
      await Promise.all(products.filter(product => !locks[product.id][LockColumn.BUNDLE]).map(product => onToggleLock(true, product.id, LockColumn.BUNDLE)));
    } catch (e) {}
  };
  const onToggleLock = async (locked, productId, fieldName) => {
    try {
      await (locked ? lock({ id: productId, fieldName, userId: userInfo.id }) : unlock({ id: productId, fieldName }));
      setLocks(locks => ({
        ...locks,
        [productId]: {
          ...locks[productId],
          [fieldName]: locked,
        },
      }));
    } catch (e) {}
  };

  const getLockableProducts = (products) => products.map(product => ({
    ...product,
    lock: {
      id: product.id,
      productLocks: locks[product.id] || {},
    },
  }));

  const PRODUCT_TABLE_VALUES = [
    { property: 'lock', caption: '', transformation: ({ id, productLocks } = {}) => {
      return (
        <Lock isLocked={productLocks[LockColumn.BUNDLE]} onToggleLock={locked => onToggleLock(locked, id, LockColumn.BUNDLE)} hideable={false} />
      );
    }},
    { property: 'title', caption: 'Produkt', primary: true },
    { property: 'type', caption: 'Typ' },
    { property: 'id', caption: 'ID' },
    { property: 'isbn', caption: 'ISBN' },
    { property: 'ean', caption: 'EAN' },
  ];

  return (
    <div className={classes.list}>
      <Typography className={classes.headline} variant="h6">
        Přiřazené produkty:
        {' '}
        {products.length !== lockedProducts.length && (
          <Button size="small" variant="outlined" color="primary" onClick={lockAllProducts}>
            Uzamknout všechny produkty
          </Button>
        )}
      </Typography>
      <ChosenValuesTable description={PRODUCT_TABLE_VALUES} values={getLockableProducts(products)} onDeleteItem={handleDeleteItem} />
      <ProductAutocompleteSearch
        alreadySelected={products}
        onProductAdd={handleAddItem}
        onError={handleError}
      />
    </div>
  );
};

export default ProductList;
