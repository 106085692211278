import React, { useEffect, useState } from 'react';
import { Chip, IconButton, InputAdornment, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import AddIcon from '@material-ui/icons/Add';
import axios from '../utils/axios';

const TreeBranchMultiselect = ({
  className,
  classes,
  name,
  onChange,
  values
}) => {
  if(typeof values === "string")
    values = values?.length ? [values] : [];

  const [options, setOptions] = useState([]);
  const [value, setValue] = useState(values);

  useEffect (() => {
    const wrapper = async () => {
      try {
        const response = await axios.get(`/tree/`)
        const data = response.data?.data;
        if(data) {
          const result = [];
          data.forEach(k => {
            if(k.key)
              result.push(k.key)
          });

          setOptions(result);
        }
      } catch(e) {
        console.log("Unable to load tree definitions.", e);
      }
    };
    wrapper(); // this is quite stupid :3
  }, []);

  const onChangeInternal = (newValues) => {
    setValue(newValues);
    onChange(newValues);
  };

  return (
    <Autocomplete
      className={className}
      classes={classes}
      name={name}
      multiple
      freeSolo
      disableClearable
      options={options}
      value={value}
      onChange={(e, values) => {
        e.stopPropagation();
        onChangeInternal(values);
      }}
      renderTags={(selected, getTagProps) => selected.map((option, index) => (
        <Chip label={option} {...getTagProps({ index })} />
      ))}
      renderInput={(params) => (
        <TextField {...params} InputProps={{
          ...params.InputProps,
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="add value to list"
                onClick={() => {
                  if (!params.inputProps.value) return;
                  onChangeInternal([...value, params.inputProps.value]);
                }}
                size="small"
              >
                <AddIcon />
              </IconButton>
            </InputAdornment>
          ),
        }} />
      )}
    />
  )
};

export default TreeBranchMultiselect;
