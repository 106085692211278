import React from 'react';
import { TextField, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
import { useAdvancedSearchFilterStyles } from '../styles/advanced-search';

export const INITIAL_FILTER_VALUE = {
  title: '',
  isbn: '',
  ean: '',
  external: '',
  publishable: 'all',
  type: 'all',
  author: '',
  publisher: '',
  brand: '',
};

export const LABELS = {
  title: 'Název',
  isbn: 'ISBN',
  ean: 'EAN',
  external: 'Externí klíč',
  publishable: 'Stav publikování',
  type: 'Typ',
  author: 'Autor',
  publisher: 'Vydavatel',
  brand: 'Značka',
};

const ProductSearchFilter = ({ filter, onFilterChange }) => {
  const classes = useAdvancedSearchFilterStyles();

  const setFilterValue = (e) => {
    e.persist();
    onFilterChange({
      ...filter,
      [e.target.name]: e.target.value,
    });
  };

  const isNumber = (value) => value.match(/^[0-9]*$/g);

  return (
    <>
      <div className={classes.formLine}>
        <TextField className={classes.searchInput} name="title" label={LABELS.title} value={filter.title} onChange={setFilterValue} />
        <TextField className={classes.searchInput} name="isbn" label={LABELS.isbn} value={filter.isbn} onChange={setFilterValue} />
        <TextField className={classes.searchInput} name="ean" label={LABELS.ean} value={filter.ean} onChange={(e) => isNumber(e.target.value) && setFilterValue(e)} />
        <TextField className={classes.searchInput} name="external" label={LABELS.external} value={filter.external} onChange={setFilterValue} />
      </div>
      <div className={classes.formLine}>
        <FormControl className={classes.searchInput}>
          <InputLabel id="publishable-filter-label">{LABELS.publishable}</InputLabel>
          <Select labelId="publishable-filter-label" name="publishable" value={filter.publishable} onChange={setFilterValue}>
            <MenuItem value="all">Vše</MenuItem>
            <MenuItem value="publishable">Pouze publikované</MenuItem>
            <MenuItem value="draft">Pouze neúplné</MenuItem>
            <MenuItem value="decomissioned">Pouze nepublikované</MenuItem>
            <MenuItem value="availableInStock">Skladem</MenuItem>
            <MenuItem value="notAvailableInStock">Není skladem</MenuItem>
            <MenuItem value="available">Dostupné</MenuItem>
          </Select>
        </FormControl>
        <FormControl className={classes.searchInput}>
          <InputLabel id="type-filter-label">{LABELS.type}</InputLabel>
          <Select labelId="type-filter-label" name="type" value={filter.type} onChange={setFilterValue}>
            <MenuItem value="all">Vše</MenuItem>
            <MenuItem value="audiobook">Audiobook</MenuItem>
            <MenuItem value="ebook">Ebook</MenuItem>
            <MenuItem value="physicalgood">Fyzické zboží</MenuItem>
            <MenuItem value="physicalgoodsnonbooks">Fyzické zboží neknižní</MenuItem>
          </Select>
        </FormControl>
      </div>
      <div className={classes.formLine}>
        <TextField className={classes.searchInput} name="author" label={LABELS.author} value={filter.author} onChange={setFilterValue} />
        <TextField className={classes.searchInput} name="publisher" label={LABELS.publisher} value={filter.publisher} onChange={setFilterValue} />
        <TextField className={classes.searchInput} name="brand" label={LABELS.brand} value={filter.brand} onChange={setFilterValue} />
      </div>
    </>
  );
};

export default ProductSearchFilter;
