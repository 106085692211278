import React from 'react';
import { Button, FormControl, InputLabel, makeStyles, NativeSelect, TextField } from '@material-ui/core';
import { Controller, useForm } from 'react-hook-form';
import Loader from '../layout/Loader';
import useStyles from '../styles/forms';

export const ASSET_TYPES = ['image-cover', 'image-carousel', 'sample-audio', 'file-attachment',
  'ebook-master-pdf', 'ebook-master-epub', 'audio-chapter-master', 'sample-pdf', 'sample-epub'];

const useStylesLocal = makeStyles({
  horizontal: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  modal: {
    top: '50%',
    left: '50%',
    transform: `translate(-50%, -50%)`,
  },
  fileInput: {
    marginTop: '30px',
  },
  actions: {
    marginTop: '40px',
  },
  filename: {
    fontSize: '0.8rem',
    fontStyle: 'italic',
    marginTop: '10px',
    marginBottom: '0',
  },
  loader: {
    width: '50px',
    height: '50px',
    margin: '0px',
    marginLeft: '10px',
  },
});

export const AssetToUploadPicker = React.forwardRef(({
  title,
  confirmationButtonText,
  defaultValues,
  serverLoading,
  onConfirm,
}, ref) => {
  const classesLocal = useStylesLocal();
  const classes = useStyles();

  const methods = useForm({
    reValidateMode: 'onBlur',
  }); // otherwise form conroller doesnt work

  const { register, control, getValues, reset } = methods;

  const handleFileUpload = () => {
    onConfirm(getValues());
  };

  const typeSelect = () => (
    <NativeSelect
      inputProps={{
        name: 'type',
        id: 'type',
      }}
    >
      {ASSET_TYPES.map((item) => (
        <option value={item} key={item}>
          {item}
        </option>
      ))}
    </NativeSelect>
  );

  React.useEffect(() => {
    if (defaultValues) {
      reset(defaultValues);
    }
  }, [defaultValues]);

  return (
    <div className={[classes.modalWindow, classesLocal.modal].join(' ')}>
      <h2 id="simple-modal-title">{title}</h2>
      <Controller as={TextField} className={classes.inputFull} inputRef={register({})} label="Titulek" name="chapter_title" control={control} />
      <FormControl className={classes.inputSmall}>
        <InputLabel id="type">Typ *</InputLabel>
        <Controller as={typeSelect()} inputRef={register({})} name="type" control={control} disabled={!!defaultValues} />
      </FormControl>

      <div className={classesLocal.fileInput}>
        <InputLabel id="file">Soubor *</InputLabel>
        <input ref={register({})} id="file" name="file" type="file" />
        {defaultValues && defaultValues.filename && (
          <p className={classesLocal.filename}>
            Stávající soubor: {defaultValues.filename}
          </p>
        )}
      </div>

      <Controller as={TextField} className={classes.inputFull} inputRef={register({})} label="Externí klíč" name="external_reference_key" control={control} disabled={!!defaultValues} />
      <Controller as={TextField} className={classes.inputFull} inputRef={register({})} label="Pořadí" name="order" control={control} />
      <Controller as={TextField} className={classes.inputFull} inputRef={register({})} label="Délka stopy" name="duration" control={control} />
      <Controller as={TextField} className={classes.inputFull} inputRef={register({})} label="Počet stránek" name="page_count" control={control} />

      <div className={[classesLocal.horizontal, classesLocal.actions].join(' ')}>
        <Button size="large" variant="contained" color="primary" type="button" disabled={serverLoading}
          onClick={handleFileUpload}
        >
          {confirmationButtonText}
        </Button>
        {serverLoading && (
          <Loader className={classesLocal.loader} />
        )}
      </div>
    </div>
  );
});
