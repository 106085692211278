import { useEffect, useState } from "react";
import axios from "../utils/axios";

const useFetch = (url, options) => {
  const [response, setResponse] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;
    const doFetch = async () => {
      setLoading(true);
      try {
        // const res = await fetch(url, options);
        const res = await axios(url);
        if (res.status === 401) {
          console.log("401 - Ověření selhalo či expirovalo. Přesměrovávám na stránku s přihlášením. ");
          localStorage.removeItem("token");
          localStorage.removeItem('consumer');
          window.location.href = "/";
        } else {
          // console.log(res);
          if (!signal.aborted) {
            setResponse(res.data);
          }
        }
      } catch (e) {
        // console.log(e);
        if (!signal.aborted) {
          setError(e);
        }
      } finally {
        if (!signal.aborted) {
          setLoading(false);
        }
      }
    };
    doFetch();
    return () => {
      abortController.abort();
    };
  }, []);
  return { response, error, loading };
};
export default useFetch;
