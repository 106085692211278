import React from 'react';
import useStyles from '../../styles/forms';
import Lock from '../../components/Lock';

const FormField = ({
  size = 'medium',
  isLocked = undefined,
  onToggleLock = () => {},
  children,
}) => {
  const classes = useStyles();
  const lockable = isLocked !== undefined;
  const sizeClasses = {
    small: 'inputContainerSmall',
    medium: 'inputContainerMedium',
    large: 'inputContainerLarge',
  };

  return (
    <div className={[classes.inputContainer, classes[sizeClasses[size]], lockable ? classes.lockableInputContainer : ''].join(' ')}>
      {children}
      {lockable && (
        <Lock
          isLocked={isLocked}
          onToggleLock={onToggleLock}
        />
      )}
    </div>
  );
};

export default FormField;
