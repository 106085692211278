import React from 'react';
import { Alert, AlertTitle } from '@material-ui/lab';

// error codes
const STRING = 'string';
const NUMBER = 'number';
const NUMBER_GREATER = 'number-greater';
const DATE = 'date';
const BOOLEAN = 'boolean';
const DATE_FORMAT = 'date-format';
const INVALID_RANGE = 'invalid-range';
const EMPTY = 'empty';
const REQUIRED = 'required';
const NOT_FOUND = 'not-found';
const INVALID = 'invalid';
const ALREADY_EXISTS = 'already-exists';
const OTHER = 'other';

const ImportModuleResult = ({
  serverSuccess,
  showSuccessfull,
  showErrors,
  validationResult,
  serverErrorMessage,
  alertMessage = 'Úspěšně zpracováno {total} položek.',
}) => {
  const getErrorText = (errorDescription) => {
    const getMessageFromCode = (errorDescription) => {
      switch (errorDescription.error) {
        case STRING:
          return 'musí být textový řetězec';
        case NUMBER:
          return 'musí být číslo';
        case NUMBER_GREATER:
            return `musí být číslo větší než ${errorDescription.valid}`;
        case DATE:
          return 'musí být datum v ISO 8601 formátu';
        case BOOLEAN:
          return 'musí být boolská hodnota';
        case DATE_FORMAT:
          return 'musí být datum v ISO 8601 formátu';
        case INVALID_RANGE:
          return `musí být hodnota z výběru: ${errorDescription.valid.map(i => i === null ? 'null' : i).join(', ')}`;
        case EMPTY:
          return 'nesmí být prázdné';
        case REQUIRED:
          return 'musí být zadané';
        case NOT_FOUND:
          return 'neodpovídá žádnému záznamu v databázi';
        case INVALID:
          return 'není platné';
        case ALREADY_EXISTS:
          return 'už existuje v databázi'
        case OTHER:
          return `má neznámou chybu s popisem ${errorDescription.description}`;
      }
    };

    if (errorDescription.field === 'author' && errorDescription.error === NOT_FOUND) {
      return 'Autor s odpovídajícími hodnotami nebyl nazelen v databázi, při importu bude vytvořen.';
    } else if (errorDescription.field === 'external_reference_key' && errorDescription.error === ALREADY_EXISTS) {
      return 'Produkt s tímto externím klíčem již existuje, nebude proto importován.'
    }
    return `Pole '${errorDescription.field}' ${getMessageFromCode(errorDescription)}.`;
  };

  return (
    <>
      {serverSuccess && showSuccessfull && (
        <Alert severity="success" style={{ marginTop: 20 }}>
          <AlertTitle>Úspěch!</AlertTitle>
          {`${alertMessage.replace('{total}', validationResult.ok_total)}`}
        </Alert>
      )}
      {serverSuccess && showErrors && (
        <>
          <Alert severity="warning" style={{ marginTop: 20 }}>
            <AlertTitle>Ajajaj!</AlertTitle>
            Kvůli chybám bylo zamítnuto následujících {validationResult.error_total} položek:
          </Alert>
          {validationResult.errors.map((item) => (
            <Alert key={item.index} severity="warning" icon={false} style={{ marginLeft: '30px', marginTop: '10px' }}>
              <AlertTitle>{`${item.index + 1}. položka${item.key ? ` (${item.key})` : ''}`}</AlertTitle>
              <ul>
                {item.details.map((errDesc, idx) => (
                  <li key={`${item.index}_${idx}`}>{getErrorText(errDesc)}</li>
                ))}
              </ul>
            </Alert>
          ))}
        </>
      )}
      {serverSuccess === false && serverErrorMessage && (
        <Alert severity="error" style={{ marginTop: 20 }}>
          <AlertTitle>Při valdiaci došlo k závažné chybě</AlertTitle>
          {serverErrorMessage}
        </Alert>
      )}
    </>
  );
};

export default ImportModuleResult;
