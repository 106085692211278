import React, { useEffect, useState } from 'react';
import { Typography, FormControl, MenuItem, Select } from '@material-ui/core';
import { AlertTitle, Alert } from '@material-ui/lab';
import moment from 'moment';

import ProductTable from '../components/ProductTable';
import axios from '../utils/axios';
import Loader from '../layout/Loader';
import DateRange from './DateRange';

const ProvisionReporting = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [serverError, setServerError] = useState(null);

  const [consumerSelectValue, setConsumerSelectValue] = useState(null);

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [focusedInput, setFocusedInput] = useState(null);

  const loadData = async (from = startDate, to = endDate) => {
    try {
      setLoading(true);
      setServerError(null);
      const query = {
        ...(from && { from: from.toISOString() }),
        ...(to && { to: to.toISOString() }),
      };
      const res = await axios('/reporting/provision', { params: query });
      setData(res.data.data);
      setConsumerSelectValue(res.data.data.consumer_total[0]?.consumer || '');
    } catch (e) {
      setServerError(e);
    } finally {
      setLoading(false);
    }
  };

  const handleDateChange = (values) => {
    const start = values.startDate?.clone().hour(0).minute(0).second(0).millisecond(0) || null;
    const end = values.endDate?.clone().hour(23).minute(59).second(59).millisecond(999) || null;
    setStartDate(start);
    setEndDate(end);
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <>
      {loading && <Loader />}
      {serverError && (
        <Alert severity="error" style={{ marginTop: 20 }}>
          <AlertTitle>Ajajaj!</AlertTitle>
          Chyba při načítání dat.
        </Alert>
      )}
      {!loading && !serverError && data && (
        <>
          <div className="provision-report-top">
            <ul className="report-numbers">
              <li className="report-numbers__row report-numbers__row--longer">
                <p className="report-numbers__row-title">Celkový počet distribucí</p>
                <p className="report-numbers__row-value">{data.all_total}</p>
              </li>
              <li className="report-numbers__row report-numbers__row--longer">
                <p className="report-numbers__row-title">Consumer</p>
                <div className="report-numbers__row-select-container">
                  <FormControl className="report-numbers__row-select">
                    <Select name="consumer-select" value={consumerSelectValue} onChange={(e) => setConsumerSelectValue(e.target.value)}>
                      {data.consumer_total.map((item) => (
                        <MenuItem value={item.consumer} key={item.consumer}>
                          {item.consumer}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <p className="report-numbers__row-value">{data.consumer_total.find((item) => item.consumer === consumerSelectValue)?.total || 0}</p>
              </li>
            </ul>
            <DateRange
              startDate={startDate}
              startDateId="start-date"
              endDate={endDate}
              endDateId="end-date"
              onDatesChange={handleDateChange}
              focusedInput={focusedInput}
              onFocusChange={setFocusedInput}
              startDatePlaceholderText={'Datum od'}
              endDatePlaceholderText={'Datum do'}
              firstDayOfWeek={1}
              minimumNights={0}
              isOutsideRange={(day) => day.isAfter(moment())}
              initialVisibleMonth={() => moment().subtract(1, 'month')}
              hideKeyboardShortcutsPanel={true}
              onSubmit={() => loadData()}
            />
          </div>
          <div className="report-popular-section">
            <Typography variant="h6" style={{ marginBottom: '0.2rem' }}>
              Populární produkty:
            </Typography>
          </div>
          <div className="reportTable">
            <ProductTable 
              products={data.top}
              addedColumns={[
                { title: 'Počet distribucí', field: 'provision_count' }
              ]}
            />
          </div>
        </>
      )}
    </>
  );
};

export default ProvisionReporting;
