import { Button, TextField, Typography } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import Autocomplete from "@material-ui/lab/Autocomplete";
import React, { useEffect, useRef, useState } from "react";
import useStyles from "../../styles/forms";
import asyncForEach from "../../utils/asyncForEach";
import axios from "../../utils/axios";

const roles = ["author", "translater", "interpret", "illustrator", "music", "director"];

// DEPRECATED
export default (props) => {
  const classes = useStyles();
  let readOnlyComponent = typeof props.callBack !== "function";
  // console.log(props);
  const [authorItems, setAuthorItems] = useState([]);
  const inputEl = useRef(null);
  const roleEl = useRef(null);

  useEffect(() => {
    let isSubscribed = true;
    if (props.data !== undefined && props.data[0] !== undefined && props.data[0].id !== undefined) {
      let promiseArray = [];
      props.data.forEach((item) => {
        // console.log("item", item);
        promiseArray.push(axios(`/author/${item.id}`));
      });
      Promise.all([...promiseArray])
        .then((myArray) => {
          if (isSubscribed) {
            const fillUp = async () => {
              let jsons = [];
              await asyncForEach(myArray, async (item, index) => {
                jsons.push({ ...item.data.data, role: props.data[index].role });
                // return json;
              });
              setAuthorItems([...authorItems, ...jsons]);
            };
            fillUp();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
    return () => (isSubscribed = false);
  }, [props.data]);

  // -- Autocomplete:
  const [openAutocomplete, setOpenAutocomplete] = React.useState(false);
  const [valueState, setValueState] = React.useState({ name: "" });
  const [options, setOptions] = React.useState([]);
  const [lookupTable, setLookupTable] = React.useState([]);
  const loading = openAutocomplete && options.length === 0;

  React.useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    (async () => {
      const response = await axios.get("/author?limit=500");
      // await sleep(1e3); // For demo purposes.
      const fullList = response.data;
      console.log(fullList.data);
      setLookupTable(fullList.data);
      if (active) {
        // setOptions(Object.keys(fullList).map((key) => fullList[key].item[0]));
        setOptions(fullList.data);
      }
    })();

    return () => {
      active = false;
    };
  }, [loading]);

  React.useEffect(() => {
    if (!openAutocomplete) {
      setOptions([]);
    }
  }, [openAutocomplete]);

  const deleteAuthor = (authorName) => {
    let authorItemsToStay = authorItems.filter((item) => item.name !== authorName);
    setAuthorItems(authorItemsToStay);
    console.log(authorName);
  };
  const addAuthor = (event) => {
    console.log(inputEl.current.value, roleEl.current.value);
    let itemToAdd = lookupTable.find((item) => item.name === inputEl.current.value);
    if (itemToAdd) {
      console.log("Item found in lookup table.");
      let itemAlreadyAdded = authorItems.find((item) => item.name === itemToAdd.name);
      if (!itemAlreadyAdded) {
        setAuthorItems([...authorItems, { ...itemToAdd, role: roleEl.current.value }]);
      } else {
        console.log("But it's already assigned to the product.");
      }
      setValueState({ name: "" });
      setOptions([]);
    }
  };

  useEffect(() => {
    if (authorItems.length > 0) {
      let cleanedAuthorArray = authorItems.map((item) => {
        return { id: item.id, role: item.role };
      });
      // console.log(cleanedAuthorArray);
      if (readOnlyComponent === false) {
        console.log("Calling back from Authors");
        props.callBack({ authors: cleanedAuthorArray });
      }
    }
  }, [authorItems]);

  return (
    <>
      <table className="lookup">
        <tbody>
          <tr>
            <td style={{ width: "59%" }}>
              <Typography variant="caption">Autoři</Typography>
            </td>
            <td style={{ width: "40%" }}>
              <Typography variant="caption">Role</Typography>
            </td>
            {readOnlyComponent === false && <td></td>}
          </tr>
          {authorItems &&
            authorItems.map((item, index) => (
              <tr key={index}>
                <td style={{ width: "59%" }}>{item.name}</td>
                <td style={{ width: "40%" }}>{item.role}</td>
                {readOnlyComponent === false && (
                  <td>
                    <Button
                      size="small"
                      variant="text"
                      color="primary"
                      className={classes.button}
                      onClick={() => deleteAuthor(item.name)}
                      style={{ padding: "4px 8px", margin: 0, minWidth: 0 }}
                    >
                      X
                    </Button>
                  </td>
                )}
              </tr>
            ))}
          {readOnlyComponent === false && (
            <>
              <tr>
                <td>
                  <Autocomplete
                    id="asynchronous-select"
                    className={classes.autocompleteFullWidth}
                    style={{ marginTop: "-5px" }}
                    open={openAutocomplete}
                    value={valueState}
                    onChange={(event, newValue) => {
                      setValueState(newValue);
                    }}
                    onOpen={() => {
                      setOpenAutocomplete(true);
                    }}
                    onClose={() => {
                      setOpenAutocomplete(false);
                    }}
                    getOptionSelected={(option, value) => option.name === value.name}
                    getOptionLabel={(option) => (option.name ? option.name : "")}
                    options={options}
                    loading={loading}
                    renderInput={(props) => (
                      <TextField
                        inputRef={inputEl}
                        {...props}
                        label="Přidat autora"
                        InputProps={{
                          ...props.InputProps,
                          endAdornment: (
                            <>
                              {loading ? <CircularProgress color="inherit" size={20} /> : null}
                              {props.InputProps.endAdornment}
                            </>
                          ),
                        }}
                      />
                    )}
                  />
                </td>
                <td colSpan="2">
                  <Autocomplete
                    id="role"
                    className={classes.autocompleteFullWidth}
                    style={{ marginTop: "-5px" }}
                    options={roles}
                    getOptionLabel={(option) => option}
                    renderInput={(props) => <TextField inputRef={roleEl} {...props} name="role" label="Role" />}
                  />
                </td>
              </tr>
              <tr>
                <td colSpan="3">
                  <Button
                    size="small"
                    variant="outlined"
                    color="primary"
                    className={classes.button}
                    onClick={addAuthor}
                    style={{ marginLeft: 0, top: "-8px", position: "relative", zIndex: 999 }}
                  >
                    Přidat
                  </Button>
                </td>
              </tr>
            </>
          )}
        </tbody>
      </table>
    </>
  );
};
