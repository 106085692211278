import { Box, Paper, Typography, TablePagination } from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import MaterialTable from "material-table";
import MTLocalization from "../utils/MTLocalization";
import React, { useEffect, useState } from "react";
import Loader from "../layout/Loader";
import axios from "../utils/axios";
import hierarchize from "../utils/hierarchize";
import nullToString from "../utils/nullToString";
import nullFromString from "../utils/nullFromString";
import { CREATE, UPDATE, AbilityContext, CATEGORY } from "../permissions";
import { useAbility } from "@casl/react";
import { subject } from "@casl/ability";
import ServerResponseAlert from '../components/ServerResponseAlert';
import SearchBox from '../components/SearchBox';
import { getSortString, getSortReadyColumns } from '../utils/tableSortUtils';

const SORT_FIELDS = {
  title: 'title',
  external_reference_key: 'externalReferenceKey',
};

export default (props) => {
  const ability = useAbility(AbilityContext);
  const [state, setState] = useState(null);
  const [serverSuccess, setServerSuccess] = useState(null);
  const [serverReply, setServerReply] = useState(false);

  // -- Data loading and pagination
  const [response, setResponse] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setDataLoading] = useState(false);
  const [error, setDataError] = useState(null);
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const [filterText, setFilterText] = useState(null);
  const [sort, setSort] = useState(null);

  const loadData = (page = currentPage, size = pageSize, filter = filterText, sortDesc = sort) => {
    const doFetch = async () => {
      setDataLoading(true);
      try {
        const query = {
          page: page + 1,
          limit: size,
          ...(filter && { title: filter }),
          ...(sortDesc && { sort: getSortString(sortDesc, SORT_FIELDS) }),
        };
        const res = await axios('/category', { params: query });
        setResponse(res.data);
        setTotalCount(res.data.meta.total_number);
      } catch (e) {
        setDataError(e);
      } finally {
        setDataLoading(false);
      }
    };
    doFetch();
  };

  const handlePageChange = (newPage, newPageSize) => {
    setCurrentPage(newPage);
    setPageSize(newPageSize);
    loadData(newPage, newPageSize);
  };

  const handleFilterChange = (value) => {
    setCurrentPage(0);
    setFilterText(value);
    loadData(0, pageSize, value);
  };

  const handleFilterReset = () => {
    handleFilterChange(null);
  };

  const handleOrderChange = (columnIdx, order) => {
    const sort = columnIdx >= 0 ? { field: state.columns[columnIdx].field, order: order } : null;
    
    setCurrentPage(0);
    setSort(sort);
    loadData(0, pageSize, filterText, sort);
  };

  // runs on load only
  useEffect(() => {
    loadData();
  }, []);


  useEffect(() => {
    console.log(response);
    if (response && response.status === 200) {
      console.log(response.data);
      // let lookupTable = {};
      // lookupTable[response.data.map((item) => item.id)];
      /* response.data.forEach((item) => {
        lookupTable[item.id] = item.title;
      }); */
      // let hierList = hierarchize(response.data);
      // const fullList = hierList.map((item) => nullToString(item));
      let newData = {
        columns: [
          { title: "Název", field: "title" },
          { title: "Id", field: "id", render: (rowData) => <span className="smallFont">{rowData.id}</span>, editable: false },
          { title: "Anotace", field: "description" },
          { title: "ID Rodiče", field: "parent_id" },
          { title: "Externí klíč", field: "external_reference_key", render: (rowData) => <span className="smallFont">{rowData.external_reference_key}</span> },
        ],
        data: [...response.data],
      };
      setState(newData);
    }
  }, [response]);

  const submitData = async (newData, oldData) => {
    let newCategoryItem = oldData === undefined;
    if (newCategoryItem && newData.id) {
      delete newData.id;
    }
    try {
      let newBody = {
        ...newData,
      };
      let cleanBody = nullFromString(newBody);
      cleanBody = JSON.stringify(cleanBody);
      console.log(cleanBody);
      const response = newCategoryItem ? await axios.post("/category", cleanBody) : await axios.put(`/category/${oldData.id}`, cleanBody);
      const responseJson = response.data;
      if (response.status === 200 || response.status === 201) {
        // created
        setServerSuccess(true);
        setServerReply("V pořádku uloženo.");
        console.log("Success");
        // console.log(responseJson.data);
        handlePageChange(currentPage, pageSize);
        window.scrollTo({ top: 0, behavior: "smooth" });
        return responseJson.data;
      } else {
        setServerSuccess(false);
        setServerReply("Chyba při ukládání.");
        console.log("Chyba při ukládání.");
        window.scrollTo({ top: 0, behavior: "smooth" });
        return false;
      }
    } catch (err) {
      console.log("Chyba při ukládání. " + err);
      setServerSuccess(false);
      setServerReply(err);
      window.scrollTo({ top: 0, behavior: "smooth" });

      return false;
    }
  };
  const deleteItem = async (oldData) => {
    try {
      const response = await axios.delete(`/category/${oldData.id}`);

      const data = response.data;
      if (response.status === 200 || response.status === 201) {
        // created
        setServerSuccess(true);
        setServerReply("Successfully deleted.");
        console.log("Success");
        handlePageChange(currentPage, pageSize);
        window.scrollTo({ top: 0, behavior: "smooth" });
      } else {
        setServerSuccess(false);
        setServerReply("Error deleting data.");
        console.log("Chyba při mazání.");
        window.scrollTo({ top: 0, behavior: "smooth" });
      }
    } catch (err) {
      console.log("Error deleting data. " + err);
    }
  };

  return (
    <Paper className="basePaper">
      <Typography variant="h5">Kategorie</Typography>

      {serverReply && (
        <>
          <br />
          <ServerResponseAlert
            serverSuccess={serverSuccess}
            serverReply={serverReply}
          />
          <br />
        </>
      )}
      <Box>
        {loading && <Loader />}
        {error && (
          <Alert severity="error" style={{ marginTop: 20 }}>
            <AlertTitle>Ajajaj!</AlertTitle>
            Chyba při načítání dat.
          </Alert>
        )}
      </Box>
      <SearchBox
        onFilterSubmit={handleFilterChange}
        onFilterReset={handleFilterReset}
      />
      {response && state && (
        <MaterialTable
          key={pageSize} // workaround for not functioning page size change
          localization={MTLocalization}
          options={{ pageSize: pageSize, actionsColumnIndex: -1, addRowPosition: "first", toolbar: false }}
          style={{ margin: 0, boxShadow: "none" }}
          columns={getSortReadyColumns(state.columns, Object.keys(SORT_FIELDS), sort)}
          data={state.data}
          onOrderChange={handleOrderChange}
          editable={{
            isEditable: rowData => ability.can(UPDATE, subject(CATEGORY, rowData)),
            ...(ability.can(CREATE, CATEGORY) && {
              onRowAdd: (newData) =>
                new Promise((resolve, reject) => {
                  let saveResponse = submitData(newData);
                  if (saveResponse) {
                    resolve(saveResponse);
                  } else {
                    reject(error);
                  }
                })
            }),
            ...(ability.can(UPDATE, CATEGORY) && {
              onRowUpdate: (newData, oldData) =>
                new Promise((resolve, reject) => {
                  let saveResponse = submitData(newData, oldData);
                  if (saveResponse) {
                    resolve(saveResponse);
                  } else {
                    reject(error);
                  }
                })
            }),
            /*             onRowDelete: (oldData) =>
              new Promise((resolve, reject) => {
                let deleteResponse = deleteItem(oldData);
                if (deleteResponse) {
                  resolve(deleteResponse);
                } else {
                  reject(error);
                }
              }), */
          }}
          components={{
            Pagination: props => <TablePagination {...props}
              rowsPerPageOptions={[5, 10, 20, 30]}
              rowsPerPage={pageSize}
              count={totalCount}
              page={currentPage}
              onChangePage={(e, page) => handlePageChange(page, pageSize)}
              onChangeRowsPerPage={(e) => handlePageChange(currentPage, e.target.value)}
            />
          }}
        />
      )}
    </Paper>
  );
};
