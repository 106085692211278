import React from 'react';
import { Alert } from '@material-ui/lab';

const ServerResponseAlert = ({
  serverSuccess,
  serverReply, //a string or error object
  onClose,
}) => {
  const getErrorDescription = (message) => {
    try{
      if (typeof message === 'string') return message;
      if (!(message.response && message.response.data)) return message.toString();

      const errorResponse = message.response;
      if (typeof errorResponse.data === 'string') return `${errorResponse.data} (${errorResponse.status})`;
      return (
        <>
        {errorResponse.data.message} ({errorResponse.status})
        {errorResponse.data.errors && (
          <ul>
            {errorResponse.data.errors.map(item => (
              <li>{item.message}</li>
            ))}
          </ul>
        )}
        </>
      );
    } catch (e) {
      // safety measure for unknown error structures
      return 'Unknown error occured';
    }
    
  }

  return (
    <Alert severity={serverSuccess ? 'success' : 'error'} onClose={onClose ? onClose : undefined}>
      {getErrorDescription(serverReply)}
    </Alert>
  );
};

export default ServerResponseAlert;
