import axios from "axios";

const instance = axios.create({
  // .. where we make our configurations
  baseURL: "/api",
});

// Where you would set stuff like your 'Authorization' header, etc ...
instance.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
// instance.defaults.headers.common["Accept"] = "application/json";
instance.defaults.headers.patch["Content-Type"] = "application/json";
instance.defaults.headers.put["Content-Type"] = "application/json";
instance.defaults.headers.post["Content-Type"] = "application/json";
instance.defaults.headers.delete["Content-Type"] = "application/json";

// Add a 401 response interceptor
instance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (401 === error.response.status) {
      console.log("401 - Ověření selhalo či expirovalo. Přesměrovávám na stránku s přihlášením. ");
      localStorage.removeItem("token");
      localStorage.removeItem('consumer');
      window.location.href = "/";
    } else {
      return Promise.reject(error);
    }
  }
);

export default instance;

// ===== HELPERS =====

export const setDefaultConsumerHeader = (consumerKey) => {
  instance.defaults.headers.common['X-AM-Consumer-Key'] = consumerKey;
};

export const getConsumerHeader = (consumerKey) => ({
  'X-AM-Consumer-Key': consumerKey,
});
