import { Button, Grid, makeStyles, MenuItem, TextField } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import AutocompleteSearch from '../../components/AutocompleteSearch';
import ChosenValuesTable from '../../components/ChosenValuesTable';
import Lock from '../../components/Lock';
import ServerResponseAlert from '../../components/ServerResponseAlert';

const useStyles = makeStyles({
  noMargin: {
    margin: '0 !important',
  },
});

const AuthorList = ({ values, onValuesChange, onError, isLocked, onToggleLock }) => {
  const AUTHOR_TABLE_VALUES = [
    { property: 'lock', caption: () => (<Lock isLocked={isLocked} onToggleLock={onToggleLock} hideable={false} />) },
    { property: 'name', caption: 'Autor' },
    { property: 'role', caption: 'Role' },
  ];

  const classes = useStyles();
  const [authors, setAuthors] = useState([]);
  const [autocompleteValue, setAutocompleteValue] = useState(null);
  const [roleValue, setRoleValue] = useState('author');
  const [validationMessage, setValidationMessage] = useState(null);

  const authorsEqual = (a, b) => a.id === b.id && a.role === b.role;

  const validateAuthor = (newAuthor) => {
    if (authors.some((item) => authorsEqual(newAuthor, item))) {
      setValidationMessage('Nelze přidat jednoho autora dvakrát se stejnou rolí');
      return false;
    }
    return true;
  };

  useEffect(() => {
    setAuthors(values || []);
  }, [values]);

  useEffect(() => {
    onValuesChange(authors);
  }, [authors]);

  const handleAutocompleteValueChange = (newValue) => {
    setAutocompleteValue(newValue);
  };

  const handleSubmit = () => {
    if (autocompleteValue) {
      const newAuthor = { ...autocompleteValue, role: roleValue };
      const validationResult = validateAuthor(newAuthor);
      if (validationResult) {
        handleAddItem(newAuthor);
        setAutocompleteValue(null);
        setRoleValue('author');
        handleCloseMessage();
      }
    }
  };

  const handleRoleChange = (event) => {
    setRoleValue(event.target.value);
  }

  const handleAddItem = (newAuthor) => {
    setAuthors((prevValue) => [...prevValue, newAuthor]);
  };

  const handleDeleteItem = (deletedAuthor) => {
    setAuthors((prevValue) => prevValue.filter((prevItem) => !authorsEqual(deletedAuthor, prevItem)));
  };

  const handleError = (e) => onError(e);

  const handleCloseMessage = () => setValidationMessage(null);

  return (
    <>
      <ChosenValuesTable description={AUTHOR_TABLE_VALUES} values={authors} onDeleteItem={handleDeleteItem} />
      {validationMessage && (
        <ServerResponseAlert
          serverSuccess={false}
          serverReply={validationMessage}
          onClose={handleCloseMessage}
        />
      )}
      <div>
        <Grid container spacing={1}>
          <Grid item xs={7} className={classes.noMargin}>
            <AutocompleteSearch
            label="Přidat autora"
            baseUrl="/author"
            searchBy="name"
            alreadySelected={[]}
            getOptionLabel={(option) => option.name}

            value={autocompleteValue}
            onValueChange={handleAutocompleteValueChange}
            onError={handleError}
          />
          </Grid>
          <Grid item xs={2} className={classes.noMargin}>
            <TextField
              select
              fullWidth
              label="Role"
              value={roleValue}
              onChange={handleRoleChange}
            >
              <MenuItem value="author">author</MenuItem>
              <MenuItem value="translater">translater</MenuItem>
              <MenuItem value="interpret">interpret</MenuItem>
              <MenuItem value="illustrator">illustrator</MenuItem>
              <MenuItem value="music">music</MenuItem>
              <MenuItem value="director">director</MenuItem>
            </TextField>
          </Grid>
        </Grid>
        <Button size="small" variant="outlined" color="primary" onClick={handleSubmit}>
          Přidat
        </Button>
      </div>
    </>
  );
};

export default AuthorList;
