import HeadsetRoundedIcon from '@material-ui/icons/HeadsetRounded';
import TabletMacRoundedIcon from '@material-ui/icons/TabletMacRounded';
import AddCircleOutlineRoundedIcon from '@material-ui/icons/AddCircleOutlineRounded';
import MenuBookRoundedIcon from '@material-ui/icons/MenuBookRounded';
import { Box, Paper, Typography, Button, TablePagination } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import MaterialTable from 'material-table';
import MTLocalization from '../utils/MTLocalization';
import React, { useEffect, useState } from 'react';
import Loader from '../layout/Loader';
import axios from '../utils/axios';
import formatDate from '../utils/formatDate';
import { getSortReadyColumns, getSortString } from '../utils/tableSortUtils';
import AdvancedSearch from '../components/AdvancedSearch';
import DistributionSearchFilter, { INITIAL_FILTER_VALUE, LABELS } from '../components/DistributionSearchFilter';

const SORT_FIELDS = {
  purchase_date: 'purchaseDate',
};

export default () => {
  const [response, setResponse] = useState(null);
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const [filterQuery, setFilterQuery] = useState(null);
  const [sort, setSort] = useState(null);

  const loadData = (page = currentPage, size = pageSize, filter = filterQuery, sortDesc = sort ) => {
    const doFetch = async () => {
      setLoading(true);

      try {
        const query = {
          page: page + 1,
          limit: size,
          ...filter,
          ...(sortDesc && { sort: getSortString(sortDesc, SORT_FIELDS) })
        };

        const res = await axios('/provision', { params: query });
        setResponse(res.data);
        setTotalCount(res.data.meta.total_number)
      } catch (e) {
        setError(e);
      } finally {
        setLoading(false);
      }
    };

    doFetch();
  };

  const handlePageChange = (newPage, newPageSize) => {
    setCurrentPage(newPage);
    setPageSize(newPageSize);
    loadData(newPage, newPageSize);
  };

  const handleFilterChange = (newFilterQuery) => {
    setCurrentPage(0);
    setFilterQuery(newFilterQuery);
    loadData(0, pageSize, newFilterQuery);
  };

  const handleFilterReset = () => {
    handleFilterChange(null);
  };

  useEffect(() => {
    loadData();
  }, ([]));

  const handleOrderChange = (columnIdx, order) => {
    const newSort = columnIdx >= 0 ? { field: columnsDescription[columnIdx].field, order } : null;

    setCurrentPage(0);
    setSort(newSort);

    loadData(0, pageSize, filterQuery, newSort);
  };

  const columnsDescription = [
    { title: 'Odběratel', field: 'consumer', render: (rowData) => <span style={{ textTransform: 'capitalize' }}>{rowData.consumer}</span> },
    { title: 'Produkt', field: 'product_name' },
    {
      title: 'Typ',
      field: 'type',
      render: (rowData) => {
        return rowData.type === 'audiobook' ? (
          <div className="productType">
            <HeadsetRoundedIcon />
            <span>Audiobook</span>
          </div>
        ) : rowData.type === 'ebook' ? (
          <div className="productType">
            <TabletMacRoundedIcon />
            <span>Ebook</span>
          </div>
        ) : (
          <div className="productType">
            <MenuBookRoundedIcon />
            <span>Fyzické zboží</span>
          </div>
        );
      },
    },
    { title: 'Číslo objednávky', field: 'order_number', render: (rowData) => <span className="smallFont">{rowData.order_number}</span> },
    { title: 'Datum zakoupení', field: 'purchase_date', render: (rowData) => <span className="smallFont">{formatDate(rowData.purchase_date)}</span> },
    { title: 'Platnost do', field: 'valid_until', render: (rowData) => <span className="smallFont">{formatDate(rowData.valid_until)}</span> },
    {
      width: 100,
      title: '',
      field: 'action',
      render: (rowData) => (
        <Button href={`/distribution-management/${rowData.id}`} variant="outlined" color="primary">
          Detail
        </Button>
      ),
    },
  ];

  return (
    <Paper className="basePaper">
      <Typography variant="h5">Správa distribuce</Typography>
      <Box>
        {loading && <Loader />}
        {error && (
          <Alert severity="error" style={{ marginTop: 20 }}>
            <AlertTitle>Ajajaj!</AlertTitle>
            Chyba při načítání dat.
          </Alert>
        )}
      </Box>
      <AdvancedSearch 
        onFilterSubmit={handleFilterChange}
        onFilterReset={handleFilterReset}
        defaultFilter={filterQuery}
        initialFilter={INITIAL_FILTER_VALUE}
        filterLabels={LABELS}
        filterFormComponent={DistributionSearchFilter}
      />
      {response && (
        <MaterialTable
          key={pageSize} // workaround for not functioning page size change
          localization={MTLocalization}
          options={{ pageSize: pageSize, actionsColumnIndex: -1, toolbar: false }}
          style={{ margin: 0, boxShadow: 'none' }}
          columns={getSortReadyColumns(columnsDescription, Object.keys(SORT_FIELDS), sort)}
          data={response.data}
          onOrderChange={handleOrderChange}
          components={{
            Pagination: props => <TablePagination {...props}
              rowsPerPageOptions={[5, 10, 20, 30]}
              rowsPerPage={pageSize}
              count={totalCount}
              page={currentPage}
              onChangePage={(_, page) => handlePageChange(page, pageSize)}
              onChangeRowsPerPage={(e) => handlePageChange(currentPage, e.target.value)}
            />
          }}
        />
      )}
    </Paper>
  );
};
