import React from 'react';
import { NavLink } from 'react-router-dom';
import { makeStyles, Typography } from '@material-ui/core';
import { useAbility } from '@casl/react';

import {
  Can,
  READ,
  AbilityContext,
  ACCESS,
  BUNDLE,
  PRODUCT,
  AUTHOR,
  PROJECT,
  PUBLISHER,
  SERIE,
  GENRE,
  EDITION,
  CATEGORY,
  MULTITREE,
  PRODUCT_TYPE,
  DISTRIBUTION_MANAGEMENT,
  REPORTING,
  IMPORT_TOOL,
  TOOLS,
  BUNDLE_TOOL,
  DEDUPLICATION_TOOL,
  SETTINGS,
} from '../permissions';

const useStyles = makeStyles((theme) => ({
  /*   root: {
    flexGrow: 1
  },
  menuButton: {
    marginRight: theme.spacing(2)
  },
  title: {
    flexGrow: 1
  } */
  menu: {
    margin: 0,
    padding: 0,
    '& li': {
      padding: 0,
      margin: 0,
      listStyle: 'none',
      textAlign: 'left',
      borderBottom: '1px solid #E6E6E6',
      '& a': {
        color: 'black', //
        display: 'inline-block',
        padding: `${theme.spacing(1.5)}px 0 ${theme.spacing(1)}px ${theme.spacing(1.5)}px`,
        boxSizing: 'border-box',
        width: '100%',
        textDecoration: 'none',
        '&.active, &:hover': {
          color: theme.palette.primary.main,
        },
      },
      '& a:hover': {
        background: '#fafafa',
      },
      '& ul': {
        margin: 0,
        padding: 0,

        '& li': {
          // borderTop: "1px dotted #f7f7f7",
          borderTop: '0',
          borderBottom: '0',
          '& a': {
            padding: `${theme.spacing(0.8)}px 0 ${theme.spacing(0.8)}px ${theme.spacing(3)}px`,
            fontSize: '0.9rem',
          },
        },
      },
    },
  },
}));

const Nav = () => {
  const classes = useStyles();
  const ability = useAbility(AbilityContext);
  return (
    <nav>
      <ul className={classes.menu} id="mainNav">
        <li>
          <NavLink exact to='/'>
            Dashboard
          </NavLink>
        </li>
        <li>
          <NavLink to='/data-management'>Správa dat</NavLink>
          <ul>
            <Can I={READ} a={BUNDLE}>
              {() => (
                <li>
                  <NavLink
                    to='/product-bundles'
                    isActive={(match, location) => {
                      const isCurrent = location.pathname.indexOf('product-bundle') > -1;
                      return isCurrent;
                    }}
                  >
                    Produktové svazky
                  </NavLink>
                </li>
              )}
            </Can>
            <Can I={READ} a={PRODUCT}>
              {() => (
                <li>
                  <NavLink
                    to='/products'
                    isActive={(match, location) => {
                      const isCurrent =
                        location.pathname.indexOf('product') > -1 && location.pathname.indexOf('product-bundle') === -1 && location.pathname.indexOf('products-types') === -1;
                      return isCurrent;
                    }}
                  >
                    Produkty{' '}
                  </NavLink>
                </li>
              )}
            </Can>
            <Can I={READ} a={PRODUCT_TYPE}>
              {() => (
                <li>
                  <NavLink to='/products-types'>Produktové typy</NavLink>
                </li>
              )}
            </Can>
            <Can I={READ} a={PROJECT}>
              {() => (
                <li>
                  <NavLink
                    to='/projects'
                    isActive={(match, location) => {
                      const isCurrent = location.pathname.indexOf('project') > -1;
                      return isCurrent;
                    }}
                  >
                    Projekty
                  </NavLink>
                </li>
              )}
            </Can>
            <Can I={READ} an={AUTHOR}>
              {() => (
                <li>
                  <NavLink
                    to='/authors'
                    isActive={(match, location) => {
                      const isCurrent = location.pathname.indexOf('author') > -1;
                      return isCurrent;
                    }}
                  >
                    Autoři
                  </NavLink>
                </li>
              )}
            </Can>
            <Can I={READ} a={PUBLISHER}>
              {() => (
                <li>
                  <NavLink
                    to='/publishers'
                    isActive={(match, location) => {
                      const isCurrent = location.pathname.indexOf('publisher') > -1;
                      return isCurrent;
                    }}
                  >
                    Vydavatelé / Značky
                  </NavLink>
                </li>
              )}
            </Can>
            {(ability.can(READ, SERIE) || ability.can(READ, EDITION) || ability.can(READ, GENRE) || ability.can(READ, CATEGORY)) && (
              <li>
                <Typography variant="caption" style={{ marginLeft: '12px', color: '#ccc' }}>
                  AM kategorie:
                </Typography>
              </li>
            )}
            <Can I={READ} a={SERIE}>
              {() => (
                <li>
                  <NavLink to='/series'>Série</NavLink>
                </li>
              )}
            </Can>
            <Can I={READ} a={EDITION}>
              {() => (
                <li>
                  <NavLink
                    to='/editions'
                    isActive={(match, location) => {
                      const isCurrent = location.pathname.indexOf('edition') > -1;
                      return isCurrent;
                    }}
                  >
                    Edice
                  </NavLink>
                </li>
              )}
            </Can>
            <Can I={READ} a={GENRE}>
              {() => (
                <li>
                  <NavLink to='/genres'>Žánry</NavLink>
                </li>
              )}
            </Can>
            <Can I={READ} a={CATEGORY}>
              {() => (
                <li>
                  <NavLink to='/categories'>Kategorie</NavLink>
                </li>
              )}
            </Can>
            <Can I={READ} a={MULTITREE}>
              {() => (
                <li>
                  <NavLink to='/tree-branches'>Stromy kategorií</NavLink>
                </li>
              )}
            </Can>
            <Can I={READ} a={MULTITREE}>
              {() => (
                <li>
                  <NavLink to='/multitree'>Mapování stromů kategorií</NavLink>
                </li>
              )}
            </Can>
          </ul>
        </li>
        <Can I={ACCESS} a={DISTRIBUTION_MANAGEMENT}>
          {() => (
            <li>
              <NavLink to='/distribution-management'>Správa distribuce</NavLink>
            </li>
          )}
        </Can>
        <Can I={ACCESS} a={REPORTING}>
          {() => (
            <li>
              <NavLink to='/reporting'>Reporting</NavLink>
            </li>
          )}
        </Can>
        <Can I={ACCESS} a={SETTINGS}>
          <li>
            <NavLink to='/settings'>Nastavení</NavLink>
          </li>
        </Can>
        <Can I={ACCESS} a={TOOLS}>
          {() => (
            <li>
              <NavLink to='/tools'>Nástroje</NavLink>
              <ul>
                <Can I={ACCESS} a={IMPORT_TOOL}>
                  {() => (
                    <li>
                      <NavLink to='/import-tool'>Import</NavLink>
                    </li>
                  )}
                </Can>
                <Can I={ACCESS} a={BUNDLE_TOOL}>
                  {() => (
                    <li>
                      <NavLink to='/bundle-tool'>Svazkování</NavLink>
                    </li>
                  )}
                </Can>
                <Can I={ACCESS} a={DEDUPLICATION_TOOL}>
                  {() => (
                    <li>
                      <NavLink to='/deduplication'>Deduplikace</NavLink>
                    </li>
                  )}
                </Can>
              </ul>
            </li>
          )}
        </Can>
      </ul>
    </nav>
  );
};
export default Nav;
