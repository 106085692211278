import { Button } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import AutocompleteSearch from '../../components/AutocompleteSearch';
import ChosenValuesTable from '../../components/ChosenValuesTable';
import Lock from '../../components/Lock';

const SimpleList = ({
  values,
  onValuesChange,
  onError,
  tableLabel,
  searchLabel,
  mainProperty,
  baseUrl,
  lockable = false,
  isLocked = undefined,
  onToggleLock = undefined,
}) => {
  const TABLE_VALUES = [
    ...(lockable && [{ property: 'lock', caption: () => (<Lock isLocked={isLocked} onToggleLock={onToggleLock} hideable={false} />) }]),
    { property: mainProperty, caption: tableLabel },
  ];
  const [selected, setSelected] = useState([]);
  const [autocompleteValue, setAutocompleteValue] = useState(null);

  useEffect(() => {
    setSelected(values || []);
  }, [values]);

  useEffect(() => {
    onValuesChange(selected);
  }, [selected]);

  const handleAutocompleteValueChange = (newValue) => {
    setAutocompleteValue(newValue);
  };

  const handleSubmit = () => {
    if (autocompleteValue) {
      handleAddItem(autocompleteValue);
      setAutocompleteValue(null);
    }
  };

  const handleAddItem = (item) => {
    if (!selected.some((p) => p.id === item.id)) {
      setSelected((prevValue) => [...prevValue, item]);
    }
  };

  const handleDeleteItem = (item) => {
    if (selected.some((p) => p.id === item.id)) {
      setSelected((prevValue) => prevValue.filter((prevItem) => prevItem.id !== item.id));
    }
  };

  const handleError = (e) => onError(e);

  return (
    <>
      <ChosenValuesTable description={TABLE_VALUES} values={selected} onDeleteItem={handleDeleteItem} />
      <div>
        <AutocompleteSearch
          label={searchLabel}
          baseUrl={baseUrl}
          searchBy={mainProperty}
          alreadySelected={selected}
          getOptionLabel={(option) => option[mainProperty]}

          value={autocompleteValue}
          onValueChange={handleAutocompleteValueChange}
          onError={handleError}
        />
        <Button size="small" variant="outlined" color="primary" onClick={handleSubmit}>
          Přidat
        </Button>
      </div>
    </>
  );
};

export default SimpleList;
