import { Button, Grid, makeStyles, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import React, { useEffect, useState } from 'react';
import axios from '../../utils/axios';
import AutocompleteSearch from '../../components/AutocompleteSearch';
import ChosenValuesTable from '../../components/ChosenValuesTable';
import Lock from '../../components/Lock';

const useStyles = makeStyles({
  noMargin: {
    margin: '0 !important',
  },
});

const buildNodePath = (node) => {
  let result = [node.title];
  let currentNode = node;

  while (currentNode.parent) {
    result = [currentNode.parent.title, ...result];
    currentNode = currentNode.parent;
  }

  return result;
};

const enhanceNodesWithParentReference = (nodes) => {
  // for each node find its parent and assign said parent as a property of that node
  // thanks to shallow copies the references form a tree in background
  const nodesWithParent = nodes.map(item => ({ ...item, parent: null }));
  nodesWithParent.forEach(item => {
    if (item.parent_id) {
      const parent = nodesWithParent.find(node => node.id === item.parent_id);
      if (parent) {
        item.parent = parent;
      }
    }
  });

  return nodesWithParent;
};

const enhanceNodesWithPaths = (nodesWithParent) => nodesWithParent.map(node => ({
  ...node,
  path: buildNodePath(node),
}));

const processTreeBranches = (rawBranches) => {
  const nodesWithParent = enhanceNodesWithParentReference(rawBranches);
  const nodesWithPaths = enhanceNodesWithPaths(nodesWithParent);
  const sorted = nodesWithPaths.sort((a, b) => {
    const textA = a.path.join('/').toLowerCase();
    const textB = b.path.join('/').toLowerCase();
    return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
  });

  return sorted;
}

const BranchList = ({ values, onValuesChange, onError, isLocked, onToggleLock }) => {
  const BRANCH_TABLE_VALUES = [
    { property: 'lock', caption: () => (<Lock isLocked={isLocked} onToggleLock={onToggleLock} hideable={false} />) },
    { property: 'tree_key', caption: 'Strom' },
    { property: 'path', caption: 'Větev', transformation: (path) => (path || []).join('/') },
  ];

  const classes = useStyles();
  const [assignedBranches, setAssignedBranches] = useState([]);
  const [availableBranches, setAvailableBranches] = useState(null);
  const [availableBranchesLoading, setAvailableBranchesLoading] = useState(false);
  const [treeValue, setTreeValue] = useState(null);
  const [branchValue, setBranchValue] = useState(null);

  const loadAvailableBranchesData = (treeKey) => {
    const doFetch = async () => {
      setAvailableBranches([]);
      setAvailableBranchesLoading(true);
      try {
        const response = await axios.get(`/tree/key/${treeKey}`);
        const branches = processTreeBranches(response.data.data.branches);
        setAvailableBranches(branches);
      } catch (e) {
        onError(e);
        setAvailableBranches(null);
      } finally {
        setAvailableBranchesLoading(false);
      }
    };
    doFetch();
  };

  useEffect(() => {
    setAssignedBranches(values || []);
  }, [values]);

  useEffect(() => {
    onValuesChange(assignedBranches);
  }, [assignedBranches]);

  const handleTreeValueChange = (newValue) => {
    setTreeValue(newValue);

    if (newValue) {
      loadAvailableBranchesData(newValue.key);
    } else {
      setAvailableBranches(null);
    }

    setBranchValue(null);
  };

  const handleSubmit = () => {
    if (branchValue) {
      handleAddBranch(branchValue);
      setTreeValue(null);
      setBranchValue(null);
      setAvailableBranches(null);
    }
  };

  const handleBranchValueChange = (_, branch) => {
    setBranchValue(branch);
  };

  const handleAddBranch = (item) => {
    if (!assignedBranches.some((p) => p.id === item.id)) {
      setAssignedBranches((prevValue) => [...prevValue, item]);
    }
  };

  const handleDeleteBranch = (item) => {
    if (assignedBranches.some((p) => p.id === item.id)) {
      setAssignedBranches((prevValue) => prevValue.filter((prevItem) => prevItem.id !== item.id));
    }
  };

  const handleError = (e) => onError(e);

  return (
    <>
      <ChosenValuesTable description={BRANCH_TABLE_VALUES} values={assignedBranches} onDeleteItem={handleDeleteBranch} />
      <div>
        <Grid container spacing={1}>
          <Grid item xs={3} className={classes.noMargin}>
            <AutocompleteSearch
              label="Vybrat strom"
              baseUrl="/tree"
              searchQuery={{ direction: 'IN' }}
              searchBy="key"
              alreadySelected={treeValue ? [treeValue] : []}
              getOptionLabel={(option) => option.key}
              identityAttribute="key"

              value={treeValue}
              onValueChange={handleTreeValueChange}
              onError={handleError}
            />
          </Grid>
          <Grid item xs={7} className={classes.noMargin}>
            <Autocomplete
              loading={availableBranchesLoading}
              loadingText={'Vyhledávání...'}
              value={branchValue}
              onChange={handleBranchValueChange}
              options={availableBranches}
              getOptionLabel={(option) => (option.path || []).join('/')}
              getOptionDisabled={(option) => assignedBranches.some((i) => i.id === option.id)}
              getOptionSelected={(option, value) => option.id === value.id}
              noOptionsText={'Nenalezeno'}
              disabled={!availableBranches}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={"Větev"}
                />
              )}
            />
          </Grid>
        </Grid>
        <Button size="small" variant="outlined" color="primary" onClick={handleSubmit}>
          Přidat
        </Button>
      </div>
    </>
  );
};

export default BranchList;
