import { Button, Grid, makeStyles, TextField } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import AutocompleteSearch from '../../components/AutocompleteSearch';
import ChosenValuesTable from '../../components/ChosenValuesTable';
import Lock from '../../components/Lock';

const useStyles = makeStyles({
  noMargin: {
    margin: '0 !important',
  },
});

const SerieList = ({ values, onValuesChange, onError, isLocked, onToggleLock }) => {
  const SERIE_TABLE_VALUES = [
    { property: 'lock', caption: () => (<Lock isLocked={isLocked} onToggleLock={onToggleLock} hideable={false} />) },
    { property: 'title', caption: 'Série' },
    { property: 'order_in_serie', caption: 'Pořadí' },
  ];

  const classes = useStyles();
  const [series, setSeries] = useState([]);
  const [autocompleteValue, setAutocompleteValue] = useState(null);
  const [orderValue, setOrderValue] = useState('');

  useEffect(() => {
    setSeries(values || []);
  }, [values]);

  useEffect(() => {
    onValuesChange(series);
  }, [series]);

  const handleAutocompleteValueChange = (newValue) => {
    setAutocompleteValue(newValue);
  };

  const handleSubmit = () => {
    if (autocompleteValue) {
      handleAddItem({ ...autocompleteValue, order_in_serie: orderValue === '' ? null : orderValue });
      setAutocompleteValue(null);
      setOrderValue('')
    }
  };

  const handleOrderChange = (event) => {
    if (event.target.value === '') {
      setOrderValue(event.target.value);
      return;
    }

    const newValue = Number.parseInt(event.target.value);
    if (!Number.isNaN(newValue) && newValue >= 0 && newValue < 2 ** 31){
      setOrderValue(newValue);
    }
  };

  const handleAddItem = (item) => {
    if (!series.some((p) => p.id === item.id)) {
      setSeries((prevValue) => [...prevValue, item]);
    }
  };

  const handleDeleteItem = (item) => {
    if (series.some((p) => p.id === item.id)) {
      setSeries((prevValue) => prevValue.filter((prevItem) => prevItem.id !== item.id));
    }
  };

  const handleError = (e) => onError(e);

  return (
    <>
      <ChosenValuesTable description={SERIE_TABLE_VALUES} values={series} onDeleteItem={handleDeleteItem} />
      <div>
        <Grid container spacing={1}>
          <Grid item xs={7} className={classes.noMargin}>
            <AutocompleteSearch
            label="Přidat sérii"
            baseUrl="/serie"
            searchBy="title"
            alreadySelected={series}
            getOptionLabel={(option) => option.title}

            value={autocompleteValue}
            onValueChange={handleAutocompleteValueChange}
            onError={handleError}
          />
          </Grid>
          <Grid item xs={2} className={classes.noMargin}>
            <TextField
              label="Pořadí v sérii"
              value={orderValue}
              onChange={handleOrderChange}
            />
          </Grid>
        </Grid>
        <Button size="small" variant="outlined" color="primary" onClick={handleSubmit}>
          Přidat
        </Button>
      </div>
    </>
  );
};

export default SerieList;
