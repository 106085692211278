import React, { useState } from 'react';
import { TextField, Button, makeStyles } from '@material-ui/core';
import { TYPE_SPECIFIC_ATTR, CONSUMER_SPECIFIC_ATTR } from '../../entities/specificAttributes';


const useStyles = makeStyles({
  addAttributeSection: {
    marginTop: '30px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  label: {
    marginRight: '20px',
  },
  buttonSection: {
    marginLeft: '20px',

  },
  input: {
    width: '350px'
  }
})

const AddConsumerAttribute = (props) => {
  const classes = useStyles();
  const helperText = 'bez mezer, povolené znaky: a-z, pomlčka, podtržítko';

  const [attributeName, setAttributeName] = useState('');
  const [error, setError] = useState(null);

  const cleanUp = () => {
    setAttributeName('');
    setError(null);
  }

  const validateAttributeName = (name) => {
    return name.match(/^spec_[a-z\-_]+$/g);
  }

  const validate = (name) => {
    if (!validateAttributeName(name)) {
      setError(`název nelze použít: ${helperText}`);
      return false;
    }

    // we need to check we are not adding something that already exists
    if (TYPE_SPECIFIC_ATTR.some(attr => `spec_${attr}` === name)) {
      setError('nelze použít jméno existujícho atributu produktového typu');
      return false;
    }

    if (CONSUMER_SPECIFIC_ATTR.some(attr => `spec_${attr}` === name) || props.existingNonstandardAttributes.some(attr => attr === name)) {
      setError('nelze použít jméno existujícho consumer atributu');
      return false;
    }

    return true;
  }

  const handleSubmit = () => {
    const name = attributeName.startsWith('spec_') ? attributeName : `spec_${attributeName}`;

    if (!validate(name)) return;

    console.log('Adding:', name);
    props.onAttributeAdd(name);
    cleanUp();
  }

  return (
    <div className={classes.addAttributeSection}>
      <span className={classes.label}>Přidat nový consumer atribut:</span>
      <TextField
        className={classes.input}
        name='new-consumer-attribute'
        label='Nový atribut'
        helperText={error ? error : helperText}
        error={!!error}
        value={attributeName}
        onChange={(e) => setAttributeName(e.target.value)}
        inputProps={{
          onKeyDown: (e) => {
            if (e.key === 'Enter'){
              e.preventDefault(); // must not trigger whole form submit, just this local part
              handleSubmit();
            }}
        }}
      />
      <span className={classes.buttonSection}>
        <Button
          className={classes.button}
          color="primary"
          variant="outlined"
          type="button"
          onClick={handleSubmit}
        >
          Přidat atribut
        </Button>
      </span>

    </div>
  )
}

export default AddConsumerAttribute;
