import TabletMacRoundedIcon from "@material-ui/icons/TabletMacRounded";
import HeadsetRoundedIcon from "@material-ui/icons/HeadsetRounded";
import MenuBookRoundedIcon from "@material-ui/icons/MenuBookRounded";
import { Box, Button, Grid, Paper, Typography } from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import MaterialTable from "material-table";
import MTLocalization from "../utils/MTLocalization";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useFetch from "../hooks/useFetch";
import Loader from "../layout/Loader";
import Assets from "../layout/Assets";
import { Can, UPDATE, READ, AbilityContext, PRODUCT, BUNDLE } from "../permissions";
import { useAbility } from "@casl/react";
import { subject } from "@casl/ability";

export default (props) => {
  let { id } = useParams();
  const { response, loading, error } = useFetch(`/product-bundle/${id}`);
  const ability = useAbility(AbilityContext);
  const [responseData, setresponseData] = useState(null);
  useEffect(() => {
    if (response && response.data) {
      console.log(response.data);
      setresponseData(response.data);
    }
  }, [response]);

  const [state, setState] = React.useState({});
  useEffect(() => {
    console.log(responseData);
    if (responseData) {
      let newData = {
        columns: [
          { title: "Název", field: "title", render: (rowData) => ability.can(READ, subject(PRODUCT, rowData)) ?
            <a href={`/product/${rowData.id}`}>{rowData.title}</a> : <span>{rowData.title}</span>
          },
          {
            title: "Autor",
            field: "authors",
            render: (rowData) => (rowData.authors[0] && rowData.authors[0].id ? rowData.authors[0].id : "-"),
          },
          { title: "Vydavatel", field: "publisher", render: (rowData) => rowData.publisher.name },
          {
            title: "Typ",
            field: "type",
            render: (rowData) => {
              return rowData.type === "audiobook" ? (
                <div className="productType">
                  <HeadsetRoundedIcon />
                  <span>Audiobook</span>
                </div>
              ) : rowData.type === "ebook" ? (
                <div className="productType">
                  <TabletMacRoundedIcon />
                  <span>Ebook</span>
                </div>
              ) : (
                <div className="productType">
                  <MenuBookRoundedIcon />
                  <span>Fyzické zboží</span>
                </div>
              );
            },
          },
          {
            title: "",
            field: "action",
            render: (rowData) => (
              <Can I={UPDATE} a={subject(PRODUCT, rowData)}>
                {() => <Button href={`/product/edit/${rowData.id}`} variant="outlined" color="primary">
                  Detail produktu
                </Button>}
              </Can>
            ),
          },
        ],
        data: responseData.products && responseData.products.length ? [...responseData.products] : [],
      };
      setState(newData);
    }
  }, [responseData]);
  return (
    <Paper className="basePaper">
      <Grid container direction="row" justify="space-between">
        <Grid item xs={8}>
          <Typography variant="h5">{responseData && responseData.title ? responseData.title : "-"}</Typography>
          <Box>
            {loading && <Loader />}
            {error && (
              <Alert severity="error" style={{ marginTop: 20 }}>
                <AlertTitle>Ajajaj!</AlertTitle>
                Chyba při načítání dat.
              </Alert>
            )}
          </Box>
          <br />
          <strong>CDI ID:</strong> {responseData && responseData.id ? responseData.id : "-"}
          <br />
          <br />
          <strong>Podtitulek:</strong> {responseData && responseData.subtitle ? responseData.subtitle : "-"}
          <br />
          <br />
          <strong>Originální název:</strong> {responseData && responseData.original_title ? responseData.original_title : "-"}
          <br />
          <p>
            <strong>Slogan:</strong> {responseData && responseData.summary ? responseData.summary : "-"}
          </p>
          <p>
            <strong>Anotace:</strong> {responseData && responseData.description ? responseData.description : "-"}
          </p>
        </Grid>
        <Grid item xs={3}>
          <Box mt={0}>
            <Can I={UPDATE} a={subject(BUNDLE, responseData)}>
              {() => <Button href={`/product-bundle/edit/${id}`} variant="outlined" color="primary">
                Upravit svazek
              </Button>}
            </Can>
            <br />

            <br />
            {/* <Typography variant="h6">Cover assets</Typography> */}
            <Typography variant="caption">Assets</Typography>
            <br />
            {responseData && responseData.assets && <Assets data={responseData.assets} />}
            <br />
          </Box>
        </Grid>
      </Grid>
      <br />
      {responseData && (
        <MaterialTable
          localization={MTLocalization}
          options={{ pageSize: 10, addRowPosition: "first" }}
          style={{ margin: 0, boxShadow: "none" }}
          className="MuiTableContainer"
          title=""
          columns={state.columns}
          data={state.data}
        />
      )}

      {/*       <br />
      <Alert severity="info">
        <AlertTitle>Dev notes:</AlertTitle>
        - Missing Provision requests section?
        <br />- look up tables so we get real names of Genres, Categories, etc?
      </Alert> */}
    </Paper>
  );
};
