import React from 'react';
import { useParams } from 'react-router-dom';
import { Button, makeStyles, Paper, Typography } from '@material-ui/core';
import axios, { getConsumerHeader } from '../utils/axios';
import { TYPE_SPECIFIC_ATTR } from '../entities/specificAttributes';
import ServerResponseAlert from '../components/ServerResponseAlert';
import Loader from '../layout/Loader';
import ConsumerAttributesComparisonTable from '../components/ConsumerAttributesComparisonTable';
import ConfirmationDialog from '../components/ConfirmationDialog';

const useStyles = makeStyles({
  container: {
    position: 'relative',
  },
  headerContainer: {
    marginBottom: '30px',
  },
  title: {
    display: 'inline-block',
  },
  buttonSection: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  controlButton: {
    margin: '8px 0px 8px 16px !important',
  },
  backButton: {
    position: 'absolute',
    top: '20px',
    right: '20px',
    margin: '0 !important',
  },
});

const ConsumerAttributesManagement = () => {
  const classes = useStyles();
  const { id } = useParams();

  const [baseConsumer, setBaseConsumer] = React.useState(null);
  const [baseConsumerAttributes, setBaseConsumerAttributes] = React.useState([]);
  const [editedConsumer, setEditedConsumer] = React.useState(null);
  const [editedConsumerOriginalAttributes, setEditedConsumerOriginalAttributes] = React.useState([]);
  const [editedConsumerAttributes, setEditedConsumerAttributes] = React.useState([]);
  const [attributeRows, setAttributeRows] = React.useState([]);

  const [serverSuccess, setServerSuccess] = React.useState(null);
  const [serverResponse, setServerResponse] = React.useState(null);
  const [loading, setLoading] = React.useState(false);

  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = React.useState(false);
  const [confirmDialogCallback, setConfirmDialogCallback] = React.useState(null);

  const handleError = (e) => {
    setServerSuccess(false);
    setServerResponse(e);
  };

  // ===== CONSUMER SELECTION AND ATTRIBUTES MANIPULATION =====

  const loadProductAttributes = (consumerKey, setter) => {
    const doFetch = async () => {
      try {
        const consumerHeader = getConsumerHeader(consumerKey);
        const res = (await axios.get(`product/${id}`, { headers: consumerHeader })).data.data;

        const attributes = Object.keys(res)
          .filter((item) => item.startsWith('spec_') && !TYPE_SPECIFIC_ATTR.some((attr) => `spec_${attr}` === item))
          .sort()
          .map(attrKey => ({ key: attrKey.slice('spec_'.length), value: res[attrKey] }));

        setter(attributes);
        setServerSuccess(true);
      } catch (e) {
        handleError(e);
      } finally {
        setLoading(false);
      }
    };
    setLoading(true);
    doFetch();
  };

  const handleBaseConsumerSelect = (consumer) => {
    setBaseConsumer(consumer);
    if (consumer) {
      loadProductAttributes(consumer.key, setBaseConsumerAttributes);
    } else {
      setBaseConsumerAttributes([]);
    }
  };

  const handleEditedConsumerSelect = (consumer) => {
    const setAttributes = (value) => {
      setEditedConsumerAttributes(value);
      setEditedConsumerOriginalAttributes(value);
    };

    confirmIfNeeded(() => {
      setEditedConsumer(consumer);
      if (consumer) {
        loadProductAttributes(consumer.key, setAttributes);
      } else {
        setAttributes([]);
      }
    });  
  };

  const handleConsumerSwap = () => {
    confirmIfNeeded(() => {
      const currentBase = baseConsumer;
      setBaseConsumer(editedConsumer);
      setEditedConsumer(currentBase);

      setEditedConsumerAttributes(baseConsumerAttributes);
      setEditedConsumerOriginalAttributes(baseConsumerAttributes);
      setBaseConsumerAttributes(editedConsumerOriginalAttributes);
    });
  };

  const handleAttributeAdd = (key, value) => {
    const attributeIndex = editedConsumerAttributes.findIndex(item => item.key >= key);
    if (editedConsumerAttributes[attributeIndex].key === key) {
      setEditedConsumerAttributes(prevValue => [
        ...prevValue.slice(0, attributeIndex),
        { key, value: value || null },
        ...prevValue.slice(attributeIndex + 1),
      ]);
    } else {
      setEditedConsumerAttributes(prevValue => [
        ...prevValue.slice(0, attributeIndex),
        { key, value: value || null },
        ...prevValue.slice(attributeIndex),
      ]);
    }
  };

  const cleanUpAttributes = (attributes) => attributes.filter(item => item.value);
  
  const createAttributeRows = (base, edited) => {
    let idxBase = 0;
    let idxEdited = 0;
    let result = [];

    while (idxBase < base.length || idxEdited < edited.length) {
      if (idxEdited === edited.length || (idxBase < base.length && base[idxBase].key < edited[idxEdited].key)) {
        result.push({
          key: base[idxBase].key,
          valueBase: base[idxBase].value,
          valueEdited: null,
        });
        idxBase++;
      } else if (idxBase === base.length || base[idxBase].key > edited[idxEdited].key) {
        result.push({
          key: edited[idxEdited].key,
          valueBase: null,
          valueEdited: edited[idxEdited].value,
        });
        idxEdited++;
      } else {
        result.push({
          key: base[idxBase].key,
          valueBase: base[idxBase].value,
          valueEdited: edited[idxEdited].value,
        });
        idxBase++;
        idxEdited++;
      }
    }

    return result;
  };

  // ===== CONTROL BUTTONS HANDLERS =====
  
  const handleGoBack = () => {
    confirmIfNeeded(() => window.location.href = `/product/${id}`);
  };

  const handleDiscardChanges = () => {
    confirmIfNeeded(() => setEditedConsumerAttributes(editedConsumerOriginalAttributes));
  };

  const handleSubmitChanges = async () => {
    setServerResponse(null);
    setLoading(true);
    try {
      const body = Object.fromEntries(editedConsumerAttributes.map(attr => [
        `spec_${attr.key}`,
        attr.value,
      ]));
      const headers = getConsumerHeader(editedConsumer.key);

      await axios.patch(`/product/${id}`, body, { headers });

      setServerSuccess(true);
      setServerResponse('Změny byly uloženy');

      const cleaned = cleanUpAttributes(editedConsumerAttributes);
      setEditedConsumerAttributes(cleaned);
      setEditedConsumerOriginalAttributes(cleaned);
    } catch (e) {
      handleError(e);
    } finally {
      setLoading(false);
    }
  };

  // ===== CONFIRM DIALOG =====

  const handleConfirmDialogClose = () => {
    setConfirmDialogCallback(null);
    setIsConfirmDialogOpen(false);
  };

  const handleConfirmDialogConfirm = () => {
    confirmDialogCallback();
    handleConfirmDialogClose();
  };

  const confirmIfNeeded = (callback) => {
    const areAttributesEqual = (a, b) => a.key === b.key && a.value === b.value;

    if (
      editedConsumerAttributes.length !== editedConsumerOriginalAttributes.length
      || editedConsumerAttributes.some((item, idx) => !areAttributesEqual(item, editedConsumerOriginalAttributes[idx]))
    ) {
      setConfirmDialogCallback(() => callback);
      setIsConfirmDialogOpen(true);
    } else {
      callback();
    }
  };

  // ===== EFFECTS =====

  React.useEffect(() => {
    const rows = createAttributeRows(baseConsumerAttributes, editedConsumerAttributes);
    setAttributeRows(rows);
  }, [baseConsumerAttributes, editedConsumerAttributes]);

  return (
    <Paper className={`basePaper ${classes.container}`}>
      <ConfirmationDialog
        open={isConfirmDialogOpen && !!confirmDialogCallback}
        title="Zahodit změny"
        text="Provedené změny nebudou trvale uloženy. Tuto akci nelze vrátit. Přejete si přesto pokračovat?"
        onClose={handleConfirmDialogClose}
        onConfirm={handleConfirmDialogConfirm}
      />

      <div className={classes.headerContainer}>
        <Typography className={classes.title} variant="h5">
          Správa consumer atributů produktu
        </Typography>
        {loading && (
          <Loader style={{
            width: '20px',
            height: '20px',
            display: 'inline-block',
            margin: '0',
            marginLeft: '20px'
          }} />
        )}
      </div>

      <Button
        className={classes.backButton}
        size='large'
        variant='outlined'
        color='primary'
        onClick={handleGoBack}
      >
        Zpět na detail
      </Button>

      {serverResponse && (
        <ServerResponseAlert
          serverSuccess={serverSuccess}
          serverReply={serverResponse}
          onClose={() => setServerResponse(null)}
        />
      )}

      <ConsumerAttributesComparisonTable
        baseConsumer={baseConsumer}
        editedConsumer={editedConsumer}
        attributeRows={attributeRows}
        onBaseConsumerSelect={handleBaseConsumerSelect}
        onEditedSoncumerSelect={handleEditedConsumerSelect}
        onConsumerSwap={handleConsumerSwap}
        onAttributeUpdate={handleAttributeAdd}
        onError={handleError}
      />

      <div className={classes.buttonSection}>
        <Button
          className={classes.controlButton}
          size='large'
          variant='outlined'
          color='primary'
          onClick={handleDiscardChanges}
        >
          Zahodit změny
        </Button>

        <Button
          className={classes.controlButton}
          size='large'
          variant='contained'
          color='primary'
          onClick={handleSubmitChanges}
          disabled={loading ? true : false}
        >
          Uložit
        </Button>
      </div>
    </Paper>
  );
};

export default ConsumerAttributesManagement;
