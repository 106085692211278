import React, { useState } from 'react';
import { Button, InputLabel, CircularProgress } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import axios from '../utils/axios';
import ImportModuleResult from './ImportModuleResult';

const ImportExecution = () => {
  const [serverSuccess, setServerSuccess] = useState(null);
  const [importResult, setImportResult] = useState(null);
  const [serverReply, setServerReply] = useState(null);
  const [loading, setLoading] = useState(false);
  const { handleSubmit, register, control } = useForm({});

  const handleFileUpload = (data) => {
    console.log(data);
    const submitData = async () => {
      try {
        const formData = new FormData();
        formData.append('file', data.file[0]);
        const response = await axios.post(`/import`, formData, { headers: { 'Content-Type': 'multipart/form-data' } });
        console.log(response);

        const responseData = response.data.data;
        setServerSuccess(true);
        setImportResult(responseData);
      } catch (e) {
        setServerSuccess(false);
        setServerReply(e.response?.data?.message);
      } finally {
        setLoading(false);
      }
    };
    setLoading(true);
    submitData();
  };

  return (
    <>
      <form onSubmit={handleSubmit(handleFileUpload)}>
        <InputLabel id="file">Soubor</InputLabel>
        <input ref={register({})} id="file" name="file" type="file" accept="application/zip,application/x-zip-compressed" />
        <Button type="submit" size="large" variant="contained" color="primary" disabled={loading}>
          Importovat
        </Button>
      </form>
      {loading && (
        <CircularProgress color="primary" />
      )}
      {!loading && (
        <ImportModuleResult
          serverSuccess={serverSuccess}
          showSuccessfull={importResult && importResult.ok_total > 0}
          showErrors={importResult && importResult.error_total > 0}
          validationResult={importResult}
          serverErrorMessage={serverReply}
          alertMessage={'Úspěšně zahájeno importování {total} položek. Import bude probíhat na pozadí.'}
        />
      )}
    </>
  );
};

export default ImportExecution;
