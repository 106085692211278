import { Ability, AbilityBuilder } from '@casl/ability';
import { ADMIN, EDITOR, PROVIDER, CONSUMER } from './roles';
import { MANAGE, READ, CHANGE_PUBLISHING_STATE, ACCESS, UPDATE, CHANGE_VIEW_FOR, CONTROL } from './activities';
import {
  ALL,
  PRODUCT,
  PRICE,
  BUNDLE,
  AUTHOR,
  ASSET,
  PROJECT,
  PUBLISHER,
  SERIE,
  GENRE,
  EDITION,
  CATEGORY,
  MULTITREE,
  USER,
  PRODUCT_TYPE,
  DISTRIBUTION_MANAGEMENT,
  REPORTING,
  IMPORT_TOOL,
  DEDUPLICATION_TOOL,
  BUNDLE_TOOL,
  TOOLS,
  SETTINGS,
  CONSUMER_ENTITY,
  ASSOCIATED_CONSUMER,
  TAG,
} from './entities';

export const ability = new Ability([]);

export const updateAbility = (auth) => {
  ability.update(defineRulesFor(auth));
};

export const unsetAbility = () => {
  ability.update([]);
};

const defineRulesFor = (auth) => {
  const { can, cannot, rules } = new AbilityBuilder();
  if (auth.role === ADMIN) {
    can(MANAGE, ALL);
  } else if (auth.role === EDITOR) {
    can(MANAGE, PRODUCT);
    can(MANAGE, BUNDLE);
    can(MANAGE, AUTHOR);
    can(MANAGE, PRICE);
    can(MANAGE, ASSET);
    can(MANAGE, PROJECT);
    can(MANAGE, PUBLISHER);
    can(MANAGE, SERIE);
    can(MANAGE, GENRE);
    can(MANAGE, EDITION);
    can(MANAGE, CATEGORY);
    can(MANAGE, MULTITREE);
    can(MANAGE, TAG);
    can(READ, USER);
    can(MANAGE, USER, { role: CONSUMER });
    can(MANAGE, USER, { role: PROVIDER });
    can(READ, PRODUCT_TYPE);
    can(ACCESS, DISTRIBUTION_MANAGEMENT);
    can(ACCESS, REPORTING);
    can(ACCESS, TOOLS);
    can(ACCESS, IMPORT_TOOL);
    can(ACCESS, BUNDLE_TOOL);
    can(ACCESS, DEDUPLICATION_TOOL);
    can(ACCESS, SETTINGS);
    can(MANAGE, CONSUMER_ENTITY);
    can(CHANGE_VIEW_FOR, ASSOCIATED_CONSUMER);
    cannot(UPDATE, USER, 'password');
    cannot(CONTROL, PRODUCT, 'consumer-attribute');
    cannot(UPDATE, PRODUCT, 'consumer-attribute');
  } else if (auth.role === PROVIDER) {
    can(MANAGE, PRODUCT);
    can(MANAGE, BUNDLE);
    can(MANAGE, AUTHOR);
    can(MANAGE, PRICE);
    can(MANAGE, ASSET);
    can(READ, PRODUCT_TYPE);
    can(ACCESS, TOOLS);
    can(ACCESS, IMPORT_TOOL);
    can(CHANGE_VIEW_FOR, ASSOCIATED_CONSUMER);
    cannot(CHANGE_PUBLISHING_STATE, PRODUCT);
    cannot(CONTROL, PRODUCT, 'consumer-attribute');
    cannot(UPDATE, PRODUCT, 'consumer-attribute');
  } else if (auth.role === CONSUMER) {
    can(READ, PRODUCT);
    can(READ, BUNDLE);
    can(READ, AUTHOR);
    can(READ, PRICE);
    can(READ, ASSET);
    can(READ, PRODUCT_TYPE);
    if (auth.associated_consumer) {
      can(UPDATE, PRODUCT, 'consumer-attribute');
    }
  }
  return rules;
};
