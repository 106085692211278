import { makeStyles } from "@material-ui/core";

export const useAdvancedSearchFilterStyles = makeStyles({
  searchInput: {
    width: '190px',
    marginRight: '20px',
  },
  formLine: {
    marginBottom: '10px',
  },
});
