import React from 'react';
import { Tabs, Tab, Paper, Typography } from '@material-ui/core';

import TabPanel from '../layout/TabPanel';
import { READ, AbilityContext, USER } from '../permissions';
import { useAbility } from '@casl/react';
import ProductReporting from '../components/ProductReporting';
import ProvisionReporting from '../components/ProvisionReporting';

export default (props) => {
  const ability = useAbility(AbilityContext);
  const [tabValue, setTabValue] = React.useState(ability.can(READ, USER) ? 0 : 1);
  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <Paper className="basePaper">
      <Typography variant="h5">Reporting</Typography>
      <br />
      <Tabs value={tabValue} onChange={handleChange} indicatorColor="primary" textColor="primary">
        <Tab label="Produkty" />
        <Tab label="Distribuce" />
      </Tabs>
      <TabPanel value={tabValue} index={0} className="productAttributes">
        <ProductReporting />
      </TabPanel>
      <TabPanel value={tabValue} index={1} className="productAttributes">
        <ProvisionReporting />
      </TabPanel>
    </Paper>
  );
};
