import React, { useState } from 'react';
import { makeStyles, Button, Accordion, AccordionSummary, Typography, AccordionDetails } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SearchIcon from '@material-ui/icons/Search';

const useStyles = makeStyles({
  filter: {
    margin: '16px 0',
    border: '1px #E6E6E6 solid',
    boxShadow: 'none',
  },
  filterIcon: {
    verticalAlign: 'bottom',
    marginRight: '10px',
  },
  form: {
    margin: '30px 0',
  },
  formButtons: {
    display: 'inline-block',
    marginTop: '10px',
  },
  secondaryHeading: {
    marginLeft: '30px',
    color: 'rgba(0, 0, 0, 0.26)',
    fontStyle: 'italic',
  }
});

const AdvancedSearch = ({
  onFilterSubmit,
  onFilterReset,
  filterValue,
  initialFilter,
  filterLabels,
  filterFormComponent,
}) => {
  const classes = useStyles();
  const [filter, setFilter] = useState(initialFilter);
  const [expanded, setExpanded] = useState(false);

  const resetFilter = () => {
    setFilter(initialFilter);
  };

  const handleFilterRemove = () => {
    resetFilter();
    onFilterReset();
  };

  const handleFilterRequest = (event) => {
    event.preventDefault();
    const result = Object.keys(filter)
      .filter(key => filter[key] !== initialFilter[key])
      .reduce((acc, key) => ({
        ...acc,
        [key]: filter[key],
      }), {});
    onFilterSubmit(result);
  };

  const getFilterPreview = () => {
    const activeFilters = Object.keys(filter)
      .filter(key => filter[key] !== initialFilter[key])
      .map(key => filterLabels[key].toLowerCase());

    if (activeFilters.length > 3) {
      return `${activeFilters.slice(0, 3).join(', ')}...`;
    }
    return activeFilters.join(', ');
  };

  React.useEffect(() => {
    setFilter({
      ...initialFilter,
      ...(filterValue || {}),
    });
  }, [JSON.stringify(filterValue)]);

  const FilterForm = filterFormComponent

  return (
    <Accordion className={classes.filter} expanded={expanded} onChange={(_, isExpanded) => setExpanded(isExpanded)}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography className={classes.heading}><SearchIcon className={classes.filterIcon} />Filtrovat</Typography>
        {!expanded && (
          <Typography className={classes.secondaryHeading}>{getFilterPreview()}</Typography>
        )}
      </AccordionSummary>
      <AccordionDetails>
        <form className={classes.form} onSubmit={handleFilterRequest}>
          <FilterForm filter={filter} onFilterChange={setFilter} />
          <span className={classes.formButtons}>
            <Button type="submit" variant="outlined" color="primary">
              Hledej
            </Button>
            <Button type="button" variant="outlined" color="primary" onClick={handleFilterRemove}>
              Odebrat filtr
            </Button>
          </span>
        </form>
      </AccordionDetails>
    </Accordion>
  );
};

export default AdvancedSearch;
