import { unstable_createMuiStrictModeTheme as createMuiTheme, MuiThemeProvider } from '@material-ui/core';
import { blue } from '@material-ui/core/colors';
import React, { useEffect, useState } from 'react';
import './App.css';

import {
  updateAbility,
  unsetAbility,
  AbilityContext,
  ability,
  UserContext,
  userContextInitialState,
} from './permissions';
import Routing from './Routing';
import axios, { setDefaultConsumerHeader } from './utils/axios';

const theme = createMuiTheme({
  palette: {
    // primary: red, // #B9012B
    primary: { main: '#B9012B' },
    secondary: blue,
  },
  typography: {
    h5: {
      lineHeight: 1.1,
    },
    h6: {
      lineHeight: 1.1,
    },
  },
  overrides: {
    root: {
      flexGrow: 1,
    },
    MuiTableCell: {
      root: {
        // margin: theme.spacing(1),
        // unfortunately doesnt work
        padding: '8px 8px 12px 4px',
      },
    },
    MuiButton: {
      root: {
        // margin: theme.spacing(1),
        margin: '8px',
      },
    },
  },
});

export default () => {
  const [isSetupComplete, setSetupComplete] = useState(false);
  const [userInfo, setUserInfo] = useState(userContextInitialState);

  //const validUser = true;
  const validUser = localStorage.getItem('token') ? localStorage.getItem('token') : false;

  useEffect(() => {
    console.log('running effect');
    if (validUser) {
      axios.get('/user/details')
        .then(response => {
          const userData = response.data.data;
          updateAbility(userData);
          setUserInfo(userData);
          if (userData.associated_consumer) {
            setDefaultConsumerHeader(userData.associated_consumer);
          }
          setSetupComplete(true);
        })
        .catch(err => unsetAbility());
    } else {
      unsetAbility();
      setSetupComplete(true);
    }
  }, []);

  return (
    <div className="App">
      <MuiThemeProvider theme={theme}>
        <UserContext.Provider value={userInfo}>
          <AbilityContext.Provider value={ability}>
            {isSetupComplete && <Routing validUser={validUser} />}
          </AbilityContext.Provider>
        </UserContext.Provider>
      </MuiThemeProvider>
    </div>
  );
};
