import { Container } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import React from "react";
import Header from "./Header";
import Nav from "./Nav";

const useStyles = makeStyles((theme) => ({
  Box: {
    padding: theme.spacing(2),
    textAlign: "left",
    color: theme.palette.text.secondary,
    marginTop: "20px",
  },
}));

const Layout = (props) => {
  props.history.listen((location, action) => {
    // location is an object like window.location
    // console.log(action, location.pathname, location.state);
    localStorage.setItem("lastUrl", location.pathname !== "undefined" ? location.pathname : "/");
  });
  const classes = useStyles();
  return (
    <>
      {props.loggedIn === true && (
        <Container maxWidth="lg" spacing={3} style={{ padding: 0 }}>
          <Header />
          <Grid container>
            <Grid item xs={12} md={2} className="desktopNav">
              <Box style={{ marginTop: "2rem" }}>
                <Nav />
              </Box>
            </Grid>
            <Grid item xs={12} md={10}>
              <Box className={classes.Box}>
                {/*             <Breadcrumbs aria-label="breadcrumb">
              <NavLink to="/getting-started/installation/">Core</NavLink>
              <Typography color="textPrimary">Breadcrumb</Typography>
            </Breadcrumbs> */}
                {props.children}
              </Box>
            </Grid>
          </Grid>
        </Container>
      )}
      {props.loggedIn === false && (
        <Container maxWidth="sm" spacing={3}>
          <Grid container>
            <Grid item xs={8} sm={9} md={10}>
              <Box className={classes.Box}>{props.children}</Box>
            </Grid>
          </Grid>
        </Container>
      )}
    </>
  );
};
export default withRouter(Layout);
