export const ALL = 'all';
export const PRODUCT = 'Product';
export const PRICE = 'Price';
export const ASSET = 'Asset';
export const BUNDLE = 'Bundle';
export const AUTHOR = 'Author';
export const PROJECT = 'Project';
export const PRODUCT_TYPE = 'ProductType';
export const PUBLISHER = 'Publisher';
export const CATEGORY = 'Category';
export const SERIE = 'Serie';
export const EDITION = 'Edition';
export const GENRE = 'Genre';
export const MULTITREE = 'Multitree';
export const TAG = 'Tag';
export const USER = 'User';
export const DISTRIBUTION_MANAGEMENT = 'DistributionManagement';
export const REPORTING = 'Reporting';
export const ASSOCIATED_CONSUMER = 'AssociatedConsumer';

export const TOOLS = 'Tools';
export const IMPORT_TOOL = 'ImportTool';
export const BUNDLE_TOOL = 'BundleTool';
export const DEDUPLICATION_TOOL = 'DeduplicationTool';
export const SETTINGS = 'Settings';
export const CONSUMER_ENTITY = 'ConsumerEntity';
