const bindingTypes = [
  { code: "V8c", name: "laminovaný potah + desky s molitanem" },
  { code: "V8b př", name: "vázaná s pap. potahem s lam. přebalem" },
  { code: "V8b", name: "vázaná s papírovým potahem bez laminace" },
  { code: "V8a př", name: "vázaná s laminovaným potahem a přebalem" },
  { code: "V8a", name: "vázaná s laminovaným potahem" },
  { code: "V8", name: "vázaná s potahem z jiného materiálu" },
  { code: "V7", name: "kombinovaný potah s plátěným hřbetem" },
  { code: "V4 chl", name: "brožovaná šitá s chlopněmi" },
  { code: "V4", name: "brožovaná, šitá nitmi" },
  { code: "V2 chl", name: "brožovaná lepená s chlopněmi" },
  { code: "V2", name: "brožovaná lepená" },
  { code: "V1", name: "brožovaná, šitá 2 skobkami (sešitová)" },
  { code: "Spirála", name: "publikace vázané spirálou" },
  { code: "Lepo R2", name: "leporelo rozkládací se hřbetem" },
  { code: "Lepo R1", name: "leporelo rozkládací" },
  { code: "Lepo K", name: "leporelo knížkové" },
  { code: "Japonská", name: "Japonská s odhaleným hřbetem" },
  { code: "Flexo", name: "měkké desky z kartonu" },
  { code: "V9", name: "brožovaná" },
  { code: "V10", name: "merkantil" },
  { code: "Ostatní", name: "ostatní" },
  { code: "V11", name: "brožovaná kůže" },
  { code: "V12", name: "vázaná kůže" },
];
export default bindingTypes;
