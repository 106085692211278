import React, { useState, useEffect } from 'react';
import MaterialTable from 'material-table';
import { Alert, AlertTitle } from '@material-ui/lab';
import { TablePagination, Box, Dialog, Typography, makeStyles, Button } from '@material-ui/core';
import SecurityOutlinedIcon from '@material-ui/icons/SecurityOutlined';
import { useAbility } from '@casl/react';

import axios from '../utils/axios';
import MTLocalization from '../utils/MTLocalization';
import Loader from '../layout/Loader';

import { CREATE, AbilityContext, DELETE, CONSUMER_ENTITY, UPDATE } from '../permissions';
import ServerResponseAlert from './ServerResponseAlert';
import { ConsumerAccessRightsDialog } from './ConsumerAccessRightsDialog';

const useStyles = makeStyles({
  dialogHeading: {
    fontWeight: '700',
    marginBottom: '10px',
  },
  dialogSubtitle: {
    marginBottom: '10px',
  },
  dialogInfoLine: {
    margin: '5px 0',
  },
  dialogStrong: {
    fontWeight: '700',
  },
  dialogApiSecret: {
    whiteSpace: 'pre-wrap',
    wordWrap: 'break-word',
  },
});

const ConsumerTable = () => {
  const classes = useStyles();
  const ability = useAbility(AbilityContext);
  // user data
  const [consumerData, setConsumerData] = useState(null);
  // pagination
  const [currentPage, setCurrentPage] = useState(0);
  const [currentPageSize, setCurrentPageSize] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  // loading
  const [loading, setLoading] = useState(false);
  // data modification response - create, delete
  const [dataModificationSuccess, setDataModificationSuccess] = useState(null);
  const [dataModificationReply, setDataModificationReply] = useState(null);
  const [creationResponse, setCreationResponse] = useState(null);
  const [credentialsDialogOpen, setCredentialsDialogOpen] = useState(false);
  // data loading error
  const [dataLoadingError, setDataLoadingError] = useState(null);
  // access rights dialog
  const [selectedACLConsumerKey, setSelectedACLConsumerKey] = useState(null);

  const loadConsumers = (page = currentPage, pageSize = currentPageSize) => {
    const doFetch = async () => {
      try {
        const query = {
          page: page + 1,
          limit: pageSize,
        };
        const result = await axios.get('/consumer', { params: query });

        setConsumerData(result.data);
        setTotalCount(result.data.meta.total_number);
      } catch (e) {
        setDataLoadingError(e);
      } finally {
        setLoading(false);
      }
    };

    setDataLoadingError(null);
    setLoading(true);
    doFetch();
  };

  const handlePageChange = (newPage, newPageSize) => {
    setCurrentPage(newPage);
    setCurrentPageSize(newPageSize);
    loadConsumers(newPage, newPageSize);
  };

  const handleCredentialsDialogClose = () => {
    setCreationResponse(null);
    setCredentialsDialogOpen(false);
  };

  // run on load only
  useEffect(() => {
    loadConsumers();
  }, []);

  // === DATA MODIFICATION ===

  const validateConsumer = (newData) => {
    if (!newData.key || newData.key.length > 255 || !newData.key.match(/^[a-z0-9\-]*$/g)) {
      setDataModificationSuccess(false);
      setDataModificationReply('Nevalidní klíč');
      return false;
    }
    if (!newData.name || newData.name.length > 45) {
      setDataModificationSuccess(false);
      setDataModificationReply('Nevalidní jméno');
      return false;
    }
    return true;
  };

  const createConsumer = async (newData) => {
    setLoading(true);
    setDataModificationReply(null);

    const body = {
      key: newData.key,
      name: newData.name,
    };

    try {
      const response = await axios.post('/consumer', JSON.stringify(body));

      setDataModificationSuccess(true);
      setDataModificationReply('V pořádku uloženo');

      setCurrentPage(0);
      loadConsumers(0);

      setCreationResponse(response.data.data);
      setCredentialsDialogOpen(true);
    } catch (e) {
      setDataModificationSuccess(false);
      setDataModificationReply(e);
    } finally {
      setLoading(false);
    }
  };

  const deleteConsumer = async (data) => {
    setLoading(true);
    setDataModificationReply(null);

    try {
      await axios.delete(`/consumer/${data.key}`);
      setDataModificationSuccess(true);
      setDataModificationReply('V pořádku smazáno');
      loadConsumers();
    } catch (e) {
      setDataModificationSuccess(false);
      setDataModificationReply('Chyba při mazání');
    } finally {
      setLoading(false);
    }
  };

  // === RENDER HELPERS ===

  const getColumns = () => {
    return [
      { title: 'Název', field: 'name', render: (rowData) => <strong>{rowData.name}</strong>, editable: 'onAdd' },
      { title: 'Klíč', field: 'key', editable: 'onAdd' },
    ];
  };

  // === RENDER ===

  return (
    <>
      <Box>
        {loading && <Loader />}
        {dataModificationReply && (
          <ServerResponseAlert serverSuccess={dataModificationSuccess} serverReply={dataModificationReply} onClose={() => setDataModificationReply(null)} />
        )}
        {dataLoadingError && (
          <Alert severity="error" style={{ marginTop: 20 }}>
            <AlertTitle>Chyba při načítání dat.</AlertTitle>
            {dataLoadingError.message}
          </Alert>
        )}
      </Box>
      {creationResponse && (
        <Dialog open={credentialsDialogOpen} onClose={handleCredentialsDialogClose}>
          <Box m={3}>
            <Typography className={classes.dialogHeading} variant="h6">Consumer vytvořen</Typography>
            <Typography className={classes.dialogSubtitle} variant="subtitle1">Přístupové údaje do API jsou následující:</Typography>
            <p className={classes.dialogInfoLine}>
              <span className={classes.dialogStrong}>API key:</span>
              <pre className={classes.dialogApiSecret}>
                {creationResponse.api_key}
              </pre>
            </p>
            <p className={classes.dialogInfoLine}>
              <span className={classes.dialogStrong}>API secret:</span>
              <pre className={classes.dialogApiSecret}>
                {creationResponse.api_secret}
              </pre>
            </p>
          </Box>
        </Dialog>
      )}
      {selectedACLConsumerKey && (
        <ConsumerAccessRightsDialog
          open={!!selectedACLConsumerKey}
          onClose={() => setSelectedACLConsumerKey(null)}
          consumerKey={selectedACLConsumerKey}
        />
      )}
      {consumerData && (
        <MaterialTable
          key={currentPageSize} // workaround for not functioning page size change
          localization={MTLocalization}
          options={{ pageSize: currentPageSize, actionsColumnIndex: -1, addRowPosition: 'first', search: false, showTitle: false }}
          style={{ margin: 0, boxShadow: 'none' }}
          columns={getColumns()}
          components={{
            Pagination: (props) => (
              <TablePagination
                {...props}
                rowsPerPageOptions={[5, 10, 20, 30]}
                rowsPerPage={currentPageSize}
                count={totalCount}
                page={currentPage}
                onChangePage={(e, page) => handlePageChange(page, currentPageSize)}
                onChangeRowsPerPage={(e) => handlePageChange(0, e.target.value)}
              />
            ),
          }}
          data={consumerData.data}
          editable={{
            ...(ability.can(CREATE, CONSUMER_ENTITY) && {
              onRowAdd: (newData) =>
                new Promise((resolve, reject) => {
                  const validationResult = validateConsumer(newData);
                  if (!validationResult) {
                    reject(dataModificationReply);
                  } else {
                    const saveResponse = createConsumer(newData);
                    if (saveResponse) {
                      resolve(saveResponse);
                    } else {
                      reject(dataModificationReply);
                    }
                  }
                }),
            }),
            ...(ability.can(DELETE, CONSUMER_ENTITY) && {
              onRowDelete: (oldData) =>
                new Promise((resolve, reject) => {
                  const deleteResponse = deleteConsumer(oldData);
                  if (deleteResponse) {
                    resolve(deleteResponse);
                  } else {
                    reject(dataModificationReply);
                  }
                }),
            }),
          }}
          actions={[
            ...(ability.can(UPDATE, CONSUMER_ENTITY) ? [{
              icon: () => <SecurityOutlinedIcon />,
              tooltip: 'Správa přístupových práv',
              onClick: (_, rowData) => setSelectedACLConsumerKey(rowData.key),
            }] : []),
          ]}
        />
      )}
    </>
  );
};

export default ConsumerTable;
