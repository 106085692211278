import React from 'react';
import {
  Button,
  Chip,
  FormControl,
  IconButton,
  Input,
  makeStyles,
  MenuItem,
  Modal,
  Select,
  TextField,
  Typography
} from '@material-ui/core';
import MaterialTable from 'material-table';
import DeleteIcon from '@material-ui/icons/Delete';
import { Alert, AlertTitle } from '@material-ui/lab';

import useStyles from '../styles/forms';
import MTLocalization from '../utils/MTLocalization';
import axios from '../utils/axios';
import Loader from '../layout/Loader';
import { DropdownSelector } from './DropdownSelector';
import ServerResponseAlert from './ServerResponseAlert';
import MultivalueInput from './MultivalueInput';
import TreeBranchMultiselect from './TreeBranchMultiselect';

// ===== CONSTANTS DEFINITION =====

const SOURCE_PRODUCT = 'product';
const SOURCE_BUNDLE = 'product-bundle';
const SOURCE_TREE = 'tree';
const SOURCE_PROVISION_EBOOK_SAMPLE = 'provision-ebook-sample';
const SOURCE_TREE_DEFINITION = 'tree-definition';
const ACCESS_READ = 'read';
const ACCESS_WRITE = 'write';

const SOURCES = {
  [SOURCE_PRODUCT]: 'Produkt',
  [SOURCE_BUNDLE]: 'Produktový svazek',
  [SOURCE_TREE]: 'Strom kategorií',
  [SOURCE_PROVISION_EBOOK_SAMPLE]: 'Ukázka ebooku (Provision API)',
  [SOURCE_TREE_DEFINITION]: 'Přístup k trees',
};

const ACCESS_TYPES = {
  [ACCESS_READ]: 'Čtení',
  [ACCESS_WRITE]: 'Zápis',
};

const EQUAL = 'eq';
const LIKE = 'like';
const NOT = 'not';

const OPERATORS = {
  [EQUAL]: { displayValue: 'je', joinWord: 'nebo' },
  [LIKE]: { displayValue: 'obsahuje', joinWord: 'nebo' },
  [NOT]: { displayValue: 'není', joinWord: 'ani' },
};

const RULE_KEYS = {
  [SOURCE_PRODUCT]: {
    type: { label: 'Typ produktu', operators: [EQUAL, NOT], baseOperator: EQUAL, field: 'multiselect', options: {
      audiobook: 'Audiobook',
      ebook: 'Ebook',
      physicalgood: 'Fyzické zboží',
      physicalgoodsnonbooks: 'Fyzické zboží neknižní',
    } },
    publisher: { label: 'Název vydavatele', operators: [LIKE, NOT], baseOperator: LIKE, field: 'multitext' },
    brand: { label: 'Název značky', operators: [LIKE, NOT], baseOperator: LIKE, field: 'multitext' },
    available: { label: 'Pouze publikované', value: ['1'] },
  },
  [SOURCE_BUNDLE]: {},
  [SOURCE_TREE]: {},
  [SOURCE_PROVISION_EBOOK_SAMPLE]: {},
  [SOURCE_TREE_DEFINITION]: {},
};

// ===== HELPER FUNCTIONS =====

const isObjectEmpty = (object) => Object.keys(object).length === 0;

const removeKeyFromObject = (object, keyToRemove) => {
  const {[keyToRemove]: _, ...newObject} = object;
  return newObject;
};

const findAccessRight = (existingRights, source, type) => existingRights.find(right => right.source === source && right.type === type);

const filterObject = (object, filteringCallback, labelCallback) => Object.keys(object)
  .filter(item => filteringCallback(item))
  .map(objectKey => ({ key: objectKey, label: labelCallback ? labelCallback(objectKey) : object[objectKey]}));

const getRuleDefinition = (source, ruleKey) => {
  const [ruleKeyBase, operator] = ruleKey.split('.');
  const ruleDefinition = RULE_KEYS[source]?.[ruleKeyBase];
  return {
    ruleDefinition,
    operator: operator || ruleDefinition?.baseOperator || EQUAL,
  };
};

const getPossibleSources = (existingRights) => filterObject(
  SOURCES,
  sourceKey => !findAccessRight(existingRights, sourceKey, 'read') || !findAccessRight(existingRights, sourceKey, 'write'),
);

const getPossibleAccessTypes = (existingRights, source) => filterObject(
  ACCESS_TYPES,
  accessType => !findAccessRight(existingRights, source, accessType),
);

const getPossibleRuleKeys = (existingRules, source) => {
  const rulesConfig = RULE_KEYS[source] || {};
  const allCombinations = Object.keys(rulesConfig).reduce((prevResult, currentBaseKey) => {
    const ruleDef = rulesConfig[currentBaseKey];
    const allAvailableRules = !ruleDef.operators ? [{ key: currentBaseKey, label: ruleDef.label }] : ruleDef.operators.map(op => ({
      key: ruleDef.baseOperator && ruleDef.baseOperator === op ? currentBaseKey : `${currentBaseKey}.${op}`,
      label: `${ruleDef.label} (${OPERATORS[op].displayValue})`,
    }));
    return [...prevResult, ...allAvailableRules];
  }, []);
  return allCombinations.filter(item => !Object.keys(existingRules).includes(item.key));
};

const shouldDisplayOperator = (ruleDefinition) => !ruleDefinition || (ruleDefinition && ruleDefinition.operators);

const shouldDisplayField = (ruleDefinition) => !ruleDefinition || (ruleDefinition && ruleDefinition.field);

const getRuleValue = (possibleValues, options, joinWord = 'nebo') => {
  const displayedValues = options ? possibleValues.map(value => options[value]) : possibleValues;
  return displayedValues.map(item => `"${item}"`).join(` ${joinWord} `);
}

const getObjectFromURLParams = (paramsString) => {
  if (paramsString === '*') return {};

  const params = new URLSearchParams(paramsString);
  const result = {};

  params.forEach((value, key) => {
    if (result[key]) {
      if (Array.isArray(result[key])) {
        result[key].push(...value.split('|'));
      } else {
        result[key] = [result[key], ...value.split('|')];
      }
    } else {
      result[key] = value.split('|');
    }
  });

  return result;
};

const getURLParamsFromObject = (paramsObject) => {
  if (Object.keys(paramsObject).length === 0) return '*';

  const mappedObject = Object.fromEntries(Object.keys(paramsObject).map((key) => [
    key,
    Array.isArray(paramsObject[key]) ? paramsObject[key].join('|') : paramsObject[key],
  ]));

  return new URLSearchParams(mappedObject).toString();
};

const mapAccessRight = (incoming) => ({ ...incoming, rule: getObjectFromURLParams(incoming.rule) });

// ===== STYLES =====

const useStylesLocal = makeStyles({
  modal: {
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    maxHeight: '90vh',
    overflow: 'scroll',
  },
  ruleList: {
    paddingLeft: '0',
    listStyleType: 'none',
  },
  ruleLine: {
    display: 'flex',
    alignItems: 'center',
    gap: '0.75rem',
  },
  ruleLineButton: {
    marginLeft: '1.5rem',
  },
  newRuleButton: {
    marginLeft: '-0.5rem',
    marginBottom: '.5rem',
  },
  select: {
    width: 'calc(100% - 1rem)',
  },
  selectSmall: {
    minWidth: '150px',
  },
  fieldFullSize: {
    flexGrow: '1',
  },
  bottomActions: {
    marginTop: '2rem !important',
  },
  headline: {
    marginTop: '1rem',
    marginBottom: '1rem',
  },
  multiselectChip: {
    margin: '2px',
  },
  multitextInput: {
    paddingTop: '6px',
    paddingBottom: '7px !important',
  },
  ruleName: {
    textWrap: 'nowrap',
  },
});

// ===== COMPONENT =====

export const ConsumerAccessRightsDialog = ({
  open,
  onClose,
  consumerKey,
}) => {
  const [accessRights, setAccessRights] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [dataLoadingError, setDataLoadingError] = React.useState(false);
  const [dataModificationError, setDataModificationError] = React.useState(false);

  const classes = useStylesLocal();
  const formClasses = useStyles();

  const getRuleLabel = (source, ruleKey) => {
    const { ruleDefinition, operator } = getRuleDefinition(source, ruleKey);
    return (
      <>
        <strong className={classes.ruleName}>
          {ruleDefinition?.label || ruleKey}
        </strong>
        {shouldDisplayOperator(ruleDefinition) && (
          <span>
            {OPERATORS[operator]?.displayValue || 'je'}
          </span>
        )}
      </>
    );
  };

  const columns = [
    {
      title: 'Zdroj',
      field: 'source',
      editable: 'onAdd',
      lookup: SOURCES,
      editComponent: ({ rowData, onRowDataChange }) => (
        <FormControl className={classes.select}>
          <Select
            name="source"
            value={rowData.source || ''}
            onChange={(e) => onRowDataChange({
              ...rowData,
              source: e.target.value,
            })}
          >
            {getPossibleSources(accessRights).map(item => (
              <MenuItem key={item.key} value={item.key}>{item.label}</MenuItem>
            ))}
          </Select>
        </FormControl>
      ),
      width: '20%',
    },
    {
      title: 'Typ přístupu',
      field: 'type',
      editable: 'onAdd',
      lookup: ACCESS_TYPES,
      editComponent: ({ rowData, onRowDataChange }) => rowData.source ? (
        <FormControl className={classes.select}>
          <Select
            name="type"
            value={rowData.type || ''}
            onChange={(e) => onRowDataChange({
              ...rowData,
              type: e.target.value,
            })}
          >
            {getPossibleAccessTypes(accessRights, rowData.source).map(item => (
              <MenuItem key={item.key} value={item.key}>{item.label}</MenuItem>
            ))}
          </Select>
        </FormControl>
      ) : (
        <em>
          Nejprve zvolte zdroj
        </em>
      ),
      width: '12%',
    },
    {
      title: 'Pravidla',
      field: 'rule',
      render: (rowData) => {
        if (isObjectEmpty(rowData.rule)) {
          return (
            <em>
              Bez omezení
            </em>
          );
        } else if(rowData.source === SOURCE_TREE_DEFINITION) {
          if(!rowData.rule?.tid)
            return (<em> Bez omezení</em>);

          if(typeof rowData.rule.tid === "string")
            return (<em>{rowData.rule.tid}</em>);

          return (<em>{rowData.rule?.tid.join(",")}</em>);
        }

        return (
          <ul className={classes.ruleList}>
            {Object.keys(rowData.rule).map(ruleKey => {
              const { ruleDefinition, operator } = getRuleDefinition(rowData.source, ruleKey);
              return (
                <li className={classes.ruleLine} key={ruleKey}>
                  {getRuleLabel(rowData.source, ruleKey)}
                  {shouldDisplayField(ruleDefinition) && (
                    <span>
                      {getRuleValue(
                        rowData.rule[ruleKey],
                        ruleDefinition?.options,
                        ruleDefinition ? OPERATORS[operator]?.joinWord : undefined,
                      )}
                    </span>
                  )}
                </li>
              )
            })}
          </ul>
        );
      },
      editComponent: ({ rowData, onRowDataChange }) => {
        if (!rowData.source || !rowData.type) return (
          <em>
            Nejprve zvolte zdroj a typ přístupu
          </em>
        );


        if(rowData.source === SOURCE_TREE_DEFINITION) {
          if(!rowData.rule)
            rowData.rule = { tid: []};

          return (
            <TreeBranchMultiselect
              className={classes.fieldFullSize}
              classes={{
                inputRoot: classes.multitextInput,
              }}
              name={`${rowData.source}-value`}
              values={rowData.rule.tid}
              onChange={(values) => {
                rowData.rule.tid = values;
              }}
            >
            </TreeBranchMultiselect>
          )
        }

        const currentRule = rowData.rule || {};
        const avaibableRuleKeys = getPossibleRuleKeys(currentRule, rowData.source);

        const updateRule = (newValue) => onRowDataChange({
          ...rowData,
          rule: newValue,
        });

        const handleFieldChange = (ruleKey, value) => {
          updateRule({
            ...currentRule,
            [ruleKey]: value,
          })
        };

        const getUpdateField = (ruleKey) => {
          const { ruleDefinition } = getRuleDefinition(rowData.source, ruleKey);
          const fieldType = ruleDefinition?.field;
          if (fieldType === 'text') return (
            <TextField
              value={currentRule[ruleKey][0] || ''}
              name={`${ruleKey}-value`}
              onChange={(e) => handleFieldChange(ruleKey, [e.target.value])}
            />
          )
          if (fieldType === 'multitext') return (
            <MultivalueInput
              className={classes.fieldFullSize}
              classes={{
                inputRoot: classes.multitextInput,
              }}
              name={`${ruleKey}-value`}
              value={currentRule[ruleKey] || []}
              onChange={(values) => handleFieldChange(ruleKey, values)}
            />
          )
          if (fieldType === 'select') return (
            <FormControl className={classes.selectSmall}>
              <Select
                name={`${ruleKey}-value`}
                value={currentRule[ruleKey][0] || ''}
                onChange={(e) => handleFieldChange(ruleKey, [e.target.value])}
              >
                {Object.keys(ruleDefinition.options).map(key => (
                  <MenuItem key={key} value={key}>{ruleDefinition.options[key]}</MenuItem>
                ))}
              </Select>
            </FormControl>
          )
          if (fieldType === 'multiselect') return (
            <FormControl className={classes.fieldFullSize}>
              <Select
                name={`${ruleKey}-value`}
                multiple
                value={currentRule[ruleKey] || []}
                onChange={(e) => handleFieldChange(ruleKey, e.target.value)}
                input={<Input />}
                renderValue={(selected) => (
                  <div>
                    {selected.map((value) => (
                      <Chip key={value} label={ruleDefinition.options[value]} className={classes.multiselectChip} />
                    ))}
                  </div>
                )}
              >
                {Object.keys(ruleDefinition.options).map(key => (
                  <MenuItem key={key} value={key}>{ruleDefinition.options[key]}</MenuItem>
                ))}
              </Select>
            </FormControl>
          )
          return '';
        }

        return (
          <>
            {!isObjectEmpty(currentRule) && (
              <ul className={classes.ruleList}>
                {Object.keys(currentRule).map(ruleKey => (
                  <li className={classes.ruleLine} key={ruleKey}>
                    {getRuleLabel(rowData.source, ruleKey)}
                    {getUpdateField(ruleKey)}
                    <IconButton
                      className={classes.ruleLineButton}
                      size="small"
                      onClick={() => updateRule(removeKeyFromObject(currentRule, ruleKey))}
                    >
                      <DeleteIcon fontSize="inherit" />
                    </IconButton>
                  </li>
                ))}
              </ul>
            )}
            {avaibableRuleKeys.length > 0 && (
              <div className={classes.newRuleButton}>
                <DropdownSelector
                  title="Nové pravidlo"
                  onOptionSelect={(ruleKey) => handleFieldChange(
                    ruleKey,
                    getRuleDefinition(rowData.source, ruleKey).ruleDefinition?.value || [],
                  )}
                  availableOptions={avaibableRuleKeys}
                />
              </div>
            )}
          </>
        );
      },
      width: '58%',
    },
  ];

  const loadAccessRights = async () => {
    try {
      setLoading(true);
      const result = await axios.get(`/consumer/${consumerKey}/access`);
      setAccessRights(result.data.data.map(item => {
        return mapAccessRight(item);
      }));
    } catch (e) {
      setDataLoadingError('Data se nepodařio načíst, zkuste to prosím později.');
    } finally {
      setLoading(false);
    }
  };

  const modifyAccessRight = async (data, dataUpdateCallback) => {
    try {
      const result = await axios.post(
        `/consumer/${consumerKey}/access/${data.source}/${data.type}`,
        { rule: getURLParamsFromObject(data.rule) },
      );
      dataUpdateCallback(mapAccessRight(result.data.data));
      setDataModificationError(false);
      return result.data.data;
    } catch (e) {
      setDataModificationError(e.message);
      return false;
    }
  };

  const createAccessRight = async (data) => modifyAccessRight(data, (result) => {
    setAccessRights((prev) => [...prev, result]);
  });

  const updateAccessRight = async (data) => modifyAccessRight(data, (result) => {
    setAccessRights((prev) => prev.map(item => {
      if (item.source === result.source && item.type === result.type) {
        return result;
      }
      return item;
    }));
  });

  const deleteAccessRight = async (data) => {
    try {
      const result = await axios.delete(`/consumer/${consumerKey}/access/${data.source}/${data.type}`);
      setAccessRights((prev) => prev.filter(item => item.source !== data.source || item.type !== data.type));
      setDataModificationError(false);
      return true;
    } catch (e) {
      setDataModificationError(e.message);
      return false;
    }
  };

  const assignUniversalRights = async () => {
    const accessRightsResult = [];
    await Promise.all(
      Object.keys(SOURCES).map(sourceKey => Promise.all(
        Object.keys(ACCESS_TYPES).map(async typeKey => modifyAccessRight({
          source: sourceKey,
          type: typeKey,
          rule: {},
        }, (result) => {
          accessRightsResult.push(result);
        })),
      )),
    );
    setAccessRights(accessRightsResult);
  };

  const validateAccessRight = (data) => {
    if (!data.source) {
      setDataModificationError('Je nutné vyplnit zdroj');
      return false;
    }
    if (!data.type) {
      setDataModificationError('Je nutné vyplnit typ přístupu');
      return false;
    }
    if (data.rule && Object.keys(data.rule).some(ruleKey => !data.rule[ruleKey] || (data.rule[ruleKey].length === 0 && data.source !== SOURCE_TREE_DEFINITION))) {
      setDataModificationError('Je nutné vyplnit všechna vybraná filtrační kritéria nebo je z výběru odebrat');
      return false;
    }
    return true;
  };

  const cleanAccessRight = (data) => {
    if (!data.rule) return { ...data, rule: {} };
    return data;
  };

  React.useEffect(() => {
    loadAccessRights();
  }, [consumerKey]);

  return (
    <Modal open={open} onClose={onClose}>
      <div className={`${formClasses.modalWindow} ${classes.modal}`}>
        <Typography variant="h5" component="h2" className={classes.headline}>Správa přístupových práv</Typography>
        {loading && <Loader />}
        {dataModificationError && (
          <ServerResponseAlert serverSuccess={!dataModificationError} serverReply={dataModificationError} onClose={() => setDataModificationError(false)} />
        )}
        {dataLoadingError && (
          <Alert severity="error">
            <AlertTitle>Chyba při načítání dat.</AlertTitle>
            {dataLoadingError}
          </Alert>
        )}
        {!loading && !dataLoadingError && (
          <>
            <MaterialTable
              localization={ MTLocalization }
              options={{ actionsColumnIndex: -1, search: false, paging: false, addRowPosition: 'first', tableLayout: 'fixed' }}
              style={{ margin: 0, boxShadow: 'none', width: '100%', position: 'relative' }}
              className="MuiTableContainer"
              title=""
              columns={columns}
              data={accessRights}
              editable={{
                onRowAdd: getPossibleSources(accessRights).length > 0 ? (data) => new Promise((resolve, reject) => {
                  const validationResult = validateAccessRight(data);
                  if (!validationResult) {
                    reject(dataModificationError);
                  } else {
                    let result = createAccessRight(cleanAccessRight(data));
                    if (result) {
                      resolve(result);
                    } else {
                      reject(dataModificationError);
                    }
                  }
                }) : undefined,
                onRowDelete: (data) => new Promise((resolve, reject) => {
                  let result = deleteAccessRight(data);
                  if (result) {
                    resolve(result);
                  } else {
                    reject(dataModificationError);
                  }
                }),
                onRowUpdate: (data) => new Promise((resolve, reject) => {
                  const validationResult = validateAccessRight(data);
                  if (!validationResult) {
                    reject(dataModificationError);
                  } else {
                    let result = updateAccessRight(cleanAccessRight(data));
                    if (result) {
                      resolve(result);
                    } else {
                      reject(dataModificationError);
                    }
                  }
                }),
              }}
            />
            <Button
              className={classes.bottomActions}
              variant="outlined" color="primary" type="button"
              onClick={() => assignUniversalRights()}
            >
              Přiřadit univerzální práva
            </Button>
          </>
        )}
      </div>
    </Modal>
  );
};
