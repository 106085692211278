import { useCallback, useEffect, useState } from "react";
import axios from '../utils/axios';

export const ProductLockColumn = {
  EXTERNAL_REFERENCE_KEY: 'external_reference_key',
  TITLE: 'title',
  SUBTITLE: 'subtitle',
  ORIGINAL_TITLE: 'original_title',
  ISBN: 'isbn',
  EAN: 'ean',
  SUBTYPE: 'subtype',
  SUMMARY: 'summary',
  DESCRIPTION: 'description',
  AGE_LIMIT: 'age_limit',
  LANGUAGE: 'language',
  ORIGINAL_LANGUAGE: 'original_language',
  FIRST_PUBLISH_DATE: 'first_publish_date',
  PUBLISH_DATE: 'publish_date',
  DISTRIBUTABLE_FROM: 'distributable_from',
  DISTRIBUTABLE_UNTIL: 'distributable_until',
  DISTRIBUTION_REGION: 'distribution_region',
  VIDEO_SAMPLE_URL: 'video_sample_url',
  STOCK_AVAILABILITY: 'stock_availability',
  REVIEW_SCORE: 'review_score',
  REVIEW_TOTAL_VOTERS: 'review_total_voters',
  PRESALE_VALID_FROM: 'presale_valid_from',
  PRESALE_VALID_UNTIL: 'presale_valid_until',
  PRIORITY: 'priority',
  EXTERNAL_PROVISION: 'external_provision',
  EXTERNAL_SAMPLE_URL: 'external_sample_url',
  PRESALE_VALID_FROM: 'presale_valid_from',
  PRESALE_VALID_UNTIL: 'presale_valid_until',

  // references
  BUNDLE: 'product:bundle',
  AUTHOR: 'product:author',
  PUBLISHER: 'product:publisher',
  BRAND: 'product:brand',
  PROJECT: 'product:project',
  TREE_BRANCH: 'product:tree_branch',
  GENRE: 'product:genre',
  CATEGORY: 'product:category',
  CROSS_LINK: 'product:cross_link',
  SERIE: 'product:serie',
  EDITION: 'product:edition',
  TAG: 'product:tag',
};

export const AuthorLockColumn = {
  FIRST_NAME: 'first_name',
  MIDDLE_NAME: 'middle_name',
  LAST_NAME: 'last_name',
  TITLE_BEFORE: 'title_before',
  TITLE_AFTER: 'title_after',
  PSEUDONYM: 'pseudonym',
  BIRTH_DATE: 'birth_date',
  DEATH_DATE: 'death_date',
  SUMMARY: 'summary',
  BIOGRAPHY: 'biography',
  EXTERNAL_REFERENCE_KEY: 'external_reference_key',
  TWITTER: 'twitter',
  FACEBOOK: 'facebook',
  WWW: 'www',
  INSTAGRAM: 'instagram',
  YOUTUBE: 'youtube',
  IS_BLACKLISTED: 'is_blacklisted',
  IS_MASTER: 'is_master',

  // references
  MASTER_AUTHOR: 'author:master_author',
  IMAGE: 'author:image',
};

const useLocks = (entity, entityId = undefined) => {
  const [locks, setLocks] = useState({});
  const loadLocks = useCallback(async (id) => {
    const response = await axios.get(`lock/${entity}/${id}`);
    const locks = response.data.data.reduce((stack, column) => ({
      ...stack,
      [column]: true,
    }), {});
    return locks;
  }, [entity]);

  const lock = useCallback(async ({
    id = entityId,
    fieldName: column,
    userId,
  }) => {
    try {
      await axios.post(`lock/${entity}/${id}/${column}`, { locked_by: userId });
      setLocks(locks => ({
        ...locks,
        [column]: true,
      }));
    } catch (err) {}
  }, [entity, entityId]);

  const unlock = useCallback(async ({
    id = entityId,
    fieldName: column,
  }) => {
    try {
      await axios.delete(`lock/${entity}/${id}/${column}`);
      setLocks(locks => ({
        ...locks,
        [column]: false,
      }));
    } catch (err) {}
  }, [entity, entityId]);

  useEffect(() => {
    entityId !== undefined && loadLocks(entityId).then(setLocks);
  }, [entity, entityId]);

  return {
    locks,
    lock,
    unlock,
    loadLocks,
  };
};

export default useLocks;
