// used so we can inherit theme variables

import { makeStyles } from "@material-ui/core";
export default makeStyles((theme) => ({
  inputSmall: {
    minWidth: `calc(25% - ${theme.spacing(5)}px)`,
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(5),
    marginBottom: theme.spacing(0),
  },
  inputSmallFixed: {
    width: `calc(25% - ${theme.spacing(5)}px)`,
  },
  inputDate: {
    width: `calc(25% - ${theme.spacing(5)}px)`,
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(5),
    marginBottom: theme.spacing(0),
  },
  input: {
    minWidth: `calc(50% - ${theme.spacing(5)}px)`,
    maxWidth: `calc(50% - ${theme.spacing(5)}px)`,
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(5),
    marginBottom: theme.spacing(0),
    display: "inline-flex",
  },
  inputFull: {
    width: `calc(100% - ${theme.spacing(5)}px)`,
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(5),
    marginBottom: theme.spacing(0),
  },
  autocomplete: {
    width: `calc(50% - ${theme.spacing(5)}px)`,
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(5),
    marginBottom: theme.spacing(0),
    display: "inline-flex",
  },
  autocompleteFullWidth: {
    width: `100%`,
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(0),
    marginBottom: theme.spacing(0),
    display: "inline-flex",
  },
  image: {
    minWidth: `calc(50% - ${theme.spacing(5)}px)`,
    maxWidth: `calc(50% - ${theme.spacing(5)}px)`,
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(5),
    marginBottom: theme.spacing(0),
    display: "inline-flex",
  },
  imageBase: {
    minWidth: `calc(50% - ${theme.spacing(5)}px)`,
    maxWidth: `calc(50% - ${theme.spacing(5)}px)`,
    marginRight: theme.spacing(5),
    marginBottom: theme.spacing(0),
    display: "inline-flex",
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  modalWindow: {
    position: "absolute",
    width: 400,
    backgroundColor: "#fff",
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  inputContainer: {
    boxSizing: 'border-box',
    position: 'relative',
    display: 'inline-flex',
    width: '50%',
    paddingRight: theme.spacing(4),
    paddingLeft: '28px',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(0),
  },
  inputContainerSmall: {
    width: '25%',
  },
  inputContainerLarge: {
    width: '100%',
  },
  inputBase: {
    flexGrow: '1',
  },
  innerInputBase: {
    display: 'flex',
  },
  lockableInputContainer: {
    '& $lockButton': {
      position: 'absolute',
      top: '16px',
      left: 0,
    },
    '&:hover $lockButton, &:focus-within $lockButton': {
      visibility: 'visible',
    }
  },
  lockButton: {},
  lockButtonHideable: {
    visibility: 'hidden',
  },
  lockButtonLocked: {
    visibility: 'visible',
  },
}));
