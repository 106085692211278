import React from 'react';
import InfoIcon from '@material-ui/icons/Info';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  indicator: {
    display: 'inline-flex',
    padding: '15px',
  },
  indicatorReadOnly: {
    color: 'rgb(102, 60, 0)',
    backgroundColor: 'rgb(255, 244, 229)',
  },
  indicatorText: {
    margin: '0',
    marginLeft: '10px',
  },
});

const StatusWarning = ({ text }) => {
  const classes = useStyles();

  return (
    <div className={`${classes.indicator} ${classes.indicatorReadOnly}`}>
      <InfoIcon />
      <p className={classes.indicatorText}>
        {text}
      </p>
    </div>
  );
};

export default StatusWarning;
