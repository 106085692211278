import React, { useState, useEffect } from 'react';
import MaterialTable from 'material-table';
import { Alert, AlertTitle } from '@material-ui/lab';
import { TablePagination, Box } from '@material-ui/core';

import axios from '../utils/axios';
import MTLocalization from '../utils/MTLocalization';
import Loader from '../layout/Loader';

import { CREATE, UPDATE, AbilityContext, DELETE, TAG } from '../permissions';
import { subject } from '@casl/ability';
import { useAbility } from '@casl/react';
import ServerResponseAlert from './ServerResponseAlert';

const TagTable = () => {
  const ability = useAbility(AbilityContext);
  // user data
  const [tags, setTags] = useState(null);
  // pagination
  const [currentPage, setCurrentPage] = useState(0);
  const [currentPageSize, setCurrentPageSize] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  // loading
  const [loading, setLoading] = useState(false);
  // data modification response - create, update, delete
  const [dataModificationSuccess, setDataModificationSuccess] = useState(null);
  const [dataModificationReply, setDataModificationReply] = useState(null);
  // data loading error
  const [dataLoadingError, setDataLoadingError] = useState(null);

  const loadTags = (page = currentPage, pageSize = currentPageSize) => {
    const doFetch = async () => {
      try {
        const result = await axios.get(`tag?page=${page + 1}&limit=${pageSize}`);
        setTags(result.data.data);
        setTotalCount(result.data.meta.total_number);
      } catch (e) {
        setDataLoadingError(e);
      } finally {
        setLoading(false);
      }
    };

    setDataLoadingError(null);
    setLoading(true);
    doFetch();
  };

  const handlePageChange = (newPage, newPageSize) => {
    setCurrentPage(newPage);
    setCurrentPageSize(newPageSize);
    loadTags(newPage, newPageSize);
  };

  // run on load only
  useEffect(() => {
    loadTags(currentPage, currentPageSize);
  }, []);

  // === DATA MODIFICATION ===

  const validateTag = (tagData) => {
    if (!tagData.name) {
      setDataModificationSuccess(false);
      setDataModificationReply('Název tagu je povinný');
      return false;
    }
    if (tagData.name.length > 255) {
      setDataModificationSuccess(false);
      setDataModificationReply('Název tagu musí být kratší než 255 znaků');
      return false;
    }
    if (!tagData.keyword) {
      setDataModificationSuccess(false);
      setDataModificationReply('Klíč tagu je povinný');
      return false;
    }
    if (tagData.keyword.length > 255) {
      setDataModificationSuccess(false);
      setDataModificationReply('Klíč tagu musí být kratší než 255 znaků');
      return false;
    }
    if (!tagData.keyword.match(/^[a-z0-9\-_]*$/g)) {
      setDataModificationSuccess(false);
      setDataModificationReply('Zadaný klíč není validní');
      return false;
    }
    return true;
  };

  const createTag = async (newTagData) => {
    setLoading(true);
    setDataModificationReply(null);

    const body = {
      name: newTagData.name,
      keyword: newTagData.keyword,
    };

    try {
      await axios.post('/tag', JSON.stringify(body));
      setDataModificationSuccess(true);
      setDataModificationReply('V pořádku uloženo');
      setCurrentPage(0);
      loadTags(0);
    } catch (e) {
      setDataModificationSuccess(false);
      setDataModificationReply(e);
    } finally {
      setLoading(false);
    }
  };

  const updateTag = async (newTagData, oldTagData) => {
    setLoading(true);
    setDataModificationReply(null);

    const body = {
      name: newTagData.name,
      keyword: newTagData.keyword,
    };

    try {
      await axios.put(`/tag/${oldTagData.id}`, JSON.stringify(body));
      setDataModificationSuccess(true);
      setDataModificationReply('V pořádku uloženo');
      loadTags();
    } catch (e) {
      setDataModificationSuccess(false);
      setDataModificationReply(e);
    } finally {
      setLoading(false);
    }
  };

  const deleteTag = async (tagData) => {
    setLoading(true);
    setDataModificationReply(null);

    try {
      await axios.delete(`/tag/${tagData.id}`);
      setDataModificationSuccess(true);
      setDataModificationReply('V pořádku smazáno');
      loadTags();
    } catch (e) {
      setDataModificationSuccess(false);
      setDataModificationReply('Chyba při mazání');
    } finally {
      setLoading(false);
    }
  };

  // === RENDER HELPERS ===

  const getColumns = () => {
    return [
      { title: 'Název', field: 'name', render: (rowData) => <strong>{rowData.name}</strong> },
      { title: 'Klíč', field: 'keyword' },
    ];
  };

  // === RENDER ===

  return (
    <>
      <Box>
        {loading && <Loader />}
        {dataModificationReply && (
          <ServerResponseAlert serverSuccess={dataModificationSuccess} serverReply={dataModificationReply} onClose={() => setDataModificationReply(null)} />
        )}
        {dataLoadingError && (
          <Alert severity="error" style={{ marginTop: 20 }}>
            <AlertTitle>Chyba při načítání dat.</AlertTitle>
            {dataLoadingError.message}
          </Alert>
        )}
      </Box>
      {tags && (
        <MaterialTable
          key={currentPageSize} // workaround for not functioning page size change
          localization={MTLocalization}
          options={{ pageSize: currentPageSize, actionsColumnIndex: -1, addRowPosition: 'first', search: false, showTitle: false }}
          style={{ margin: 0, boxShadow: 'none' }}
          columns={getColumns()}
          components={{
            Pagination: (props) => (
              <TablePagination
                {...props}
                rowsPerPageOptions={[5, 10, 20, 30]}
                rowsPerPage={currentPageSize}
                count={totalCount}
                page={currentPage}
                onChangePage={(e, page) => handlePageChange(page, currentPageSize)}
                onChangeRowsPerPage={(e) => handlePageChange(0, e.target.value)}
              />
            ),
          }}
          data={tags}
          editable={{
            isEditable: (rowData) => ability.can(UPDATE, subject(TAG, rowData)),
            isDeletable: (rowData) => ability.can(DELETE, subject(TAG, rowData)),
            ...(ability.can(CREATE, TAG) && {
              onRowAdd: (newData) =>
                new Promise((resolve, reject) => {
                  const validationResult = validateTag(newData);
                  if (!validationResult) {
                    reject(dataModificationReply);
                  } else {
                    const saveResponse = createTag(newData);
                    if (saveResponse) {
                      resolve(saveResponse);
                    } else {
                      reject(dataModificationReply);
                    }
                  }
                }),
            }),
            onRowUpdate: (newData, oldData) =>
              new Promise((resolve, reject) => {
                const validationResult = validateTag(newData);
                if (!validationResult) {
                  reject(dataModificationReply);
                } else {
                  const saveResponse = updateTag(newData, oldData);
                  if (saveResponse) {
                    resolve(saveResponse);
                  } else {
                    reject(dataModificationReply);
                  }
                }
              }),
            onRowDelete: (oldData) =>
              new Promise((resolve, reject) => {
                const deleteResponse = deleteTag(oldData);
                if (deleteResponse) {
                  resolve(deleteResponse);
                } else {
                  reject(dataModificationReply);
                }
              }),
          }}
        />
      )}
    </>
  );
};

export default TagTable;
