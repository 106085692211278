import { Box, Grid, Paper, Typography } from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import productBundleEntity from "../entities/productBundle";
import ProductBundleForm from "../forms/ProductBundle";
import useFetch from "../hooks/useFetch";
import Loader from "../layout/Loader";
import formatDate4Input from "../utils/formatDate4Input";
import nullToString from "../utils/nullToString";

export default (props) => {
  let { id } = useParams();
  id = id === undefined ? "" : id;
  const newBookItem = id === "" ? true : false;
  const [bookItem, setBookItem] = useState({ ...productBundleEntity });
  const { response, loading, error } = useFetch(`/product-bundle/${id}`);
  useEffect(() => {
    if (response && response.data && response.data.title && newBookItem === false) {
      let cleanResponse = nullToString(response.data);
      setBookItem(cleanResponse);
    }
  }, [response]);
  return (
    <Paper className="basePaper">
      <Grid container direction="row" justify="space-between">
        <Grid item xs={12}>
          <Typography variant="h5">{newBookItem === true ? "Nový svazek:" : "Upravit svazek:"} </Typography>

          <Box>
            {loading && <Loader />}
            {error && (
              <Alert severity="error" style={{ marginTop: 20 }}>
                <AlertTitle>Ajajaj!</AlertTitle>
                Chyba při načítání dat.
              </Alert>
            )}
          </Box>
          <ProductBundleForm key={bookItem.id} data={bookItem} />
        </Grid>
      </Grid>
      <br />
      {/*       <Alert severity="info">
        <AlertTitle>Soubory</AlertTitle>
        - měl by zde být file uploader anebo jenom možnost vybrat z knihovny?
        <br />
      </Alert> */}
    </Paper>
  );
};
