import React from 'react';
import MaterialTable from 'material-table';
import { IconButton, makeStyles, Modal, Tooltip, Typography } from '@material-ui/core';
import MTLocalization from '../utils/MTLocalization';
import AssetPreview, { isAudioAsset, isImageAsset } from './AssetPreview';
import { STATUS_ACTIVE, STATUS_CREATE, STATUS_UPDATE } from './AssetList';

import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import RemoveRedEyeIcon from '@material-ui/icons/RemoveRedEye';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import DownloadIcon from '@material-ui/icons/ArrowDownward';

const useStyles = makeStyles({
  container: {
    '&:not(:first-child)': {
      marginTop: '50px',
    },
  },
  statusActive: {
    color: '#3aad00',
  },
  statusUpdate: {
    color: '#d67900',
  },
  statusCreate: {
    color: '#d60000',
  },
  rowActions: {
    textAlign: 'right',
  },
  title: {
    fontWeight: 'bold',
  },
  titleSubtitle: {
    fontWeight: 'normal',
  },
});

export const AssetTable = ({
  title,
  subtitle,
  assets,
  readOnly = false,
  onEdit,
  onDelete,
}) => {
  const classes = useStyles();
  const [assetPreview, setAssetPreview] = React.useState(null);

  const handleAssetDelete = (asset) => {
    onDelete(asset);
  };

  const handleAssetEdit = (asset) => {
    onEdit(asset);
  };

  const closeAssetPreview = () => {
    setAssetPreview(null);
  };

  const getPreviewButton = (asset) => (
    <Tooltip title={isAudioAsset(asset) ? 'Přehrát' : 'Zobrazit náhled'}>
      <IconButton
        aria-label="preview"
        color="primary"
        onClick={() => setAssetPreview(asset)}
      >
        {isAudioAsset(asset) ? (
          <PlayArrowIcon />
        ) : (
          <RemoveRedEyeIcon />
        )}
      </IconButton>
    </Tooltip>
  );

  const getViewAction = (asset) => {
    if (isImageAsset(asset) || isAudioAsset(asset)) {
      return getPreviewButton(asset);
    }

    return (
      <Tooltip title="Stáhnout">
        <IconButton
          aria-label="preview"
          color="primary"
          href={asset.storage_url}
        >
          <DownloadIcon />
        </IconButton>
      </Tooltip>
    );
  };

  const statusLookupTable = {
    [STATUS_ACTIVE]: {
      text: 'Aktivní',
      class: classes.statusActive,
    },
    [STATUS_UPDATE]: {
      text: 'Čeká na zpracování úprav',
      class: classes.statusUpdate,
    },
    [STATUS_CREATE]: {
      text: 'Čeká na zpracování',
      class: classes.statusCreate,
    },
  };

  const columns = [
    {
      title: 'Název souboru',
      field: 'filename',
    },
    {
      title: 'Typ assetu',
      field: 'type',
    },
    {
      title: 'Stav',
      render: (rowData) => (
        <span className={statusLookupTable[rowData.status].class}>
          {statusLookupTable[rowData.status].text}
        </span>
      ),
    },
    {
      title: '',
      render: (rowData) => (
        <div className={classes.rowActions}>
          {getViewAction(rowData)}
          {!readOnly && (
            <>
              <Tooltip title="Upravit">
                <IconButton
                  aria-label="edit"
                  color="primary"
                  onClick={() => handleAssetEdit(rowData)}
                  disabled={!rowData.source_id}
                >
                  <EditIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Smazat">
                <IconButton
                  aria-label="delete"
                  color="primary"
                  onClick={() => handleAssetDelete(rowData)}
                >
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </>
          )}
        </div>
      ),
    },
  ];

  return (
    <div className={classes.container}>
      <Typography className={classes.title} variant="h6">
        {title}
        {subtitle && (<span className={classes.titleSubtitle}> {subtitle}</span>)}
      </Typography>

      {assets && (
        <MaterialTable
          localization={MTLocalization}
          options={{ addRowPosition: 'first', search: false, paging: false }}
          style={{ margin: 0, boxShadow: 'none' }}
          className="MuiTableContainer"
          title=""
          columns={columns}
          data={assets}
          components={{
            Toolbar: props => null,
          }}
        />
      )}
      <Modal open={!!assetPreview} onClose={closeAssetPreview}>
        <AssetPreview asset={assetPreview} />
      </Modal>
    </div>
  );
};
