import HeadsetRoundedIcon from "@material-ui/icons/HeadsetRounded";
import TabletMacRoundedIcon from "@material-ui/icons/TabletMacRounded";
import AddCircleOutlineRoundedIcon from "@material-ui/icons/AddCircleOutlineRounded";
import MenuBookRoundedIcon from "@material-ui/icons/MenuBookRounded";
import { Box, Button, Link, Paper, Typography, Checkbox, TablePagination } from "@material-ui/core";

import MaterialTable from "material-table";
import MTLocalization from "../utils/MTLocalization";
import React, { useEffect, useState, useReducer } from "react";
import Loader from "../layout/Loader";
import axios from "../utils/axios";
import { Can, CHANGE_PUBLISHING_STATE, CREATE, UPDATE, PRODUCT } from "../permissions";
import { subject } from "@casl/ability";
import { getSortReadyColumns, getSortString } from '../utils/tableSortUtils';
import ServerResponseAlert from '../components/ServerResponseAlert';
import AdvancedSearch from '../components/AdvancedSearch';
import ProductSearchFilter, { INITIAL_FILTER_VALUE, LABELS } from '../components/ProductSearchFilter';
import {PublicRounded} from "@material-ui/icons";

const SORT_FIELDS = {
  title: 'title',
  type: 'type',
  publisher: 'publisher',
  external_reference_key: 'externalReferenceKey',
  is_publishable: 'isPublishable',
};

// publishing change types
const PUBLISH = 'publish';
const DECOMISSION = 'decomission';

// constants
const PRODUCT_FILTER = 'product-filter';

function reducer(state, action) {
  switch (action.type) {
    case "add":
      return [...state, action.item];
    case "remove":
      const newArray = state.filter((item) => item !== action.item);
      return newArray;
    case 'clean':
      return [];
    default:
      throw new Error();
  }
}


export default (props) => {
  const [state, setState] = useState(null);

  const [selectedItems, setSelectedItems] = useReducer(reducer, []);

  // -- Data loading
  const [response, setResponse] = useState([]);
  const [loading, setDataLoading] = useState(false);
  const [error, setDataError] = useState(null);

  const [totalCount, setTotalCount] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);

  const [activeFilterQuery, setFilterQuery] = useState(null);

  const [sort, setSort] = useState(null);

  const loadProductData = (page = currentPage, limit = pageSize, filterQuery = activeFilterQuery, sortDesc = sort) => {
    const query = {
      page: page + 1,
      limit,
      ...processFilterQueryToSend(filterQuery),
      ...(sortDesc && { sort: getSortString(sortDesc, SORT_FIELDS) }),
    };
    const doFetch = async () => {
      setDataLoading(true);
      try {
        const res = await axios('/product', { params: query });
        setResponse(res.data);
        setTotalCount(res.data.meta.total_number);
      } catch (e) {
        setDataError(e);
      } finally {
        setDataLoading(false);
      }
    };

    doFetch();
  }

  // --- on load ---

  useEffect(() => {
      const existingFilterString = window.localStorage.getItem(PRODUCT_FILTER);
      if (existingFilterString) {
        const filter = JSON.parse(existingFilterString);
        setFilterQuery(filter);
        loadProductData(currentPage, pageSize, filter);
      } else {
        loadProductData();
      }
    }, []);


  // --- pagination ---

  const handlePageChange = (newPage, newPageSize) => {
    resetSelectedItems();
    setCurrentPage(newPage);
    setPageSize(newPageSize);
    loadProductData(newPage, newPageSize);
  }

  // --- filtering ---

  const handleFilterChange = (filterQuery) => {
    resetSelectedItems();
    setCurrentPage(0);
    setFilterQuery(filterQuery);
    loadProductData(0, pageSize, filterQuery);
    preserveFilter(filterQuery);
  }

  const handleFilterReset = () => {
    handleFilterChange(null);
  }

  const preserveFilter = (filterQuery) => {
    if (filterQuery) {
      const stringified = JSON.stringify(filterQuery);
      window.localStorage.setItem(PRODUCT_FILTER, stringified);
    } else {
      window.localStorage.removeItem(PRODUCT_FILTER);
    }
  }

  const processFilterQueryToSend = (filterQuery) => {
    if (!filterQuery) return {};

    const { publishable, ...rest } = filterQuery;

    const getPublishingStateQuery = (requestedState) => {
      if (requestedState === 'publishable') return { publishable: '1', decomissioned: '0' };
      if (requestedState === 'draft') return { publishable: '0', decomissioned: '0' };
      if (requestedState === 'decomissioned') return { decomissioned: '1' };
      if (requestedState === 'availableInStock') return { stockAvailability: '1' };
      if (requestedState === 'notAvailableInStock') return { stockAvailability: '0' };
      if (requestedState === 'available') return { available: '1' };

      return {};
    };

    return {
      ...rest,
      ...getPublishingStateQuery(publishable),
    };
  }

  // --- sorting ---

  const handleOrderChange = (columnIdx, order) => {
    const sort = columnIdx >= 0 ? { field: state.columns[columnIdx].field, order: order } : null;

    setCurrentPage(0);
    setSort(sort);
    loadProductData(0, pageSize, activeFilterQuery, sort);
  }

  // -- Authors lookup:
  const [lookupTable, setLookupTable] = React.useState([]);
  const loading2 = lookupTable.length === 0;
  React.useEffect(() => {
    if (!loading2) {
      return undefined;
    }
    (async () => {
      const response = await axios.get("/author");
      console.log(response.data.data);
      setLookupTable(response.data.data);
    })();
  }, [loading2]);

  const changePublishingState = (type, value) => {
    const promiseArray = [];
    let endpoint = '';
    if (type === PUBLISH) {
      endpoint = value ? 'publish' : 'unpublish';
    } else if (type === DECOMISSION) {
      endpoint = value ? 'decomission' : 'revert-decomission';
    }

    selectedItems.forEach((item) => {
      promiseArray.push(axios.post(`/product/${item}/${endpoint}`));
    });

    Promise.all(promiseArray)
      .then((responses) => {
        setResponse((prevState) => ({
          ...prevState,
          data: prevState.data.map(item => {
            const responseIndex = responses.findIndex(response => response.data.data.id === item.id);
            if (responseIndex === -1) return item;
            return {
              ...item,
              is_publishable: responses[responseIndex].data.data.is_publishable,
              is_decomissioned: responses[responseIndex].data.data.is_decomissioned,
            };
          }),
        }));
        resetSelectedItems();
        setDataError(null);
      })
      .catch((err) => {
        setDataError(err);
      });
  };

  const resetSelectedItems = () => {
    if (selectedItems.length > 0) {
      setSelectedItems({ type: 'clean' });
    }
  }

  const handleChange = (event) => {
    if (event.target.checked) {
      setSelectedItems({ type: 'add', item: event.target.name });
    } else {
      setSelectedItems({ type: 'remove', item: event.target.name });
    }
  };


  useEffect(() => {
    if (response && response.status === 200) {
      console.log(response);
      let newData = {
        columns: [
          {
            title: "",
            field: "",
            width: 10,
            render: (rowData) => {
              const checked = selectedItems.some(item => item === rowData.id);
              return (
                <Can I={CHANGE_PUBLISHING_STATE} a={subject(PRODUCT, rowData)}>
                  {() => <Checkbox name={`${rowData.id}`} onClick={handleChange} inputProps={{ "aria-label": "uncontrolled-checkbox" }} checked={checked} />}
                </Can>
              );
            },
          },
          {
            title: 'Stav publikování',
            width: 120,
            render: (rowData) => {
              if (rowData.is_decomissioned) return (
                <span className="smallFont">Nepublikovaný</span>
              );
              if (rowData.is_publishable) return (
                <span className="smallFont">Publikovaný</span>
              );
              return (
                <span className="smallFont">Neúplný</span>
              );
            },
          },
          {
            title: "Název",
            field: "title",
            render: (rowData) => <a href={`/product/${rowData.id}`}>{rowData.title}</a>,
          },
          // {
          //   title: "Autor",
          //   field: "authors",
          //   render: (rowData) => {
          //     if (rowData.authors[0]) {
          //       const found = lookupTable.find((item) => item.id === rowData.authors[0].id);
          //       return found && found.name ? found.name : "---";
          //     } else {
          //       return "---";
          //     }
          //   },
          // },
          {
            title: "Vydavatel",
            field: "publisher",
            render: (rowData) => rowData.publisher.name,
          },
          {
            title: "Typ",
            field: "type",
            customFilterAndSearch: (term, rowData) => rowData.type.toLowerCase().indexOf(term.toLowerCase()) > -1,
            render: (rowData) => {
              return rowData.type === "audiobook" ? (
                <div className="productType">
                  <HeadsetRoundedIcon />
                  <span>Audiobook</span>
                </div>
              ) : rowData.type === "ebook" ? (
                  <div className="productType">
                    <TabletMacRoundedIcon />
                    <span>Ebook</span>
                  </div>
              ) : rowData.type === "physicalgood" ? (
                  <div className="productType">
                    <MenuBookRoundedIcon />
                    <span>Fyzické zboží</span>
                  </div>
              ) : rowData.type === "physicalgoodsnonbooks" ? (
                <div className="productType">
                  <PublicRounded />
                  <span>Fyzické zboží neknižní</span>
                </div>
              ) : (
                <div className="productType">
                  <span>{rowData.type}</span>
                </div>
              );
            },
          },
          { title: "Externí klíč", field: "external_reference_key", render: (rowData) => <span className="smallFont">{rowData.external_reference_key}</span> },
          {
            width: 100,
            title: "",
            field: "action",
            filtering: false,
            render: (rowData) => (
              <Can I={UPDATE} a={subject(PRODUCT, rowData)}>
                {() => <Button href={`/product/edit/${rowData.id}`} variant="outlined" color="primary">
                  Upravit
                </Button>}
              </Can>
            ),
          },
        ],
        data: [...response.data],
      };
      console.log();
      setState(newData);
    }
  }, [response, lookupTable, selectedItems]);

  return (
    <Paper className="basePaper">
      <Typography variant="h5">
        <div className="sectionName">
          Produkty &nbsp;
          <Can I={CREATE} a={PRODUCT}>
            {() => <Link href="/product/new/">
              <AddCircleOutlineRoundedIcon className="createNew" />
            </Link>}
          </Can>
          {/* <Button href="/product/new/" variant="outlined" color="primary">
            Vytvořit nový
          </Button> */}
        </div>
      </Typography>
      <Box>
        {loading && <Loader />}
        {error && (
          <ServerResponseAlert
            serverSuccess={false}
            serverReply={error}
          />
        )}
      </Box>
      <AdvancedSearch
        onFilterSubmit={handleFilterChange}
        onFilterReset={handleFilterReset}
        filterValue={activeFilterQuery}
        initialFilter={INITIAL_FILTER_VALUE}
        filterLabels={LABELS}
        filterFormComponent={ProductSearchFilter}
      />
      {response && state && (
        <>
          <MaterialTable
            key={pageSize} // workaround for not functioning page size change
            localization={MTLocalization}
            options={{ pageSize: pageSize, addRowPosition: "first", search: false }}
            style={{ margin: 0, boxShadow: "none" }}
            className="MuiTableContainer"
            title=""
            columns={getSortReadyColumns(state.columns, Object.keys(SORT_FIELDS), sort)}
            data={state.data}
            onOrderChange={handleOrderChange}
            components={{
              Pagination: (props) => (
                <TablePagination
                  {...props}
                  rowsPerPageOptions={[5, 10, 20, 30]}
                  rowsPerPage={pageSize}
                  count={totalCount}
                  page={currentPage}
                  onChangePage={(e, page) => handlePageChange(page, pageSize)}
                  onChangeRowsPerPage={(e) => handlePageChange(currentPage, e.target.value)}
                />
              ),
              Toolbar: props => null,
            }}
          />
          <Can I={CHANGE_PUBLISHING_STATE} a={PRODUCT}>
            {() => <>
              <Button
                onClick={() => changePublishingState(PUBLISH, true)}
                variant="outlined"
                color="primary"
                disabled={selectedItems.length === 0}
                style={{ marginTop: "-80px" }}
              >
                Publikovat
              </Button>
              <Button
                onClick={() => changePublishingState(PUBLISH, false)}
                variant="outlined"
                color="primary"
                disabled={selectedItems.length === 0}
                style={{ marginTop: "-80px" }}
              >
                Označit jako neúplný
              </Button>
              <Button
                onClick={() => changePublishingState(DECOMISSION, true)}
                variant="outlined"
                color="primary"
                disabled={selectedItems.length === 0}
                style={{ marginTop: "-80px" }}
              >
                Vyřadit z prodeje
              </Button>
              <Button
                onClick={() => changePublishingState(DECOMISSION, false)}
                variant="outlined"
                color="primary"
                disabled={selectedItems.length === 0}
                style={{ marginTop: "-80px" }}
              >
                Zrušit vyřazení z prodeje
              </Button>
            </>}
          </Can>
        </>
      )}
    </Paper>
  );
};
