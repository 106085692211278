import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';

export const isImageAsset = (asset) => ['image-cover', 'image-carousel'].includes(asset.type);
export const isAudioAsset = (asset) => ['sample-audio', 'audio-chapter-master'].includes(asset.type);
export const isFileAsset = (asset) => ['ebook-master-pdf', 'ebook-master-epub', 'ebook-presale-pdf',
  'ebook-presale-epub', 'audio-archive', 'sample-master-pdf', 'sample-master-epub', 'sample-pdf',
  'sample-epub', 'sample-mobi', 'file-attachment', 'audio-playlist'].includes(asset.type);

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'relative',
    width: '800px',
    maxWidth: '80%',
    maxHeight: '80%',
    top: '50%',
    left: '50%',
    transform: `translate(-50%, -50%)`,
    backgroundColor: "#fff",
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  conatinerForImage: {
    height: '600px',
  },
  content: {
    textAlign: 'center',
    marginTop: '40px',
  },
  audio: {
    width: '100%',
  },
  subtitle: {
    marginTop: '10px',
    fontStyle: 'italic',
  },
  imageContent: {
    height: 'calc(100% - 114px)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  image: {
    maxWidth: '100%',
    maxHeight: '100%',
  },
}));

export const AssetPreview = React.forwardRef(({
  asset,
}) => {
  const classes = useStyles();

  const getTitle = () => {
    if (isImageAsset(asset)) return 'Náhled souboru';
    if (isAudioAsset(asset)) return 'Přehrávání souboru';
    if (isFileAsset(asset)) return 'Stažení souboru';
    return 'Neznámý typ souboru';
  };

  const getPreview = () => {
    if (isImageAsset(asset)) return (
      <div className={[classes.content, classes.imageContent].join(' ')}>
        <img className={classes.image} src={asset.storage_url} alt={asset.filename} />
      </div>
    );
    if (isAudioAsset(asset)) return (
      <div className={classes.content}>
        <audio className={classes.audio} id="player" controls>
          <source src={asset.storage_url} type="audio/mp3" />
        </audio>
      </div>
    );
    if (isFileAsset(asset)) return (
      <div className={classes.content}>
        <a href={asset.storage_url}>{asset.filename}</a>
      </div>
    );

    return (
      <div className={classes.content}>
        <p>
          Náhled nelze zobrazit, můžete se pokusit soubor stáhnout:
          {' '}
          <a href={asset.storage_url}>{asset.filename}</a>
        </p>
      </div>
    );
  };

  return (
    <div className={`${classes.container}${isImageAsset(asset) ? ` ${classes.conatinerForImage}` : ''}`}>
      <Typography variant="h4">
        {getTitle()}
      </Typography>
      <Typography className={classes.subtitle} variant="h6">
        {asset.filename}
      </Typography>
      {getPreview()}
    </div>
  );
});

export default AssetPreview;
