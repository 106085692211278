import React, { useState, useEffect } from 'react';
import MaterialTable from 'material-table';
import { Alert, AlertTitle } from '@material-ui/lab';
import { TablePagination, Box } from '@material-ui/core';

import axios from '../utils/axios';
import MTLocalization from '../utils/MTLocalization';
import Loader from '../layout/Loader';

import { ADMIN, EDITOR, PROVIDER, CONSUMER, CREATE, UPDATE, AbilityContext, USER, DELETE } from '../permissions';
import { subject } from '@casl/ability';
import { useAbility } from '@casl/react';
import ServerResponseAlert from './ServerResponseAlert';

const UserTable = () => {
  const ability = useAbility(AbilityContext);
  // user data
  const [userData, setUserData] = useState(null);
  // pagination
  const [currentPage, setCurrentPage] = useState(0);
  const [currentPageSize, setCurrentPageSize] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  // loading
  const [loading, setLoading] = useState(false);
  // data modification response - create, update, delete
  const [dataModificationSuccess, setDataModificationSuccess] = useState(null);
  const [dataModificationReply, setDataModificationReply] = useState(null);
  // data loading error
  const [dataLoadingError, setDataLoadingError] = useState(null);

  const loadUsers = (page = currentPage, pageSize = currentPageSize) => {
    const doFetch = async () => {
      try {
        const result = await axios.get(`user?page=${page + 1}&limit=${pageSize}`);
        setUserData(result.data);
        setTotalCount(result.data.meta.total_number);
      } catch (e) {
        setDataLoadingError(e);
      } finally {
        setLoading(false);
      }
    };

    setDataLoadingError(null);
    setLoading(true);
    doFetch();
  };

  const handlePageChange = (newPage, newPageSize) => {
    setCurrentPage(newPage);
    setCurrentPageSize(newPageSize);
    loadUsers(newPage, newPageSize);
  };

  // run on load only
  useEffect(() => {
    loadUsers(currentPage, currentPageSize);
  }, []);

  // === DATA MODIFICATION ===

  const validateNewUser = (newUserData) => {
    if (!newUserData.username || newUserData.username.length < 4) {
      setDataModificationSuccess(false);
      setDataModificationReply('Uživatelské jméno musí mít více než 4 znaky');
      return false;
    }
    if (!newUserData.password || newUserData.password.length < 6) {
      setDataModificationSuccess(false);
      setDataModificationReply('Heslo musí mít více než 6 znaků');
      return false;
    }
    if ((newUserData.role !== CONSUMER && newUserData.associated_consumer)) {
      setDataModificationSuccess(false);
      setDataModificationReply(`Uživatel v roli ${newUserData.role} nemůže mít přiřazeného consumera`);
      return false;
    }
    if (!newUserData.associated_consumer.match(/^[a-z0-9\-]*$/g)) {
      setDataModificationSuccess(false);
      setDataModificationReply('Přiřazený consumer není validní');
      return false;
    }
    return true;
  };

  const validateUpdateUser = (newUserData) => {
    if (newUserData.password && newUserData.password.length < 6) {
      setDataModificationSuccess(false);
      setDataModificationReply('Heslo musí mít více než 6 znaků');
      return false;
    }
    if (newUserData.role !== CONSUMER && newUserData.associated_consumer) {
      setDataModificationSuccess(false);
      setDataModificationReply(`Uživatel v roli ${newUserData.role} nemůže mít přiřazeného consumera`);
      return false;
    }
    if (!newUserData.associated_consumer.match(/^[a-z0-9\-]*$/g)) {
      setDataModificationSuccess(false);
      setDataModificationReply('Přiřazený consumer není validní');
      return false;
    }
    return true;
  };

  const createUser = async (newUserData) => {
    setLoading(true);
    setDataModificationReply(null);

    const body = {
      username: newUserData.username,
      role: newUserData.role,
      password: newUserData.password,
      associated_consumer: newUserData.associated_consumer || null,
    };

    try {
      await axios.post('/user', JSON.stringify(body));
      setDataModificationSuccess(true);
      setDataModificationReply('V pořádku uloženo');
      setCurrentPage(0);
      loadUsers(0);
    } catch (e) {
      setDataModificationSuccess(false);
      setDataModificationReply(e);
    } finally {
      setLoading(false);
    }
  };

  const updateUser = async (newUserData, oldUserData) => {
    setLoading(true);
    setDataModificationReply(null);

    const body = {
      role: newUserData.role,
      associated_consumer: newUserData.associated_consumer || null,
      password: newUserData.password ? newUserData.password : undefined,
    };

    try {
      await axios.put(`/user/${oldUserData.id}`, JSON.stringify(body));
      setDataModificationSuccess(true);
      setDataModificationReply('V pořádku uloženo');
      loadUsers();
    } catch (e) {
      setDataModificationSuccess(false);
      setDataModificationReply(e);
    } finally {
      setLoading(false);
    }
  };

  const deleteUser = async (userData) => {
    setLoading(true);
    setDataModificationReply(null);

    try {
      await axios.delete(`/user/${userData.id}`);
      setDataModificationSuccess(true);
      setDataModificationReply('V pořádku smazáno');
      loadUsers();
    } catch (e) {
      setDataModificationSuccess(false);
      setDataModificationReply('Chyba při mazání');
    } finally {
      setLoading(false);
    }
  };

  // === RENDER HELPERS ===

  const getColumns = () => {
    const lookupTable = {
      [ADMIN]: 'Admin',
      [EDITOR]: 'Editor',
      [PROVIDER]: 'Provider',
      [CONSUMER]: 'Consumer',
    };

    const allowedRoles = {
      ...(ability.can(CREATE, subject(USER, { role: ADMIN })) && { [ADMIN]: lookupTable[ADMIN] }),
      ...(ability.can(CREATE, subject(USER, { role: EDITOR })) && { [EDITOR]: lookupTable[EDITOR] }),
      ...(ability.can(CREATE, subject(USER, { role: PROVIDER })) && { [PROVIDER]: lookupTable[PROVIDER] }),
      ...(ability.can(CREATE, subject(USER, { role: CONSUMER })) && { [CONSUMER]: lookupTable[CONSUMER] }),
    };
    return [
      { title: 'Uživatel', field: 'username', render: (rowData) => <strong>{rowData.username}</strong>, editable: 'onAdd' },
      { title: 'Heslo', field: 'password', render: () => '---', editable: ability.can(UPDATE, USER, 'password') ? 'always' : 'onAdd' },
      { title: 'Role', field: 'role', lookup: { ...allowedRoles }, initialEditValue: CONSUMER, render: (rowData) => lookupTable[rowData.role] },
      { title: 'Přiřazený consumer', field: 'associated_consumer', initialEditValue: '' },
    ];
  };

  // === RENDER ===

  return (
    <>
      <Box>
        {loading && <Loader />}
        {dataModificationReply && (
          <ServerResponseAlert serverSuccess={dataModificationSuccess} serverReply={dataModificationReply} onClose={() => setDataModificationReply(null)} />
        )}
        {dataLoadingError && (
          <Alert severity="error" style={{ marginTop: 20 }}>
            <AlertTitle>Chyba při načítání dat.</AlertTitle>
            {dataLoadingError.message}
          </Alert>
        )}
      </Box>
      {userData && (
        <MaterialTable
          key={currentPageSize} // workaround for not functioning page size change
          localization={MTLocalization}
          options={{ pageSize: currentPageSize, actionsColumnIndex: -1, addRowPosition: 'first', search: false, showTitle: false }}
          style={{ margin: 0, boxShadow: 'none' }}
          columns={getColumns()}
          components={{
            Pagination: (props) => (
              <TablePagination
                {...props}
                rowsPerPageOptions={[5, 10, 20, 30]}
                rowsPerPage={currentPageSize}
                count={totalCount}
                page={currentPage}
                onChangePage={(e, page) => handlePageChange(page, currentPageSize)}
                onChangeRowsPerPage={(e) => handlePageChange(0, e.target.value)}
              />
            ),
          }}
          data={userData.data.map(item => ({ ...item, associated_consumer: item.associated_consumer || ''}))}
          editable={{
            isEditable: (rowData) => ability.can(UPDATE, subject(USER, { role: rowData.role })),
            isDeletable: (rowData) => ability.can(DELETE, subject(USER, { role: rowData.role })),
            ...(ability.can(CREATE, USER) && {
              onRowAdd: (newData) =>
                new Promise((resolve, reject) => {
                  const validationResult = validateNewUser(newData);
                  if (!validationResult) {
                    reject(dataModificationReply);
                  } else {
                    const saveResponse = createUser(newData);
                    if (saveResponse) {
                      resolve(saveResponse);
                    } else {
                      reject(dataModificationReply);
                    }
                  }
                }),
            }),
            onRowUpdate: (newData, oldData) =>
              new Promise((resolve, reject) => {
                const validationResult = validateUpdateUser(newData);
                if (!validationResult) {
                  reject(dataModificationReply);
                } else {
                  const saveResponse = updateUser(newData, oldData);
                  if (saveResponse) {
                    resolve(saveResponse);
                  } else {
                    reject(dataModificationReply);
                  }
                }
              }),
            onRowDelete: (oldData) =>
              new Promise((resolve, reject) => {
                const deleteResponse = deleteUser(oldData);
                if (deleteResponse) {
                  resolve(deleteResponse);
                } else {
                  reject(dataModificationReply);
                }
              }),
          }}
        />
      )}
    </>
  );
};

export default UserTable;
