import React from 'react';
import { TextField } from '@material-ui/core';
import { useAdvancedSearchFilterStyles } from '../styles/advanced-search';

export const INITIAL_FILTER_VALUE = {
  product_name: '',
  order_number: '',
};

export const LABELS = {
  product_name: 'Produkt',
  order_number: 'Číslo objednávky',
};

const DistributionSearchFilter = ({ filter, onFilterChange }) => {
  const classes = useAdvancedSearchFilterStyles();

  const setFilterValue = (e) => {
    e.persist();
    onFilterChange({
      ...filter,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <div className={classes.formLine}>
      <TextField className={classes.searchInput} name="product_name" label={LABELS.product_name} value={filter.product_name} onChange={setFilterValue} />
      <TextField className={classes.searchInput} name="order_number" label={LABELS.order_number} value={filter.order_number} onChange={setFilterValue} />
    </div>
  );
};

export default DistributionSearchFilter;
