import { makeStyles } from "@material-ui/core";
import React from "react";
import * as jsPlumbBrowserUI from '@jsplumb/browser-ui';
import { DotEndpoint, Connectors } from '@jsplumb/core';
import { BezierConnector } from '@jsplumb/connector-bezier';
import MultitreeElement from './MultitreeElement';

const standardEndpointStyle = {
  fill: '#fff',
  stroke: '#555',
  strokeWidth: 2,
};

const standardEndpointHoverStyle = {
  fill: '#fff',
  stroke: '#B9012B',
};

const emptyTargetEndpointStyle = {
  fill: '#fff',
  stroke: '#999',
  strokeWidth: 2,
};

const emptyTargetEndpointHoverStyle = {
  fill: '#fff',
  stroke: '#999',
};

const connectorStyle = {
  stroke: '#555',
  strokeWidth: 1,
  outlineStroke: 'transparent',
  outlineWidth: 2,
  dashstyle: '6 3',
};

const connectorActiveStyle = {
  stroke: '#B9012B',
  strokeWidth: 2,
  outlineStroke: 'transparent',
  outlineWidth: 2,
};

const connectorHoverStyle = {
  stroke: '#B9012B',
  dashstyle: 'none',
};

const DELETE_OVERLAY = 'DELETE';

const useStyles = makeStyles({
  resultContainer: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'space-between',
    overflow: 'hidden',
  },
  resultColumn: {
    position: 'relative',
    width: '40%',
    maxHeight: '500px',
    overflowY: 'auto',
  },
  resultColumnLeft: {
    paddingRight: '10px',
  },
  treeNodeContainer: {
    border: 'solid #ddd',
    borderWidth: '0 0 1px 0',
  },
  treeBranchContainer: {
    position: 'relative',
  },
  treeBranchContainerHidden: {
    position: 'absolute',
    width: '100%',
    top: 0,
    left: 0,
    zIndex: -1,
  },
});

const MultitreeMappingTable = ({
  sourceTreeData,
  targetTreeData,
  onCreateMappping,
  onDeleteMapping,
}) => {
  const classes = useStyles();
  const elementsContainer = React.useRef();

  const [jsplumb, setJsplumb] = React.useState(null);

  const [scrollTimeout, setScrollTimeout] = React.useState(null);

  const [collapsedNodes, setCollapsedNodes] = React.useState([]);
  const [hiddenNodes, setHiddenNodes] = React.useState([]);

  const [references, setReferences] = React.useState({});
  const [endpoints, setEndpoints] = React.useState({});

  const handleRegisterReference = (name, ref) => {
    setReferences((prevState) => ({
      ...prevState,
      [name]: ref,
    }));
  };

  const handleRegisterEndpoint = (name, ref) => {
    setEndpoints((prevState) => ({
      ...prevState,
      [name]: ref,
    }));
  };

  // ========== JS PLUMB HELPERS ==========

  const setConnectionActive = (connection) => {
    connection.showOverlay(DELETE_OVERLAY);
    connection.setPaintStyle(connectorActiveStyle);
    //connection.instance.paintConnection(connection);
  };

  const setConnectionInactive = (connection) => {
    connection.hideOverlay(DELETE_OVERLAY);
    connection.setPaintStyle(connectorStyle);
    //connection.instance.paintConnection(connection);
  };

  const setEndpointStandard = (endpoint) => {
    endpoint.setPaintStyle(standardEndpointStyle);
    endpoint.setHoverPaintStyle(standardEndpointHoverStyle);
    //endpoint.instance.paintEndpoint(endpoint);
  };

  const setEndpointEmptyTarget = (endpoint) => {
    endpoint.setPaintStyle(emptyTargetEndpointStyle);
    endpoint.setHoverPaintStyle(emptyTargetEndpointHoverStyle);
    //endpoint.instance.paintEndpoint(endpoint);
  };

  const handleConnectionClick = (connection) => {
    connection.instance.connections.forEach(item => {
      if (item.id !== connection.id) {
        setConnectionInactive(item);
      }
    });
    setConnectionActive(connection);
  };

  const handleConnectionCreate = (params) => {
    setEndpointStandard(params.targetEndpoint);
  };

  const handleConnectionDetach = (params) => {
    if (params.targetEndpoint.connections.length === 1) {
      setEndpointEmptyTarget(params.targetEndpoint);
    }
  };

  const interceptConnectionDragStart = (params) => {
    return params.endpoint.isSource;
  };

  const interceptConnectionCreate = (params) => {
    if (params.dropEndpoint.connections.some(connection => connection.sourceId === params.sourceId)) {
      return false; // such connection already exists
    } else {
      onCreateMappping(params.sourceId, params.targetId);
      return true;
    }
  };

  const repaintConnections = () => {
    Object.keys(references).forEach(id => jsplumb.revalidate(references[id].current));
  };

  const hideAllConnections = () => {
    jsplumb.getConnections().forEach(c => {
      c.setVisible(false);
    });
  };

  const hideAllEndpoints = () => {
    jsplumb.selectEndpoints().entries.forEach(e => {
      e.setVisible(false, true);
    });
  };

  const processConnectionsVisibility = () => {
    jsplumb.getConnections().forEach(c => {
      c.setVisible(!hiddenNodes.includes(c.sourceId) && !hiddenNodes.includes(c.targetId));
      c.hideOverlay(DELETE_OVERLAY);
    });
  };

  const processEndpointsVisibility = () => {
    jsplumb.selectEndpoints().entries.forEach(e => {
      e.setVisible(!hiddenNodes.includes(e.elementId), true);
    });
  };

  // ========== SCROLLING ==========

  const handleScrollEnd = () => {
    repaintConnections();
    processConnectionsVisibility();
    processEndpointsVisibility();
    setScrollTimeout(null);
  };

  const handleScroll = () => {
    setScrollTimeout(prevValue => {
      if (!prevValue) {
        hideAllConnections();
        hideAllEndpoints();
        return setTimeout(() => handleScrollEnd(), 400);
      } else {
        clearTimeout(prevValue);
        return setTimeout(() => handleScrollEnd(), 400);
      }
    });
  };

  // ========== BRANCH HIDING LOGIC ==========

  const getHiddenNodesFromTree = (roots) => {
    const hidden = [];

    const markSelfAndAllChildrenAsHidden = (node) => {
      hidden.push(node.id);
      node.children.forEach(child => markSelfAndAllChildrenAsHidden(child));
    };

    const processSelf = (node) => {
      if (collapsedNodes.includes(node.id)) {
        node.children.forEach(child => markSelfAndAllChildrenAsHidden(child));
      } else {
        node.children.forEach(child => processSelf(child));
      }
    }

    roots.forEach(root => processSelf(root));

    return hidden;
  };

  const handleTriggerChildren = (node, showValue) => {
    setCollapsedNodes(prevValue => {
      if (showValue) {
        return prevValue.filter(itemId => itemId !== node.id);
      } else {
        return [...prevValue, node.id];
      }
    });
  };

  // ========== RENDERING HELPERS ==========

  const nodeCompareTitle = (a, b) => a.title < b.title ? -1 : a.title > b.title;
  const nodeCompareOrder = (a, b) => a.level_order - b.level_order;

  const renderTreeNode = (node, level, right = false) => {
    const showChildren = !collapsedNodes.includes(node.id);
    const hidden = hiddenNodes.includes(node.id);

    return (
      <div key={node.id} className={`${classes.treeBranchContainer}${hidden ? ` ${classes.treeBranchContainerHidden}` : '' }`}>
        <div className={classes.treeNodeContainer}>
          <MultitreeElement
            node={node}
            level={level}
            register={handleRegisterReference}
            hasChildren={node.children.length > 0}
            showChildren={showChildren}
            onTriggerChildren={(showValue) => handleTriggerChildren(node, showValue)}
            right={right}
          />
        </div>
        {node.children
          .sort(nodeCompareTitle)
          .sort(nodeCompareOrder)
          .map(child => renderTreeNode(
            child,
            level + 1,
            right,
          ))
        }
      </div>
    );
  };

  // ========== EFFECTS ==========

  // when container for jsPlumb element is loaded => load jsPlumb instance
  React.useEffect(() => {
    if (elementsContainer && elementsContainer.current !== null) {
      const defaultOptions = {
        endpoint: {
          type: DotEndpoint.type,
          options: {
            radius: 6,
          },
        },
        endpointStyle: standardEndpointStyle,
        endpointHoverStyle: standardEndpointHoverStyle,
        connector: {
          type: BezierConnector.type,
          options: {
            curvinnes: 50,
          },
        },
        paintStyle: connectorStyle,
        hoverPaintStyle: connectorHoverStyle,
        anchors: ['Right', 'Left'],
        maxConnections: -1,
        connectionsDetachable: false,
        connectionOverlays: [{
          type: 'Custom',
          options: {
            create: (x) => {
              let overlay = document.createElement('div');
              overlay.innerHTML = '<svg class="MuiSvgIcon-root MuiSvgIcon-colorPrimary MuiSvgIcon-fontSizeSmall" focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z"></path></svg>';
              overlay.style.marginTop = '-10px';
              overlay.style.display = 'none';
              overlay.style.zIndex = '100';
              overlay.style.cursor = 'pointer';
              return overlay;
            },
            events: {
              click: (e) => {
                e.e.stopPropagation()
                onDeleteMapping(
                  e.overlay.component.sourceId,
                  e.overlay.component.targetId,
                  () => {
                    e.overlay.instance.deleteConnection(e.overlay.component)
                  }
                );
              },
            },
            id: DELETE_OVERLAY,
            visible: false,
            location: -15,
          },
        }],
      };

      const instance = jsPlumbBrowserUI.newInstance({
        container: elementsContainer.current,
      });
      Connectors.register(BezierConnector.type, BezierConnector);

      instance.importDefaults(defaultOptions);

      instance.bind('beforeDrag', interceptConnectionDragStart);
      instance.bind('beforeDrop', interceptConnectionCreate);
      instance.bind('connection', handleConnectionCreate);
      instance.bind('connection:detach', handleConnectionDetach);
      instance.bind('connection:click', handleConnectionClick);

      setJsplumb(instance);
    }
  }, [elementsContainer]);

  // when jsPlumb instance is ready => prepare handler for connection deactivation
  React.useEffect(() => {
    if (jsplumb) {
      const handler = (e) => {
        if (jsplumb && !e.target.matches('.jtk-connector path')) {
          jsplumb.connections.forEach(connection => setConnectionInactive(connection));
        }
      };

      document.addEventListener('click', handler);
      return () => document.removeEventListener('click', handler);
    }
  }, [jsplumb]);

  // after references for all tree nodes are registered => create endpoints
  React.useEffect(() => {
    if (sourceTreeData && targetTreeData
      && Object.keys(references).length === sourceTreeData.branches.length + targetTreeData.branches.length
    ) {
      sourceTreeData.branches.forEach(branch => {
        const endpoint = jsplumb.addEndpoint(references[branch.id].current, {
          anchor: 'Right',
          source: true,
          target: false,
        });
        handleRegisterEndpoint(branch.id, endpoint);
      });

      targetTreeData.branches.forEach(branch => {
        const endpoint = jsplumb.addEndpoint(references[branch.id].current, {
          anchor: 'Left',
          source: false,
          target: true,
          paintStyle: emptyTargetEndpointStyle,
          hoverPaintStyle: emptyTargetEndpointHoverStyle,
        });
        handleRegisterEndpoint(branch.id, endpoint);
      });
    }
  }, [Object.keys(references).length]);

  // after endpoints are created => create connections
  React.useEffect(() => {
    if (sourceTreeData && targetTreeData
      && Object.keys(endpoints).length === sourceTreeData.branches.length + targetTreeData.branches.length
    ) {
      sourceTreeData.branches.forEach(branch => {
        branch.mappings.forEach(mapping => {
          const connection =jsplumb.connect({
            source: endpoints[branch.id],
            target: endpoints[mapping.id],
          });
        });
      });
    }
  }, [Object.keys(endpoints).length]);

  // react to node collapse
  React.useEffect(() => {
    setHiddenNodes([
      ...getHiddenNodesFromTree(sourceTreeData.branchTree),
      ...getHiddenNodesFromTree(targetTreeData.branchTree),
    ]);
  }, [collapsedNodes.length]);

  React.useEffect(() => {
    if (jsplumb) {
      repaintConnections();
      processConnectionsVisibility();
      processEndpointsVisibility();
    }
  }, [JSON.stringify(hiddenNodes)]);

  // ========== RENDER ==========

  return (
    <div className={classes.resultContainer} ref={elementsContainer}>
      <div className={[classes.resultColumn, classes.resultColumnLeft].join(' ')} onScroll={handleScroll}>
        {sourceTreeData && (
          sourceTreeData.branchTree
            .sort(nodeCompareTitle)
            .sort(nodeCompareOrder)
            .map(root => renderTreeNode(root, 0))
        )}
      </div>
      <div className={classes.resultColumn} onScroll={handleScroll}>
        {targetTreeData && (
          targetTreeData.branchTree
            .sort(nodeCompareTitle)
            .sort(nodeCompareOrder)
            .map(root => renderTreeNode(root, 0, true))
        )}
      </div>
    </div>
  );
};

export default MultitreeMappingTable;
