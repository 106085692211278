import { Box, Grid, Paper, Typography, Tabs, Tab, makeStyles, Button } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import { useAbility } from '@casl/react';
import { subject } from '@casl/ability';
import React, { useEffect, useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import DistributionAssets from '../layout/Assets';
import Loader from '../layout/Loader';
import Assets from '../layout/Assets';
import TabPanel from '../layout/TabPanel';
import formatDate from '../utils/formatDate';
import axios, { getConsumerHeader } from '../utils/axios';
import Prices from '../layout/Prices';
import { TYPE_SPECIFIC_ATTR, TYPE_SPEC_NAMES_LOOKUP, CONSUMER_SPECIFIC_ATTR, CONSUMER_SPEC_NAMES_LOOKUP } from '../entities/specificAttributes';
import { Can, UPDATE, PRODUCT, UserContext, CHANGE_VIEW_FOR, ASSOCIATED_CONSUMER, AbilityContext, CONTROL, CHANGE_PUBLISHING_STATE } from '../permissions';
import AutocompleteViewSelect from '../components/AutocompleteViewSelect';
import ChosenValuesTable from '../components/ChosenValuesTable';
import DistributionList from '../components/DitributionList';
import CrosslinkList from '../components/CrosslinkList';
import ProductStateActions from '../components/ProductStateActions';
import ServerResponseAlert from '../components/ServerResponseAlert';
import ProductHeader from '../components/ProductHeader';
import { getCzechTypeName } from '../utils/productTypeCzechName';
import { AssetList } from '../components/AssetList';
import languages from '../entities/languages';

// publishing change types
const PUBLISH = 'publish';
const DECOMISSION = 'decomission';

// tabs
const TAB_ATTRIBUTES = 'attributes';
const TAB_TYPE_SPECIFIC_ATTRIBUTES = 'type-specific-attributes';
const TAB_PRICES = 'prices';
const TAB_ASSETS = 'assets';
const TAB_DISTRIBUTIONS = 'distributions';
const TAB_CROSSLINKS = 'crosslinks';

const useStyles = makeStyles({
  list: {
    padding: 0,
  },
  listItem: {
    listStyle: 'none',
    paddingBottom: '5px',
  },
  line: {
    margin: '0 25px 5px 0',
    borderBottom: '1px solid rgba(230, 230, 230, 1)',
    '& p': {
      margin: '6px 0 7px',
    },
    '&--italic': {
      fontStyle: 'italic',
      margin: '0',
    }
  },
  lineNoneContent: {
    margin: '0 25px 5px 0',
    borderBottom: '1px solid rgba(230, 230, 230, 1)',
    color: 'rgba(0, 0, 0, 0.38)',
    '& p': {
      margin: '6px 0 7px',
    }
  },
  section: {
    margin: '1em 0',
  },
  title: {
    margin: '0',
    color: 'grey',
    fontSize: '.75em',
  },
  fieldValue: {
    paddingRight: '34px',
  },
  table: {
    padding: '16px',
    borderSpacing: '0',
    marginBottom: '0',
    '& td': {
      paddingLeft: '0',
      minWidth: '350px',
    },
    '& p': {
      borderBottom: 'none',
      padding: '6px 0',
    }
  }
});

export default (props) => {
  const classes = useStyles();
  const userInfo = useContext(UserContext);
  const ability = useAbility(AbilityContext);

  const { id } = useParams();

  const [responseData, setResponseData] = useState(null);
  const [loading, setLoading] = useState(null);
  const [error, setError] = useState(null);
  const [consumerViewKey, setConsumerViewKey] = useState(localStorage.getItem('consumer') || null);
  const [treeMappingKey, setTreeMappingKey] = useState(null);

  const loadData = (viewKey = consumerViewKey, mappingKey = treeMappingKey) => {
    const doFetch = async () => {
      setLoading(true);
      try {
        const headers = {
          ...(ability.can(CHANGE_VIEW_FOR, ASSOCIATED_CONSUMER) && viewKey && getConsumerHeader(viewKey) ),
        };
        const query = {
          withBranchPaths: '1',
          ...(mappingKey && { treeMapping: mappingKey }),
        };
        const res = (await axios.get(`product/${id}`, { headers, params: query })).data.data;
        const bundleTitle = res.bundle_id === null ? '-' : await loadBundle(res.bundle_id) ;
        const projectTitle = res.project_id === null ? '-' : await loadProject(res.project_id) ;
        console.log(res);
        setResponseData({
          ...res,
          bundle_title: bundleTitle,
          project_title: projectTitle,
        });
      } catch (e) {
        setError(e);
      } finally {
        setLoading(false);
      }
    };
    setError(false);
    doFetch();
  };

  useEffect(() => {
    loadData();
  }, []);

  const loadBundle = async (id) => {
    try {
      const res = await axios.get(`product-bundle/${id}`);
      console.log(res.data.data.title);
      return res.data.data.title;
    } catch (e) {
      setError(e);
    }
  };

  const loadProject = async (id) => {
    try {
      const res = await axios.get(`project/${id}`);
      console.log(res.data.data.name);
      return res.data.data.name;
    } catch (e) {
      setError(e);
    }
  };


  const handleConsumerViewChange = (key) => {
    setConsumerViewKey(key);
    loadData(key);
    localStorage.setItem('consumer', key || '');
  };

  const handleTreeMappingChange = (key) => {
    setTreeMappingKey(key);
    loadData(consumerViewKey, key);
  };

  const [tabValue, setTabValue] = React.useState(TAB_ATTRIBUTES);
  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const toggleProductPublishingState = (changeType, value) => {
    const submitData = async (value) => {
      let endpoint = '';
      if (changeType === PUBLISH) {
        endpoint = value ? 'publish' : 'unpublish';
      } else if (changeType === DECOMISSION) {
        endpoint = value ? 'decomission' : 'revert-decomission';
      }

      try {
        const response = await axios.post(`/product/${id}/${endpoint}`);
        const responseJson = response.data.data;

        setResponseData({
          ...responseData,
          is_publishable: responseJson.is_publishable,
          is_decomissioned: responseJson.is_decomissioned,
        });
        setError(null);
      } catch (err) {
        console.log('Chyba při ukládání. ' + err);
        setError(err);
      }
    };
    submitData(value);
  };

  // --- helper render functions ---

  const areTypeSpecificAttributesPresent = (responseData) => TYPE_SPECIFIC_ATTR.some((item) => responseData[`spec_${item}`]);
  const areConsumerSpecificAttributesPresent = (responseData) =>
    Object.keys(responseData).some((item) => item.startsWith('spec_') && !TYPE_SPECIFIC_ATTR.some((attr) => `spec_${attr}` === item));
  const getNonstandardConsumerSpecificAttributes = (responseData) =>
    Object.keys(responseData).filter(
      (item) =>
        item.startsWith('spec_') && // get spec attributes
        !TYPE_SPECIFIC_ATTR.some((attr) => `spec_${attr}` === item) && // that are not type specific
        !CONSUMER_SPECIFIC_ATTR.some((attr) => `spec_${attr}` === item)
    ); // nor are they standard consumer specific

  const getLanguage = (code) => languages.find(item => item.code === code)?.name || code;

  const isValueNotEmpty = (value) => value !== null && value !== undefined && value !== '';

  const getField = (fieldTitle, sourceName, displayFunction = (arg) => arg) => (
    <div className={isValueNotEmpty(responseData[sourceName]) ? classes.line : classes.lineNoneContent }>
      <p className={classes.title}>{fieldTitle}</p>
      <p className={classes.fieldValue}>
        {isValueNotEmpty(responseData[sourceName]) ? displayFunction(responseData[sourceName]) : '-'}
      </p>
    </div>
  );

  // --- rendering ---

  return (
    <>
      <Can I={CHANGE_VIEW_FOR} an={ASSOCIATED_CONSUMER}>
        <AutocompleteViewSelect
          inputLabel="Consumer"
          baseUrl="/consumer"
          identityAttribute="key"
          onChange={handleConsumerViewChange}
          defaultValue={consumerViewKey}
        />
      </Can>
      <AutocompleteViewSelect
          label="Mapovat větve na"
          buttonlabel="Mapovat"
          inputLabel="Strom"
          baseUrl="/tree"
          identityAttribute="key"
          onChange={handleTreeMappingChange}
          defaultValue={treeMappingKey}
        />
      <Paper className="basePaper">
        <Box mb={1} mt={1}>
          {loading && <Loader />}
          {error && (
            <ServerResponseAlert
              serverSuccess={false}
              serverReply={error}
            />
          )}
        </Box>

        {!loading && responseData && (
          <>
            <Grid container direction="column" justify="space-between">
              <Grid item xs={12} md={12}>
                <ProductHeader
                  title={responseData.title}
                  type={responseData.type}
                  subtype={responseData.subtype}
                  cover={responseData.assets.find(item => item.type === 'image-cover')}
                  isPublishable={responseData.is_publishable}
                  isDecomissioned={responseData.is_decomissioned}
                  actions={
                    <>
                      <Grid item container direction="row" justify="flex-end">
                        <Grid item>
                          <Can I={CHANGE_PUBLISHING_STATE} a={subject(PRODUCT, responseData)}>
                            {() => (
                              <ProductStateActions
                                publishable={responseData.is_publishable}
                                decomissioned={responseData.is_decomissioned}
                                onPublishToggle={(value) => toggleProductPublishingState(PUBLISH, value)}
                                onDecomissionToggle={(value) => toggleProductPublishingState(DECOMISSION, value)}
                              />
                            )}
                          </Can>
                        </Grid>
                      </Grid>
                      <Grid item container direction="row" justify="flex-end">
                        <Grid item>
                          <Can I={UPDATE} a={subject(PRODUCT, responseData)}>
                            {() => (
                              <Button href={`/product/edit/${id}`} variant="outlined" color="primary">
                                Upravit produkt
                              </Button>
                            )}
                          </Can>
                        </Grid>
                        <Grid item>
                          <Can I={CONTROL} a={subject(PRODUCT, responseData)} field="consumer-attribute">
                            {() => (
                              <Button href={`/product/consumer-attribute/${id}`} variant="outlined" color="primary">
                                Spravovat consumer atributy
                              </Button>
                            )}
                          </Can>
                        </Grid>
                      </Grid>
                    </>
                  }
                />
              </Grid>
              <Grid item container direction="row">
                <Grid item xs={12} sm={6}>
                  {getField('Název', 'title')}
                </Grid>
                <Grid item xs={12} sm={3}>
                  {getField('Typ', 'type')}
                </Grid>
              </Grid>
              <Grid item container direction="row">
                <Grid item xs={12} sm={6} md={3}>
                  {getField('ISBN', 'isbn')}
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  {getField('EAN', 'ean')}
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  {getField('Datum prvního vydání', 'first_publish_date', arg => formatDate(arg))}
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  {getField('Datum vydání', 'publish_date', arg => formatDate(arg))}
                </Grid>
              </Grid>
              <Grid item container direction="row">
                <Grid item xs={12} sm={6}>
                  {getField('Podtitulek', 'subtitle')}
                </Grid>
                <Grid item xs={12} sm={6}>
                  {getField('Originální název', 'original_title')}
                </Grid>
              </Grid>
              <Grid item container direction="row">
                <Grid item xs={12} sm={6}>
                  {getField('Projekt', 'project_id')}
                </Grid>
                <Grid item xs={12} sm={6}>
                  {getField('Svazek', 'bundle_title')}
                </Grid>
              </Grid>
              <Grid item container direction="row">
                <Grid item xs={12} sm={6}>
                  {getField('Vydavatel', 'publisher', arg => arg.name)}
                </Grid>
                <Grid item xs={12} sm={6}>
                  {getField('Značka', 'brand', arg => arg.name)}
                </Grid>
              </Grid>
              <Grid item container direction="row">
                  {getField('Slogan', 'summary')}
              </Grid>
              <Grid item container direction="row">
                  {getField('Anotace', 'description')}
              </Grid>
              <Grid item container direction="row">
                <Grid item xs={12} sm={6} md={3}>
                  {getField('Věkový limit', 'age_limit')}
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  {getField('Jazyk', 'language', arg => getLanguage(arg))}
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  {getField('Jazyk originálu', 'original_language', arg => getLanguage(arg))}
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  {getField('Region', 'distribution_region')}
                </Grid>
              </Grid>
              <Grid item container direction="row">
                <Grid item xs={12} md={3}>
                    {getField('Distribuce od', 'distributable_from', arg => formatDate(arg))}
                  </Grid>
                  <Grid item xs={12} md={3}>
                    {getField('Distribuce do', 'distributable_until', arg => formatDate(arg))}
                  </Grid>
                  <Grid item xs={12} md={3}>
                    {getField('Dostupnost skladem', 'stock_availability', arg => arg ? 'ano' : 'ne')}
                  </Grid>
                  <Grid item xs={12} md={3}>
                    {getField('Priorita', 'priority')}
                  </Grid>
              </Grid>
              <Grid item container direction="row">
                <Grid item xs={12} sm={6}>
                  {getField('Externí klíč', 'external_reference_key')}
                </Grid>
                <Grid item xs={12} sm={6}>
                  {getField('Video ukázka', 'video_sample_url')}
                </Grid>
                
              </Grid>
              <Grid item container direction="row">
                <Grid item xs={12} sm={6}>
                  {getField('Vytvořeno', 'create_date', arg => formatDate(arg))}
                </Grid>
                <Grid item xs={12} sm={6}>
                  {getField('Naposledy upraveno', 'modify_date', arg => formatDate(arg))}
                </Grid>
              </Grid>
              <Grid item container direction="row">
              </Grid>
              <Grid item container direction="row">
                <Grid item xs={12} sm={3}>
                  {getField('Hodnocení', 'review_score')}
                </Grid>
                <Grid item xs={12} sm={3}>
                  {getField('Počet hodnotitelů', 'review_total_voters')}
                </Grid>
                <Grid item xs={12} sm={6}>
                  {getField('CDI ID', 'id')}
                </Grid>
              </Grid>
              <Grid item container direction="row">
                <Grid item xs={12} sm={3}>
                  {getField('Předprodej od', 'presale_valid_from', arg => formatDate(arg))}
                </Grid>
                <Grid item xs={12} sm={3}>
                  {getField('Předprodej do', 'presale_valid_until', arg => formatDate(arg))}
                </Grid>
                <Grid item xs={12} sm={3}>
                  {getField('External provision', 'external_provision', arg => arg ? 'Ano': 'Ne')}
                </Grid>
              </Grid>

            </Grid>
            <Grid item xs={12} className="fullWidth">
              <Box mt={8} className="productTabs">
                <Tabs value={tabValue} onChange={handleChange} variant="scrollable" scrollButtons="auto" indicatorColor="primary" textColor="primary">
                  <Tab label="Atributy" value={TAB_ATTRIBUTES} />
                  {responseData.type &&
                    <Tab label={`Atributy pro ${getCzechTypeName(responseData.type)}`} value={TAB_TYPE_SPECIFIC_ATTRIBUTES} />
                  }
                  <Tab label="Ceny" value={TAB_PRICES} />
                  <Tab label="Soubory" value={TAB_ASSETS} />
                  <Tab label="Distribuce" value={TAB_DISTRIBUTIONS} />
                  <Tab label="Křížové odkazy" value={TAB_CROSSLINKS} />
                </Tabs>
              </Box>
              <TabPanel value={tabValue} index={TAB_ATTRIBUTES} className="productAttributes">
                <Grid container direction="column" justify="space-between">
                  <Grid item xs={12} md={6} className={classes.table}>
                    <ChosenValuesTable
                      description={[
                        { property: 'name', caption: 'Autor' },
                        { property: 'role', caption: 'Role' },
                      ]}
                      values={responseData.authors}
                      readonly
                    />
                    {responseData.authors.length === 0 && (
                      <p className={`${classes.line}--italic`}>Produkt nemá žádné autory</p>
                    )}
                  </Grid>
                  <Grid item xs={12} md={6} className={classes.table}>
                    <ChosenValuesTable
                      description={[
                        { property: 'title', caption: 'Série' },
                        { property: 'order_in_serie', caption: 'Pořadí' },
                      ]}
                      values={responseData.series}
                      readonly
                    />
                    {responseData.series.length === 0 && (
                      <p className={`${classes.line}--italic`}>Produkt nemá žádné série</p>
                    )}
                  </Grid>
                  <Grid item xs={4} md={4} className={classes.table}>
                    {responseData.genres && (
                      <>
                        <Typography variant="h6">Žánry</Typography>
                        <>
                          {responseData.genres.map((item, index) => (
                            <p className={classes.line} key={item.id}>
                              {item.title}
                            </p>
                          ))}
                          {responseData.genres.length === 0 && (
                            <p className={`${classes.line}--italic`}>Produkt nemá žádné žánry</p>
                          )}
                        </>
                      </>
                    )}
                  </Grid>
                  <Grid item xs={4} md={4} className={classes.table}>
                    {responseData.editions && (
                      <>
                        <Typography variant="h6">Edice</Typography>
                        <>
                          {responseData.editions.map((item, index) => (
                            <p className={classes.line} key={item.id}>
                              {item.title}
                            </p>
                          ))}
                          {responseData.editions.length === 0 && (
                            <p className={`${classes.line}--italic`}>Produkt nemá žádné edice</p>
                          )}
                        </>
                      </>
                    )}
                  </Grid>
                  <Grid item xs={4} md={4} className={classes.table}>
                    {responseData.categories && (
                      <>
                        <Typography variant="h6">Kategorie</Typography>
                        <>
                          {responseData.categories.map((item, index) => (
                            <p className={classes.line} key={item.id}>
                              {item.title}
                            </p>
                          ))}
                          {responseData.categories.length === 0 && (
                            <p className={`${classes.line}--italic`}>Produkt nemá žádné kategorie</p>
                          )}
                        </>
                      </>
                    )}
                  </Grid>
                  <Grid item xs={4} md={4} className={classes.table}>
                    {responseData.tree_branches && (
                      <>
                        <ChosenValuesTable
                          description={[
                            { property: 'tree_key', caption: 'Strom' },
                            { property: 'path', caption: 'Větev', transformation: (path) => (path || []).join('/') },
                          ]}
                          values={responseData.tree_branches}
                          readonly
                        />
                        {responseData.tree_branches.length === 0 && (
                          <p className={`${classes.line}--italic`}>Produkt nemá žádné větve</p>
                        )}
                      </>
                    )}
                  </Grid>
                  <Grid item xs={4} md={4} className={classes.table}>
                    {responseData.tags && (
                      <>
                        <ChosenValuesTable
                          description={[
                            { property: 'name', caption: 'Tag' },
                            { property: 'valid_from', caption: 'Platnost od', transformation: (date) => (date ? (new Date(date)).toLocaleString() : 'bez omezení') },
                            { property: 'valid_until', caption: 'Platnost do', transformation: (date) => (date ? (new Date(date)).toLocaleString() : 'bez omezení') },
                          ]}
                          values={responseData.tags}
                          readonly
                        />
                        {responseData.tags.length === 0 && (
                          <p className={`${classes.line}--italic`}>Produkt nemá žádné tagy</p>
                        )}
                      </>
                    )}
                  </Grid>
                </Grid>
              </TabPanel>
              {responseData.type &&
                <TabPanel value={tabValue} index={TAB_TYPE_SPECIFIC_ATTRIBUTES} className='productAttributes'>
                <Grid container direction="row">
                  {TYPE_SPECIFIC_ATTR.map((attr, idx) => {
                    const attrName = `spec_${attr}`;
                    if (responseData[attrName]) {
                      return (
                        <Grid item xs={12} sm={6} key={idx}>
                          <div className={classes.line}>
                            <p className={classes.title}>
                              {TYPE_SPEC_NAMES_LOOKUP[attr]}
                            </p>
                            <p>
                              {responseData[attrName]}
                            </p>
                          </div>
                        </Grid>
                      );
                    }
                  })}
                </Grid>
                {(userInfo.associated_consumer || (ability.can(CHANGE_VIEW_FOR, ASSOCIATED_CONSUMER) && consumerViewKey)) && (
                  <>
                    {areConsumerSpecificAttributesPresent(responseData) && <Typography variant="caption">{`"Consumer" Atributy`}</Typography>}
                    <ul className={classes.list}>
                      {CONSUMER_SPECIFIC_ATTR.map((attr, idx) => {
                        const attrName = `spec_${attr}`;
                        if (responseData[attrName]) {
                          return (
                            <li className={classes.listItem} key={idx}>
                              <strong>{CONSUMER_SPEC_NAMES_LOOKUP[attr]}: </strong>
                              {responseData[attrName]}
                            </li>
                          );
                        }
                      })}
                      {getNonstandardConsumerSpecificAttributes(responseData).map((attr, idx) => (
                        <li className={classes.listItem} key={idx}>
                          <strong>{attr.slice('spec_'.length)}: </strong>
                          {responseData[attr]}
                        </li>
                      ))}
                    </ul>
                  </>
                )}
                </TabPanel>
                }
              <TabPanel value={tabValue} index={TAB_PRICES} className="productAttributes">
                <Typography variant="caption" style={{ position: 'relative', marginTop: 0, zIndex: 95555 }}>
                  Ceny
                </Typography>
                {<Prices productId={responseData.id} />}
              </TabPanel>
              <TabPanel value={tabValue} index={TAB_ASSETS}>
                <AssetList productId={responseData.id} readOnly />
              </TabPanel>
              <TabPanel value={tabValue} index={TAB_DISTRIBUTIONS}>
                {responseData.distributions.length === 0 ? (
                  <p className={classes.line}>
                    Tento produkt nemá žádné distribuce
                  </p>
                ) : (
                  <DistributionList distributions={responseData.distributions} />
                )}
              </TabPanel>
              <TabPanel value={tabValue} index={TAB_CROSSLINKS}>
                {Object.keys(responseData.crosslinks).length === 0 ? (
                  <p className={classes.line}>
                    Tento produkt nemá žádné křížové odkazy
                  </p>
                ) : (
                  <CrosslinkList crosslinks={responseData.crosslinks} />
                )}
              </TabPanel>
            </Grid>
          </>
        )}
      </Paper>
    </>
  );
};
