import { Box, Button, Card, CardActions, CardContent, Grid, makeStyles, Paper, Typography } from '@material-ui/core';
import React from 'react';
import InputRoundedIcon from '@material-ui/icons/InputRounded';
import ShopRoundedIcon from '@material-ui/icons/ShopRounded';
import StyleRoundedIcon from '@material-ui/icons/StyleRounded';
import { IMPORT_TOOL, ACCESS, DEDUPLICATION_TOOL, AbilityContext, BUNDLE_TOOL } from '../permissions';
import { useAbility } from '@casl/react';

const useStyles = makeStyles((theme) => ({
  button: {
    marginTop: 0,
  },
}));

const getDashboardData = (ability) => [
  ...(ability.can(ACCESS, IMPORT_TOOL)
    ? [
        {
          name: 'Import',
          description: 'Lorem ipsum fenal bullet abraka a well meaning and kindly.',
          url: '/import-tool',
          icon: <InputRoundedIcon className="greyIcon" />,
        },
      ]
    : []),
  ...(ability.can(ACCESS, BUNDLE_TOOL)
    ? [
        {
          name: 'Svazkování',
          description: 'Lorem ipsum fenal bullet abraka a well meaning and kindly.',
          url: '/bundle-tool',
          icon: <ShopRoundedIcon className="greyIcon" />,
        },
      ]
    : []),
  ...(ability.can(ACCESS, DEDUPLICATION_TOOL)
    ? [
        {
          name: 'Deduplikace',
          description: 'Lorem ipsum fenal bullet abraka a well meaning and kindly.',
          url: '/deduplication',
          icon: <StyleRoundedIcon className="greyIcon" />,
        },
      ]
    : []),
];

export default (props) => {
  const ability = useAbility(AbilityContext);
  const dashboardData = getDashboardData(ability);
  const classes = useStyles();
  return (
    <Paper className="basePaper">
      <Typography variant="h5">Správa dat</Typography>
      <br />
      <Grid container spacing={3}>
        {dashboardData &&
          dashboardData.map((item, index) => (
            <Grid key={index} item xs={12} sm={6} md={3}>
              <Card className="card" variant="outlined">
                <CardContent>
                  <Box mt={1} mb={2} className={classes.icon}>
                    {item.icon}
                  </Box>
                  <Typography variant="h6" className={classes.title} color="textSecondary" gutterBottom>
                    {item.name}
                  </Typography>
                  {/*                   <Typography variant="body2" component="p">
                    {item.description}
                  </Typography> */}
                </CardContent>
                <CardActions>
                  <Button variant="outlined" color="primary" href={item.url} size="small" className={classes.button}>
                    Otevřít
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          ))}
      </Grid>
      <br />
    </Paper>
  );
};
