import React, { useState, useEffect } from 'react';
import { Button, makeStyles, Typography } from '@material-ui/core';
import MaterialTable from 'material-table';
import axios from '../utils/axios';
import ProductSearchFilter, { INITIAL_FILTER_VALUE } from './ProductSearchFilter';

const useStyles = makeStyles({
  headline: {
    marginTop: '50px',
    marginBottom: '10px',
  },
  filter: {
    marginBottom: '30px',
  },
  searchTitle: {
    marginBottom: '10px',
  },
  searchContent: {
    marginLeft: '30px',
    '& .MuiButton-root': {
      margin: '0 !important',
    },
  },
  searchCount: {
    color: 'gray',
    fontSize: '0.8rem',
    marginTop: '10px',
  },
  searchText: {
    color: 'gray',
    fontSize: '1rem',
  },
});

const LIMIT = 20;

const ProductAutocompleteSearch = ({
  alreadySelected,
  onProductAdd,
  onError,
}) => {
  const classes = useStyles();
  const [autocompleteLoading, setAutocompleteLoading] = useState(false);
  const [autocompleteOptions, setAutocompleteOptions] = useState([]);
  const [optionsTotalCount, setOptionsTotalCount] = useState(0);
  const [filter, setFilter] = useState(INITIAL_FILTER_VALUE)

  const handleFilterChange = (value) => {
    setFilter(value);
  };

  const getFilterQuery = () => ({
    ...(filter.title && { title: filter.title }),
    ...(filter.isbn && { isbn: filter.isbn }),
    ...(filter.ean && { ean: filter.ean }),
    ...(filter.external && { external: filter.external }),
    ...(filter.publishable !== 'all' && { publishable: filter.publishable }),
    ...(filter.type !== 'all' && { type: filter.type }),
    ...(filter.author && { author: filter.author }),
    ...(filter.publisher && { publisher: filter.publisher }),
    ...(filter.brand && { brand: filter.brand }),
  });

  const doFetchOptions = async () => {
    try {
      const query = {
        limit: LIMIT,
        ...getFilterQuery(),
      };
      const result = await axios('/product', { params: query });
      setAutocompleteOptions(result.data.data);
      setOptionsTotalCount(result.data.meta.total_number);
    } catch (e) {
      onError(e);
    } finally {
      setAutocompleteLoading(false);
    }
  };

  const handleAddProduct = (value) => {
    onProductAdd(value);
  }

  useEffect(() => {
    setAutocompleteLoading(true);
    setAutocompleteOptions([]);

    const timer = setTimeout(() => {
      doFetchOptions();
    }, 500);

    return () => {
      clearTimeout(timer);
    }
  }, [filter]);

  return (
    <>
      <Typography variant="h6" className={classes.headline}>Přidat produkt:</Typography>
      <div className={classes.filter}>
        <ProductSearchFilter className={classes.filter} filter={filter} onFilterChange={handleFilterChange} />
      </div>
      <div className={classes.search}>
        <Typography className={classes.searchTitle}>Výsledky vyhledávání:</Typography>
        <div className={classes.searchContent}>
          {autocompleteLoading && <Typography className={classes.searchText}>Vyhledávání...</Typography>}
          {!autocompleteLoading && autocompleteOptions.length === 0 && <Typography className={classes.searchText}>Nenalezeno</Typography>}
          {!autocompleteLoading && autocompleteOptions.length > 0 && (
            <MaterialTable
              options={{ paging: false, toolbar: false }}
              style={{ margin: 0, boxShadow: 'none' }}
              columns={[
                { title: 'Název', field: 'title', render: (rowData) => <strong>{rowData.title}</strong> },
                { title: 'Typ', field: 'type' },
                { title: 'Externí kíč', field: 'external_reference_key' },
                {
                  width: 100,
                  align: 'right',
                  title: '',
                  render: (rowData) => (
                    <Button variant="text" size="small" color="primary" className={classes.addButton} 
                      onClick={() => handleAddProduct(rowData)}
                      disabled={alreadySelected.some(item => item.id === rowData.id)}
                    >
                      Přidat
                    </Button>
                  ),
                },
              ]}
              data={autocompleteOptions}
            />
          )}
          {!autocompleteLoading && optionsTotalCount > LIMIT && (
            <Typography className={classes.searchCount}>Zobrazuji {LIMIT} z celkem {optionsTotalCount} výsledků, upřesněte parametry vyhledávání</Typography>
          )}
        </div>
      </div>
    </>
  );
};

export default ProductAutocompleteSearch;
