import { Box, Grid, Paper, Typography } from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import productEntity from "../entities/project";
import ProjectForm from "../forms/Project";
import useFetch from "../hooks/useFetch";
import Loader from "../layout/Loader";
import formatDate4Input from "../utils/formatDate4Input";
import nullToString from "../utils/nullToString";

export default (props) => {
  let { id } = useParams();
  id = id === undefined ? "" : id;
  const newProjectItem = id === "" ? true : false;
  const [projectItem, setBookItem] = useState({ ...productEntity });
  const { response, loading, error } = useFetch(`/project/${id}`);
  useEffect(() => {
    if (response && response.data && response.data.name && newProjectItem === false) {
      let cleanResponse = nullToString(response.data);
      setBookItem(cleanResponse);
    }
  }, [response]);
  return (
    <Paper className="basePaper">
      <Grid container direction="row" justify="space-between">
        <Grid item xs={12}>
          <Typography variant="h5">{newProjectItem === true ? "Nový projekt:" : "Upravit projekt:"} </Typography>

          <Box>
            {loading && <Loader />}
            {error && (
              <Alert severity="error" style={{ marginTop: 20 }}>
                <AlertTitle>Ajajaj!</AlertTitle>
                Chyba při načítání dat.
              </Alert>
            )}
          </Box>
          <ProjectForm key={projectItem.id} data={projectItem} />
        </Grid>
      </Grid>
      <br />
      {/*       <Alert severity="info">
        <AlertTitle>Dev notes:</AlertTitle>
        - BUG: when you hit save twice it will create 2 items. <br />
      </Alert> */}
    </Paper>
  );
};
