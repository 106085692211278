import React from 'react';
import { Tabs, Tab, Paper, Typography } from '@material-ui/core';

export default () => {
  return (
    <Paper className="basePaper">
      <Typography variant="h5">Svazkování</Typography>
      <br />
    </Paper>
  );
};
