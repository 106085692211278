const hierarchize = (list) => {
  // console.log(list);
  // console.log("list.length", list.length);

  // set parents and children aside
  let listWithIndex = list.map((item, index) => ({ ...item, idx: index }));

  let topLevel = listWithIndex.filter((item) => item.parent_id === null);
  let topLevelWithIndent = topLevel.map((item, index) => ({ ...item, indent: 0 }));
  // console.log("topLevelWithIndent.length", topLevelWithIndent.length);
  let children = listWithIndex.filter((item) => item.parent_id !== null);
  let childrenWidthIndent = children.map((item, index) => ({ ...item, indent: 0 }));
  // console.log("childrenWidthIndent.length", childrenWidthIndent.length);

  // attach childrenWidthIndent to their parents
  let family = [...topLevelWithIndent];

  // 2nd level
  let children2 = [...childrenWidthIndent];
  childrenWidthIndent.forEach((item) => {
    item.indent = item.indent + 1;
    // console.log(item);
    const indexOfParent = family.findIndex((item2) => item.parent_id === item2.id);
    if (indexOfParent > -1) {
      // console.log(indexOfParent);
      // console.log("Parent found at position", indexOfParent);
      family.splice(indexOfParent + 1, 0, item);
      let indexOfChild = children2.findIndex((item2) => item.id === item2.id);
      children2.splice(indexOfChild, 1);
      // console.log("children2.length", children2.length);
    } else {
      // console.log("%c Parent not in top level.", "background: #222; color: #bada55");
    }
  });

  // 3rd level
  let children3 = [...children2];
  // console.log("children3.length", children3.length);
  children2.forEach((item) => {
    item.indent = item.indent + 1;
    // console.log(item);
    const indexOfParent = family.findIndex((item2) => item.parent_id === item2.id);
    // console.log(indexOfParent);
    if (indexOfParent > -1) {
      // console.log("Parent found at position", indexOfParent);
      family.splice(indexOfParent + 1, 0, item);
      let indexOfChild = children3.findIndex((item2) => item.id === item2.id);
      children3.splice(indexOfChild, 1);
      // console.log("children3.length", children3.length);
    } else {
      // console.log("%c Parent not in top level.", "background: #222; color: #bada55");
    }
  });

  // console.log(family);
  return family;
};

export default hierarchize;
