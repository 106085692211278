import React from 'react';

export default (props) => {
  // console.log(props);
  let assets = null;
  if (props && props.data && props.data.sort) {
    assets = props.data.sort((a, b) => (a.type < b.type ? 1 : -1));
  }
  return (
    <div className='distributionList'>
      {assets &&
        assets.map((item, index) => {
          // images
          if (item.type === 'image-cover' || item.type === 'image-carousel') {
            return (
              <div className='distributionItem' key={index}>
                <span>{item.filename}</span>
                <br />
                <img width='100%' src={item.storage_url} alt={item.filename} />
              </div>
            );
            // audio files
          } else if (item.type === 'sample-audio' || item.type === 'audio-playlist' || item.type === 'audio-chapter-master') {
            return (
              <div className='distributionItem' key={index}>
                <span>{item.filename}</span>
                <br />
                <audio id='player' controls>
                  <source src={item.storage_url} type='audio/mp3' />
                </audio>
              </div>
            );
            // text and other files
          } else if (
            item.type === 'ebook-master-pdf' ||
            item.type === 'ebook-master-epub' ||
            item.type === 'ebook-presale-pdf' ||
            item.type === 'ebook-presale-epub' ||
            item.type === 'audio-archive' ||
            item.type === 'sample-master-pdf' ||
            item.type === 'sample-master-epub' ||
            item.type === 'sample-pdf' ||
            item.type === 'sample-epub' ||
            item.type === 'sample-mobi' ||
            item.type === 'file-attachment'
          ) {
            return (
              <div className='distributionItem' key={index}>
                <a href={item.storage_url}>{item.filename}</a>
              </div>
            );
          } else {
            return (
              <div className='distributionItem' key={index}>
                <p key={index}>
                  Unknown media type: <br />
                  <a href={item.storage_url}>{item.filename}</a>
                </p>
              </div>
            );
          }
        })}
    </div>
  );
};
