const authorEntity = {
  id: '',
  external_reference_key: '',
  first_name: '',
  middle_name: '',
  last_name: '',
  title_before: '',
  title_after: '',
  pseudonym: '',
  name: '',
  birth_date: '',
  death_date: '',
  website_url: '',
  twitter_url: '',
  facebook_url: '',
  instagram_url: '',
  youtube_url: '',
  summary: '',
  biography: '',
  pictureContainerId: '',
  pictureUrl: '',
  pictureChecksum: '',
  picture_external_reference_key: '',
  is_blacklisted: false,
  is_master: false,
  master_author_id: '',
};
export default authorEntity;
