import MenuBookRoundedIcon from "@material-ui/icons/MenuBookRounded";
import HeadsetRoundedIcon from "@material-ui/icons/HeadsetRounded";
import TabletMacRoundedIcon from "@material-ui/icons/TabletMacRounded";
import { Paper, Typography } from "@material-ui/core";
import MaterialTable from "material-table";
import MTLocalization from "../utils/MTLocalization";
import React from "react";
import {PublicRounded} from "@material-ui/icons";

export default (props) => {
  const [state] = React.useState({
    columns: [
      { title: "Id", field: "id" },
      { title: "Název", field: "name" },
      {
        title: "Ikona",
        field: "icon",
      },
    ],
    data: [
      {
        name: "Audiobook",
        id: "1",
        icon: <HeadsetRoundedIcon style={{ margin: "12px 12px 12px 3px" }} />,
      },
      {
        name: "Ebook",
        id: "2",
        icon: <TabletMacRoundedIcon style={{ margin: "12px 12px 12px 3px" }} />,
      },
      {
        name: "Fyzické zboží",
        id: "3",
        icon: <MenuBookRoundedIcon style={{ margin: "12px 12px 12px 3px" }} />,
      },
      {
        name: "Fyzické zboží neknižní",
        id: "4",
        icon: <PublicRounded style={{ margin: "12px 12px 12px 3px" }} />,
      },
    ],
  });

  return (
    <Paper className="basePaper">
      <Typography variant="h5">Produktové typy</Typography>
      <MaterialTable
        localization={MTLocalization}
        options={{ paging: false, actionsColumnIndex: -1, addRowPosition: "first" }}
        title=""
        style={{ margin: 0, boxShadow: "none" }}
        columns={state.columns}
        data={state.data}
      />
    </Paper>
  );
};
