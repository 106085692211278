const removeExtraFields = (fields, productType) => {
  console.log("productType", productType);
  let newFields = { ...fields };
  if (productType === "audiobook") {
    // delete newFields["spec_length"];

    delete newFields['subtype'];

    delete newFields["spec_total-pages"];
    delete newFields["spec_release-order"];

    delete newFields["spec_ean-mobi"];
    delete newFields["spec_ean-pdf"];
    delete newFields["spec_ean-epub"];
    delete newFields["spec_isbn-mobi"];
    delete newFields["spec_isbn-pdf"];
    delete newFields["spec_isbn-epub"];

    delete newFields["spec_weight"];
    delete newFields["spec_binding-type"];
    delete newFields["spec_dimensions"];
    delete newFields["spec_expedition-date"];
    delete newFields["spec_reprint-date"];
    delete newFields["spec_stock-availability-count"];
    delete newFields["spec_stock-availability-central-count"];
    delete newFields["spec_width"];
    delete newFields["spec_height"];
    delete newFields["spec_depth"];
  } else if (productType === "physicalgood") {
    // delete newFields["spec_total-pages"];
    // delete newFields["spec_release-order"];

    delete newFields["spec_ean-mobi"];
    delete newFields["spec_ean-pdf"];
    delete newFields["spec_ean-epub"];
    delete newFields["spec_isbn-mobi"];
    delete newFields["spec_isbn-pdf"];
    delete newFields["spec_isbn-epub"];

    // delete newFields["spec_weight"];
    // delete newFields["spec_binding-type"];
    // delete newFields["spec_dimensions"];
    // delete newFields["spec_expedition-date"];
    // delete newFields["spec_reprint-date"];
    // delete newFields["spec_stock-availability-count"];
    // delete newFields["spec_stock-availability-central-count"];
  }else if (productType === "physicalgoodsnonbooks") {
    delete newFields["spec_ean-mobi"];
    delete newFields["spec_ean-pdf"];
    delete newFields["spec_ean-epub"];
    delete newFields["spec_isbn-mobi"];
    delete newFields["spec_isbn-pdf"];
    delete newFields["spec_isbn-epub"];
  } else if (productType === "ebook") {
    delete newFields["spec_length"];
    delete newFields["spec_ean-mp3"];
    delete newFields["spec_ean-cd"];

    delete newFields['subtype'];

    // delete newFields["spec_total-pages"];
    // delete newFields["spec_release-order"];

    // delete newFields["spec_ean-mobi"];
    // delete newFields["spec_ean-pdf"];
    // delete newFields["spec_ean-epub"];
    // delete newFields["spec_isbn-mobi"];
    // delete newFields["spec_isbn-pdf"];
    // delete newFields["spec_isbn-epub"];

    delete newFields["spec_weight"];
    delete newFields["spec_binding-type"];
    delete newFields["spec_dimensions"];
    delete newFields["spec_expedition-date"];
    delete newFields["spec_reprint-date"];
    delete newFields["spec_stock-availability-count"];
    delete newFields["spec_stock-availability-central-count"];
    delete newFields["spec_width"];
    delete newFields["spec_height"];
    delete newFields["spec_depth"];
  }
  return newFields;
};
export default removeExtraFields;
