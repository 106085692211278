import React, { useState } from 'react';
import { makeStyles, TextField, InputAdornment, Button } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';

const useStyles = makeStyles({
  form: {
    margin: '30px 0',
  },
  formButtons: {
    margin: '8px 0px -8px 20px',
    display: 'inline-block',
  },
  searchTextInput: {
    width: '250px',
  },
});


const SearchBox = ({
  onFilterSubmit,
  onFilterReset,
}) => {
  const classes = useStyles();
  const [filterText, setFilterText] = useState('');

  const handleFilterRequest = (event) => {
    event.preventDefault();
    onFilterSubmit(filterText);
  }

  const handleFilterRemove = () => {
    setFilterText('');
    onFilterReset();
  }

  return(
    <form className={classes.form} onSubmit={handleFilterRequest}>
      <TextField
        className={classes.searchTextInput}
        name='search-text'
        label="Hledat"
        value={filterText}
        onChange={(e) => {setFilterText(e.target.value)}}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />
      <span className={classes.formButtons}>
        <Button type="submit" variant="outlined" color="primary">
          Hledej
        </Button>
        <Button type="button" variant="outlined" color="primary" onClick={handleFilterRemove}>
          Odebrat filtr
        </Button>
      </span>
    </form>
  );
}

export default SearchBox;
