import React from 'react';

// DATE PICKER INIT IMPORTS
import 'react-dates/initialize';
import { DateRangePicker } from 'react-dates';
import 'moment/locale/cs';
import 'react-dates/lib/css/_datepicker.css';
import { Button } from '@material-ui/core';

const DateRange = ({ onSubmit, ...props }) => {
  return (
    <div className="date-range-container">
      <DateRangePicker {...props} />
      <Button type="button" variant="outlined" color="primary" onClick={onSubmit}>
        Filtruj
      </Button>
    </div>
  );
};

export default DateRange;
