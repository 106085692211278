const MTLocalization = {
  toolbar: {
    searchPlaceholder: "Hledat",
    searchTooltip: "Hledat",
    nRowsSelected: "{0} řádek vybráno",
  },
  pagination: {
    labelDisplayedRows: "{from}-{to} z {count}",
    labelRowsSelect: "záznamů",
    firstAriaLabel: "První stránka",
    firstTooltip: "První stránka",
    previousAriaLabel: "Předchozí stránka",
    previousTooltip: "Předchozí stránka",
    nextAriaLabel: "Další stránka",
    nextTooltip: "Další stránka",
    firstTooltip: "První stránka",
    lastAriaLabel: "Poslední stránka",
    lastTooltip: "Poslední stránka",
  },
  header: {
    actions: "Akce",
  },
  body: {
    editTooltip: "Upravit",
    deleteTooltip: "Smazat",
    emptyDataSourceMessage: "Žádné data",
    editRow: {
      deleteText: "Opravdu si přejete smazat tento záznam?",
      cancelTooltip: "Zrušit",
      saveTooltip: "Uložit",
    },
    filterRow: {
      filterTooltip: "Filtrovat",
    },
  },
};
export default MTLocalization;
