const nullFromString = (object) => {
  const keys = Object.keys(object);
  let newObject = {};
  for (const key of keys) {
    /* if (key === "publisher") {
      newObject[key] = object[key] === object[key];
    } else { */
    newObject[key] = object[key] === "" ? null : object[key];
    // }
  }
  // console.log(newObject);
  return newObject;
};
export default nullFromString;
