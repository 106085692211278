import HeadsetRoundedIcon from "@material-ui/icons/HeadsetRounded";
import TabletMacRoundedIcon from "@material-ui/icons/TabletMacRounded";
import AddCircleOutlineRoundedIcon from "@material-ui/icons/AddCircleOutlineRounded";
import MenuBookRoundedIcon from "@material-ui/icons/MenuBookRounded";
import { Box, Button, Link, Paper, Typography, Radio } from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import MaterialTable from "material-table";
import MTLocalization from "../utils/MTLocalization";
import React, { useEffect, useState, useReducer } from "react";
import useFetch from "../hooks/useFetch";
import Loader from "../layout/Loader";
import asyncForEach from "../utils/asyncForEach";
import axios from "../utils/axios";

export default (props) => {
  const { response, loading, error } = useFetch("/product?limit=2");
  const [state, setState] = React.useState({});

  // -- Authors lookup:
  const [lookupTable, setLookupTable] = React.useState([]);
  const loading2 = lookupTable.length === 0;
  React.useEffect(() => {
    if (!loading2) {
      return undefined;
    }
    (async () => {
      const response = await axios.get("/author");
      console.log(response.data.data);
      setLookupTable(response.data.data);
    })();
  }, [loading2]);

  function reducer(state, action) {
    switch (action.type) {
      case "add":
        return [...state, action.item];
      case "remove":
        const newArray = state.filter((item) => item !== action.item);
        return newArray;
      default:
        throw new Error();
    }
  }
  const [publishedItems, setPublishedItems] = useState([]);

  const publish = (yesOrNo) => {
    const promiseArray = [];
    items2Delete.forEach((item) => {
      promiseArray.push(axios.patch(`/product/${item}`, { is_publishable: yesOrNo }));
    });
    Promise.all([...promiseArray])
      .then((myArray) => {
        const fillUp = async () => {
          const jsons = [];
          await asyncForEach(myArray, async (item) => {
            jsons.push({ ...item.data.data });
          });
          setPublishedItems([...publishedItems, ...jsons]);
          window.location.reload();
        };
        fillUp();
      })
      .catch((err) => {
        console.log(err);
      }); /*  */
  };
  const [items2Delete, setItems2Delete] = useReducer(reducer, []);
  const handleChange = (event) => {
    if (event.target.checked) {
      setItems2Delete({ type: "add", item: event.target.name });
    } else {
      setItems2Delete({ type: "remove", item: event.target.name });
    }
  };

  useEffect(() => {
    if (response && response.status === 200) {
      console.log(response);
      let newData = {
        columns: [
          {
            title: "",
            field: "",
            width: 10,
            render: (rowData) => (
              <>
                <Radio name={`${rowData.id}`} onClick={handleChange} checked={true} inputProps={{ "aria-label": "uncontrolled-radio" }} />
                <Radio name={`${rowData.id}`} onClick={handleChange} inputProps={{ "aria-label": "uncontrolled-radio" }} />
              </>
            ),
          },
          /* {
            title: "Publikováno",
            field: "is_publishable",
            width: 30,
            customFilterAndSearch: (term, rowData) => {
              console.log(term);
              const realTerm = "ano".indexOf(term) > -1 ? true : "ne".indexOf(term) > -1 ? false : null;
              return realTerm === rowData.is_publishable;
            },
            render: (rowData) => (rowData.is_publishable ? "ano" : "ne"),
          }, */
          {
            title: "Název",
            field: "title",
            render: (rowData) => (
              <>
                <strong>
                  <a href={`/product/${rowData.id}`}>{rowData.title}</a>
                </strong>
                <br />
                <br />
                <a href={`/product/${rowData.id}`}>{rowData.title}</a>
              </>
            ),
          },
          {
            title: "Importováno",
            field: "user",
            render: (rowData) => (
              <>
                <strong>Tomáš H.</strong>
                <br />
                <br />
                Jan P.
              </>
            ),
          },
          {
            title: "Typ",
            field: "type",
            customFilterAndSearch: (term, rowData) => rowData.type.toLowerCase().indexOf(term.toLowerCase()) > -1,
            render: (rowData) => {
              return rowData.type === "audiobook" ? (
                <div className="productType">
                  <HeadsetRoundedIcon />
                  <span>Audiobook</span>
                </div>
              ) : rowData.type === "ebook" ? (
                <div className="productType">
                  <TabletMacRoundedIcon />
                  <span>Ebook</span>
                </div>
              ) : (
                <div className="productType">
                  <MenuBookRoundedIcon />
                  <span>Fyzické zboží</span>
                </div>
              );
            },
          },
          {
            title: "Kolize",
            field: "colisions",
            render: (rowData) => <>title, description</>,
          },
          {
            width: 100,
            title: "",
            field: "action",
            filtering: false,
            render: (rowData) => (
              <Button href={`/deduplication/${rowData.id}`} variant="outlined" color="primary">
                Detail
              </Button>
            ),
          },
        ],
        data: [...response.data],
      };
      console.log();
      setState(newData);
    }
  }, [response, lookupTable]);

  return (
    <Paper className="basePaper">
      <Typography variant="h5">
        <div className="sectionName">Duplikáty &nbsp;</div>
      </Typography>
      <Box>
        {loading && <Loader />}
        {error && (
          <Alert severity="error" style={{ marginTop: 20 }}>
            <AlertTitle>Ajajaj!</AlertTitle>
            Chyba při načítání dat.
          </Alert>
        )}
      </Box>
      {response && (
        <>
          <MaterialTable
            localization={MTLocalization}
            options={{ pageSize: 10, filtering: true, addRowPosition: "first" }}
            style={{ margin: 0, boxShadow: "none" }}
            className="MuiTableContainer"
            title=""
            columns={state.columns}
            data={state.data}
          />
          <Button onClick={() => publish(true)} variant="outlined" color="primary" disabled={items2Delete.length === 0} style={{ marginTop: "-80px" }}>
            Odstranit
          </Button>
        </>
      )}
    </Paper>
  );
};
