import { Box, TextField, Select, FormControl, Grid, InputLabel, MenuItem, NativeSelect } from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import MaterialTable from "material-table";
import MTLocalization from "../utils/MTLocalization";
import React, { useEffect, useState } from "react";
import useFetch from "../hooks/useFetch";
import Loader from "../layout/Loader";
import axios from "../utils/axios";
import { useParams } from "react-router-dom";
import formatDate from "../utils/formatDate";
import formatDate4Input from "../utils/formatDate4Input";
import nullToString from "../utils/nullToString";
import noEmpties from "../utils/noEmpties";
import { Controller, useForm } from "react-hook-form";
import useStyles from "../styles/forms";
import InfoRoundedIcon from "@material-ui/icons/InfoRounded";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import { DELETE, CREATE, UPDATE, AbilityContext, PRICE } from "../permissions";
import { useAbility } from "@casl/react";
import { subject } from "@casl/ability";

export default (props) => {
  let { id } = useParams();
  const classes = useStyles();
  const ability = useAbility(AbilityContext);
  const productId = props.productId ? props.productId : id;
  // const { response, loading, error } = useFetch(`/product/${productId}/price`);
  const [response, setResponse] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const [serverSuccess, setServerSuccess] = useState(null);
  const [serverReply, setServerReply] = useState(false);
  const [prices, setPrices] = useState([]);
  const [refreshTimeStamp, setTimeStamp] = useState(new Date());
  const validTitle = "Platnost je vypočítána automaticky na základě atributů Platnosti a Akce. Dále se jako aktuálně platná cena bere cena později vytvořená nebo později upravená.";

  const myData = {
    currency: "CZK",
    price: "",
    retail_price: "",
    rabat: "",
    vat: "",
    valid_from: "26. 02. 2020",
  };
  const methods = useForm({
    defaultValues: { ...myData, is_publishable: "false" },
    reValidateMode: "onBlur",
  }); // otherwise form conroller doesnt work
  const { trigger, register, control, getValues, errors } = methods;
  /* useEffect(() => {
    console.log(errors);
  }, [errors]); */

  const StringContent = () => (
    <Tippy content={validTitle}>
      <span>
        ano <InfoRoundedIcon style={{ verticalAlign: "middle", color: "#000", fontSize: 16 }} />
      </span>
    </Tippy>
  );

  const JSXContent = () => (
    <Tippy content={validTitle}>
      <span>
        ne <InfoRoundedIcon style={{ verticalAlign: "middle", color: "#999", fontSize: 16 }} />
      </span>
    </Tippy>
  );

  useEffect(() => {
    // if (response && response.status === 200) {
    const getValidPrice = async () => {
      setLoading(true);
      const response = await axios.get(`/product/${productId}/price`);
      console.log(response);
      const responseValid = await axios.get(`/product/${productId}/price/valid`);
      console.log(responseValid);
      console.log(response.data.data);
      console.log(responseValid.data.data);
      let validId = null;
      if (responseValid && responseValid.status === 200) {
        validId = responseValid && responseValid.data && responseValid.data.data && responseValid.data.data.row_id;
      }
      if (response && response.status === 200) {
        // console.log(response.data);
        const stringData = response.data.data.map((item) => nullToString(item));
        // console.log(stringData);
        let newData = {
          columns: [
            {
              title: "Měna",
              field: "currency",
              editComponent: (props) => (
                <>
                  <Controller style={{ display: "none" }} inputRef={register()} control={control} name="row_id" as={TextField} defaultValue={props.rowData.row_id}></Controller>
                  <FormControl className={classes.inputSmall}>
                    <Controller
                      inputRef={register({ required: true })}
                      rules={{ required: true }}
                      error={errors.currency ? true : false}
                      control={control}
                      name="currency"
                      as={
                        <NativeSelect
                          inputProps={{
                            name: "currency",
                            id: "currency",
                          }}
                        >
                          <option value="CZK">CZK</option>
                          <option value="EUR">EUR</option>
                        </NativeSelect>
                      }
                    ></Controller>
                  </FormControl>
                </>
              ),
              width: 120,
            },
            {
              title: "Cena / MOC",
              field: "price",
              render: (rowData) => (
                <span className="smallFont">
                  {rowData.price}
                  <br />
                  {rowData.retail_price}
                </span>
              ),
              editComponent: (props) => {
                return (
                  <>
                    <Controller
                      as={TextField}
                      // className={classes.input}
                      // label="Cena *"
                      defaultValue={props.rowData.price}
                      placeholder="Cena *"
                      name="price"
                      inputRef={register()}
                      rules={{ required: true }}
                      error={errors.price ? true : false}
                      control={control}
                    />
                    <Controller
                      as={TextField}
                      // className={classes.input}
                      defaultValue={props.rowData.retail_price}
                      placeholder="MOC *"
                      name="retail_price"
                      inputRef={register()}
                      rules={{ required: true }}
                      error={errors.retail_price ? true : false}
                      control={control}
                    />
                  </>
                );
              },
              width: 120,
            },
            {
              title: "Rabat / DPH",
              field: "rabat",
              render: (rowData) => (
                <span className="smallFont">
                  {rowData.rabat}
                  <br />
                  {rowData.vat}
                </span>
              ),
              editComponent: (props) => {
                return (
                  <>
                    <Controller
                      as={TextField}
                      // className={classes.input}
                      defaultValue={props.rowData.rabat}
                      placeholder="Rabat *"
                      name="rabat"
                      inputRef={register()}
                      rules={{ required: true }}
                      error={errors.rabat ? true : false}
                      control={control}
                    />
                    <Controller
                      as={TextField}
                      // className={classes.input}
                      defaultValue={props.rowData.vat}
                      placeholder="DPH *"
                      name="vat"
                      inputRef={register()}
                      rules={{ required: true }}
                      error={errors.vat ? true : false}
                      control={control}
                    />
                  </>
                );
              },
            },

            {
              title: "Platnost",
              field: "valid_from",
              render: (rowData) => (
                <span className="smallFont">
                  {formatDate(rowData.valid_from)}
                  <br />
                  {formatDate(rowData.valid_to)}
                </span>
              ),
              editComponent: (props) => (
                <>
                  <Controller
                    as={TextField}
                    // className={classes.inputDate}
                    inputRef={register({})}
                    // rules={{ required: true }}
                    // error={errors.valid_from ? true : false}
                    defaultValue={formatDate4Input(props.rowData.valid_from)}
                    type="date"
                    // label="Platnost od"
                    name="valid_from"
                    control={control}
                  />{" "}
                  <Controller
                    as={TextField}
                    // className={classes.inputDate}
                    inputRef={register({})}
                    // rules={{ required: true }}
                    // error={errors.valid_to ? true : false}
                    defaultValue={formatDate4Input(props.rowData.valid_to)}
                    type="date"
                    // label="Platnost do"
                    name="valid_to"
                    control={control}
                  />
                </>
              ),
            },
            {
              title: "Akce",
              field: "is_special_price",
              width: 80, //lookup: { ...lookupTable }
              editComponent: (props) => (
                <FormControl>
                  <InputLabel htmlFor="is_special_price">Akce</InputLabel>
                  <Controller
                    inputRef={register({ required: true })}
                    defaultValue={props.rowData.is_special_price ? 'true' : 'false'}
                    rules={{ required: true }}
                    error={errors.is_special_price ? true : false}
                    control={control}
                    name="is_special_price"
                    as={
                      <NativeSelect
                        inputProps={{
                          name: "is_special_price",
                          id: "is_special_price",
                        }}
                      >
                        <option value="false">ne</option>
                        <option value="true">ano</option>
                      </NativeSelect>
                    }
                  ></Controller>
                </FormControl>
              ),
              render: (rowData) => {
                return rowData.is_special_price ? "ano" : "ne";
              },
            },
            {
              title: "Aktuálně platná",
              field: "current",
              width: 80,
              editComponent: (props) => <></>,
              render: (rowData) => {
                /* return validId && rowData.row_id === validId ? <Tooltip title={validTitle}>ano</Tooltip> : <Tooltip title={validTitle}>-</Tooltip>; */
                return validId && rowData.row_id === validId ? <StringContent /> : <JSXContent />;
              },
            },
            {
              title: "Externí klíč / patner",
              field: "external_reference_key",
              render: (rowData) => (
                <span className="smallFont">
                  {rowData.external_reference_key}
                  <br />
                  {rowData.partner_external_reference_key}
                </span>
              ),
              editComponent: (props) => {
                return (
                  <>
                    <Controller
                      as={TextField}
                      // className={classes.input}
                      // label="Cena *"
                      defaultValue={props.rowData.external_reference_key}
                      placeholder="Externí klíč"
                      name="external_reference_key"
                      // inputRef={register()}
                      // rules={{ required: true }}
                      error={errors.external_reference_key ? true : false}
                      control={control}
                    />
                    <Controller
                      as={TextField}
                      // className={classes.input}
                      defaultValue={props.rowData.partner_external_reference_key}
                      placeholder="Patner"
                      name="partner_external_reference_key"
                      inputRef={register()}
                      // rules={{ required: true }}
                      // error={errors.partner_external_reference_key ? true : false}
                      control={control}
                    />
                  </>
                );
              },
              width: 120,
            },
          ],
          data: [...stringData],
        };
        setPrices(newData);
        setLoading(false);
      } else {
        setError(response.data.message);
        setLoading(false);
      }
    };
    getValidPrice();
    // }
  }, [refreshTimeStamp]);

  const submitData = async (newData, oldData) => {
    console.log("oldData");
    console.log(oldData);
    let newAuthorItem = oldData === undefined;
    try {
      let newBody = {
        ...newData,
        price: Number(newData.price),
        retail_price: Number(newData.retail_price),
        rabat: Number(newData.rabat),
        vat: Number(newData.vat),
        external_reference_key: newData.external_reference_key === "" ? null : newData.external_reference_key,
        partner_external_reference_key: newData.partner_external_reference_key === "" ? null : newData.partner_external_reference_key,
        valid_from: newData.valid_from || null,
        valid_to: newData.valid_to || null,
      };
      newBody = JSON.stringify(newBody);
      const response = newAuthorItem ? await axios.post(`/product/${productId}/price/`, newBody) : await axios.put(`/product/${productId}/price/${oldData.row_id}`, newBody);
      const responseJson = response.data.data;
      if (response.status === 200 || response.status === 201) {
        // created
        setServerSuccess(true);
        setServerReply("V pořádku uloženo.");
        console.log("Success");
        setTimeStamp(new Date());
        // window.scrollTo({ top: 0, behavior: "smooth" });
        return responseJson.data;
      } else {
        setServerSuccess(false);
        setServerReply("Chyba při ukládání.");
        console.log("Chyba při ukládání.");
        // window.scrollTo({ top: 0, behavior: "smooth" });
        return false;
      }
    } catch (err) {
      console.log("Chyba při ukládání. " + err);
      setServerSuccess(false);
      setServerReply("Chyba při ukládání: " + err.response.data.data.message);
      // window.scrollTo({ top: 0, behavior: "smooth" });
      return false;
    }
  };
  const deleteItem = async (oldData) => {
    console.log("oldData", oldData);
    try {
      const response = await axios.delete(`/product/${productId}/price/${oldData.row_id}`);
      console.log(response);
      if (response.status === 200 || response.status === 204) {
        // created
        setServerSuccess(true);
        setServerReply("Successfully deleted.");
        console.log("Success");
        setPrices((prevState) => {
          const data = [...prevState.data];
          data.splice(data.indexOf(oldData), 1);
          return { ...prevState, data };
        });
        window.scrollTo({ top: 0, behavior: "smooth" });
      } else {
        setServerSuccess(false);
        setServerReply("Error deleting data.");
        console.log("Chyba při mazání.");
        window.scrollTo({ top: 0, behavior: "smooth" });
      }
    } catch (err) {
      console.log("Error deleting data. " + err);
    }
  };
  return (
    <>
      <Box>
        {loading && <Loader />}
        {error && (
          <Alert severity="error" style={{ marginTop: 20 }}>
            <AlertTitle>Ajajaj!</AlertTitle>
            Chyba při načítání dat.
          </Alert>
        )}
      </Box>

      <div className="distributionList">
        {prices && (
          <MaterialTable
            localization={MTLocalization}
            options={{ pageSize: 10, actionsColumnIndex: -1, search: false }}
            style={{ margin: 0, boxShadow: "none", width: "100%", position: "relative", marginTop: -40, zIndex: 5555 }}
            className="MuiTableContainer"
            title=""
            columns={prices.columns}
            data={prices.data}
            icons={{ add: "" }}
            editable={{
              isEditable: rowData => ability.can(UPDATE, subject(PRICE, rowData)),
              isDeletable: rowData => ability.can(DELETE, subject(PRICE, rowData)),
              ...(ability.can(CREATE, PRICE) && {
                onRowAdd: (prices) =>
                  new Promise((resolve, reject) => {
                    const validate = async () => {
                      await trigger();
                      console.log(errors);
                      const formValues = getValues();
                      console.log(formValues);
                      const cleanValues = { ...formValues, is_special_price: formValues.is_special_price === 'true' };
                      console.log(cleanValues);
                      if (Object.keys(errors).length === 0) {
                        let saveResponse = submitData(cleanValues);
                        if (saveResponse) {
                          resolve(saveResponse);
                        } else {
                          reject(error);
                        }
                      } else {
                        reject("Validation errors");
                      }
                    };
                    validate();
                  }),
              }),
              ...(ability.can(UPDATE, PRICE) && {
                onRowUpdate: (prices, oldData) =>
                  new Promise((resolve, reject) => {
                    const validate = async () => {
                      await trigger();
                      console.log(errors);
                      const formValues = getValues();
                      console.log(formValues);
                      const cleanValues = { ...formValues, is_special_price: formValues.is_special_price === "false" ? false : true };
                      console.log(cleanValues);
                      if (Object.keys(errors).length === 0) {
                        let saveResponse = submitData(cleanValues, oldData);
                        if (saveResponse) {
                          resolve(saveResponse);
                        } else {
                          reject(error);
                        }
                      } else {
                        reject("Validation errors");
                      }
                    };
                    validate();
                  }),
              }),
              ...(ability.can(DELETE, PRICE) && {
                onRowDelete: (oldData) =>
                  new Promise((resolve, reject) => {
                    let deleteResponse = deleteItem(oldData);
                    if (deleteResponse) {
                      resolve(deleteResponse);
                    } else {
                      reject(error);
                    }
                  }),
              }),
            }}
          />
        )}
      </div>
    </>
  );
};
