import React, { useState } from 'react';
import { Autocomplete } from '@material-ui/lab';
import { makeStyles, TextField } from '@material-ui/core';
import axios from '../utils/axios';

const useStyles = makeStyles({
  autocomplete: {
    position: 'relative',
  },
  errorText: {
    color: '#f44336',
    position: 'absolute',
    bottom: 'calc(-0.75rem - 6px)',
    left: '0px',
    fontSize: '0.75rem',
  },
});

const AutocompleteSearch = ({
  label,
  baseUrl,
  searchBy,
  searchQuery,
  getOptionLabel,
  alreadySelected,
  identityAttribute = 'id',
  inputClassName = '',
  errorMessage = '',
  disabled = false,

  value,
  onValueChange,
  onError,
  error,
}) => {
  const classes = useStyles();
  const [searchDelayTimer, setSearchDelayTimer] = useState(null);
  const [autocompleteLoading, setAutocompleteLoading] = useState(false);
  const [autocompleteOptions, setAutocompleteOptions] = useState([]);
  const [optionsLoaded, setOptionsLoaded] = useState(false);

  const handleInputChange = (event, value, reason) => {
    if (reason !== 'reset') {
      handleSearch(value);
    }
  };

  const handleSearch = (value) => {
    const doFetch = async () => {
      try {
        const query = {
          limit: 50,
          ...(value && { [searchBy]: value }),
          ...searchQuery,
        };
        const result = await axios(baseUrl, { params: query });
        setAutocompleteOptions(result.data.data);
      } catch (e) {
        onError(e);
      } finally {
        setAutocompleteLoading(false);
        setOptionsLoaded(true);
      }
    };

    setAutocompleteLoading(true);
    setAutocompleteOptions([]);

    clearTimeout(searchDelayTimer);
    setSearchDelayTimer(
      setTimeout(() => {
        doFetch();
      }, 500)
    );
  };

  const handleValueChange = (_, v) => {
    onValueChange(v);
  };

  const handleAutocompleteOpen = () => {
    if (!optionsLoaded) {
      handleSearch(value ? value[searchBy] : null);
    }
  }

  const errorClass = error ? 'Mui-error' : '';
  return (
    <Autocomplete
      className={`${errorClass} ${classes.autocomplete}`}
      loading={autocompleteLoading}
      loadingText={'Vyhledávání...'}
      onInputChange={handleInputChange}
      value={value}
      onChange={handleValueChange}
      onOpen={handleAutocompleteOpen}
      options={autocompleteOptions}
      getOptionLabel={getOptionLabel}
      getOptionDisabled={(option) => alreadySelected.some((i) => i[identityAttribute] === option[identityAttribute])}
      getOptionSelected={(option, value) => option[identityAttribute] === value[identityAttribute]}
      noOptionsText={'Nenalezeno'}
      disabled={disabled}
      renderInput={(params) => (
        <>
          <TextField
            {...params}
            className={inputClassName}
            label={label}
          />
          {error && errorMessage && (
            <span className={classes.errorText}>{errorMessage}</span>
          )}
        </>
      )}
    />
  );
};

export default AutocompleteSearch;
