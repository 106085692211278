import { makeStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import AutocompleteSearch from '../../components/AutocompleteSearch';

const useStyles = makeStyles({
  container: {
    display: 'inline-block',
    maxWidth: 'calc(50% - 40px)',
    minWidth: 'calc(50% - 40px)',
    marginTop: '16px',
    marginRight: '40px',
    marginBottom: '0',
  },
});

const AutocompleteField = ({
  value,
  onValueChange,
  onError,
  baseUrl,
  label,
  searchBy,
  searchQuery,
  error,
  className = undefined,
}) => {
  const classes = useStyles();

  const [autocompleteValue, setAutocompleteValue] = useState(null);

  useEffect(() => {
    setAutocompleteValue(value || null)
  }, [value]);

  useEffect(() => {
    onValueChange(autocompleteValue);
  }, [autocompleteValue]);

  const handleAutocompleteValueChange = (newValue) => {
    setAutocompleteValue(newValue);
  };

  const handleError = (e) => onError(e);

  return (
    <div className={className || classes.container}>
      <AutocompleteSearch
        label={label}
        baseUrl={baseUrl}
        searchBy={searchBy}
        searchQuery={searchQuery}
        alreadySelected={[]}
        getOptionLabel={(option) => option[searchBy]}
        value={autocompleteValue}
        onValueChange={handleAutocompleteValueChange}
        onError={handleError}
        error={error}
      />
    </div>
  );
};

export default AutocompleteField;
