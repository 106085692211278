import React from 'react';
import { Button, FormControl, makeStyles, TextField } from '@material-ui/core';
import { Controller, useForm } from 'react-hook-form';
import Loader from '../layout/Loader';
import useStyles from '../styles/forms';
import { Autocomplete } from '@material-ui/lab';

const useStylesLocal = makeStyles({
  horizontal: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  modal: {
    top: '50%',
    left: '50%',
    transform: `translate(-50%, -50%)`,
  },
  actions: {
    marginTop: '40px',
  },
  loader: {
    width: '50px',
    height: '50px',
    margin: '0px',
    marginLeft: '10px',
  },
});

export const TreeBranchForm = React.forwardRef(({
  title,
  confirmationButtonText,
  defaultValues,
  serverLoading,
  onConfirm,
  availableBranches,
}, ref) => {
  const classesLocal = useStylesLocal();
  const classes = useStyles();

  const methods = useForm({
    reValidateMode: 'onBlur',
    defaultValues: {
      ...defaultValues,
      parent_id: availableBranches.find(item => item.id === defaultValues.parent_id) || null,
    },
  }); // otherwise form conroller doesnt work

  const { register, control, reset, handleSubmit, errors } = methods;

  const handleDataSumit = (data, e) => {
    e.preventDefault();
    onConfirm({
      ...data,
      parent_id: data.parent_id ? data.parent_id.id : null,
    });
  };

  return (
    <div className={[classes.modalWindow, classesLocal.modal].join(' ')}>
      <h2 id="simple-modal-title">{title}</h2>
      <form onSubmit={handleSubmit(handleDataSumit)}>
        <Controller 
          as={TextField}
          className={classes.inputFull}
          inputRef={register({})}
          label="Název"
          name="title"
          control={control}
          rules={{
            required: 'Pole je povinné',
          }}
          error={!!errors.title}
          helperText={errors.title?.message}
        />
        <Controller 
          as={TextField}
          className={classes.inputFull}
          inputRef={register({})}
          label="Strom"
          name="tree_key"
          control={control}
          disabled
        />
        <Controller 
          as={TextField}
          className={classes.inputFull}
          inputRef={register({})}
          label="Externí klíč"
          name="external_reference_key"
          control={control}
          disabled={defaultValues.external_reference_key !== undefined}
        />
        <Controller 
          as={TextField}
          className={classes.inputFull}
          inputRef={register({})}
          label="Pořadí v rámci úrovně"
          name="level_order"
          control={control}
          type="number"
        />

        <FormControl className={classes.inputFull}>
          <Controller
            render={({ onChange, ...props }) => (
              <Autocomplete
                options={availableBranches}
                getOptionLabel={option => `${option.path || option.title}`}
                getOptionDisabled={option => option.id === defaultValues.id}
                renderInput={(params) => (
                  <TextField {...params} label="Rodičovská kategorie" variant="standard" />
                )}
                onChange={(e, data) => onChange(data)}
                {...props}
              />
            )}
            onChange={([, data]) => data}
            name="parent_id"
            control={control}
          />
        </FormControl>

        <div className={[classesLocal.horizontal, classesLocal.actions].join(' ')}>
          <Button size="large" variant="contained" color="primary" type="submit" disabled={serverLoading}>
            {confirmationButtonText}
          </Button>
          {serverLoading && (
            <Loader className={classesLocal.loader} />
          )}
        </div>
      </form>
    </div>
  );
});
