import { Box, Button, Card, CardActions, CardContent, Grid, makeStyles, Paper, Typography } from "@material-ui/core";
import React from "react";
import ShopTwoRoundedIcon from "@material-ui/icons/ShopTwoRounded";
import ShopRoundedIcon from "@material-ui/icons/ShopRounded";
import StyleRoundedIcon from "@material-ui/icons/StyleRounded";
import AccountTreeRoundedIcon from "@material-ui/icons/AccountTreeRounded";
import FaceRoundedIcon from "@material-ui/icons/FaceRounded";
import ApartmentRoundedIcon from "@material-ui/icons/ApartmentRounded";
import DynamicFeedRoundedIcon from "@material-ui/icons/DynamicFeedRounded";
import Filter9PlusRoundedIcon from "@material-ui/icons/Filter9PlusRounded";
import BookmarksRoundedIcon from "@material-ui/icons/BookmarksRounded";
import LocalActivityRoundedIcon from "@material-ui/icons/LocalActivityRounded";
import LanguageRoundedIcon from "@material-ui/icons/LanguageRounded";
import { Can, READ, AbilityContext, BUNDLE, PRODUCT, AUTHOR, PROJECT, PUBLISHER, SERIE, GENRE, EDITION, CATEGORY, MULTITREE, PRODUCT_TYPE } from "../permissions";
import { useAbility } from "@casl/react";

const useStyles = makeStyles((theme) => ({
  button: {
    marginTop: 0,
  },
}));

const getDashboardData = (ability) => [
  ...(ability.can(READ, BUNDLE) ? [{
    name: "Produktové svazky",
    description: "Lorem ipsum fenal bullet abraka a well meaning and kindly.",
    url: "/product-bundles",
    icon: <ShopTwoRoundedIcon className="greyIcon" />,
  }] : []),
  ...(ability.can(READ, PRODUCT) ? [{
    name: "Produkty",
    description: "Lorem ipsum fenal bullet abraka a well meaning and kindly.",
    url: "/products",
    icon: <ShopRoundedIcon className="greyIcon" />,
  }] : []),
  ...(ability.can(READ, PRODUCT_TYPE) ? [{
    name: "Produktové typy",
    description: "Lorem ipsum fenal bullet abraka a well meaning and kindly.",
    url: "/products-types",
    icon: <StyleRoundedIcon className="greyIcon" />,
  }] : []),
  ...(ability.can(READ, PROJECT) ? [{
    name: "Projekty",
    description: "Lorem ipsum fenal bullet abraka a well meaning and kindly.",
    url: "/projects",
    icon: <AccountTreeRoundedIcon className="greyIcon" />,
  }] : []),
  ...(ability.can(READ, AUTHOR) ? [{
    name: "Autoři",
    description: "Lorem ipsum fenal bullet abraka a well meaning and kindly.",
    url: "/authors",
    icon: <FaceRoundedIcon className="greyIcon" />,
  }] : []),
  ...(ability.can(READ, PUBLISHER) ? [{
    name: "Vydavatelé / Značky",
    description: "Lorem ipsum fenal bullet abraka a well meaning and kindly.",
    url: "/publishers",
    icon: <ApartmentRoundedIcon className="greyIcon" />,
  }] : []),
];
const getDashboardData2 = (ability) => [
  ...(ability.can(READ, SERIE) ? [{
    name: "Série",
    description: "Lorem ipsum fenal bullet abraka a well meaning and kindly.",
    url: "/series",
    icon: <DynamicFeedRoundedIcon className="greyIcon" />,
  }] : []),
  ...(ability.can(READ, EDITION) ? [{
    name: "Edice",
    description: "Lorem ipsum fenal bullet abraka a well meaning and kindly.",
    url: "/editions",
    icon: <Filter9PlusRoundedIcon className="greyIcon" />,
  }] : []),
  ...(ability.can(READ, GENRE) ? [{
    name: "Žánry",
    description: "Lorem ipsum fenal bullet abraka a well meaning and kindly.",
    url: "/genres",
    icon: <LocalActivityRoundedIcon className="greyIcon" />,
  }] : []),
  ...(ability.can(READ, CATEGORY) ? [{
    name: "Kategorie",
    description: "Lorem ipsum fenal bullet abraka a well meaning and kindly.",
    url: "/categories",
    icon: <BookmarksRoundedIcon className="greyIcon" />,
  }] : []),
];

export default (props) => {
  const classes = useStyles();
  const ability = useAbility(AbilityContext);
  const dashboardData = getDashboardData(ability);
  const dashboardData2 = getDashboardData2(ability);
  return (
    <Paper className="basePaper">
      <Typography variant="h5">Správa dat</Typography>
      <br />
      <Grid container spacing={3}>
        {dashboardData &&
          dashboardData.map((item, index) => (
            <Grid key={index} item xs={12} sm={6} md={3}>
              <Card className="card" variant="outlined">
                <CardContent>
                  <Box mt={1} mb={2} className={classes.icon}>
                    {item.icon}
                  </Box>
                  <Typography variant="h6" className={classes.title} color="textSecondary" gutterBottom>
                    {item.name}
                  </Typography>
                  {/*                   <Typography variant="body2" component="p">
                    {item.description}
                  </Typography> */}
                </CardContent>
                <CardActions>
                  <Button variant="outlined" color="primary" href={item.url} size="small" className={classes.button}>
                    Otevřít
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          ))}
      </Grid>
      <br />
      {(ability.can(READ, SERIE) || ability.can(READ, EDITION) || ability.can(READ, GENRE) || ability.can(READ, CATEGORY)) &&
      <>
        <Typography variant="h5">AM Kategorie</Typography>
        <br />
        <Grid container spacing={3}>
          {dashboardData2 &&
            dashboardData2.map((item, index) => (
              <Grid key={index} item xs={12} sm={6} md={3}>
                <Card className="card" variant="outlined">
                  <CardContent>
                    <Box mt={1} mb={2} className={classes.icon}>
                      {item.icon}
                    </Box>
                    <Typography variant="h6" className={classes.title} color="textSecondary" gutterBottom>
                      {item.name}
                    </Typography>
                    {/*                   <Typography variant="body2" component="p">
                      {item.description}
                    </Typography> */}
                  </CardContent>
                  <CardActions>
                    <Button variant="outlined" color="primary" href={item.url} size="small" className={classes.button}>
                      Otevřít
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            ))}
        </Grid>
      </>}
      <br />
      {ability.can(READ, MULTITREE) && 
      <>
        <Typography variant="h6">Jiné</Typography>
        <br />
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={3}>
            <Can I={READ} a={MULTITREE}>
              {() => <Card className="card" variant="outlined">
                <CardContent>
                  <Box mt={1} mb={2} className={classes.icon}>
                    <LanguageRoundedIcon className="greyIcon" />
                  </Box>
                  <Typography variant="h6" className={classes.title} color="textSecondary" gutterBottom>
                    Stromy kategorií
                  </Typography>
                  {/*               <Typography variant="body2" component="p">
                    Lorem ipsum fenal bullet abraka a well meaning and kindly.
                  </Typography> */}
                </CardContent>
                <CardActions>
                  <Button variant="outlined" color="primary" href="/multitree/" size="small" className={classes.button}>
                    Otevřít
                  </Button>
                </CardActions>
              </Card>}
            </Can>
          </Grid>
        </Grid>
      </>}
      <br />
    </Paper>
  );
};
