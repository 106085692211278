import React, { useState } from 'react';
import { Button, InputLabel, CircularProgress } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import FileDownload from 'js-file-download';

import axios from '../utils/axios';
import ImportModuleResult from './ImportModuleResult';

const ImportValidation = () => {
  const [serverSuccess, setServerSuccess] = useState(null);
  const [validationSuccess, setValidationSuccess] = useState(null);
  const [validationResult, setValidationResult] = useState(null);
  const [serverReply, setServerReply] = useState(null);
  const [loading, setLoading] = useState(false);

  const { handleSubmit, register, control } = useForm({});

  const handleFileUpload = (data) => {
    const submitData = async () => {
      try {
        const formData = new FormData();
        formData.append('file', data.file[0]);
        const response = await axios.post(`/import/validity`, formData, { headers: { 'Content-Type': 'multipart/form-data' } });

        const responseData = response.data.data;
        setServerSuccess(true);
        setValidationResult(responseData);

        if (responseData.error_total === 0) {
          setValidationSuccess(true);
        } else {
          setValidationSuccess(false);
        }
      } catch (e) {
        setServerSuccess(false);
        setServerReply(e.response?.data?.message);
      } finally {
        setLoading(false)
      }
    };
    setLoading(true)
    submitData();
  };

  const downloadExample = () => {
    axios.get('import/validity/example')
      .then((response) => {
        FileDownload(response.data, 'data.csv');
      });
  }

  return (
    <>
      <form onSubmit={handleSubmit(handleFileUpload)}>
        <InputLabel id="file">Soubor</InputLabel>
        <input ref={register({})} id="file" name="file" type="file" accept=".csv" />
        <Button type="submit" size="large" variant="contained" color="primary" disabled={loading}>
          Zkontrolovat
        </Button>
      </form>
      <Button type="button" color="primary" onClick={downloadExample}>
        Stáhnout příklad CSV souboru
      </Button>
      {loading && (
        <CircularProgress color="primary" style={{ display: 'block' }} />
      )}
      {!loading && (
        <ImportModuleResult
          serverSuccess={serverSuccess}
          showSuccessfull={validationSuccess === true && validationResult && validationResult.ok_total > 0}
          showErrors={validationSuccess === false && validationResult && validationResult.error_total > 0}
          validationResult={validationResult}
          serverErrorMessage={serverReply}
        />
      )}

    </>
  );
};

export default ImportValidation;
