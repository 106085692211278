import { Button, Grid, Paper, TextField, Typography } from "@material-ui/core";
import { Face, Fingerprint } from "@material-ui/icons";
import { Alert } from "@material-ui/lab";
import React, { useState } from "react";
import { useForm } from "react-hook-form";

export default (props) => {
  const [username, setUser] = useState("");
  const [password, setPass] = useState("");

  const [serverSuccess, setServerSuccess] = useState(null);
  const [serverReply, setServerReply] = useState(false);
  const methods = useForm({ defaultValues: {} });
  const { handleSubmit } = methods;
  const onSubmit = (data) => {
    // const sendAuthRequest = (params) => {
    const sendit = async () => {
      try {
        fetch("/api/auth", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ username, password }),
        })
          .then((resp) => resp.json())
          .then((response) => {
            if (response.status === 200 || response.status === 201) {
              setServerSuccess(true);
              setServerReply("Paráda! Přesměrovávám....");
              console.log("Success");
              localStorage.setItem("token", response.data.access_token);
              const lastUrl = localStorage.getItem("lastUrl") ? localStorage.getItem("lastUrl") : "/";
              window.location.href = lastUrl;
              console.log(response);
            } else {
              setServerSuccess(false);
              setServerReply("Jméno či heslo nesedí. :(");
              console.log("Incorrect user name or password");
              console.log(response);
              // Here you should have logic to handle invalid login credentials.
            }
          });
      } catch (err) {
        console.log("Incorrect user name or password " + err);
      }
    };
    sendit();
  };

  return (
    <Paper className="basePaper">
      <Typography variant="h5">Přihlášení</Typography>
      {serverReply && (
        <>
          <br />
          <Alert severity={serverSuccess ? "success" : "error"}>{serverReply}</Alert>
          <br />
        </>
      )}
      <br />
      <form onSubmit={handleSubmit(onSubmit)} name="loginForm">
        <Grid container spacing={3} style={{ padding: "0 16px" }}>
          <Grid container spacing={3} alignItems="flex-end">
            <Grid item style={{ width: 50 }}>
              <Face />
            </Grid>
            <Grid item md={true} sm={true} xs={true}>
              <TextField
                id="user"
                name="username"
                label="Uživatelské jméno"
                type="text"
                value={username}
                onChange={(event) => setUser(event.target.value)}
                fullWidth
                autoFocus
                required
              />
            </Grid>
          </Grid>
          <Grid container spacing={3} alignItems="flex-end">
            <Grid item style={{ width: 50 }}>
              <Fingerprint />
            </Grid>
            <Grid item md={true} sm={true} xs={true} pl={0}>
              <TextField id="password" name="password" label="Heslo" type="password" value={password} onChange={(event) => setPass(event.target.value)} fullWidth required />
            </Grid>
          </Grid>
          <br />
          <Grid container justify="center" style={{ marginTop: "10px" }}>
            <Button type="submit" id="submit" variant="outlined" color="primary" style={{ textTransform: "none" }}>
              Přihlásit
            </Button>
          </Grid>
        </Grid>
      </form>
      <br />
    </Paper>
  );
};
